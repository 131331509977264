import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env';
import { _getEnv, _isDev, _log } from '@shared/aux_helper_environment';
import { _debounceDecorator, _get, _ms } from '@shared/aux_helper_functions';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';

const _global = window as any;
const STATUS_CONNECTION_CONFIG = _getEnv('statusConnectionConfig');
const BASEURL = environment.apiBaseUrl_identity;

@Injectable({
  providedIn: 'root',
})
export class StatusConecctionService {
  private initied = false;
  private interval = null;

  public connectionOk = true;
  public onLine = true;
  public conectionSpeed: number = null;
  public conectionSpeedSTR: String = null;

  testNetworkSpeed = null;

  constructor(public _snackBar: MatSnackBar, private translate: IanTranslateService) {}

  public initStatusConecction() {
    if (this.initied) return;
    if (STATUS_CONNECTION_CONFIG == null || !STATUS_CONNECTION_CONFIG.baseUrlAsset) return;
    if (!STATUS_CONNECTION_CONFIG.checkConnection || !STATUS_CONNECTION_CONFIG.checkInterval) return;
    if (
      STATUS_CONNECTION_CONFIG.checkConnectionOnLocal !== true &&
      window &&
      window.location &&
      window.location.href.indexOf('localhost') !== -1
    ) {
      return;
    }

    this.startCheckConnection();

    if (window) {
      (window as any).__checkConnection = () => {
        this.checkConnection();
      };
    }
  }

  startCheckConnection() {
    if (this.initied) return;
    if (this.interval) return;
    this.initied = true;

    this.interval = setInterval(() => {
      this.checkConnection();
    }, STATUS_CONNECTION_CONFIG.checkInterval || _ms('3m'));

    if (window) {
      window.addEventListener('online', () => this.checkConnection());
      window.addEventListener('offline', () => this.checkConnection());
    }
  }

  @_debounceDecorator(256)
  private async checkConnection() {
    if (true) _log('[checkConnection]', STATUS_CONNECTION_CONFIG);

    if (window && window.navigator && !window.navigator.onLine) {
      this.noConnection();
      return;
    }

    if (false) this.detectSepped();
  }

  private async detectSepped() {
    const baseUrlAsset = STATUS_CONNECTION_CONFIG.baseUrlAsset.replace('{{BASE_URL}}/', BASEURL).replace('{{BASE_URL}}', BASEURL);
    const _self = this;
    _aux_GgetSeppedDetector().startSpeedCheck(baseUrlAsset, function callback(timings) {
      if (_isDev() && true) _log('\n\n[checkConnection]', { timings });
      if (timings) _self.evalConnection(Number(Math.min(timings.throughput, 9999000)), timings);
    });
  }

  public delegateError() {
    if (!this.initied) return false;
    if (STATUS_CONNECTION_CONFIG.avoidErrorsOnPoorConnection !== true) return false;

    //Hubo un error, no hay conexión y no está la alerta prendida
    if (!this.connectionOk && !document.querySelector('.mat-simple-snackbar')) {
      if (this.conectionSpeed === 0) {
        this.alert('NO_CONNECTION');
      } else {
        this.alert('BAD_CONNECTION');
      }
    }

    return !this.connectionOk;
  }

  private alert(msg) {
    if (STATUS_CONNECTION_CONFIG.snackOnFail) {
      this._snackBar.open(
        this.translate.instant('STATUS_CONNECTION.' + msg, null, ''),
        this.translate.instant('STATUS_CONNECTION.ACCEPT', null, ''),
        {
          duration: STATUS_CONNECTION_CONFIG.snackTime || 0,
        }
      );
    }
    let data = _get(_global, '_info._statusConecction', null);
    if (true) console.warn('\n\n[StatusConecctionService] ' + msg, data, '\n\n');
  }

  private evalConnection(speed, timings?) {
    const goodConnectionThresholdKbps = STATUS_CONNECTION_CONFIG.goodConnectionThresholdKbps || 50 * 1000;
    _log('[StatusConecctionService Speed:]', { speed, goodConnectionThresholdKbps });

    if (timings) this.conectionSpeedSTR = _get(timings, 'throughPutSpeedClass.name');

    if (speed > goodConnectionThresholdKbps) return this.goodConnection(speed);
    this.badConnection(speed);
  }

  private goodConnection($speed) {
    if (false) _log('[StatusConecctionService] goodConnection');
    this.connectionOk = true;
    this.onLine = true;
    this.conectionSpeed = $speed;
    this.setGlobalInfo();

    //Si tiene un snack prendido lo apaga
    let alertButton: any = document.querySelector('.mat-simple-snackbar-action button');
    if (alertButton && alertButton.click) alertButton.click();
  }

  private badConnection($speed) {
    if ($speed === 0) return this.noConnection();
    this.connectionOk = false;
    this.onLine = true;
    this.conectionSpeed = $speed;
    this.setGlobalInfo();
    this.alert('BAD_CONNECTION');
  }

  private noConnection() {
    this.connectionOk = false;
    this.onLine = false;
    this.conectionSpeed = 0;
    this.conectionSpeedSTR = 'OFFLINE';
    this.setGlobalInfo();
    this.alert('NO_CONNECTION');
  }

  private setGlobalInfo() {
    if (!_global || !_global._info) return;
    _global._info._statusConecction = _global._info._statusConecction || {};
    _global._info._statusConecction.conectionOk = this.connectionOk;
    _global._info._statusConecction.onLine = this.onLine;
    _global._info._statusConecction.conectionSpeed = this.conectionSpeed;
    _global._info._statusConecction.conectionSpeedSTR = this.conectionSpeedSTR;
  }
}

const _speedClasses = [
  {
    name: 'OFFLINE',
    latency: Number.POSITIVE_INFINITY,
    throughput: 0,
  },
  {
    name: '2G',
    latency: 300,
    throughput: 80,
  },
  {
    name: '3G',
    latency: 200,
    throughput: 200,
  },
  {
    name: 'WIFI',
    latency: 100,
    throughput: 750,
  },
];

const _aux_GgetSeppedDetector = () => {
  // https://github.com/ashanbh/detectClientSpeed
  const detectSpeed: any = {};

  for (let s = 0; s < _speedClasses.length; s++) {
    detectSpeed['SPEED_' + _speedClasses[s].name] = _speedClasses[s];
  }

  detectSpeed.startSpeedCheck = ($earl: string = 'assets/isotipo_prisma.png', callback: Function) => {
    const earl = $earl + (/\?/.test($earl) ? '&' : '?') + 'cacheBuster=' + Date.now();

    const _timings: any = {};

    const _progress = e => {
      if (_timings.firstByte) return;
      _timings.firstByte = Date.now();
      oReq.removeEventListener('progress', _progress, false);
    };

    const _done = data => {
      let size = data.target.response.length;

      _timings.url = earl;
      _timings.dataSizeKB = size / 1000;
      _timings.end = Date.now();
      _timings.latency = _timings.firstByte - _timings.start;
      _timings.timeDownload = _timings.end - _timings.firstByte;
      _timings.throughput = Math.round((size / _timings.timeDownload) * 100) / 100; //in KBPS

      for (let s = 0; s < _speedClasses.length; s++) {
        if (_timings.throughput > _speedClasses[s].throughput) {
          _timings.throughPutSpeedClass = _speedClasses[s];
        }
        if (_timings.latency < _speedClasses[s].latency) {
          _timings.latencySpeedClass = _speedClasses[s];
        }
      }

      callback && callback(_timings);
    };

    const oReq = new XMLHttpRequest();
    oReq.addEventListener('progress', _progress, false);
    oReq.onload = _done;
    oReq.open('GET', earl);
    oReq.setRequestHeader('ngsw-bypass', 'true');
    oReq.send();

    _timings.start = Date.now();
  };

  return detectSpeed;
};
