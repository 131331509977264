import { Component, Input } from '@angular/core';

@Component({
  selector: 'ai-icon',
  template: `
    <ng-container *ngIf="true">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="white"
        viewBox="0 0 16 16"
        aria-hidden="true"
        focusable="false"
        class=""
        [attr.style]="svgStyle"
      >
        <path
          fill="#007bff"
          d="M3.848 10.627 4 9.75l.152.877a1.5 1.5 0 0 0 1.221 1.22L6.25 12l-.877.152a1.5 1.5 0 0 0-1.22 1.221L4 14.25l-.152-.877a1.5 1.5 0 0 0-1.221-1.22L1.75 12l.877-.152a1.5 1.5 0 0 0 1.22-1.221"
        ></path>
        <path
          fill="#007bff"
          fill-rule="evenodd"
          d="M4 9a.75.75 0 0 1 .739.621l.152.877a.75.75 0 0 0 .61.61l.878.153a.75.75 0 0 1 0 1.478l-.877.152a.75.75 0 0 0-.61.61l-.153.878a.75.75 0 0 1-1.478 0l-.152-.877a.75.75 0 0 0-.61-.61l-.878-.153a.75.75 0 0 1 0-1.478l.877-.152a.75.75 0 0 0 .61-.61l.153-.878A.75.75 0 0 1 4 9m0 2.92-.08.08q.042.039.08.08.038-.042.08-.08zM10 0c.36 0 .67.257.737.611l.264 1.398A3.75 3.75 0 0 0 13.99 5l1.398.264a.75.75 0 0 1 0 1.474l-1.398.264A3.75 3.75 0 0 0 11 9.99l-.264 1.398a.75.75 0 0 1-1.474 0l-.264-1.398A3.75 3.75 0 0 0 6.01 7l-1.398-.264a.75.75 0 0 1 0-1.474l1.398-.264A3.75 3.75 0 0 0 9 2.01L9.263.611A.75.75 0 0 1 10 0m0 3.682A5.26 5.26 0 0 1 7.682 6 5.26 5.26 0 0 1 10 8.318 5.26 5.26 0 0 1 12.318 6 5.26 5.26 0 0 1 10 3.682"
          clip-rule="evenodd"
        ></path>
      </svg>
    </ng-container>
    <ng-container *ngIf="false">
      <svg [attr.style]="svgStyle" width="150" height="150" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
        <rect width="150" height="150" fill="transparent" />
        <polygon points="50,10 100,10 140,50 140,100 100,140 50,140 10,100 10,50" fill="#007bff" />
        <text
          x="48%"
          y="52%"
          fill="white"
          font-size="70"
          font-family="Arial, sans-serif"
          font-weight="bold"
          text-anchor="middle"
          dominant-baseline="middle"
        >
          AI
        </text>
      </svg></ng-container
    >
  `,
})
export class AiIconComponent {
  @Input() svgStyle: any;
}
