import { Pipe, PipeTransform } from '@angular/core';
import { PlanogramsPopMaterial } from './generic-promotional-spaces.component';
import { _log } from '@shared/aux_helper_environment';

@Pipe({
  name: 'selectedPlanogramPopMaterial',
})
export class SelectedPlanogramPopMaterialPipe implements PipeTransform {
  transform(planogramPopMaterialList: PlanogramsPopMaterial[], selectedGroupIds: number[], isTooltip = false): string {
    if (!selectedGroupIds?.length || !planogramPopMaterialList?.length) return '';

    let rv = planogramPopMaterialList
      .filter(item => item.selectedGroupIds?.some(groupId => selectedGroupIds.includes(groupId)))
      .map(item => item.fullName)
      .join(', ');

    if (!isTooltip && rv && rv.length > 52) {
      return rv.substring(0, 52) + '...';
    }
    return rv;
  }
}
