<div class="spinner-container spinner-container-custom" style="min-height: 100px; margin-top: -50px" *ngIf="isLoading">
  <custom-loader></custom-loader>
</div>

<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="!showOnlySelected">
    <generic-lookup
      [values]="planogramPopMaterialList"
      [selected]="selectedItemsInteralIds"
      (onChange)="onSelectedChanges($event)"
      [multiple]="true"
      [itemSize]="4"
      [propId]="'_internalId'"
      [propValue]="'fullName'"
      [placeholder]="('PROMOV2.ACTIONS_EDIT.MAIN_DATA.PROMOTIONAL_MATERIAL' | pTranslate)"
      [alwaysShowToolTipValues]="false"
      [required]="required"
      [panelClassCustom]="'bigPromotionalSpace'"
      [disabled]="disabled || planogramPopMaterialList == null"
      [panelClassCustom]="showFullModalWidth ? 'bigPanel' : ''"
    ></generic-lookup>
    <ng-container *ngIf="showPlanogramsWarning">
      <div class="dateErrorWarn p-absolute">
        <mat-icon class="warning-icon" style="vertical-align: text-top; font-size: 18px">warning</mat-icon>
        {{planogramWarningMessage}}
      </div>
    </ng-container>
    <ng-container *ngIf="deletedPlanogramsOnChange?.length > 0">
      <div class="dateErrorWarn p-absolute">
        <mat-icon class="warning-icon" style="vertical-align: text-top; font-size: 18px">warning</mat-icon>
        {{'PROMOV2.ACTIONS_EDIT.MAIN_DATA.PLANOGRAMS_DELETED' | pTranslate:deletedPlanogramsOnChange.length}}.
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="showOnlySelected">
    <span [matTooltip]="planogramPopMaterialList | selectedPlanogramPopMaterial: planogramPopMaterialIds:true">
      {{planogramPopMaterialList | selectedPlanogramPopMaterial: planogramPopMaterialIds}}
    </span>
  </ng-container>
</ng-container>
