import { CheckBoxData } from '@prisma/components/check-box/check-box-v2/model/checkBoxModel';
import { PayloadToSendTags } from '@prisma/components/dinamic-tags/model/dinamicTagsModel';
import { PromotionActionTypeV2 } from '@prisma/components/generic-select-promotion-types/services/generic-select-promotion-types.service';
import { PagedList } from 'core/models/paged-list.model';
import { ActionsV2_StatusEnum, ActionsV2_ValidationStatus } from '../../actions-edit/store/actionsV2-FormStateModel';

export interface ActionsV2ListStateModel {
  list: PagedList<ActionsV2RowModel>;
  filtersList: FilterActionsV2Model;
  listenToTaskCompletion: boolean;
  checkBoxData: CheckBoxData;
}

export interface FilterActionsV2Model {
  promotionTypes: Array<PromotionActionTypeV2>;
  promotionTypeIds: Array<number>;
  status: Array<number>; //[1, 5];
  isDeleted?: boolean;
  isSuggested: boolean; //DES-4759
  alreadyToPublish: Array<AlreadyToPublishType>; // ['Enable', 'Disable'];
  campaignsId: Array<number>;
  planogramPopMaterialIds: Array<number>;
  query: string;
  pageIndex: number;
  pageSize: number;
  itemTags: Array<PayloadToSendTags>;
  storeTags: Array<PayloadToSendTags>;
  categories: Array<number>;
  subCategories: Array<number>;
  promotionAssortmentMethod?: Array<number>; //[0, 1];
  customPromotionsOfStoreIds?: Array<number>; //[];
  promotionCreatedOnFrom?: Date; //'2021-06-01T00:00:00+00:00';
  promotionCreatedOnTo?: Date; //'2022-06-01T00:00:00+00:00';
  //
  promotionYear: string | number;
  promotionPeriodId: string[] | number[]; //DES-4663
  validationStatus: ActionsV2_ValidationStatus[]; //DES-4663
  withoutPOP: boolean;
}

export interface ActionsV2RowModel {
  id: number;
  name: string;
  code: string;
  status: ActionsV2_StatusEnum;
  type: PromotionActionTypeV2;
  assortmentMethod: PromotionActionAssortmentMethodType;
  discountType: PromotionActionDiscountType;
  frecuency: PromotionActionFrequencyType;
  validFrom: string;
  validTo: string;
  scopeStore: number;
  publishEnable: boolean;
  promotionTypeId: number;
  // sin usar
  updatedBy?: string;
  updatedOn?: string;
  promotionPeriodName: string;
  promotionPeriodCode: string;
  externalCode?: string;
}

export interface ActionsV2ListModel {
  id: number;
  name: string;
  code: string;
  status: ActionsV2_StatusEnum;
  type: PromotionActionTypeV2;
  assortmentMethod: PromotionActionAssortmentMethodType;
  discountType: PromotionActionDiscountType;
  frecuency: PromotionActionFrequencyType;
  validFrom: string;
  validTo: string;
  scopeStore: number;
  publishEnable: boolean;
  promotionTypeId: number;
  // sin usar
  updatedBy?: string;
  updatedOn?: string;
  promotionPeriod: PromotionPeriod;
  externalCode?: string;
  withoutPop?: boolean;
  isLoadingReport?: boolean;
}
export interface PromotionPeriod {
  id: number;
  code: string;
  name: string;
  startingDayOfWeek: number;
  durationInDays: number;
  promotionTypeId: number;
  validFrom: string;
  validTo: string;
  order: number;
  isSpecialEvent: boolean;
  extraPlanningDays: number;
  furthestTaskStartingDate?: string;
  succeeded: boolean;
  message?: string;
}

export enum PromotionActionAssortmentMethodType {
  ActivateOnlyDuringPromotion = 'ActivateOnlyDuringPromotion', //Activar los productos durante la vigencia de la promoción.
  ActivateIndefinitely = 'ActivateIndefinitely', //Activar los productos indefinidamente
  DoNotActivate = 'DoNotActivate', //No activar los productos.
}

export enum PromotionActionFrequencyType {
  EveryDay = 'EveryDay', //La promocion está activa todos los dias
  CertainDaysOfTheWeek = 'CertainDaysOfTheWeek', //La promocion solo esta activa ciertos dias de la semana
  CertainDaysOfTheMonth = 'CertainDaysOfTheMonth', //La promocion solo esta activa ciertos dias del mes
}

export enum PromotionActionDiscountType {
  AbsoluteValue = 'AbsoluteValue',
  Percentage = 'Percentage',
}

export enum AlreadyToPublishType {
  Enable = 'Enable',
  Disable = 'Disable',
}

export enum CustomFieldAttributeTypeSelModel {
  'NUMBER' = 'NUMBER',
  'STRING' = 'STRING',
  'BOOLEAN' = 'BOOLEAN',
  'LIST' = 'LIST',
  'DATETIME' = 'DATETIME',
}

export enum NotificationActionEvents {
  validations = 'validations',
  downloadReport = 'downloadReport',
}
