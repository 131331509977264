import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { interval, Observable, Subject } from 'rxjs';
import { takeUntil, first, distinctUntilChanged, map, take } from 'rxjs/operators';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

import { _extendObjDepp, _isBase64, _timeout } from '@shared/aux_helper_functions';
import { getEnabledBlockVal } from '@shared/disabled_blocks';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { navigation } from 'app/navigation/navigation';
import { environment } from 'environments/environment';
import { NotificationsState } from '../notifications-panel/notifications-panel.state';

import { Select, Store } from '@ngxs/store';
import { _isDev, _log, _isLab, _isStaging, _clearAllLocalStorageData, _warn, _isFeDev } from '@shared/aux_helper_environment';
import { IanTranslateService, PrismaDynamicEnv } from 'core/services/ian-core-singleton.service';
import { DomSanitizer } from '@angular/platform-browser';

import { MatDialog } from '@angular/material/dialog';
import { _getUseUXV2 } from '../global-settings-panel/global-settings-panel.component';
import { DevSettingsService, FeatureFlagsKeysEnum } from 'core/services/dev-settings.service';
import { Router } from '@angular/router';
import { MessagingService } from 'app/services/messaging.service';
import { MatSnackBar, MatSnackBarDismiss, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActiveNotificationsModalComponent } from '../active-notifications-modal/active-notifications-modal.component';
import { ToastrService } from 'ngx-toastr';

// import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
// import { locale as es_base } from '../../../i18n/es_base';
// import { locale as en_base } from '../../../i18n/en_base';
// import { locale as pt_base } from '../../../i18n/pt_base';

const _devTest = true;
const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy, AfterViewInit {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: Array<any>;
  isAuthenticated: boolean;
  userData: any;
  pathBrandImage: any = null;
  brandLogoCss = 'margin: 0 20px';

  getEnabledBlockVal = getEnabledBlockVal;

  canShowHelpMenu = this.getEnabledBlockVal('TOOLBAR_SHOW_HELP_MENU'); /*TODO: Por ahora solo muestra el help en modo dev*/
  viewProfile = this.prismaDynamicEnv.getConf('brandCustomization.infoProfile.viewProfile');
  isDev = _isDev();

  _useUXV2 = _getUseUXV2();

  @Output() emitBlock = new EventEmitter<any>();
  blockScreenApi = this.prismaDynamicEnv.getConf('brandCustomization.notifications.blockAppifUserNotHaveNotifications');

  //DES-5109
  enableTthirdPartyPlatforms =
    environment.enableTthirdPartyPlatforms &&
    this.prismaDynamicEnv.getConf('brandCustomization.featuresEnabled.blocksEnabled.ENABLE_TTHIRD_PARTY_PLATFORMS');

  // -----------------------------------------------------------------------------------------------------
  // @ NOTIFICATIONS // id:of0O_o2aha
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle Notifications open
   *
   * @param key
   */

  @Select(NotificationsState.getNotificationsNewSize)
  notificationsSize$: Observable<number>;

  private snackBarRef: MatSnackBarRef<any>;

  // Private
  private _unsubscribeAll: Subject<any>;
  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private translate: IanTranslateService,
    private oidcSecurityService: OidcSecurityService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private messagingService: MessagingService,
    private snackBar: MatSnackBar,
    private toastr: ToastrService,
    private prismaDynamicEnv: PrismaDynamicEnv,
    private store: Store,
    private _sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private router: Router,
    private configFlags: DevSettingsService
  ) {
    // //seguridad OIDC
    // if (this.oidcSecurityService.moduleSetup) {
    //     this.doCallbackLogicIfRequired();
    // } else {
    //     this.oidcSecurityService.onModuleSetup.subscribe(() => {
    //         this.doCallbackLogicIfRequired();
    //     });
    // }

    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us',
      },
      {
        id: 'es',
        title: 'Español',
        flag: 'us',
      },
      {
        id: 'pt',
        title: 'Português',
        flag: 'us',
      },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    if (_isFeDev() && false) this.enableTthirdPartyPlatforms = true;
  }

  __flagNotifications = !!this.configFlags.hasFeatureFlag(FeatureFlagsKeysEnum['FeatureFlag__PRO-540']);

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(settings => {
      this.horizontalNavbar = settings.layout.navbar.position === 'top';
      this.rightNavbar = settings.layout.navbar.position === 'right';
      this.hiddenNavbar = settings.layout.navbar.hidden === false;
    });

    // Set the selected language from default languages
    this.selectedLanguage = this.languages.find(x => x.id === this._translateService.currentLang);

    // security checks
    this.oidcSecurityService
      .getIsAuthorized()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(auth => {
        this.isAuthenticated = auth;
        if (false && _isDev()) console.debug('AUTHORIZED: ', auth);
      });

    this.oidcSecurityService
      .getUserData()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(userData => {
        this.userData = userData;
        if (false && _isDev()) console.debug('USER DATA: ', userData);
      });

    this.putBrandImage();

    this.setupHotJar();

    this.addGoogleTagManagerEvent({ event: 'app-loading-complete' });

    if (true) _log('[ToolbarComponent]');

    if (this.__flagNotifications) {
      // Crear un Observable para monitorear cambios en getAlertEnabledNotifications
      interval(1000) // Emite valores cada 1000ms (1 segundo)
        .pipe(
          map(() => this.getAlertEnabledNotifications()),
          distinctUntilChanged(),
          takeUntil(this._unsubscribeAll)
        )
        .subscribe(isEnabled => {
          if (isEnabled) {
            this.showSnackBar();
            if (this.blockScreenApi) {
              this.emitBlock.emit(true);
            } else {
              this.emitBlock.emit(false);
            }
          } else {
            this.emitBlock.emit(false);
          }
        });
    }
  }

  profile() {
    this.router.navigate(['/admin/profile/info/data']);
  }

  isAssistantEnabled() {
    if (this.isAssistantEnabledAi()) return false;
    return this.prismaDynamicEnv.getConf('brandCustomization.featuresEnabled.blocksEnabled.PRISMA-ASSISTANT');
  }

  isAssistantEnabledAi() {
    return (
      _isDev() ||
      _isLab() ||
      this.prismaDynamicEnv.getConf('brandCustomization.featuresEnabled.blocksEnabled.PRISMA-ASSISTANT-AI-DATA-CHAT-DEMO')
    );
  }

  putBrandImage() {
    if (this.pathBrandImage) return;

    const brandImage = false ? './assets/brands/prismart.png' : this.prismaDynamicEnv.getConf('brandCustomization.brandLogoAsset', null);

    if (brandImage) {
      if (_isBase64(brandImage)) {
        this.pathBrandImage = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + brandImage);
      } else {
        this.pathBrandImage = this._sanitizer.bypassSecurityTrustResourceUrl(brandImage);
      }
    }

    const brandLogoCss = this.prismaDynamicEnv.getConf('brandCustomization.brandLogoCss', null);

    if (brandLogoCss) {
      this.brandLogoCss = brandLogoCss;
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key) && this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  toggleNotifications(key): void {
    this._fuseSidebarService.getSidebar(key) && this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  toggleGlobalSettings(): void {
    this._fuseSidebarService.getSidebar('globalSettings') && this._fuseSidebarService.getSidebar('globalSettings').toggleOpen();
  }

  toggleInsights(key): void {
    this._fuseSidebarService.getSidebar(key) && this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  isPair(x) {
    return !!((x || 0) & 1);
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    _log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  fullScreen(): Promise<void> {
    let ret: Promise<void>;
    const elem = document.documentElement as any;
    const methodToBeInvoked =
      elem.requestFullscreen || elem.webkitRequestFullScreen || elem.mozRequestFullscreen || elem.msRequestFullscreen;
    if (methodToBeInvoked) {
      ret = methodToBeInvoked.call(elem);
    } else {
      ret = new Promise<void>(resolve => {
        resolve();
      });
    }

    return ret;
  }

  exitFullscreen(): Promise<void> {
    let ret: Promise<void>;
    const methodToBeInvoked =
      (document as any).exitFullscreen ||
      (document as any).mozExitFullscreen ||
      (document as any).webkitExitFullscreen ||
      (document as any).msExitFullscreen;
    if (methodToBeInvoked) {
      ret = methodToBeInvoked.call(document);
    } else {
      ret = new Promise<void>(resolve => {
        resolve();
      });
    }

    return ret;
  }

  toggleFullScreen(): void {
    if (!this.isFullScreen()) {
      this.fullScreen().then(() => {
        const navbar = this._fuseSidebarService.getSidebar('navbar');
        if (navbar) {
          navbar.folded = true;
        }
      });
    } else {
      this.exitFullscreen().then(() => {
        const navbar = this._fuseSidebarService.getSidebar('navbar');
        if (navbar) {
          navbar.folded = false;
        }
      });
    }
  }

  isFullScreen(): boolean {
    return !!(
      (document as any).fullscreenElement ||
      (document as any).mozFullScreenElement ||
      (document as any).webkitFullscreenElement ||
      (document as any).msFullscreenElement
    );
  }

  getAlertEnabledNotifications(): boolean {
    if (false && _isFeDev()) return true;

    return this.messagingService.getAlertEnabledNotifications();
  }

  showSnackBar(txt = this.translate.instant('NOTIFICATIONS.ENABLE_NOTIFICATIONS')) {
    const message = ` <span>${txt}</span>`;

    const toast = this.toastr.error(message, '', {
      timeOut: 0, // No cerrar automáticamente
      extendedTimeOut: 0, // No cerrar automáticamente
      closeButton: true,
      enableHtml: true,
      toastClass: 'ngx-toastr ngx-toastr-notif ngx-toastr-notifOK ngx-toastr toast-red-background',
      positionClass: 'toast-bottom-center', // Clase personalizada para la posición
      easeTime: 300,
    });

    toast.onTap.pipe(take(1)).subscribe(() => this.onActionClick());
  }

  onActionClick() {
    const dialogRef = this.dialog.open(ActiveNotificationsModalComponent, {
      width: '650px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(data => {
      if (this.getAlertEnabledNotifications()) {
        if (this.blockScreenApi) {
          this.showSnackBar();
        }
      }
    });
  }
  login(): void {
    this.oidcSecurityService.authorize();
  }

  logout(): void {
    if (_devTest) {
      _log('[Logout]');
    }

    this.addGoogleTagManagerEvent({ event: 'app-logout' });

    this._fuseSplashScreenService.setLockLoaderShow(true);
    this._fuseSplashScreenService.show();
    this.oidcSecurityService.logoff();

    _clearAllLocalStorageData();
  }

  // private doCallbackLogicIfRequired() {
  //     // Will do a callback, if the url has a code and state parameter.
  //     this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());
  // }

  canShowChangeLang() {
    if (!this.isAuthenticated) return false;
    if (getEnabledBlockVal('LANGUAJE-USER-CAN-CHANGE') || _isDev() || _isLab() || _isStaging()) return true;
    return false;
  }

  canShowPortuguesLang() {
    if (!this.isAuthenticated) return false;
    if (getEnabledBlockVal('LANGUAJE-USER-PORTUGUES') || _isDev() || _isLab() || _isStaging()) return true;
    return false;
  }

  getCurrentLang() {
    return (window as any)?._translateService?.getDefaultLang();
  }

  setCurrentLang(lang) {
    if (!lang) return;
    (window as any)?.forceSetLanguaje(lang);
  }

  ngAfterViewInit() {
    this.oidcSecurityService.getUserData().subscribe((userData: any) => {
      if (true && userData) this.setupIntegrations(userData);
    });
  }

  //DES-5109
  async setupIntegrations(userData) {
    if (!this.enableTthirdPartyPlatforms) return false;

    const brandCustomization = this.prismaDynamicEnv.getConf('brandCustomization');

    //Google tagmanager
    this.setupGoogleTagManagerLogin(userData, brandCustomization);

    //InterCom
    this.setupInterComChat(userData, brandCustomization);
  }

  //DES-5109
  async setupHotJar() {
    if (!this.enableTthirdPartyPlatforms) return;

    await _timeout(1024);

    const loadingSplahElement = document.getElementById('fuse-splash-screen');

    if (!loadingSplahElement) throw new Error('setupInterCom: no loadingSplahElement');

    loadingSplahElement.style.zIndex = '-10';

    _log('[HOTJAR]');
  }

  //DES-5109
  async setupGoogleTagManagerLogin(userData, brandCustomization) {
    const brandCode = brandCustomization.code;

    let { email, email_verified, family_name, given_name, name, permission, preferred_username, role } = userData;

    const $userData = { email, email_verified, family_name, given_name, name, permission, preferred_username, role };

    this.addGoogleTagManagerEvent({ event: 'app-login', userData: $userData, brandCode });
  }

  //DES-5109
  async addGoogleTagManagerEvent(event) {
    if (!this.enableTthirdPartyPlatforms) return;

    const googleTagdataLayer = globalThis?.dataLayer;

    if (!googleTagdataLayer) throw new Error('setupInterCom: no googleTagdataLayer');

    googleTagdataLayer.push(event);

    _log('[Google tagmanager Event]', { event });
  }

  //DES-5109
  async setupInterComChat(userData, brandCustomization) {
    const button = document.querySelectorAll('#CUSTOM_INTERCOM_CHAT_BUTTON');

    const Intercom = globalThis?.Intercom;
    const APP_ID_INTERCOM = globalThis?.APP_ID_INTERCOM; //definido en: src/index.html

    if (!(button.length > 0)) throw new Error('setupInterCom: no CUSTOM_INTERCOM_CHAT_BUTTON');
    if (userData == null) throw new Error('setupInterCom: no userData');
    if (Intercom == null) throw new Error('setupInterCom: no globalThis.Intercom');
    if (APP_ID_INTERCOM == null) throw new Error('setupInterCom: no APP_ID_INTERCOM');

    const userEmail = userData.email;
    const userName = userData.name;
    const brandCode = brandCustomization.code;
    const environmentType = brandCustomization.environmentType;

    try {
      //DOC: https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app

      let resp = Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: APP_ID_INTERCOM,
        custom_launcher_selector: '#CUSTOM_INTERCOM_CHAT_BUTTON',
        hide_default_launcher: true,
        user_id: userEmail,
        email: userEmail,
        company: {
          id: brandCode,
          name: brandCode,
        },
      });

      if (true) _log('[setupInterCom OK]', { resp, userEmail, brandCode, userName, environmentType });
    } catch (e) {
      _warn(e);
    }
  }
}
