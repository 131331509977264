import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { __getplanogramPopMaterialIdDummy } from './promotionalSpacesDummyData';
import { catchError, delay, map, tap } from 'rxjs/operators';
import { kayshOperator } from '@karacas/kaysh';
import { _createUnicId, _ms, _throwError, _toNumber } from '@shared/aux_helper_functions';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { _logTap, _useDummyData } from '@shared/aux_helper_environment';
import { FormModel_scopeItems_FE, FormModel_scopeStores_FE } from '@prisma/containers/model/scope.models';
import { PlanogramsPopMaterial } from './generic-promotional-spaces.component';

@Injectable({
  providedIn: 'root',
})
export class PromotionalSpacesService {
  private configBaseSpaces = environment.apiBaseUrl_spaces;
  private spacesConfig = environment.spaces.planograms.api;

  constructor(private http: HttpClient) {}
  //
  //  POP MATERIAL
  //
  getplanogramPopMaterialList(actionId?: string | number): Observable<PlanogramsPopMaterial[]> {
    const USE_DUMMY_DATA = false;

    if (USE_DUMMY_DATA) {
      return of(__getplanogramPopMaterialIdDummy()).pipe(
        map(data => _mapPromotionalSpacesFromBe(data)),
        delay(16),
        kayshOperator('getplanogramPopMaterialIdDirect', null, { localStorage: false, maxTime: _ms('30m') })
      );
    }

    let url = `${this.configBaseSpaces}${this.spacesConfig.resources.popMaterialList}`;
    if (!!actionId) {
      url = `${url}?actionId=${actionId}`;
    }
    return this.http.get<PlanogramsPopMaterial[]>(url).pipe(
      tap(data => _logTap(`${PromotionalSpacesService.name}::getplanogramPopMaterialList (tap)\n\tdata: %o`, data)),
      map(data => _mapPromotionalSpacesFromBe(data)),
      kayshOperator('getplanogramPopMaterialList', { url }, { localStorage: false, maxTime: _ms('5m') }),
      catchError(error => {
        if (true && _useDummyData()) {
          return of(__getplanogramPopMaterialIdDummy()).pipe(
            map(data => _mapPromotionalSpacesFromBe(data)),
            delay(500)
          );
        }
      })
    );
  }

  getCalculatedPlanogramPopMaterialList(
    promotionTypeId: number,
    storeScope: FormModel_scopeStores_FE,
    itemScope: FormModel_scopeItems_FE
  ): Observable<PlanogramsPopMaterial[]> {
    let _payload = { promotionTypeId, storeScope, itemScope };

    const url = `${this.configBaseSpaces}${this.spacesConfig.resources.calculatedPopMaterialList}`;

    return this.http.post<PlanogramsPopMaterial[]>(url, _payload).pipe(
      tap(_data => _logTap(`${PromotionalSpacesService.name}::getCalculatedPlanogramPopMaterialList (tap)\n\tdata: %o`, _data)),
      map(data => _mapPromotionalSpacesFromBe(data)),
      kayshOperator('getCalculatedPlanogramPopMaterialList', { ..._payload }, { localStorage: false, maxTime: _ms('1h') }),
      catchError(error => {
        return _throwError(error, PromotionalSpacesService);
      })
    );
  }
}

const _mapPromotionalSpacesFromBe = (data: Omit<PlanogramsPopMaterial, '_internalId'>[]): PlanogramsPopMaterial[] => {
  return data.map(el => ({ ...el, _internalId: _createUnicId() }));
};
