export const locale = {
  lang: 'en',
  data: {
    TESTLANG: 'en_base',
    LNG_ITEMS: 'Items',
    LNG_ITEM: 'Item',
    LNG_TIENDAS: 'Stores',
    LNG_TIENDA: 'Store',
    LNG_PRICES_DECISIONS_FILTER_CATEGORYS: 'Subcategories',
    GENERAL_DOWNLOAD: null,
    GENERAL_INFO: 'Information',
    GENERIC_CLEAN: 'Clean search',
    GENERIC_SELECTED_OPTION: 'Selected option',
    GENERIC_PUBLISH_CONTENT: 'Do you want to publish the following {{val}} selected records?',
    GENERIC_CANCEL_CONTENT: null,
    GENERIC_DOWNLOAD_CONTENT: 'Do you want to download the following {{val}} selected records?',
    GENERIC_UNPUBLISH: 'Massive publish errors',
    GENERIC_WITHOUT_PERMISSION: 'Without Permission to complete this action',
    GENERIC_ACCESS_PERMISSION: 'Without permission to access this screen',
    GENERIC_ACTION_ROW_CONTENT_FAILED: 'The action could not be carried out correctly',
    GENERIC_CANCEL: 'Cancel',
    GENERIC_ACCEPT: 'Accept',
    GENERIC_CLOSE: 'Close',
    GENERIC_OK: 'Ok',
    GENERIC_CONTINUE: 'Continue',
    GENERIC_WARNING: 'Warning',
    GENERIC_REPORT: 'Complete Report',
    GENERIC_ADD: 'Add',
    GENERIC_FILTER: 'Filter',
    GENERIC_FULLSCREEN_TTIP: 'Expand',
    GENERIC_EXITFULLSCREEN_TTIP: 'Reduce',
    GENERIC_EXPORT_TTIP: 'Export',
    GENERIC_CLONE: 'Clone',
    GENERIC_EDIT: 'Edit',
    GENERIC_DELETE: 'Delete',
    GENERIC_VIEW: 'View',
    GENERIC_CONFIRM: 'Confirm',
    GENERIC_RESET: 'Reset',
    GENERIC_RESET_ALT: 'Reset',
    GENERIC_SAVE: 'Save',
    GENERIC_SAVE_OK: 'Saved successfully.',
    GENERIC_SAVE_AND_GENERATE: 'Save and Generate',
    GENERIC_GENERATE: 'Generate',
    GENERIC_NONE: 'None',
    GENERIC_DOWNLOAD_EXCEL: 'download.xlsx',
    GENERIC_ITEMS_NOT_FOUND: 'No results found',
    GENERIC_ITEMS_NOT_ITEMS: 'There are no more items.',
    GENERIC_NOTICE: 'Notice',
    GENERIC_SOME: '(Some)',
    GENERIC_YES: 'Yes',
    GENERIC_NO: 'No',
    GENERIC_ALL: 'All',
    GENERIC_ALL_PAGE: 'All page',
    GENERIC_DESELECT: 'Deselect',
    GENERIC_ALL_F: 'All',
    GENERIC_DAY1: null,
    GENERIC_DAY2: null,
    GENERIC_DAY3: null,
    GENERIC_DAY4: null,
    GENERIC_DAY5: null,
    GENERIC_DAY6: null,
    GENERIC_DAY7: null,
    GENERAL_NAME_DUPLICATE: 'This name already exists',
    GENERIC_DELETE_LOGIC: 'Inactive record',
    GENERIC_DELETE_LOGIC_SINGLE_CONTENT: 'Are you sure you want to inactive {{val}}?',
    GENERIC_DELETE_MULTIPLE: 'Inactive records',
    GENERIC_SUPPORT_CHAT: null,
    GENERIC_DELETE_LOGIC_MULTIPLE_CONTENT: 'Are you sure you want to inactive {{val}}',
    LINEAR_SPACE_TYPES: {
      Sales: 'Sales',
    },
    LNG_PRODUCTS_TYPES: {
      Retail: 'Retail',
      Ingredient: 'Ingredient',
      Combo: 'Kit',
      Recipe: 'Recipe',
      Supply: 'Supply',
      Item: 'Retail',
    },
    LNG_STORES_TYPES: {
      Store: 'Store',
      DistributionCenter: 'Distribution Center ',
      StoreAndDistributionCenter: 'Store And Distribution Center',
    },
    LNG_STRATEGIES_VARIABLES: {
      Sales: 'Sales',
      SalesUN: 'Sales Units',
      Deliveries: 'Deliveries',
      DeliveriesUN: 'Units Delivered',
      Margin: 'Margin',
      MarginWithVendors: 'Agreement Margin',
      MarketSales: null,
      MarketUN: null,
    },
    LNG_STRATEGIES_EVENTS: {
      First: 'First purchase',
      CreationDate: 'Creation Date',
    },
    STATUS_CONNECTION: {
      NO_CONNECTION: 'No Connection.',
      BAD_CONNECTION: 'Slow Connection.',
      ACCEPT: 'OK',
    },
    ABM_STATUS: {
      NEW: 'New',
      ENDORSED: 'Endorsed',
      INFORMED: 'Informed',
      DELETED: 'Deleted',
      MODIFIED: 'Modified',
      CURRENT: 'Current',
      CANCELLED: 'Cancelled',
    },
    PUBLISH_STATUS: {
      PROCESSING: 'Publishing',
      PROCESSING_TOOLTIP: 'Refresh search results when notified about the success of the publication',
      ROW_DISABLED_BY_PROCESSING: 'The action cannot be performed because the record is being processed',
      DELETING: 'Deleting',
      DELETING_TOOLTIP: 'Search results will be updated upon notification of successful deletion',
      ABM_WARNING_DELETING: 'Record in process of deletion.',
    },
    LNG_ADMINISTRATION_MASTER_SUPPLIER_searchStores: 'Find Stores',
    LNG_ADMINISTRATION_MASTER_STORES_newStore: 'New Store',
    LNG_ADMINISTRATION_MASTER_STORES_tooltipDeleteStore: 'Delete selected stores',
    LNG_ADMINISTRATION_MASTER_STORES_storeType: 'Store Type',
    LNG_MASTER_STORETYPES_Store: 'Store',
    LNG_MASTER_STORETYPES_DistributionCenter: 'Distribution Center ',
    LNG_MASTER_STORETYPES_StoreAndDistributionCenter: 'Store and Distribution Center',
    LNG_PRICES_SETUP_PRICERULE_storeScope: 'Store Scope',
    LNG_PRICES_SETUP_PRICERULE_step3storeScope: 'Step 3 - Stores Scope',
    'LNG_PRICE-RULES-TYPES_Margin': 'Margin',
    'LNG_PRICE-RULES-TYPES_Competitive': 'Competitive',
    'LNG_PRICE-RULES-TYPES_ParentItem': 'Product Relationships',
    'LNG_PRICE-RULES-TYPES_ParentZone': 'Store Relationships',
    LNG_SET_EFFECTIVE_DATES: 'Set valid dates',
    LNG_SET_EFFECTIVE_DATE: 'Set valid date',
    LNG_PREVIEW: 'Preview',
    TIME_UNITS_SHORT: {
      seconds: 'Sec.',
      minutes: 'Min.',
      hours: 'Hours',
      days: 'Days',
    },
    GLOBAL: {
      DATES: 'Dates',
      brand: 'Brand',
      brands: 'Brands',
      manufacturer: 'Supplier',
      internalCode: 'Internal Code',
      externalCode: 'External Code',
      Vendor: null,
      Imprent: null,
      Region: 'Region',
      Market: 'Market',
      Format: 'Company Operated',
      Country: 'Country',
      State: 'State',
      Department: 'Department',
      NAME: 'Name',
      QUANTITY: 'Quantity',
      City: 'City',
      ZipCode: 'Zip Code',
      Subcategoría: 'Subcategory',
      OperationType: 'Operation Type Competitors',
      UnCheckedSuggestion: 'Unchecked suggestion',
      PriceRaised: 'Increased prices',
      NoSuggestions: 'No suggestions',
      NotCheckedSimulation: 'Unchecked simulation',
      SuggestionAccepted: 'Accepted suggestion',
      ManualPriceEdited: 'Manually edited price',
      SimulationAcepted: 'Accepted simulation',
      Regular: 'Regular',
      Competition: 'Competition',
      Current: 'Current',
      New: 'New',
      Endorsed: 'Endorsed',
      Informed: 'Informed',
      Historic: 'Historic',
      Future: 'Future',
      Checked: 'Checked',
      Unchecked: 'Unchecked',
      PriceZone: 'Price Zone',
      Search: 'Search',
      Type: 'Type',
      WITHOUT_PERMISSIONS: 'Without Permissions',
      PLACEHOLDER_START_DATE: 'Start date',
      PLACEHOLDER_END_DATE: 'End date',
      MAX_DIFF_DATE: 'Date range must not be greater than ',
      SECOND_DATE_LOWER_THAN_FIRST: null,
      DATE: 'Date',
      PDV: 'Stores',
      WITHOUT_CHANGES: 'Without Changes',
      WITHOUT_INVALID_MAIN_DATA: 'Invalid Main Data',
    },
    TYPE_ACTION: {
      approved: 'Approve',
      informToERP: 'Publish Decisions',
      TOOLTIP: {
        approved: 'Approve Changes',
        informToERP: 'Publish Decisions',
      },
    },
    NOTIFICATIONS: {
      TITLE: 'Notifications',
      NO_NEW_ITEMS: 'There are no new notifications.',
      NO_OLD_ITEMS: 'There are no unread notifications.',
      MARK_AS_READ: 'Mark as read',
      MARK_ALL_AS_READ: 'Mark all as read',
      VIEW_LAST: 'View read notifications',
      CLOSE_LAST: 'Close read notifications',
      NOT_ENABLED: 'Your browser notifications are disabled.',
      ENABLE_NOTIFICATIONS: 'You must enable notifications in your browser. Click for more info.',
      ENABLE_NOTIFICATIONS_TUTORIAL: 'Activation tutorial',
      DOWNLOAD: 'Download File',
      TOGO: 'Go',
    },
    INSIGHTS: {
      TITLE: 'Insights',
      GO_TO_INSIGHTS: 'Go to the Insights screen',
    },
    GLOBAL_CONFIG: {
      TITLE: 'Settings',
      FONT_SIZE: 'Font size',
      SMALL: 'Small',
      NORMAL: 'Normal',
      BIG: 'Big',
      THEME: 'Theme',
      LIGTH: 'Light',
      DARK: 'Dark',
      DATE_FORMAT: 'Date format',
      RESET_WARNING: 'To apply the changes it is necessary to restart the application',
    },
    HELPS: {
      PRICE: {
        SOON: 'Coming Soon',
        FAQS: {
          ROUTE: 'Pricing',
          TITLE: 'FAQs',
          QUESTION1:
            'What if I have a margin rule and a competition rule for the same product and the same point of sale? Which rule will Prisma prioritize?',
          ANSWER1:
            'In Prisma, the most specific rules will always take precedence over the others. That is, if there is a margin rule for the Electrical Appliances subcategory in Buenos Aires, and in turn there is a competition rule for the Electrical Appliances subcategory, for a specific point of sale in the Buenos Aires area, then the competition rule will have priority in that particular point of sale, while the remaining points of sale in Buenos Aires will be governed by the margin rule.',
          ANSWER1_1:
            'If there is a competition rule and a margin rule, they have an equal product and point of sale scope. Then the competition rule will always take precedence. In other words, Prisma will try to comply with the competitive positioning and if this is not possible then suggestions will be generated from the margin rule.',
          QUESTION2: 'How does the zone margin rule work? In what cases will I use it?',
          QUESTION3: 'What is the difference between margin or markup rules? Which one should I use?',
          ANSWER3:
            'The Profit Margin and the Mark Up are two ways of looking at the same situation: how much I want to earn. They are exactly the same. Their difference is in the way they are obtained: the Mark Up is calculated based on cost, while the Profit Margin is calculated on the sale price.',
          ANSWER3_1:
            'For example, a product or service that has a final cost of $ 100.00 and for which you want to earn a Mark Up of 20%, will have a final price of $ 120.00 and a Profit Margin of 16.66%.',
          ANSWER3_2: 'Mark Up = 20 / Cost = 20/100 = 0.2 x 100 = 20%',
          ANSWER3_3: 'Profit Margin = 20 / Price = 20/120 = 0.16 x 100 = 16.16%',
          QUESTION4: 'What kind of cost does Prisma use to calculate the margin?',
          ANSWER4:
            'At Prisma we recommend working with the Replacement Cost, that is, the price that we have to pay to the supplier to replace a merchandise or raw material. This cost may differ from the accounting cost.',
          QUESTION5:
            'If I have the product "Small Coffee", which is the parent of the product "Medium Coffee" in a rule configured so that the child product costs 20% more than the parent product. Can I have another rule, at the same time, where "Small Coffee" is the parent of "Large Coffee" and the child product ("Large Coffee") costs 40% more than the parent ("Small Coffee")?',
          ANSWER5:
            'You can, Prisma allows you to create several product relationship rules where you define the same item as the parent. In this case, you will create a kinship rule where the item Small Coffee is the parent of medium coffee with a difference of 20% and then you will create another relationship rule where the product Small Coffee is the parent of Large Coffee with a difference of 40%.',
          QUESTION6:
            'I created a rule where product "A" is a child of product "B". "A" changes its price each time price "B" changes. I want to create another rule where the product "C" is also the parent of "A"? Then "A" will change price every time the price of "B" or "C" changes.',
          ANSWER6:
            'Product kinship rules cannot share Children. In this way, if you define that a product will be a child, it can only have a single parent.',
          QUESTION7: "I created kinship rules and I don't see the price suggestions, how can I generate them?",
          ANSWER7:
            'The kinship rules do not generate suggestions. The only rules that generate price suggestions are Margin Rules and Competition Rules.',
          QUESTION8: 'I created a competition / margin rule for a product and Prisma does not suggest a price, what should I do?',
          ANSWER8:
            'If prisma did not generate any suggestions, it means that your prices comply with the defined positioning and guidelines. Therefore, you do not need to generate any suggestions.',
          ANSWER8_1:
            'In the case of competition rules, remember that Prisma feeds on the prices of your competitors. You should check that you have updated prices from competitors.',
          QUESTION9: 'When I am creating a competition rule, there are points of sale that are not listed for selection in scope.',
          ANSWER9:
            'If they do not appear listed, it is because you have points of sale that are badly labeled or do not contain the correct attributes, you can check this from the Configuration Menu> Masters> Points of Sale',
          QUESTION10: 'When I am creating a competition rule there are products that are not listed for selection in scope.',
          ANSWER10:
            'If not all the products are listed, you must check that they are well categorized or have the correct attributes, you can check that from the Item Master module.',
          QUESTION11: 'When I am creating a margin rule there are stores that are not listed for selection in scope.',
          ANSWER11:
            'If they do not appear listed, it is because you have points of sale that are badly labeled or do not contain the correct attributes, you can check this from the Configuration Menu> Masters> Points of Sale',
          QUESTION12: 'When I am creating a margin rule there are products that are not listed for selection in scope.',
          ANSWER12:
            'If not all the products are listed, you must check that they are well categorized or have the correct attributes, you can check that from the Item Master module.',
          QUESTION13: 'When I am creating a relationship rule, there are points of sale that are not listed for selection in the scope.',
          ANSWER13:
            'If they do not appear listed, it is because you have points of sale that are badly labeled or do not contain the correct attributes, you can check this from the Configuration Menu> Masters> Points of Sale',
          QUESTION14: 'When I am creating a relationship rule there are products that are not listed for selection in scope.',
          ANSWER14:
            'If not all the products are listed, you must check that they are well categorized or have the correct attributes, you can check that from the Item Master module.',
        },
      },
    },
    GENERIC_COMP: {
      SCOPE: {
        CLIPBOARD_STORE_TITLE: 'Paste points of sale from clipboard',
        CLIPBOARD_PRODUCT_TITLE: 'Paste products from clipboard',
        STRATEGY_ATTR_TITLE: 'Selection by attribute',
        STRATEGY_ATTR_MSG: 'Attributes are selected to define the scope of products',
        STRATEGY_PROD_TITLE: 'Selection by product',
        STRATEGY_PROD_MSG: 'Products must be chosen explicitly one by one',
        STRATEGY_ATTR_TITLE_STORE: 'Selection by attribute',
        STRATEGY_ATTR_MSG_STORE: 'Attributes are selected to define the scope of points of sale',
        STRATEGY_STORE_TITLE_STORE: 'Selection by points of sale',
        STRATEGY_STORE_MSG_STORE: 'The points of sale must be explicitly chosen one by one',
      },
      LOOKUP: {
        Search: 'Search...',
        clearAll: 'Unselect all',
        clear: 'Deselect the item',
        selectAll: 'Select all',
        selectAllPage: 'Select all elements on the current page',
        selectAllChilds: 'Select all child',
        onlySelected: 'Show only selected',
        allItemsSelected: 'All items are selected',
        noneItemsSelected: 'No selected items',
        selectedItemsLabel: 'Selected items: ({{val}}):',
        Included: 'Included',
        Excluded: 'Excluded',
        includedAll: 'Include all elements',
        excludedAll: 'Exclude all elements',
        includedclearAll: 'Deselect all included elements',
        excludedclearAll: 'Deselect all excluded elements',
        itemsNotFound: 'No results found',
        parent_item_active: 'This product is a child in other rules, it cannot be used as a parent',
        parent_item_message:
          'A parent item cannot be a child in another rule. The products that are children in other rules are shown in grey',
        parent_store_active: 'This point of sale is a child in other rules, it cannot be used as a parent',
        parent_store_message:
          'A parent store cannot be a child in another rule. The points of sale that are children in other rules are shown in gray',
      },
      CAT_SELECT_V2: {
        LEVEL2: 'Business Unit',
        LEVEL3: 'Department',
        LEVEL4: 'Category',
        LEVEL5: 'Subcategory',
      },
      WEEKDAY_COMPONENT_DAYS: {
        '0': 'Su',
        '1': 'Mo',
        '2': 'Tu',
        '3': 'We',
        '4': 'Th',
        '5': 'Fr',
        '6': 'Sa',
      },
      IMPORTERV2: {
        DEFAULT_TITLE: 'General Importer',
        SELECT_TYPE_PLACEHOLDER: 'Select an Importer',
        DEFAULT_DESCRIPTION:
          'On this screen you can import information by uploading a file. Its format can be downloaded by clicking on the template download button. Once the file is uploaded you will receive instructions on how to continue.',
        DOWNLOAD_TEMPLATE: 'Download Template',
        AssortmentMarketData_alternativeDescription: null,
        TITLES: {
          FERRYES_AND_COMISIONS: 'Importer Freight and Commissions',
          COST_AND_TAXES: 'Costs and Taxes',
          VENDOR_COST: 'Costs by Supplier',
          STORE_TAXES: 'Tax Importer for Store',
          NEW_PRICES: 'New Prices Importer',
          COMPETITOR_PRICES: 'Competitor Prices',
          ITEMS: 'Items',
          IMPORT_VENDORS: 'Resolve Vendor conflicts',
          IMPORT_PRICES_TITLE: 'Prices',
          MARKET_INFORMATION: 'Market Information',
          MARGIN_WITH_VENDOR: 'Margin with Vendor',
          STORE_ASSORTMENT: 'Assortment Importer by Store',
          STORE_COSTS: 'Costs per Store',
          STORE_PRICES: 'Prices by Store',
          STORE_PRICE_OUTPUT: 'Publication of prices',
          COMPETITORS: 'Competitors',
          STORE_PRICE_OUTPUT_PREVIEW: 'Publication of prices - Preview',
          CustomItems: 'Regional Products',
          REGULATED_PRICES: 'Regulated Prices',
          VENDOR_COSTS: 'Costs by Vendor',
          CustomItemsDelete: 'Deletion of regional products',
          CustomItemsUpdatePrice: 'Regional product price update',
          CustomItemsUpdateCosts: 'Regional product cost update',
          VendorCosts: 'Vendor Costs',
          DailySales: 'Daily Sales',
          AssortmentMarketData: 'Market Data',
          Categories: 'Categories',
          Margin2: 'Margin by Agreements',
          MonthlyInventory: 'Monthly Inventory',
          StoreAssortment: 'Store Assortment',
          Stores: 'Stores',
          MonthlySales: 'Monthly Sales',
          ItemMatchings: null,
          SpacesAvailability: null,
          None: 'No Selection',
          CategoriesV2: 'Categories V2',
        },
        RESPONSE_OK: 'The import was sent to the process queue, to track it click here:',
        RESPONSE_OK_TITLE: 'Import in progress',
        TYPES: {
          ALL: 'All',
          Items: 'Items',
          RegulatedPrices: 'Regulated Prices',
          PriceRegulated: 'Regulated Prices',
          ActionExceptionalValue: null,
          ActionExceptionalMixedValue: null,
          StoreCosts: 'Costs per Store',
          StorePrices: 'Prices per Store',
          StorePriceOutput: 'Publication of Prices',
          CustomItems: 'Regional Products',
          CustomItemsDelete: 'Regional product deletion',
          CustomItemsUpdatePrice: 'Regional product prices',
          CustomItemsUpdateCosts: 'Regional product costs',
          StorePriceOutputPreview: 'Price Publication - Preview',
          ItemCodes: 'Item Codes',
          UnitsOfMeasures: 'Units of Measurement',
          Competitors: 'Competitors',
          CompetitorPrices: "Competitors' Prices",
          CompetitorsPrices: null,
          SalesTickets: 'Sales Tickets',
          Vendors: 'Vendors',
          VendorItems: 'Vendors Costs',
          DailyInventory: 'Daily Inventory',
          DailySales: 'Daily Sales',
          AssortmentOutputPreview: 'Assortment Publication - Preview',
          AssortmentOutput: 'Assortment Publication',
          VendorCosts: 'Vendor Costs',
          VENDOR_COSTS: 'Vendor Costs',
          StoreCostOutput: 'Cost Output',
          AssortmentMarketData: 'Market Data',
          Categories: 'Categories',
          Margin2: 'Margin by Agreements',
          ItemTags: 'Item Tags',
          MonthlyInventory: 'Monthly Inventory',
          StoreAssortment: 'Store Assortment',
          Stores: 'Stores',
          MonthlySales: 'Monthly Sales',
          None: 'No Selection',
          DailyMissionReport: null,
          DailyMissionReport_alt: null,
          ItemMatchings: null,
          SpacesAvailability: null,
          PlanogramPopMaterialMarketplaces: 'Rented Material',
          MarketItemToRegister: 'Market Products',
          PromiotonPeriod: 'Promotional Periods',
          PromiotionPeriod: 'Promotional Periods',
          PromotionReport: 'Promotion Report',
          PrintingList: 'Printing List',
          LogisticsList: 'Logistic List',
          BranchesYpfTiendas: 'Branches',
          DeliveryStoresYpfTienda: 'Delivery locations',
          ItemsYpfTiendas: 'Items',
          TagValues: 'Tag Values',
        },
        STATUS: {
          Pending: 'Pending',
          InProgress: 'In progress',
          Succeeded: 'Succeeded',
          SucceededWithWarnings: 'Succeeded With Warnings',
          Failed: 'Failed',
          Cancelled: 'Cancelled',
          NoDataProcessed: 'No data processed',
        },
        STATUS_MSG: {
          information: 'Ok',
          warning: 'Warnings',
          error: 'Error',
        },
        SOURCE: {
          WebApi: 'Web Api',
          Sftp: 'SFTP',
          ExternalPull: 'Datos Externos',
        },
      },
      INTERFACES_LOG: {
        DEFAULT_TITLE: 'Interface Log',
        LIST_COL_TYPE: 'Entity',
        ENTITY: 'Entity',
        LIST_COL_SOURCE: 'Source',
        LIST_COL_ID: 'Id',
        LIST_COL_CREATED: 'Created',
        LIST_COL_STARTED: 'Started',
        LIST_COL_FINISHED: 'Finished',
        LIST_COL_DURATION: 'Duration',
        LIST_COL_STATUS: 'Status',
        LIST_COL_USERNAME: 'User Name',
        LIST_COL_ORIGINAL_FILE_NAME: 'File Name',
        MORE_DETAIL: 'More Details',
        RETRY: 'Try again',
        RETRY_PERMISSION: 'You do not have the necessary permissions to retry the processing of failed interfaces.',
        RETRY_OK: 'The failed interface is running again.',
        LIST_COL_RECORDS: 'Records',
        LIST_COL_BATCHES: 'Batches',
        LIST_COL_BATCHES_PROGRESS: 'Progress',
        LIST_COL_BATCHES_NUM: 'Batch No.',
        LIST_COL_MESSAGES: 'Messages',
        LIST_COL_LINE: 'Line',
        LIST_COL_BATCHES_COMPLETED: 'Batches Completed',
        DATE: 'Fecha',
        IMPORTER: 'Importer',
        EXPORT_MSG: 'Export Messages',
        EXPORT_MSG_SHRT: 'Export',
        SIMPLE_VIEW: 'Simple View',
        ADVANCED_VIEW: 'Advanced View',
      },
    },
    PRICE_RULES: {
      COMPETENCE: {
        POSITION_AGAINST: {
          Minimum: 'Minimum',
          Average: 'Average',
          Maximum: 'Maximum',
          Mode: 'Mode',
        },
        POSITION_TREND: {
          HighestMode: 'Most repeated mode',
          MinimumPrice: 'Minimum Price',
          AveragePrice: 'Average Price',
          MaximumPrice: 'Maximum Price',
        },
        RULE_TYPE: {
          Guideline: 'Guideline',
          Margin: 'Margin',
          Competitive: 'Competitive',
          ParentItem: 'Related Products',
          ParentZone: 'Related Stores',
          BasePriceList: 'Base Price List',
        },
      },
    },
    NAV: {
      HOME: {
        TITLE: 'Home',
        DASHBOARD: 'Dashboard',
      },
      DASHBOARD: {
        TITLE: 'Dashboard',
      },
      PRICES: {
        TITLE: 'Pricing',
        SETUP: {
          TITLE: 'Setup',
          PRICEZONES: 'Price Zones',
          COMPETITORS: 'Competitors',
          PRICESRULES: 'Pricing Rules',
          BRANDS: 'Brands',
          GUIDELINESANDSTRATEGIES: 'Guidelines and Strategies',
          SENSITIVITIES: 'Sensitivities',
          ROUNDING: 'Rounding Rules',
          STRATEGYANDRULES: 'Strategy and Rules',
        },
        DECISIONS: {
          TITLE: 'Decisions',
          DECISIONS: 'Decisions',
          INFORM_ERP: 'Publish Decisions',
          PRICE_ELASTICITY: 'Price elasticity',
          PRICE_DECISIONS: 'Pricing Decisions',
          DECISIONSSUMMARY: 'Decisions Summary',
        },
        REPORTS: {
          TITLE: 'Reports',
          PRICE_CHANGES: 'Price Audit',
          COMPETITIVENESS_INDEX: 'Competitiveness index',
          PRICE_REPORTS: 'Price Reports',
          SUGGESTION_REPORTS: 'Suggestion Reports',
          COST_REPORTS: 'Cost Reports',
          YPF_REPORTS: 'Ypf Reports',
          PRICES_MAP: 'Price Map',
          PRICE_INCONSISTENCY_REPORT: 'Price Inconsistencies',
          DAILY_MARGIN: 'Daily Margin',
          COMPETITOR_PRICES: 'Competitor Prices',
        },
        ADMIN: {
          TITLE: 'Admin',
          TAXES: {
            TITLE: 'Taxes',
            CO2: 'Tax CO2',
            ICL: 'Tax ICL',
            IIBB: 'Tax IIBB',
          },
          IMPORTERS: {
            TITLE: 'Importers',
            IMPORTPARITY: 'Import Parity',
            IMPORTFLETESCOMISIONES: 'Shipping and commissions',
            IMPORTVENDORCOSTS: 'Vendor Costs',
            IMPORTSTORETAXES: 'Store Taxes',
            IMPORTSTORECOSTS: 'Store Costs',
            IMPORTNEWPRICES: 'New Prices',
            REGULATEDPRICES: 'Regulated Prices',
            COMPETITORSPRICES: 'Competitors Prices',
            IMPORTCOSTSANDTAXES: 'Costs and Taxes',
          },
        },
      },
      ADMIN: {
        TITLE: 'Administration',
        MASTER: {
          TITLE: 'Masters',
          STORES: 'Stores',
          STORES_V2: 'Stores',
          SUPPLIER: 'Supplier',
          SUPPLIER_V2: 'Supplier ',
          TAG: 'Tag',
          TAGS: 'Tags',
          INSIGHTS: 'Insights',
          EQUIPMENTS: 'Type of Equipment',
          GENERAL_IMPORTER: 'General Importer',
          INTERFACES_LOG: 'Interface Log',
        },
        SECURITY: {
          TITLE: 'Security',
          USERS: 'Users',
          ROLES: 'Roles',
        },
      },
      MYSTERYSHOPPER: {
        TITLE: 'Mystery Shopper',
        MISSIONS: {
          TITLE: 'Missions',
        },
        USER_ASSIGNMENT: {
          TITLE: 'Users Assignment',
        },
      },
      ITEMMASTER: {
        TITLE: 'Item Master',
      },
      ASSORTMENT: {
        TITLE: 'Assortment Planning',
        SETUP: {
          TITLE: 'Setup',
          CLUSTERING: 'Clusterization',
          CATEGORY_SETUP: 'Category Setup',
          TREEOFCATEGORYS: 'Category Tree',
          ROLES: 'Roles',
          STRATEGIES: 'Strategies',
          SEGMENTASSIGNMENTS: 'Assign Segments',
          ROLESANDSTRATEGIESASSIGNMENT: 'Category Settings',
          MARKET_INFORMATION: 'Market Information',
        },
        DECISIONS: {
          TITLE: 'Decisions',
          ASSORTMENTASIGNATION: 'Assortment Decisions',
          INFORM_ERP: 'Publish Decisions',
          SHOW_COLUMNS_TITLE: 'Select Columns',
          DINAMIC_COLUMNS_TITLE_ORDER: 'Order',
          DINAMIC_COLUMNS_SUB_TITLE_ORDER: 'Select the order within each column group',
          MAX_COLUMNS_SELECTED: 'Maximum amount of selected columns ({{val}})',
          MAX_COLUMNS_EXCEED: 'Maximum amount exceeded, the first {{val}} results were selected',
          WITHOUT_CLUSTERS_FOR_LEVEL: 'Without clusters assigned for this level of category',
          DINAMIC_COLUMNS_BTN_CANCEL: 'Cancel',
          DINAMIC_COLUMNS_BTN_APPLY: 'Apply',
          DINAMIC_COLUMNS_TITLE: 'Columns',
          DINAMIC_COLUMNS_SUB_TITLE: 'Select the columns you want to view in the table',
          TAGS_IN_USE: 'It is only possible to manage tags that are applied to the items of this cluster',
          DINAMIC_COLUMNS_GROUP: {
            defaultHeader: 'General',
            productHeader: 'Product Data',
            ticketsHeader: 'Tickets',
            performanceHeader: 'Performance',
            stocksHeader: 'Stock',
            exhibitionHeader: 'Display',
            marketHeader: 'Market',
            paretoHeader: 'Pareto',
            clasificationHeader: 'Classification',
            TGENERAL: 'Total General',
            TGENERAL_RECALC: 'Pareto Recalculated',
          },
          DINAMIC_COLUMNS: {
            itemCode: 'Code',
            itemName: 'Product',
            isNew: 'New',
            status: 'Status',
            segmento: 'Segment',
            tag: 'Tag',
            itemBrand: 'Brand',
            itemManufacturer: 'Manufacturer',
            pareto: 'Pareto',
            sales: 'Sales $',
            salesShare: 'Sales Share %',
            salesRanking: 'Sales $ Rank',
            soldUnits: 'Sales UN',
            soldUnitsShared: '% Sales UN',
            soldUnitsRanked: 'Sales UN Rank',
            margin: 'Margin $',
            marginPercentage: 'Margin %',
            marginWithVendor: 'Margin With Vendor',
            presence: 'Presence',
            discounts: 'Discounts $',
            price: 'Price',
            tickets: 'Tickets',
            ticketsTotal: 'Tickets Total',
            ticketsShare: 'Tickets %',
            stockCost: 'Stock $',
            stockCostShare: '% Stock $',
            stockCostCurrent: 'Current Stock $',
            stockUnits: 'Stock UN',
            stockUnitsShare: '% Stock UN',
            stockUnitsCurrent: 'Current Stock UN',
            wasteUnits: 'Waste UN',
            availability: 'Availability',
            outOfStock: 'Out Of Stock',
            daysInStock: 'Stock Days',
            daysInStockCurrent: 'Stock Days Current',
            gmroi: 'GMROI',
            planograms: 'Planograms',
            popMaterial: 'POP Material',
            facings: 'Facings',
            linearSpace: 'Linear Space (mt)',
            gmros: 'GMROS',
            marketSales: 'Market Sales',
            marketUnits: 'Market Units',
            marketPrice: 'Market Price',
            marketSalesShare: 'Market Sales Share',
            marketSalesRanking: 'Market Sales Ranking',
            marketUnitsShare: 'Market Units Share',
            marketUnitsRanking: 'Market Units Ranking',
            salesShareGap: 'Sales GAP',
            unitsShareGap: 'Units GAP',
            weightedIndex: 'IP %',
            weightedIndexPareto: 'Pareto',
            IP: 'IP %',
            UN: 'UN',
            suggestionRoleName: 'Suggestion Name',
            suggestionRoleAction: 'Suggestion Description',
          },
        },
        REPORTS: {
          TITLE: 'Reports',
          CLUSTERANALYSIS: 'Cluster Analysis',
          REPORT_DECISIONS: 'Report of Decisions',
        },
        ADMINISTRATION: {
          TITLE: 'Administration',
          IMPORTERS: {
            TITLE: 'Importers',
            MARKET_INFORMATION: 'Market Information',
            MARGIN_WITH_VENDOR: 'Margin with Vendor',
          },
        },
      },
      PROMOTIONS: {
        TITLE: 'Promotions',
        CAMPAIGN: {
          TITLE: 'Campaign',
        },
        ACTIONS: {
          TITLE: 'Promotional Actions',
        },
      },
      PROMOTIONSV2: {
        TITLE: 'Promotions',
        CONFIGURATIONS: {
          TITLE: 'Configuration',
          RULES: {
            TITLE: 'Promotion Rules',
          },
          REFERENCE_PRICES: {
            TITLE: 'Reference Prices',
          },
          PROMOTIONAL_PROCESS: {
            TITLE: 'Promotional Processes',
          },
        },
        CAMPAIGN: {
          TITLE: 'Campaign ',
        },
        CALENDAR: {
          TITLE: 'Calendar',
        },
        PERIODS: {
          TITLE: null,
        },
        PROMOTIONAL_PERIOD: {
          TITLE: 'Promotional Periods',
        },
        ACTIONS: {
          TITLE: 'Actions',
        },
        PRODUCTIONLIST: {
          TITLE: 'Production List',
        },
        PRODUCTION_REPORTS: {
          TITLE: 'Operational reports',
        },
        PROMOTION_OUTPUT: {
          TITLE: 'Promotion output',
        },
        LOGISTICS_REPORT: {
          TITLE: 'Logistics report',
        },
        AVAILABLE_SPACES: {
          TITLE: 'Promotional material',
        },
        PROMOTION_REPORT: {
          TITLE: null,
        },
        MARKETPLACE: {
          TITLE: 'Marketplace',
        },
        REPORTS: {
          TITLE: 'Reports',
        },
      },
      SPACES: {
        REPORTS_TITLE: 'Reports',
        DECISIONS_TITLE: 'Decisions',
        ADMIN_TITLE: 'Administration',
        MATERIAL_PROM_TITLE: 'Promotional Material',
        CONFIG_TITLE: 'Configuration',
        INCONSISTENCIES: {
          TITLE: 'Inconsistencies',
        },
        FLOORPLANS: {
          TITLE: 'Layouts',
        },
        SPACES_TEMPLATES: {
          TITLE: 'Templates',
        },
        TITLE: 'Space Planning',
        TITLEV2: 'Space Planning',
        PLANOGRAMS: {
          TITLE: 'Planograms',
        },
        STRUCTURE: {
          TITLE: 'Structures',
        },
        GENERIC_SPACE: {
          TITLE: 'Generic Space',
        },
        POPMATERIAL: {
          TITLE: 'POP Material',
        },
      },
      REPORTS: {
        TITLE: 'Reports',
        LIST: {
          TITLE: 'General Reports',
        },
      },
      SUPPLY: {
        TITLE: 'Smart Supply',
        SETUP: {
          TITLE: 'Setup',
          ORDER_SCHEDULE: 'Order Schedule',
          GENERAL: 'General',
          HOLIDAYS: 'Holidays',
          CAPACITIES: 'Capacity',
        },
        DECISIONS: {
          TITLE: 'Decisions',
          ORDER_CONTROL: 'Order Control',
        },
        ADMINISTRATION: {
          TITLE: 'Administration',
          IMPORTERS: 'Importers',
        },
      },
    },
    TEST_COMPONENT: {
      TEST_A: 'Frase de testing A',
      TEST_B: 'Frase de testing B',
      ANIDADA: {
        TEST_C: 'Frase de testing C',
      },
      TEST_D: 'Esta frase tiene {{val}} valor.',
      TEST_E: 'Esta frase tiene {{val}} valores, no {{val2}} ni {{val3}}.',
      TEST_F: 'Esta otra frase tiene {{val}} valor.',
      TEST_G: 'Esta frase tiene {{val}}, {{val2}} o {{val3}} valores.',
      TEST_H: 'Frase con {{val}} anidadoes',
      TEST_HB: 'VALORES MULTIPLES',
      TEST_I: 'Esta frase tiene {{val}} {{val2}}',
      TEST_I_sinfular: 'valor',
      TEST_I_plural: 'valores',
      TEST_J_ENUM: {
        '1': 'Asignado',
        '2': 'No Asignado',
      },
    },
    HOME: {
      ROUTE: 'Home',
      TITLE: 'Welcome',
      PRICING_MODULE: 'Pricing',
      ASSORTMENT_MODULE: 'Assortment Planning',
      SMART_SUPPLY_MODULE: 'Smart Supply',
      PROMOTIONS_MODULE: 'Promotions',
      ITEM_MASTER_MODULE: 'Item Master',
      SPACE_MODULE: 'Space Planning',
      QUICK_ACCESS: 'Quick Access',
      PRICING_MODULE_RULES: 'Pricing - Pricing Rules',
      PRICING_MODULE_DESICIONS: 'Pricing - Pricing Decisions',
      ASSORTMENT_MODULE_ROL: 'Assortment Planning - Roles',
      ASSORTMENT_MODULE_STRATEGIES: 'Assortment Planning - Strategies',
      ASSORTMENT_MODULE_DECISIONS: 'Assortment Planning - Assortment Decisions',
      ITEM_MASTER_MODULE_ABM: 'Item Master - ABM',
      HELPS: 'Help',
      HELP_1: 'How to create a Margin Rule?',
      ARTICLE_BLOG_TITLE: 'Price Perceptions, Consumer Choices and the Compromise Effect',
      ARTICLE_BLOG_TITLE2: 'How to extract the most value from your retail data',
      READ_MORE_BTN: 'Read More',
      PRISMA_PRICES: 'Prisma Prices',
      COMPETITORS_PRICES: 'Competitor Prices',
      ARTICLE2_BLOG_TITLE: '“Canastas de Alimentos” : listas para comprar en un solo click',
      ARTICLE3_BLOG_TITLE: 'Is your pricing strategy not working? These could be the 4 reasons.',
      PRISMA_SPACES: 'Prisma Spaces',
      PRISMA_SPACES_SUB: 'For retailers who want to make their planograms more profitable',
    },
    PR: {
      STP: {
        COMP: {
          LIST: {
            TITLE: 'Competitors',
            DELETE_TTIP: 'Delete selected competitors',
            DELETE: 'Delete',
            FILTER_TTIP: 'Filter',
            VIEW_TTIP: 'View',
            VIEW_TABLE: 'Table only',
            VIEW_MAP: 'Map only',
            VIEW_TABLE_MAP: 'Map and table',
            NAME: 'Name',
            CODE: 'Code',
            BRAND: 'Brand',
            MARKER: 'Marker',
            EDIT_ROW: 'Edit',
            EDIT_ROW_DISABLED_TOOLTIP: 'Edit selected competitors',
            DELETE_ROW: 'Delete',
          },
          ROUTE: 'Prices / Setup',
          TITLE: 'Competitors',
          NEW_COMP_BTN: 'New Competitor',
          IMPORT_BTN: 'Import',
          IMPORT_OP1_314: 'Res. 314 Prices',
          IMPORT_OP2_COMP: 'Competitors Prices',
          FILTER_TTIP: 'Filter',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          VIEW_TTIP: 'View',
          VIEW_TABLE: 'Table only',
          VIEW_MAP: 'Map only',
          VIEW_TABLE_MAP: 'Map and table',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          DELETE_TTIP: 'Delete selected competitors',
          DELETE: 'Delete',
          NAME: 'Name',
          CODE: 'Code',
          BRAND: 'Brand',
          MARKER: 'Marker',
          TYPE: 'Mechanic',
          CHANNEL: 'Channel',
          PROVINCE: 'State',
          LOCATION: 'Location',
          PRICEZONE1: 'Price Zone 1',
          PRICEZONE2: 'Price Zone 2',
          PRICEZONE3: 'Price Zone 3',
          NEW: 'New',
          INCOMPLETES: 'Incomplete',
          TITLE_FILTERS: 'Filters',
          MARKER_FILTER: 'Marker',
          OP1_ALL: 'All',
          OP2_MARKER: 'Marker',
          OP3_NO_MARKER: 'No Marker',
          BRAND_FILTER: 'Brand',
          OPERATION_TYPE_FILTER: 'Operation Type',
          PROVINCES_FILTER: 'State',
          LOCATION_FILTER: 'Location',
          CLEAN_FILTER_BTN: 'Clear',
          APPLY_FILTER_BTN: 'Apply',
          MODAL_314: 'The 314 data was imported correctly',
          TITLE_FORM: 'New Competitor',
          SAVE_BTN: 'Save',
          BASIC_INFORMATION_TAB: 'Main Data',
          CODE_INPUT: 'Internal Code',
          EXTERNALCODE_INPUT: 'External Code',
          VALIDATION_CODE: 'The field must be greater than 0',
          NAME_INPUT: 'Name',
          BRAND_INPUT: 'Brand',
          MARKER_CHECK: 'Marker',
          MARKER_CHECK_TTP: 'Set competitor as Marker',
          ADRESS_INPUT: 'Address',
          MAP_DATA: 'Map Data',
          LATITUDE_INPUT: 'Latitude',
          VALIDATION_LATITUDE1: 'Latitude must be less than or equal to 90',
          VALIDATION_LATITUDE2: 'Latitude must be greater than or equal to -90',
          LONGITUDE_INPUT: 'Longitude',
          VALIDATION_LONGITUDE1: 'Longitude must be less than or equal to 180',
          VALIDATION_LONGITUDE2: 'Longitude must be greater than or equal to -180',
          CLASIFICATION_TAB: 'Classification',
          Nuevo: 'New',
          Incompleto: 'Incomplete',
        },
        SENSITIVITIES: {
          NEW_SENSITIVITIE: 'New sensitivity',
          SENSITIVITIE: 'Sensitivity',
          NAME: 'Name',
          COD: 'Code',
          SURVEYFREQUENCY: 'Survey Frequency',
          EXPIRATIONDAYS: 'Expiration days',
          VALIDITYINDAYS: 'Effective Days',
          VALIDATION_1: 'The field must be greater than 0',
          SAVE_BTN: 'Save',
          BASIC_INFORMATION_TAB: 'Main Data',
          DAYSTORELIEVE: 'Days to relieve',
          _weekday: {
            Monday: 'Monday',
            Tuesday: 'Tuesday',
            Wednesday: 'Wednesday',
            Thursday: 'Thursday',
            Friday: 'Friday',
            Saturday: 'Saturday',
            Sunday: 'Sunday',
          },
          _frecuency: {
            Daily: 'Daily',
            Weekly: 'Weekly',
            Biweekly: 'Biweekly',
            Monthly: 'Monthly',
            Biannual: 'Biannual',
            Annual: 'Annual',
            BiMonthly: 'Bimonthly',
            ThreeMonth: 'Quarterly',
            Never: 'Never',
          },
        },
        ROUND: {
          ROUTE: 'Prices / Setup',
          TITLE: 'Rounding Rules',
          NEW_ROUNDING_BTN: 'New Rounding Rule',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          PRINT_TTIP: 'Print',
          DELETE_TTIP: 'Delete selected rounding',
          DELETE: 'Delete',
          PRICE_TYPE: 'Price Type',
          SINCE: 'Since',
          TO: 'To',
          DIRECTION: 'Direction',
          TO_TOP_OP1: 'Up',
          NEAREST_OP2: 'Nearest',
          DOWN: 'Down',
          ROUNDING_TYPE: 'Rounding Type',
          WITHOUT_TERMINATIONS: 'Without Ending',
          TOLERANCE: 'Tolerance %',
          SKUS: '# Products',
          SKUS_TO_EXCLUDE: '# Products to Exclude',
          USERS: '# Users',
          DEFINE_ROUNDING_STP1: 'Setup Rounding',
          PRODUCT_SCOPE_STP2: 'Products Scope',
          STORE_SCOPE_STP3: 'Stores Scope',
          STP1: 'Step 1 - Setup Rounding',
          TITLE_FORM_NEW: 'New Rounding',
          TITLE_FORM_EDIT: 'Edit Rounding',
          PRICE_TYPE_INPUT: 'Starting Price Type',
          SINCE_INPUT: 'From',
          TO_INPUT: 'To',
          TOLERANCE_INPUT: 'Tolerance %',
          DIRECTION_INPUT: 'Direction',
          ROUNDING_TYPE_INPUT: 'Rounding Type',
          ROUNDING_UNITS_OP1: 'Rounding Units',
          TERMINATIONS_OP2: 'Endings',
          ROUNDING_UNITS_INPUT: 'Rounding Units',
          TERMINATIONS_INPUT: 'Endings',
          NEXT_BTN: 'Next',
          NEW_TERMINATION: 'New Ending',
          TERMINATION_MODAL_INPUT: 'Ending',
          NO_TERMINATION: 'No ending added',
          ADDED_TERMINATION: 'Added ending',
          ADDED_TERMINATIONS: 'Added endings',
          ADD_BTN: 'Add',
          STP2: 'Step 2 - Products Scope',
          ADD_ATTRIBUTE_BTN: '+',
          PREVIUS_BTN: 'Previous',
          STP3: 'Step 3 - Stores Scope',
          SAVE_BTN: 'Save',
          NONE: 'None',
          ALL: 'All',
          EXCLUDED: 'excluded',
          SEARCH: 'Search',
          milesimas: 'Thousandths ',
          centesimas: 'Hundredths ',
          decimas: 'Tenths ',
          unidades: 'Units ',
          decenas: 'Tens ',
          centenas: 'Hundreds ',
          miles: 'Thousands ',
          decenas_de_miles: 'Tens of thousands',
          Nearest: 'To the nearest',
          Down: 'Down',
          Up: 'Up',
        },
        RULES: {
          DELETE_MODAL: {
            TITLE_ONE: 'Do you want to remove {{val}}?',
            CONTENT: 'Suggestions pending approval will be discarded.\\n Suggestions that are manually approved or modified will be kept.',
            TITLE_MULTIPLE: 'Do you want to delete the selected rules ({{val}})?',
          },
          PARENT_STORE_TITLE: '{{val}} Relationships',
          PARENT_ITEM_TITLE: 'Product Relationships',
          COMPETENCE_TITLE: 'Competitive',
          MARGIN_TITLE: 'Margin',
          SELECT_ALL_SUBCATEGORY: 'Select all Subcategories',
          SELECT_ALL_CATEGORY: 'Select all Categories',
          ROUTE: 'Prices / Setup',
          TITLE: 'Pricing Rules',
          SUGGESTION_BTN: 'Generate Suggestions',
          GENERATE_SUGGESTIONS_TOOLTIP: 'In order to generate suggestions, you must select a category (only one) or items from the list.',
          GENERATE_SUGGESTIONS_TOOLTIP_WITH_CATEGORY_REQUIRED: 'You cannot generate suggestions for more than one category each time',
          GENERATE_SUGGESTIONS_TOOLTIP_WITH_CATEGORY_REQUIRED_MODAL_DESCRIPTION:
            'This can be applied in the advanced filter or from this same window. Once this selection is applied, the list will be filtered with the rules reached and you will be able to use the Generate Suggestion button (green color) to order it.',
          GENERATE_SUGGESTIONS_SELECTION: 'Suggestions will be generated regarding the following selected category: ',
          SUGGESTION_OP1_MARGIN: 'Margin',
          SUGGESTION_OP2_COMPETITION: 'Competition',
          NEW_RULE_BTN: 'New Rule',
          NEW_RULE_TITLE_MODAL: 'New Rule',
          RULE_TYPE_INPUT: 'Rule Type',
          ADDITIONAL_FILTERS: 'Additional filters',
          LEGEND_COMP: 'Competitive rules are triggered when a change in competitors prices is detected.',
          LEGEND_MARG: 'Margin rules are triggered when a change in supplier or manufacturing costs is detected.',
          LEGEND_P_STORES: 'Parent Rules of {{val}} are triggered every time the price of a {{val2}} parent is changed.',
          STORES_VAL: 'Stores',
          LEGEND_P_ITEMS: 'Product relationships are evaluated each time a price of a parent product is changed.',
          CANCEL_BTN: 'Cancel',
          CREATE_BTN: 'Create',
          FILTER_TTIP: 'Filter',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          DELETE_TTIP: 'Delete selected rules',
          DELETE: 'Delete',
          CLEAN_FILTERS: 'Clear Filters',
          SHOW_RULES: 'Select at least one filter to show the Rules',
          NAME: 'Name',
          TYPE: 'Type',
          STATE: 'State',
          COUNTRY: 'Country',
          ZONE: 'Zone',
          STORES: '# Stores',
          ITEMS: '# Products',
          MARGIN: 'Margin %',
          IPC: 'IPC %',
          LAST_MODIFICATION: 'Last modification',
          USER: 'User',
          TYPE_RULES: 'Price rules',
          SUGGESTIONS_GENERATED_SNACKBAR:
            'The process to generate suggestions was initiated. You will receive a notification when this process is completed.',
          INCLUDED_ITEMS: 'Included Products',
          ACCEPT_BTN: 'Accept',
          INCLUDED_STORES: 'Included Stores',
          TITLE_FILTERS: 'Filters',
          CATEGORY_FILTER: 'Category',
          SEARCH_CATEGORIES_FILTER: 'Search Categories',
          SUBCATEGORY_FILTER: 'Subcategories',
          SEARCH_SUBCATEGORIES_FILTER: 'Search Subcategories',
          RULE_TYPE_FILTER: 'Rule Type',
          USER_FILTER: 'User',
          SEARCH_USER_FILTER: 'Search User',
          CLEAN_FILTER_BTN: 'Clear',
          APPLY_FILTER_BTN: 'Apply',
          DEFINE_RULE_STP1: 'Setup Rule',
          PRODUCT_SCOPE_STP2: 'Products Scope',
          STORE_SCOPE_STP3: 'Stores Scope',
          STP3_STORE: 'Step 3 - Products Scope',
          STP2_STORE: 'Step 2 - Stores Scope',
          STP4_COMPETENCE: 'Step 5 - Limits by Competitor',
          STP5_COMPETENCE: 'Step 6 - Margin and Variation Limits',
          STP5_COMPETENCE_ALT: 'Step 5 - Margin and Variation Limits',
          STP3_MARGIN: 'Step 4 - Limits by Competitor',
          STP4_MARGIN: 'Step 5 - Margin Limits',
          STP4_MARGIN_ALT: 'Step 3 - Margin Limits',
          STORE_SCOPE_STP5: 'Limits by Competitor',
          STORE_SCOPE_STP6: 'Margin and Variation Limits',
          TITLE_FORM_NEW: 'New Margin or Markup Rule',
          TITLE_FORM_EDIT: 'View Margin or Markup Rule',
          STP1: 'Step 1 - Setup Rule',
          NAME_INPUT: 'Name',
          QUERY: 'Description / Code',
          POSITION_TYPE_INPUT: 'Rule type',
          INDICATE_MARGIN_INPUT: 'Indicate margin',
          VALIDATION_MARGIN: 'The field must be less than 100',
          NEXT_BTN: 'Next',
          STP2: 'Step 2 - Products Scope',
          ADD_ATTRIBUTE_BTN: '+',
          SEARCH: 'Search',
          SEE_PROD: 'View Products',
          CODE: 'Code',
          BRAND: 'Brand',
          MANUFACTURER: 'Manufacturer',
          PRICE: 'Price $',
          COST: 'Cost $',
          PRICE_CHIP: 'Price: $',
          COST_CHIP: 'Cost: $',
          REFRESH_PRODUCTS: 'Refresh Products',
          REFRESH_STORES: 'Refresh Stores',
          SEE_STORE: 'View Stores',
          SELECT_PROD: 'Selected Products ',
          SELECT_STORE: 'Selected Stores ',
          FILTER_PROD: 'Filtered Products ',
          FILTER_STORE: 'Filtered Stores ',
          PRICE_TABLE: 'Price: $',
          COST_TABLE: 'Cost: $',
          ADD_PROD: 'Add Products to the list',
          CHANGE_FORM: 'Change Filters',
          PREVIUS_BTN: 'Previous',
          STP3: 'Step 3 - Stores Scope',
          SAVE_BTN: 'Save',
          EXIT_BTN: 'Exit',
          SKIP_BTN: 'Skip',
          NONE: 'None',
          ALL: 'All',
          EXCLUDED: 'excluded',
          COMPETITORS_SCOPE_STP2: 'Competitors Scope',
          PARENT_PRODUCT_SCOPE_STP1: 'Setup Rule',
          PARENT_PRODUCT_SCOPE_STP2: 'Products Scope',
          PARENT_PRODUCT_SCOPE_STP3: 'Stores Scope',
          PARENT_PRODUCT_SCOPE_STP4: 'Item Relationship Rule Configuration',
          PARENT_PRODUCT_SCOPE_STP1_TITLE: 'Step 1 - Setup Rule',
          PARENT_PRODUCT_SCOPE_STP2_TITLE: 'Step 2 - Products Scope',
          PARENT_PRODUCT_SCOPE_STP3_TITLE: 'Step 3 - Stores Scope',
          PARENT_PRODUCT_SCOPE_STP4_TITLE: 'Step 4 - Summary',
          PARENT_STORE_SCOPE_STP1: 'Setup Rule',
          PARENT_STORE_SCOPE_STP2: 'Stores Scope',
          PARENT_STORE_SCOPE_STP3: 'Products Scope',
          PARENT_STORE_SCOPE_STP1_TITLE: 'Step 1 - Setup Rule',
          PARENT_STORE_SCOPE_STP2_TITLE: 'Step 2 - Stores Scope',
          PARENT_STORE_SCOPE_STP3_TITLE: 'Step 3 - Products Scope',
          STORE_SCOPE_STP4: 'Stores Scope',
          COMPETITION_TITLE_FORM_NEW: 'New Competition Rule',
          COMPETITION_TITLE_FORM_EDIT: 'View Competition Rule',
          POSITIONING_INPUT: 'Positioning',
          PERCENT_MARGIN_OBJETIVE: '%',
          ONLY_ALERT: 'Alert Only: Will generate an alert on the price decision screen but will not generate any change suggestion.',
          DO_NOTHING: 'Do nothing',
          IN_CASE: 'In case of not finding prices of Competitors:',
          PRICE_SUGGESTION_STEP5: 'The suggested price must be:',
          SELECTION_COMPETITORS_STEP5: 'Selection of competitors to calculate the limit:',
          RADIO_MIN_STEP5: 'At least one',
          RADIO_MAX_STEP5: 'At most one',
          RADIO_AVG_STEP5: 'Enter the',
          MORE: 'more',
          AND_THE: 'and the',
          COMPETITORS_STEP5: 'which competitors to select',
          COMPETITORS_STEP5_SECONDVALUE_CHEAP: 'cheaper',
          COMPETITORS_STEP5_SECONDVALUE_EXPENSIVE: 'more expensive',
          COMPETITORS_AVG_STEP5: 'with respect to the competitors to select in this step',
          SUGESSTION_LOW_STEP5: 'Suggest below Minimum',
          SUGESSTION_HIGH_STEP5: 'Suggest above Maximum',
          TITLE_COMPETITORS_STEP6:
            'Specific margin and variation guidelines can be defined for this rule. If you want to use the category guidelines, save the changes directly by skipping this step.',
          TITLE_COMPETITORS_STEP6_NOTICE: 'If this limit is configured, the category limits configuration will be overridden.',
          LINEAL_MARGIN_COMPETITORS_STEP6: 'Margin Guidelines',
          MIN_MARGIN_INPUT_STEP6: 'Margin Min %',
          MAX_MARGIN_INPUT_STEP6: 'Margin Max %',
          MIN_MARGIN_INPUT_STEP6_ALT: null,
          MAX_MARGIN_INPUT_STEP6_ALT: null,
          VARIATION_MARGIN_INPUT_STEP6: 'Significant Variation Margin $',
          MIN_SUGGESTION_MARGIN_INPUT_STEP6: 'Suggest below Minimum',
          MAX_SUGGESTION_MARGIN_INPUT_STEP6: 'Suggest above Maximum',
          VARIATION_PRICE_COMPETITORS_STEP6: 'Price Variation',
          MIN_VARIATION_PRICE_INPUT_STEP6: 'Maximum Decrease %',
          MAX_VARIATION_PRICE_INPUT_STEP6: 'Maximum Increase %',
          MIN_VARIATION_PRICE_INPUT_STEP6_ALT: null,
          MAX_VARIATION_PRICE_INPUT_STEP6_ALT: null,
          MIN_SUGGESTION_VARIATION_PRICE_INPUT_STEP6: 'Suggest below Minimum',
          MAX_SUGGESTION_VARIATION_PRICE_INPUT_STEP6: 'Suggest above the Maximum',
          MARGIN_OBJETIVE: 'Define Target Margin %',
          MARGIN_OBJETIVE_CATEGORY: 'Margin Objective by Subcategories',
          MARGIN_OBJETIVE_CATEGORY_HELP: 'Keep in mind that the Target Margin % must be complete in Guidelines and Strategies.',
          POSITION_AGAINST_INPUT: 'Position Yourself Against',
          POSITION_TREND_INPUT: 'If there is more than one trend to use',
          SURVEY_SOURCES_INPUT: 'Survey sources',
          STP2_COMP: 'Step 2 - Competitor Selection',
          STP2_COMP_BUTTON_TITLE: 'Select how you want to compare against your competitors.',
          MAINMARKET_TITLE: 'Markers',
          MAINMARKET_MSG: 'It will search the price of the Markers of each Point of Sale.',
          USE_TRADE_AREA_TITLE: 'Trade Area',
          USE_TRADE_AREA_MSG: 'It will search the price of the Competitors associated with the Trade Area of ​​each Point of Sale.',
          SELECT_COMPETITORS_TITLE: 'Selection of Competitors',
          SELECT_COMPETITORS_MSG: 'It will search for competitors who meet the criteria to be defined below',
          TRADE_AREA_CHECK: 'Only trade area',
          MARKERS_CHECK: 'Only markers',
          STP3_COMP: 'Step 3 - Products Scope',
          STP4_COMP: 'Step 4 - Stores Scope',
          STORE_PARENT_TITLE_FORM_NEW: 'New Rule Store Relationships',
          STORE_PARENT_TITLE_FORM_EDIT: 'View Rule Store Relationships',
          STORE_PARENT_INPUT: 'Store Relationships',
          STORE_PARENT_TTIP:
            'Only the stores that have not been assigned as related stores in other rules will appear in the list of parent stores.',
          SEARCH_PARENT: 'Search Parent',
          RELATIONSHIP_TYPE_INPUT: 'Child items position',
          DIFFERENCE_IN_OPTION: 'Difference in',
          DIFFERENCE_IN_INPUT: 'Condition',
          relationshipType_Price: 'Evaluate price',
          relationshipType_Margin: 'Evaluate margin',
          relationshipType_DeltaPrice: 'Clone parent item values',
          EVALUATE_PARENT_ITEM_PRICE: 'Evaluate parent item price',
          EVALUATE_PARENT_ITEM_MARGIN: 'Evaluate parent item margin',
          DIFFERENCE_INPUT: 'Value',
          DIFFERENCE_INPUT_NOT_ZERO:
            'The value must be different from 0 (Zero). If you want to replicate the value from the parent item, you must select “Match parent item margin/price” in the Condition field.',
          TEXT_P_STORES: 'The {{val}} children will have {{val2}} {{val3}} father',
          CATEGORIES_INPUT: 'Categories*',
          CATEGORIES_INPUT_EXC: 'Categories Excluded*',
          CATEGORIES_TTIP: 'You must select at least one category, to select the subcategories',
          SEARCH_CATEGORIES: 'Search Categories',
          SUBCATEGORIES_INPUT: 'Subcategories*',
          SUBCATEGORIES_INPUT_EXC: 'Subcategories Excluded*',
          SUBCATEGORIES_TTIP: 'You must select at least one subcategory per selected category',
          SEARCH_SUBCATEGORIES: 'Search Subcategories',
          SELECT_ALL: 'Select all',
          VALIDATION_CATEGORY: 'You must select at least one category',
          VALIDATION_SUBCATEGORY: 'You must select at least one option for each selected category',
          GET_OUT_BTN: 'Exit',
          SAME_PRICE: 'the same price that ',
          HIGHER: 'Higher',
          LOWER: 'Lower',
          UNDER: 'under',
          ABOVE: 'above',
          MAYOR: 'Higher',
          MINOR: 'Lower',
          TEXT_P_STORES_TS: 'a Price {{val}} in {{val2}} at the',
          ITEM_PARENT_TITLE_FORM_NEW: 'New Rule Product Relationships',
          ITEM_PARENT_TITLE_FORM_EDIT: 'View Rule Product Relationships',
          PARENT_RULE_GENERATE_SUGGESTION_TITLE: 'Generate Suggestions',
          PARENT_RULE_ONSAVE_GENERATE_SUGGESTION_CONTENT: 'The rule will be executed immediately',
          ITEM_PARENT_INPUT: 'Product Relationships',
          ITEM_PARENT_TTIP:
            ' Only those products that have not been assigned as related products in other rules will appear in the list of parent products.',
          TEXT_P_COMPETENCE: 'Position a {{val}} ',
          TEXT_P_COMPETENCE_TS: '{{val}} {{val2}} the {{val3}} ({{val4}}) of my competitors',
          TEXT_P_COMPETENCE_TRADEAREA: 'within {{val}} of each Store',
          TEXT_P_COMPETENCE_MARKERS: '| markers',
          TEXT_P_COMPETENCE_COMPETITORS_SELECTED: 'selected',
          TEXT_P_COMPETENCE_STP3: 'within {{val}} of each Store',
          TEXT_P_COMPETENCE_STP4: 'for selected items',
          TEXT_P_COMPETENCE_STP4_ALL: 'for all items ',
          TEXT_P_ACTUAL_MARGIN: '{{val}} {{val2}}',
          TEXT_P_MARGIN: 'Position with a {{val}} {{val2}}',
          TEXT_P_MARGIN_TS_1: 'for selected items',
          TEXT_P_MARGIN_TS_2: 'in the required regions ',
          TEXT_P_MARGIN_POSITION: '{{val}} of{{val2}}',
          TEXT_P_MARGIN_POSITION_0: 'Margin',
          TEXT_P_MARGIN_POSITION_1: 'Margin',
          TEXT_P_MARGIN_POSITION_2: 'Keep current margin in %',
          TEXT_P_MARGIN_POSITION_4: 'Keep current margin in $',
          TEXT_P_MARGIN_POSITION_5: 'Target margin by Zone',
          TEXT_P_MARGIN_POSITION_6: 'Markup',
          TEXT_P_MARGIN_POSITION_7: 'Category Margin',
          MARGIN_POSITION_TYPE_0: 'Margin in %',
          MARGIN_POSITION_TYPE_1: 'Margin in $',
          MARGIN_POSITION_TYPE_2: 'Keep current margin in %',
          MARGIN_POSITION_TYPE_4: 'Keep current margin at $',
          MARGIN_POSITION_TYPE_5: 'Target margin per Zone',
          MARGIN_POSITION_TYPE_6: 'Markup in %',
          MARGIN_POSITION_TYPE_7: 'Category Margin',
          LEGEND_RULE_TITLE: 'RULE: ',
          TEXT_P_ITEMS: 'Related Products will have {{val}} {{val2}}',
          TEXT_P_ITEMS_VARIANT: 'Related Products will have the same Delta Price to {{val}}',
          TEXT_P_ITEMS_TS: 'a {{val}} Price by {{val2}} than ',
          TEXT_P_ITEMS_TS_MARGEN: 'a Margin by {{val}} than ',
          TEXT_P_STORE: 'Related Stores will have {{val}} {{val2}}',
          TEXT_P_STORE_VARIANT: 'Related Stores will have the same Delta Price to {{val}}',
          SEARCH_RESULT_CHILD_STORE: '{{val}} Stores will be affected by this rule.',
          TEXT_P_STORE_TS: 'a Price {{val}} in {{val2}} to ',
          MARK_AS_ZONE: 'Mark as Zone',
          MARK_AS_ZONE_TTIP: 'Same price for all areas',
          STRATEGY: 'Choose the selection strategy of the child products',
          STRATEGY_ATTR_TITLE: 'Selection by attribute (Dinamic)',
          STRATEGY_ATTR_MSG:
            'Attributes are selected to define the scope of products, if the scope changes, the impact of this setting is done automatically and does not need to be changed',
          STRATEGY_STORE_ATTR_MSG: 'Attributes are selected to define the scope of stores',
          STRATEGY_PROD_TITLE: 'Selection by product',
          STRATEGY_STORE_TITLE: 'Selection by store',
          STRATEGY_PROD_MSG: 'Products should be selected one by one',
          STRATEGY_STORE_MSG: 'Stores should be selected one by one',
          STP3_PARENT_ITEM: 'Step 3 - Stores Scope',
          GENERIC_MSG_ERRROR_EXECSUGGESTION: 'Suggestions could not be generated.',
          GENERIC_MSG_NOSUGGESTIONS_EXECSUGGESTION: 'There are no new suggestions.',
          NO_DIFF: 'No Difference',
          LEVEL_SELECT: 'Select Category Tree Level',
          RULE_NAME: 'Rule name',
          RULE_CONDITION: 'Condition',
          RULE_CLONE_CHILD_ITEMS: 'Child items will clone any price or margin modification from the parent item.',
          RULE_SAME_PRICE_PARENT_ITEM: 'Child items will maintain the same {{val}} as the parent item.',
          RULE_PRICE_POSITION: 'Child items will position with a {{val}} of {{val2}} relative to the parent item.',
          RULE_PRICE: 'price',
          RULE_MARGIN: 'margin',
          RULE_ITEM_PARENT_SELECTED: 'Parent item selected',
          RULE_ITEM_CHILD_SELECTED: 'Child items selected',
          RULE_PRICE_UPDATE_ON_CHILD:
            'Any price modification on the parent item will be replicated in the child items according to the configuration.',
          RULE_POINT_OF_SALE_SELECTED: 'Selected points of sale',
          RULE_REMEMBER_PARENT_ITEM_INDEPENDENT:
            'Remember that since it is an item relationship rule, the parent item is independent of the point of sale. ',
          RULE_REMEMBER_CHECK_ASSORTMENT:
            'Remember to check the assortment of the points of sale to ensure that the child items are included.',
        },
        BRAND: {
          ROUTE: 'Prices / Setup',
          TITLE: 'Brands',
          NEW_BRAND_BTN: 'New Brand',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          DELETE_TTIP: 'Delete selected brands',
          DELETE: 'Delete',
          NAME: 'Name',
          LOGO: 'Logo',
          COLOR: 'Color',
          TITLE_FORM_NEW: 'New Brand',
          SAVE_BTN: 'Save',
          BASIC_INFORMATION_TAB: 'Main Data',
          NAME_INPUT: 'Name',
          PICK_COLOR: 'Pick a color',
          CLEAN_COLOR_TTIP: 'Clean color',
          IS_ASIGNED: 'This flag is assigned to points of sale or competitors so it can not be eliminated',
          OWN_BRAND: 'Own Brand',
        },
        GUI_AND_STRA: {
          ROUTE: 'Prices / Setup',
          TITLE: 'Guidelines and Strategies',
          EXPORT_TTIP: 'Export',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          CATEGORIES: 'Categories',
          RULES: 'Rules',
          RULES_TTIP: 'Number of price rules',
          TARGET_MARGIN: 'Target Margin %',
          TARGET_MARGIN_TTIP: 'Target Margin Percentage',
          MIN_MARGIN: 'Min Mg %',
          MIN_MARGIN_TTIP: 'Minimum Margin Percentage',
          MAX_MARGIN: 'Max Mg %',
          MAX_MARGIN_TTIP: 'Maximum Margin Percentage',
          ALLOW_MIN_VIOLATION: 'Sug. Under Min Mg',
          ALLOW_MIN_VIOLATION_TTIP:
            'If this box is checked, Prisma will suggest below the minimum margin, and will display a warning sign next to the suggestion. Otherwise the suggestion will consider the minimum value entered.\n',
          ALLOW_MAX_VIOLATION: 'Sug. On Max Mg',
          ALLOW_MAX_VIOLATION_TTIP:
            'If this box is checked, Prisma will suggest above the maximum margin, and will display a warning sign next to the suggestion, otherwise the suggestion will take you to the maximum value entered.',
          SIGNIFICANT_VAR_MARGIN: 'Significant Variation Mg $',
          SIGNIFICANT_VAR_MARGIN_TTIP: 'Significant Variation of Margin in $',
          MIN_VARIATION: 'Max Dec. %',
          MIN_VARIATION_TTIP: 'Maximum price decrease allowed per price change',
          MAX_VARIATION: 'Max Inc. %',
          MAX_VARIATION_TTIP: 'Maximum price increase allowed per price change',
          ALLOW_MIN_VARIATION: 'Sug. Above Max Dec.',
          ALLOW_MIN_VARIATION_TTIP:
            'If this box is checked, Prisma will suggest above the maximum decrease, and will display a warning sign next to the suggestion, otherwise the suggestion will take you to the maximum value entered.',
          ALLOW_MAX_VARIATION: 'Sug. Above Max Inc.',
          ALLOW_MAX_VARIATION_TTIP:
            'If this box is checked, Prisma will suggest above the maximum increase, and will display a warning sign next to the suggestion, otherwise the suggestion will take you to the maximum value entered.',
          SIGNIFICANT_VARIATION: 'Significant Variation $',
          SIGNIFICANT_VARIATION_TTIP: 'Significant Variation in $',
          SAVE_BTN: 'Save',
          BASIC_INFORMATION_TAB: 'Main Data',
          MARGIN_GUIDELINES: 'Margin Guidelines',
          TARGET_MARGIN_INPUT: 'Target Margin %',
          MIN_MARGIN_INPUT: 'Margin Min %',
          MAX_MARGIN_INPUT: 'Margin Max %',
          SIGNIFICANT_VAR_MARGIN_INPUT: 'Significant variation of margin $',
          ALLOW_MIN_VIOLATION_CHECK: 'Suggest below minimum',
          ALLOW_MIN_VIOLATION_CHECK_TTIP:
            'If this box is checked, Prisma will suggest below the minimum margin, and will show a warning sign next to the suggestion, otherwise the suggestion will take you to the minimum value entered.',
          ALLOW_MAX_VIOLATION_CHECK: 'Suggest above Maximum',
          ALLOW_MAX_VIOLATION_CHECK_TTIP:
            'If this box is checked, Prisma will suggest above the maximum margin, and will show a warning sign next to the suggestion, otherwise it will take the suggestion to the maximum value',
          VARIATION_PRICE: 'Price Variation',
          MIN_MARGIN_V_INPUT: 'Maximum Decrease %',
          MAX_MARGIN_V_INPUT: 'Maximum Increase %',
          SIGNIFICANT_VAR_MARGIN_V_INPUT: 'Significant Variation of Margin $',
          ALLOW_MIN_VIOLATION_V_CHECK: 'Suggest Below Minimum',
          ALLOW_MIN_VIOLATION_V_CHECK_TTIP:
            ' If this box is checked, Prisma will suggest above the maximum decrease, and will display a warning sign next to the suggestion, otherwise the suggestion will take you to the maximum value entered.',
          ALLOW_MAX_VIOLATION_V_CHECK: 'Suggest above Maximum',
          ALLOW_MAX_VIOLATION_V_CHECK_TTIP:
            ' If this box is checked, Prisma will suggest above the maximum increase, and will display a warning sign next to the suggestion, otherwise the suggestion will take you to the maximum value entered.',
          TITLE_MODAL: 'Impact changes to the next levels of the category tree',
          SUBTITLE_MODAL:
            'Do you want to establish these same guidelines for the following levels of the category tree, up to and including the subcategory level?',
          REFUSE_BTN: 'Cancel',
          ACCEPT_BTN: 'Accept',
        },
      },
      REPORTS: {
        ROUTE: 'Prices / Reports',
        SUGGESTIONREPORTS: {
          ROUTE: 'Prices / Reports',
          GENERATE: 'Generate report',
          TITLE: 'Suggestions Report',
          GENERATE_TITLE: 'Generate Suggestions Report',
          INPROCESS: 'Generating report',
          DAY: 'Day',
          INTRODUCTION: 'Generates a report with the details of all the suggestions from the selected day',
        },
        COSTREPORTS: {
          ROUTE: 'Prices / Reports',
          GENERATE: 'Generate report',
          TITLE: 'Cost Report',
          GENERATE_TITLE: 'Generate Cost Report',
          INPROCESS: 'Generating report',
          DAY: 'Day',
          INTRODUCTION:
            'Generates a report detailing all the filtered costs. From here you can select a date range of no more than 30 days to download costs.',
          PATH: 'Prices / Reports',
        },
        YPF: {
          ROUTE: 'Prices / Reports',
          TITLE: 'Reports YPF',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          COMPETITIVE_POSITION: 'Competitive Positioning',
          EXPORT_TTIP: 'Export',
          APP_TTIP: 'Share to the App',
          DASHBOARD_TTIP: 'Go to dashboard',
          COMPETITOR: 'Competitor',
          N2: 'N2',
          N3: 'N3',
          G2: 'G2',
          G3: 'G3',
          CHEAPER: 'Cheaper',
          EQUEAL_PRICE: 'Equal price',
          MORE_EXPENSIVE: 'More Expensive',
          PROVINCE_FILTER: 'Province',
          FILTER: 'Filter',
          LOCATION_FILTER: 'Location',
          BRANDS_FILTER: 'Brands',
          APPLY_BTN: 'APPLY FILTERS',
          COMPETITION_PRICES: 'Competition Prices',
          NS: 'NS',
          INF: 'INF',
          UD: 'UD',
          D500: 'D500',
          INF_D: 'INF D',
          INF_NS: 'INF vs NS',
          INFD_D500: 'INF D vs D500',
          RETAIL_MARGIN: 'Retail Margins',
          PRODUCTS_FILTER: 'Products',
        },
        DAILYMARGIN: {
          LIST: {
            TITLE: 'Daily Margins Reports',
            ROUTE: '/ prices / reports / daily-margin',
            DELETE: 'Delete',
            DELETE_TTIP: 'Delete',
            FILTER_TTIP: 'Filter',
            EXPORT: 'Download',
            EXPORT_TTIP: 'Export report on excel format',
            STORECODE: 'Store code',
            STORENAME: 'Store',
            ITEMCODE: 'Product code',
            ITEMNAME: 'Product',
            PRICE: 'Price',
            PRICEWOTAXES: 'Price without taxes',
            COST: 'Cost',
            MARGIN: 'Margin',
            MAXMARGINAMOUNT: 'Maximum margin',
            COMPETITOR: 'Competitor',
            COMPETITORSPRICE: 'Competitor price',
            MARKETAVERAGEPRICE: 'Average Price',
            CURRENTDATE: 'Current date',
            MARGINVSMAXMARGIN: 'Margin vs Maximum Margin',
            COMPETITIVEINDEX: 'Competitiveness',
            MARKETCOMPETITIVEINDEX: 'Market competitiveness',
          },
        },
        PRICE_INCONSISTENCY: {
          ROUTE: 'Prices / Reports',
          TITLE: 'Price Inconsistencies',
          CODE: 'Code',
          NAME: 'Name',
          CURRENT_PRICE: 'Current Price',
          MAXIMUM_PRICE: 'Maximum price',
          STORE: 'Store',
          FILTER_TTIP: 'Filter',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          TITLE_FILTERS: 'Filters',
          CATEGORY_FILTER: 'Category',
          SEARCH_CATEGORY_FILTER: 'Search Categories',
          CLEAN_FILTER_BTN: 'Clear',
          APPLY_FILTER_BTN: 'Apply',
        },
        PRICES_MAP: {
          ROUTE: 'Prices / Reports',
          TITLE: 'Prices Map',
          CHOOSE_PRODUCT: 'Choose a product to show the map',
          CHOOSE_PRODUCT_AND_ZONE: 'Choose a product and zone to show the map',
          FILTER_TTIP: 'Filter',
          PRODUCT_FILTER: 'Product',
          MARKER_FILTER: 'Marker',
          OP1_ALL: 'All',
          OP2_MARKER: 'Marker',
          OP3_NO_MARKER: 'No Marker',
          NAME: 'Name',
          CODE: 'Code',
          PRICE: 'Price',
          BRAND: 'Brand',
          MARKER: 'Marker',
          Nuevo: 'New',
          SOURCE: 'Source',
          SOURCE_TTIP: 'Survey source',
          VALIDFROM: 'Survey Date',
          Incompleto: 'Incomplete',
          OWN_BRAND: 'Own Brand',
          CHART_TITLE: 'Historical Prices',
          CHART_TITLE_PRICE: 'Price',
          CHART_TITLE_VENTAS: 'Sales',
          REFERENCE_STORE: 'Reference Store',
        },
        COMPETITOR_PRICES: {
          ROUTE: 'Prices / Reports',
          TITLE: 'Competitor Prices',
          DOWLOAD_REPORT: 'Download Report',
          SINCE_INPUT: 'From',
          TO_INPUT: 'To',
        },
        PRICES_AUDIT: {
          PRICE_CHANGES: 'Price Auditing',
          PROD_FILTERS: 'Product Filters',
          FUELS: 'Fuels',
          BUSINESS_UNIT: 'Business Unit',
          DEP: 'Department',
          CAT: 'Category',
          SUBCAT: 'Subcategory',
          PROD: 'Product',
          POS_FILTERS: 'POS Filters',
          REGION: 'Region',
          OPERATION_TYPE_FILTER: 'Operation Type',
          STORE_TYPE_INPUT: 'Store',
          DATE_FILTER: 'Date Filters',
          INITIAL_DATE: 'Initial Date',
          END_DATE: 'End date',
          USER: 'User',
          REPORT: 'Generate Report',
          REPORT_UPDATE: 'Update Report',
          CODE: 'Code',
          STORES: 'Stores',
          NAME: 'Name',
          PREVIUS_PRICE_INPUT: '$ Previous price',
          MARGIN_PRICE_INPUT: '% Previous Margin',
          NEW_PRICE_INPUT: '$ New price',
          AUDIT_PRICE_CHANGE: 'Price Change',
          MARGIN_NEW: '% New Margin',
          INFORM: 'informed Date',
          INFORM_BY: 'updated By',
          SUBTITLE: 'Pricing / Reports',
        },
        PRICE_REPORT: {
          TITLE: 'Pricing / Reports',
          WeightedIndex: 'W index',
          SalesUnits: 'Sales U',
          Sales: 'Sales $',
          Margin: 'Margin $',
          PercentageMargin: 'Margin %',
          Gmroi: 'GMROI',
          COMPETITIVENESS_INDEX: 'Competitiveness index',
          PREVIOUS_MONTH_DIF: 'Dif % Vs Previous Month',
          SUGGESTIONS_ACCEPTED: '% Suggestions Accepted',
          MarginDif: 'Margin Dif. vs Previous Month',
          PercentageMarginDif: 'Percentage Point vs Previous month',
          DEC_TYPE: 'Types of Decisions',
          INDEX_COMP: 'Competitiveness Index (IC%)',
          CATEGORY: 'Category',
          CHEAPER: 'Cheaper',
          SAME: 'Same',
          MORE_EXPENSIVE: 'More Expensive',
          PRICES_TYPES: 'Price Types',
          PERIOD: 'Period',
          CATEGORYS: 'Categories',
          EVOL_GRAPH: 'Evolutionary Graph',
          VARIABLE: 'Variable',
          VARIABLES: 'Variables',
          MAP: 'Map',
          PRODUCT_PERFORMANCE: 'Product Performance',
          PRODUCT: 'Product',
          VOLUME: 'Volume',
        },
      },
      DECISIONS: {
        SUMMARY: {
          ROUTE: 'Prices / Decisions',
          TITLE: 'Decisions Summary',
          REVISE_BTN: 'Review all',
          UPDATE_STATISTICS: 'Update Statistics',
          LASTUPDATEDON: 'Last updated on:',
          FILTER_TTIP: 'Filter',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          REVIEW_PENDING: '# Pend. Rev.',
          CATEGORY: 'Category',
          REVIEW_PENDING_TTIP: 'Suggestions Pending Review',
          DECISIONS: '# Decis.',
          DECISIONS_TTIP: 'Decisions Made',
          ADVANCED: 'Progress %',
          ADVANCED_TTIP: 'Take into account only suggestions',
          SEND: 'Send',
          SUGGESTED_IMPACT: 'Sug. Imp. $',
          SUGGESTED_IMPACT_TTIP: 'Suggested Impact',
          DECISIONS_IMPACT: 'Decis. Imp. $',
          DECISIONS_IMPACT_TTIP: 'Decisions Impact',
          REVIEW_IMPACT: 'Rev. Impact $',
          REVIEW_IMPACT_TTIP: 'Review Impact',
          PENDING_APPROVAL: '# Pend. Approve.',
          PENDING_APPROVAL_TTIP: 'Number of Decisions Pending for Approval',
          APPROVED: '# Approve.',
          APPROVED_TTIP: 'Approved Decisions',
          TITLE_FILTERS: 'Filters',
          PROVINCES_FILTER: 'State',
          CLEAN_FILTER_BTN: 'Clear',
          APPLY_FILTER_BTN: 'Apply',
          REJECTED_TTIP: 'Rejected',
          REJECTED: 'Rejtd.',
          APPROVE: 'Approve',
          APPROVE_ALL: 'Approve All',
          REVIEW: 'Review',
          CONFIRM_SEND: 'Confirm Send?',
          CONFIRM_SEND_ALL: 'Do you confirm the send all items?',
          CONFIRM_APPROVE: 'Confirm approval?',
          CONFIRM_APPROVE_ALL: 'Confirm approval of all items?',
          CONFIRM_REVIEW: 'Confirm the review of all items?',
        },
        ERP: {
          ROUTE: 'Prices / Decisions',
          TITLE: 'Publish Decisions',
          DOWLOAD_BTN: 'Download Info',
          PREVIEW_BTN: 'Preview',
          INFORM_BTN: 'Publish',
          LAST_PRICES: 'Latest Prices Published',
          GENERATION_DATE: 'Generation date',
          GENERATION_TIME: 'Generation Time',
          USER: 'User',
          FILE: 'File',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          PREVIEW_PROCCESING: 'Processing has started. You will receive a notification when it is finished.',
          PREVIEW_WITHOUT_PROCCESING: 'There is no new data to process.',
        },
        PRICE_DECISIONS: {
          NO_GROUPID: 'Choose a category to display the list',
          APPLY_FILTERS: 'Applicable filters',
          STRATEGY_DIALOG: {
            UNIT: 'Unit',
            Price: '$',
            Margin: '%',
            UsdxM3: 'USD * M3',
          },
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          SUGGESTED_IMPACT_TITLE: 'Suggested Impact $',
          CHANGES_TITLE: '# Changes',
          VS_SUGGESTED_IMPACT_TITLE: 'Vs Suggested Impact $',
          CHANGES_PRICE_TITLE: 'Price Change %',
          NEW_IMPACT_TITLE: 'New Impact $',
          SUGGESTIONS_ACCEPTED_DESCRIPTION: 'Suggestions Accepted',
          WEIGHTED_SALE_DESCRIPTION: 'Weighted by sale',
          PERCENT_CHANGE: 'Changes %',
          FILTER_CHIP: 'Filter price by',
          CODE_ITEM: 'Item code',
          CODE_STORE: 'Store code',
          PARENT_RULE: 'Parent rule',
          FILTER_CHIP_PARENT: 'Filter prices by parent rule',
          FILTER_CHIP_PARENT_ITEM: 'Parent Item:',
          FILTER_CHIP_PARENT_STORE: 'Parent Store:',
          TABLE_TITLE: 'Suggestion Analysis',
          INIT_PRICE_IMPORT_TITLE: 'Initialized price per import',
          OBSERVATIONS: 'Observations',
          REASON: 'Reason',
          STEP_BY_STEP: 'Step by step',
          SEE_MORE_TABLE: 'See More...',
          PRICE_TABLE: 'Price',
          MARGIN_TABLE: 'Margin',
          CI_TABLE: 'Costs more Taxes',
          ORIGINAL_TABLE: 'Original $',
          ACTUAL_TABLE: 'Current $',
          SUGGESTED_TABLE: 'Suggested $',
          DECISION_TABLE: 'Decision $',
          ROUTE: 'Prices / Decisions',
          TITLE: 'Pricing Decisions',
          FILTER_TTIP: 'Filter',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          KEEP_PRICES: 'Ignore Suggestions',
          KEEP_PRICES_TTIP: 'Ignore selected suggestions',
          ACCEPT_SUGGESTIONS: 'Accept Suggestions',
          ACCEPT_SUGGESTIONS_TTIP: 'Accept selected suggestions',
          ENDORSE: 'Approve Changes',
          ENDORSE_TTIP: 'Approve selected changes',
          REJECT_ENDORSE: 'Reject Changes',
          REJECT_ENDORSE_TTIP: 'Reject selected changes',
          REVERT_PRICE: 'Reverse Prices',
          REVERT_PRICE_TTIP: 'Reverse selected prices',
          MARK_REVIEWED: 'Mark as Reviewed',
          MARK_REVIEWED_TTIP: 'Mark selected decisions as reviewed',
          EDIT_PRICES: 'Modify Prices',
          EDIT_PRICES_TTIP: 'Modify prices manually',
          EFFECTIVE_DATE: 'Valid Dates',
          EFFECTIVE_DATE_TTIP: 'Set Valid Dates for selected products',
          CLEAN_FILTERS: 'Clear Filters',
          PRODUCT: 'Product',
          PRICE_TYPE: 'Price Type',
          CURRENT_PRICE_TTIP: 'Current Price',
          VALID_PRICE_TTIP: 'Valid Price',
          NOT_VALID_PRICE_TTIP: 'Invalid Price',
          SUGGESTION_PRICE_TTIP: 'Suggested Price $',
          CHECK_TTIP: 'Reviewed',
          PRICE: 'Current Price $',
          COMPETITION_PRICE: 'Competition Price $',
          SUGGESTION: 'Suggested Price $',
          DECISIONS: 'Decision $',
          IMPACT: 'Impact $',
          NEWVALIDATEDATE: 'Validate Date',
          ACCEPT_SUGGESTION: 'Accept suggestion',
          KEEP_PRICE: 'Keep the current price',
          EDIT_MANUALLY: 'Change manually',
          TEXT_SNACKBAR: '{{val}} records were modified',
          TEXT_SNACKBAR_MAX_BULK_LIMIT: 'It is not possible to perform bulk actions for more than {{val}} items.',
          EFFECTIVE_DATE_MODAL: 'Valid date',
          EFFECTIVE_DATE_INPUT: 'Valid date',
          TO_DATE_INPUT: 'Valid date To',
          CANCEL_BTN: 'Cancel',
          ACCEPT_BTN: 'Accept',
          TEXT_SNACKBAR2: '{{val}} records were modified',
          TITLE_FILTERS: 'Filters',
          PROVINCE_FILTERS: 'Province',
          CITY_FILTERS: 'City',
          PRICE_ZONE1_FILTERS: 'Price Zone 1',
          PRICE_ZONE2_FILTERS: 'Price Zone 2',
          PRICE_ZONE3_FILTERS: 'Price Zone 3',
          CLEAN_FILTER_BTN: 'Clear',
          APPLY_FILTER_BTN: 'Apply',
          EDIT_PRICE_MODAL: 'Modify prices',
          STRATEGY_INPUT: 'Strategy',
          ROUNDING_CHECK: 'Use rounding rules',
          VALUE_INPUT: 'Value',
          TAB1: 'Decision',
          TAB2: 'Competitors',
          TAB3: 'Costs and Margins',
          TAB4: 'Audit',
          TAB5: 'Related Stores',
          TAB6: 'Recipe',
          TAB7: 'Products in Kit',
          TAB8: 'Kit',
          TAB_ZONES: 'Related Stores',
          TAB_ZONES_TITLE: 'Related Stores',
          CODE_INPUT: 'Code',
          UM_BASE_INPUT: 'Base UM',
          BRAND_INPUT: 'Brand',
          NAME_INPUT: 'Name',
          WITHOUT_SUGGESTION: 'Without Suggestion',
          REVIEW_RULE_BTN: 'Review Rule',
          DISABLED_BY_CHILD: 'No actions can be taken. This product depends on the decisions made in the parent of the parent rule',
          SAME_PRICE_WARNING: 'The selected item already has the price: $',
          SUGGESTION_ACCEPTED_BTN: 'Suggestion Accepted',
          SUGGESTION_ACCEPTED_OP1: 'Keep the Current Price',
          SUGGESTION_ACCEPTED_OP2: 'Change manually',
          SUGGESTION_WITHOUT_REVIEW: 'Suggestion without review',
          SUGGESTION_ACCEPTED: 'Suggestion Accepted',
          SUGGESTION_IGNORED: 'Suggestion Ignored',
          SUGGESTION_ACCEPT: 'Accept Suggestion',
          SUGGESTION_IGNORE: 'Ignore Suggestion',
          SUGGESTION_REVIEWED: 'Reviewed',
          SUGGESTION_ENDOECE: 'Approve Changes',
          SUGGESTION_ENDOECE_REJECTED: 'Reject Changes',
          SUGGESTION_REJECTED: 'Changes rejected',
          SUGGESTION_ALREADY_ENDOECE: 'Approved',
          SUGGESTION_CHANGE_MANUALY: 'Change manually',
          SUGGESTION_CHANGED_MANUALY: 'Changed manually',
          SUGGESTION_MARK_AS_REVIEW: 'Mark as Review',
          REVERT_NEW_PRICE: 'Revert Price',
          NO_DECISIONS_BTN: 'No decisions',
          NO_DECISIONS_OP1: 'Confirm the current price',
          NO_DECISIONS_OP2: 'Change manually',
          EDIT_MANUALLY_BTN: 'Manually Edited',
          EDIT_MANUALLY_OP1: 'Change manually',
          EDIT_MANUALLY_OP2: 'Keep Current Price',
          ORIGINAL_PRICE_INPUT: 'Original price',
          PREVIUS_PRICE_INPUT: 'Previous price',
          COST_INPUT: 'Cost',
          MARGIN_INPUT: 'Margin $',
          PERCENTAGE_MARGIN_INPUT: 'Margin %',
          LEGEND_COMP_MAP: 'Filter the map and the KPIs using prices in the store trade area or using the prices of the competitive rule',
          COMPETITOR_INPUT: 'Competitor',
          SOURCE_INPUT: 'Source',
          MARKERS_CHECK: 'Markers Only',
          MARKERS_TTIP: 'Relevant Competitors Only',
          BRAND: 'Brand',
          CODE_NAME: 'Cod | Name',
          TYPE: 'Type',
          SURVEY_DATE: 'Date',
          SURVEY: 'Source',
          COMPETENCE_TAB_KPIS_TITLE_1: 'Current Comp. Price',
          COMPETENCE_TAB_KPIS_TITLE_2: 'Current CI %',
          COMPETENCE_TAB_KPIS_TITLE_3: 'Suggested CI %',
          COMPETENCE_TAB_KPIS_TITLE_4: 'Suggested Price',
          COMPETENCE_TAB_KPIS_DESC_3: 'Vs. Current CI',
          COMPETENCE_TAB_KPIS_DESC_4: 'Vs. Current Price',
          COMPETENCE_KPIS_TITLE_TRADE: 'Trade Area',
          COMPETENCE_KPIS_TITLE_COMPETITOR: 'Main Competitors',
          COMPETENCE_KPIS_TITLE_RULE: 'Rule',
          TITLE_MAP_SECTION: 'Competition Map',
          TITLE_TABLE: 'Trade Area',
          TITLE_KPIS: 'Competition Analysis',
          NEW: 'Status',
          NEW_CHIP: 'NEW',
          INVALID_CHIP: 'Invalid',
          OLD_CHIP: 'Old',
          SUGGESTION_COLUMN: 'Suggested',
          MARK: 'Marker',
          OWNER: 'Main Competitor',
          PVC: 'PVP',
          MARGIN: 'Margin',
          MARGIN_USD: 'Margin USDxM3',
          PERCENTAGE_MARGIN: 'Margin %',
          COST_TAXES: 'Costs and Taxes',
          CURRENT: 'Current',
          PREVIUS: 'Previous',
          VAR: 'Variation %',
          LAST_VAR: 'Last Var.',
          EVENT: 'Event',
          DATE: 'Date',
          PREVIUS_PRICE: 'Previous price $',
          DECISIONS_PRICE: 'Price decision $',
          COST: 'Cost',
          USER: 'User',
          CHILDREN_STORE: 'Related Store',
          CODE: 'Code',
          RECIPE: 'Recipe',
          NAME: 'Name',
          CURRENT_PRICE: 'Current $',
          SUGGESTION_RECIPE: 'Suggestion $',
          DECISION: 'Decision $',
          SEE_RECIPE_BTN: 'View Recipes',
          INGREDIENTS: 'Ingredients',
          ORIGIN_COST: 'Original Cost $',
          QUANTITY: 'Quantity',
          CURRENT_COST: 'Current Cost $',
          COMBO_PRODUCT: 'Product in kit',
          COMBOS: 'Kits',
          PARTICIPATION: 'Share %',
          SEE_COMBO_BTN: 'View Kit',
          PARTICIPATION_TTIP: 'Sales share within this kit.',
          LEGEND_COMBO: 'The following Kits may be directly or indirectly affected by the price decision made.',
          COMBO: 'Kit',
          SUGGESTION_COMBO: 'Suggestion $',
          COMBOS_COMPONENTS: 'Kit Components',
          BLOCK: 'Group',
          TOOLTIP_PARENT: 'Parent Product',
          TOOLTIP_PARENT_OPEN: 'open related products',
          TOOLTIP_PARENT_OPENED: 'close related products',
          TOOLTIP_CHILD: 'Related Product',
          TOOLTIP_PRICE_VARIATION: 'The child products will have the same Price Variation as the parent product',
          TOOLTIP_SUPERIOR_PRICE: 'The Child Products will have a Higher Price in {{val}} than the parent product',
          TOOLTIP_INFERIOR_PRICE: 'The Child Products will have a Lower Price in {{val}} than the parent product',
          TOOLTIP_SAME_PRICE: 'Child Products will have the same Price as the parent product',
          TOOLTIP_SUPERIOR_MARGIN: 'Child Products will have a Higher Margin in {{val}} than the parent product',
          TOOLTIP_INFERIOR_MARGIN: 'Child Products will have a Lower Margin in {{val}} than the parent product',
          TOOLTIP_SAME_MARGIN: 'Child Products will have the same Margin as the parent product',
          TOOLTIP_COMBO: 'Kit',
          TOOLTIP_RECIPE: 'Recipe',
          TOOLTIP_STORE: 'Store relationship',
          TOOLTIP_COMBO_ITEM: 'Kit Component',
          TOOLTIP_MAX_PRICE: 'The price is above the regulated price',
          TOOLTIP_IS_PARENT: 'Parent item in kinship rule. The variation of its price affects its child items.',
          RULE_TYPE_TITLE: 'Rule Type',
          COMBO_ANALYST: 'Analyst',
          COMBO_DECISIONTYPE: 'Decision Type',
          COMBO_APPROVER: 'Approver',
          COMBO_SITUATION: 'Situation',
          COMBO_RULETYPE: 'Rule Type',
          AUDIT_PRICE_CHANGE: 'Price Change',
          SHOW_KPIS: 'Show KPIs',
          HIDE_KPIS: 'Hide KPIs',
          RULE_TYPE: {
            Guideline: 'Guideline',
            Margin: 'Margin',
            Competitive: 'Competitive',
            ParentItem: 'Related Products',
            ParentZone: 'Related Stores',
            BasePriceList: 'Base Price List',
          },
          ADVANCED_FILTER: {
            NONE: 'All',
            SEE_CHILDS_MSG:
              'By enabling this option you will be able to search and view the products that are children of other products with respect to the price management performed in the price rules of stores and products',
            SEE_CHILDS: 'See Child Products',
            SEE_FIXED_PRICES_MSG:
              "By enabling this option you will be able to search and see only the items with fixed prices. You can't make decisions over suggestions on them.",
            SEE_FIXED_PRICES: 'See Fixed Prices',
            TITLE_FILTERS: 'Filters',
            STATE_FILTERS: 'Status',
            ACTION_TYPE_FILTERS: 'Situation',
            PRICE_TYPE_FILTERS: 'Price Type',
            TYPE_FILTERS: 'Type',
            CATEGORY_INPUT: 'Category',
            SEARCH_CATEGORY: 'Search Categories...',
            SUBCATEGORIES_INPUT: 'Subcategories',
            SEARCH_SUBCATEGORIES: 'Search Subcategories',
            TitleStoreTags: 'Store Filters',
            TitleItemTags: 'Product Filters',
            UnCheckedSuggestion: 'Unchecked Suggestion',
            PriceRaised: 'Price Raised',
            NoSuggestions: 'Without Suggestions',
            NotCheckedSimulation: 'Not Reviewed Suggestions',
            SuggestionAccepted: 'Accepted Suggestions',
            ManualPriceEdited: 'Manually Changed Prices',
            SimulationAcepted: 'Accepted Simulation',
            AboutToExpire: 'About to Expire',
            OldPrices: 'Old Prices',
            Regular: 'Regular',
            Competition: 'Competition',
            Current: 'Current',
            Todos: 'All',
            Checked: 'Checked',
            Unchecked: 'Unchecked',
            ApprovalPending: 'Approval Pending',
            'ApprovalPending-Aproval': 'Approval Pending',
            Rejected: 'Rejected',
            Approved: 'Approved',
            Error: 'Suggestion calculation failed',
            SuggestionIgnored: 'Suggestions Ignored',
            PriceDrop: 'Price Drop',
            SharpPriceDrop: 'Sharp Price Drop',
            SharpPriceRaised: 'Sharp Price Raised',
            DepressedMargins: 'Depressed Margins',
            HighMargins: 'High Margins',
            Margin: 'Margin Rule',
            Competitive: 'Competitive Rule',
            AUDIT_PRICE_CHANGE: 'Price Change',
          },
          TAB: {
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_APPLIED_FORCED:
              'The price of {{val}} was changed to {{val3}} because the limits of Regulated Maximum Prices were applied.',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_NOTAPPLIED_FORCED:
              'The price of {{val}} is within the limits of regulated maximum prices {{val3}}.',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_NOTAPPLIED_HIGHER_NOTFORCED:
              'The price of {{val}} exceeds the limits of Regulated Maximum Prices {{val3}}, but no corrections were made.',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_NOTAPPLIED_MINOR_NOTFORCED:
              'The price of {{val}} is within the limits of Regulated Maximum Prices {{val3}}, and no corrections were made.',
            STEP_LINK_COMPETITIVE_RULE: 'Competitive Rule ',
            STEP_TEXT_COMPETENCE_RULE_below: 'below average ',
            STEP_TEXT_COMPETENCE_RULE_above: 'above average ',
            STEP_TEXT_COMPETENCE_RULE_equal: 'equal to average ',
            STEP_TEXT_COMPETENCE_RULE_PRE: 'A',
            STEP_TEXT_COMPETENCE_RULE_POST: 'to set the price a {{val2}}{{val}} {{val4}}, remaining at {{val3}}',
            STEP_TEXT_COMPETENCE_RULE: 'A {{val}} was applied: "{{val2}}" and generated a suggested price of {{val3}}',
            STEP_TEXT_MARGIN_RULE: 'A {{val}} was applied: "{{val2}}" and generated a suggested price of {{val3}}',
            STEP_TEXT_COMPETENCE_RULE_NOTAPPLIED: 'No price rule applied',
            STEP_TEXT_MARGIN_RULE_NOTAPPLIED: 'No price rule applied',
            STEP_TEXT_MARGIN_SUGGESTION_TRIGGER:
              'PRISMA detected that your current price of {{val}} does not comply with the target margin of {{val2}}',
            STEP_TEXT_COMPETITIVE_SUGGESTION_TRIGGER:
              'PRISMA detected that your current price of {{val}} does not comply with your competitive positioning defined in rule {{val2}} considering market reference price of {{val3}}',
            STEP_TEXT_SUGGESTION_ERROR:
              'PRISMA could not calculate the suggested price for this item and store (suggested price {{val}}), please check its costs and taxes or contact support',
            STEP_TEXT_COMPETITIVE_SUGGESTION_TRIGGER_SHORT:
              'PRISMA detected that your current price of {{val}} does not comply with your competitive positioning.',
            STEP_TEXT_RELATED_PRODUCTS_RULE_SHORT: 'A Rule Parent Item was applied: "{{val}}" and generated a price of {{val2}}',
            STEP_TEXT_RELATED_STORES_RULE_SHORT: 'A Rule Parent Store was applied: "{{val}}" and generated a price of {{val2}}',
            STEP_TEXT_COMPETENCE_LIMITS_APPLIED:
              'The suggestion of {{val}} was changed to {{val2}} because the competence limits were applied',
            STEP_TEXT_COMPETENCE_LIMITS_NOTAPPLIED: 'Competence Limits where verified. No corrections were made.',
            STEP_TEXT_COMPETENCE_RULE_MARGIN_FALLBACK:
              'Prisma could not find market reference price. Suggestion was created using rule margin fallback. {{val}}',
            STEP_TEXT_COMPETENCE_CATEGORY_MARGIN_FALLBACK:
              'Prisma could not find market reference price. Suggestion was created using category margin fallback. {{val}}',
            STEP_TEXT_VARIATION_GUIDELINES_APPLIED:
              'The suggestion of {{val}} (variation: {{val2}}) has been changed to {{val3}} since the {{val5}} supported for this rule is {{val4}}.',
            STEP_TEXT_VARIATION_GUIDELINES_NOTAPPLIED: 'The price variation guidelines were checked. No corrections were made.',
            STEP_TEXT_MARGIN_GUIDELINES_APPLIED:
              'The suggestion of {{val}} (margin: {{val2}}) was changed to {{val4}} as {{val3}} was the {{val5}} margin for the category.',
            STEP_TEXT_VARIATION_GUIDELINES_APPLIED_RULE: null,
            STEP_TEXT_MARGIN_GUIDELINES_APPLIED_RULE: null,
            STEP_TEXT_MARGIN_GUIDELINES_NOTAPPLIED: 'Margin guidelines were verified. No corrections were made.',
            STEP_TEXT_ROUNDING_APPLIED: 'The suggestion was rounded to {{val}}',
            STEP_TEXT_ROUNDING_NOTAPPLIED: 'No rounding was applied',
            STEP_TEXT_SUMMARY_ACCEPTED: 'You have made a decision, and accepted the suggestion of {{val}}',
            STEP_TEXT_SUMMARY_CHANGED: 'You have made a decision, and you changed the price to {{val}}',
            STEP_TEXT_SUMMARY_IGNORED: 'You have made a decision, and ignore the price {{val}}',
            STEP_TEXT_SUMMARY_WITHOUT_SUGGESTION: 'You have made a decision, and you changed the price from {{val}} to {{val2}}',
            STEP_LINK_APPLIED_RULE: 'view rule...',
            STEP_LINK_COMPETITIVE_SUGGESTION_TRIGGER: 'view data...',
            STEP_LINK_VARIATION_GUIDELINES_APPLIED: 'view guidelines...',
            STEP_LINK_MARGIN_GUIDELINES_APPLIED: 'view guidelines...',
            STEP_LINK_VARIATION_GUIDELINES_APPLIED_RULE: null,
            STEP_LINK_MARGIN_GUIDELINES_APPLIED_RULE: null,
            STEP_LINK_ROUNDING_APPLIED: 'view rule...',
            STEP_LINK_REGULATEDPRICE_GUIDELINES_APPLIED: 'view configuration',
            STEP_LINK_REGULATEDPRICE_GUIDELINES_NOTAPPLIED: 'view configuration',
            STEP_LINK_RELATED_PRODUCTS_RULE: 'view parent rule...',
            STEP_LINK_RELATED_STORES_RULE: 'view parent rule...',
            STEP_COMPETENCE_RULE: 'Competitive rule',
            STEP_MARGIN_RULE: 'Margin rule',
            SUGGESTION_TITLE: 'Price Decision',
            NOT_STEPS: 'No Steps found',
            AUX_MAX_MARGIN: 'maximum',
            AUX_MIN_MARGIN: 'minimum',
            AUX_MAX_VARIATION: 'max raise',
            AUX_MIN_VARIATION: 'low minimum',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_APPLIED:
              'The price of {{val}} was changed to {{val3}} because the Maximum Regulated Price limits were applied.',
            STEP_TEXT_REGULATEDPRICE_GUIDELINES_NOTAPPLIED:
              'The price of {{val}} exceeds the limits of Maximum Regulated Prices {{val3}} but no corrections were made.',
            STEP_TEXT_APPLY_PRODUCTS_RULE:
              'This item was identified as a parent in certain item parent rules. In correlation with this item, the following rules were applied and generated a decision on the child items: {{val}}',
            STEP_TEXT_APPLY_STORES_RULE:
              'This store was identified as a parent in certain store parent rules. In correlation with this item, the following rules were applied and generated a decision on the child stores: {{val}}',
          },
          TAB_ZONES_COL_ZONE: 'Rule (Stores Relationships)',
          TAB_ZONES_COL_RULE: 'Rule',
          TAB_ZONES_COL_ACTUAL: 'Current',
          TAB_ZONES_COL_SUGGEST: 'Suggest',
          TAB_ZONES_COL_DIFF: 'diff %',
          TAB_ZONES_COL_DECISION: 'Decision',
          TAB_ZONES_COL_IMPACT: 'Impact',
          WARNING_CHILD_WHITH_WARNING: 'Related prices with warnings',
          WARNINGS_PRICES_LIST: {
            TEST: 'warning test / value: {{val}}, type: {{val2}}',
            MIN_MARGIN: 'Margin {{val}} is below the minimum margin of {{val2}}.',
            MAX_MARGIN: 'Margin {{val} is above the maximum margin of {{val2}}.',
            EQUAL_MARGIN: 'The margin of {{val}} is equal to the maximum margin of {{val2}}',
            VAR_BAJA: 'The price decreased by {{val}}. he maximum decrease is {{val2}} according to the guidelines.',
            VAR_SUBA: 'The price increased by{{val}}. he maximum increase is {{val2}} according to the guidelines.',
            SUGGESTION_ERROR: 'Invalid suggested price.',
          },
          STRATEGY_OP3: 'Carry a fixed price',
          STRATEGY_OP2: 'Lead to a target margin',
          STRATEGY_OP1: 'Apply price variation',
          SUGGESTED_IMPACT_TTIP: 'Does not include child products',
          NEW_IMPACT_TTIP: 'Includes children products',
        },
      },
      TAXES: {
        IIBB: {
          ROUTE: 'Prices / Administration / Taxes',
          TITLE: 'Gross Income',
          JURISDICTION: 'Jurisdiction',
          PERCENTAGE_RATE: 'Percentage rate',
          SAVE_BTN: 'Save',
          PERCENTAGE_RATE_INPUT: 'Percentage rate',
          VALIDATION_1: 'The field cannot be greater than 100',
          VALIDATION_2: 'The field must be greater than 0',
          VALIDATION_3: 'The field is mandatory',
        },
        CO2: {
          ROUTE: 'Prices / Administration / Taxes',
          TITLE: 'CO2 taxes',
          YES: 'Yes',
          NOT: 'Not',
          EXCEMPT_ZONE: 'Exempt Zone',
          NS: 'NS',
          IN: 'IN',
          UD: 'UD',
          D500: 'D500',
          EURO: 'EURO',
          SAVE_BTN: 'Save',
          NS_INPUT: 'NS',
          VALIDATION1: 'The field must not exceed 100',
          VALIDATION2: 'The field must be greater than 0',
          IN_INPUT: 'IN',
          UD_INPUT: 'UD',
          D500_INPUT: 'D500',
          EURO_INPUT: 'EURO',
          TITLE_FORM: 'CO2 Taxes - Exempt {{val}} area',
        },
        ICL: {
          ROUTE: 'Prices / Administration / Taxes',
          TITLE: 'ICL taxes',
          YES: 'Yes',
          NOT: 'Not',
          EXCEMPT_ZONE: 'Exempt Zone',
          NS: 'NS',
          IN: 'IN',
          UD: 'UD',
          D500: 'D500',
          EURO: 'EURO',
          SAVE_BTN: 'Save',
          NS_INPUT: 'NS',
          VALIDATION1: 'The field must not exceed 100',
          VALIDATION2: 'The field must be greater than 0',
          IN_INPUT: 'IN',
          UD_INPUT: 'UD',
          D500_INPUT: 'D500',
          EURO_INPUT: 'EURO',
          TITLE_FORM: 'ICL Tax - Zone {{val}} exempt',
        },
      },
      IMPORTERS: {
        PARITY: {
          ROUTE: 'Pricing / Administration / Importers',
          TITLE: 'Import Parity',
          IP_BTN: 'Upload Daily IP',
          DAY: 'Day',
          TRANSFER_PRICING: 'Transfer pricing',
          IMPORT_TTIP: 'Import data',
          IP_DAILY: 'Daily IP weight',
          IP_NS: 'IP NS',
          IP_IN: 'IP IN',
          IP_GO2: 'IP GO2',
          IP_GO3: 'IP GO3',
          TC: 'TC',
          TERMINAL: 'Terminal',
          DESCRIPTION: 'Description',
          NS: 'NS',
          IN: 'IN',
          GO2: 'GO2',
          GO3: 'GO3',
          TITLE_FORM: 'Import Parity - Coefficients',
          SAVE_BTN: 'Save',
          DATE_COEFFICIENTS_INPUT: 'Change date of IP coefficients',
          VALIDATION1: 'The field must be greater than 0',
          TC_INPUT: 'TC',
          NS_INPUT: 'NS',
          IN_INPUT: 'IN',
          GO2_INPUT: 'GO grado 2',
          GO3_INPUT: 'GO grado 3',
        },
        FERRYES_AND_COMISIONS: {
          TITLE: 'Freight and commissions',
        },
        COST_AND_TAXES: {
          TITLE: 'Costs and taxes',
        },
        VENDOR_COST: {
          TITLE: 'Cost importer by supplier',
        },
        STORE_TAXES: {
          TITLE: 'Tax importer by store',
        },
        NEW_PRICES: {
          TITLE: 'New Prices',
        },
        COMPETITOR_PRICES: {
          TITLE: 'Competitors prices',
        },
      },
    },
    ASSORT: {
      STP: {
        CLUSTERING: {
          ROUTE: 'Assortment / Setup',
          TITLE: 'Clusterization',
          SUBTITLE:
            'Clustering allows to strategically classify points of sale according to a set of variables. It can be a single cluster for all categories or multiple classifications depending on the category. In this way, business decision-making is streamlined and product offerings are standardized. From this screen you can create and modify clusterizations.',
          NEW_CLUSTERIZATION_BTN: 'New Clustering',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          DELETE_TTIP: 'Delete selected clusters',
          DELETE: 'Delete',
          NAME: 'Name',
          OBSERVATION: 'Observation',
          EDIT_TITLE_FORM: 'Edit Clustering',
          NEW_TITLE_FORM: 'New Clustering',
          SAVE_BTN: 'Save',
          BASIC_INFORMATION_TAB: 'Main Data',
          NAME_INPUT: 'Name',
          OBSERVATION_INPUT: 'Observation',
          CLUSTER_NAME_TTIP: 'Click here to view stores and associated clusters',
        },
        CLUSTER_ASSIGN: {
          REDO_CHANGE_CLUSTERS: 'Return changes of selected clusters',
          REDO_CHANGE_CLUSTERS_DESC: 'Do you want to filter by the selected cluster? this will erase unsaved changes',
          ROUTE: 'Clustering / Cluster Assignment',
          SUBTITLE:
            'On this screen you can create and assign a cluster to each point of sale. Or work the points of sale as free agents without assigning a cluster. In this case, it works independently as a cluster with a single point of sale.',
          SAVE_BTN: 'Save',
          FILTER_TTIP: 'Filter',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          STORES: 'Stores',
          CODE: 'Code',
          TYPE: 'Type',
          PROVINCE: 'Province',
          STATE: 'State',
          CLUSTER: 'Cluster',
          NEW_CLUSTER: 'New Cluster',
          NO_CLUSTER_ASSIGNED: 'No Assigned Cluster',
          NEW_CLUSTER_BTN: 'NEW CLUSTER',
          NEW_CLUSTER_TITLE: 'New Cluster',
          NEW_CLUSTER_SUBTITLE:
            'The cluster is a group of points of sale, with specific characteristics or similar purchasing behavior, to which a role and a strategy are assigned together with the category. In turn, it simplifies assortment allocation and management.',
          NAME_INPUT: 'Name',
          OBSERVATION_INPUT: 'Observation',
          CANCEL_BTN: 'Cancel',
          ADD_BTN: 'Add',
          TITLE_FILTERS: 'Filters',
          STATE_FILTERS: 'State',
          TYPE_FILTERS: 'Type',
          SEARCH_TYPE: 'Search Type...',
          PROVINCE_FILTERS: 'Province',
          CLUSTER_FILTERS: 'Cluster',
          SEARCH_CLUSTER: 'Search Cluster...',
          CLEAN_BTN: 'Clear',
          APPLY_BTN: 'Apply',
          Todos: 'All',
          Checked: 'Active',
          Unchecked: 'Inactive',
          FREE_AGENTS: 'Free Agents',
        },
        CATEGORY_SETUP: {
          ROUTE: 'Assortment / Configuration',
          TITLE: 'Category Setup',
          SUBTITLE:
            'On this screen you can assign a clustering and the main segmentation of products for each category level, previously defined in the category tree; In turn, you can assign a role and a differentiated strategy for each cluster, and see the information regarding the distribution and assignment of the segments.',
          LIST: {
            CATEGORIES: 'Categories',
            CLUSTERIZATION: 'Clustering',
            ROL: 'Role',
            STRATEGY: 'Strategy',
            SEGMENTATION: 'Segmentation',
            SEGMENT_QUANTITY: '# Segments',
            SEGMENT_QUANTITY_TTIP: 'Segments Quantity',
            CLASSIFIELDS: 'Classified %',
            CLASSIFIELDS_TTIP: 'Percentage of products within the category classified with a segment.',
            SWITCH_ACTIVE: 'Show Categories without Assignment',
            SWITCH_INACTIVE: 'Show Categories with Assignment',
            CLUSTERIZATION_OR_SEGMENT_DISABLE: 'Category not editable because it has associated items.',
            FILTER_TTIP: 'Filter',
            NOTFOUNDCATEGORYTREE:
              'No Categories without Assignment were found with these selected filters, request assistance from support to enable them',
            WITHOUTCLUSTERIZATION: 'Without Clustering',
            WITHOUTSEGMENT: 'Without Segments',
            WITHOUTSTRATEGY: 'Without Strategies',
            WITHOUTROL: 'Without Clusters',
            WITHOUTCLUSTER: 'Without Clusters',
          },
        },
        ROLES_STRATEGIES_ASSIGN: {
          SUBTITLE:
            'On this screen you can assign a cluster for each category level, previously defined in the category tree. In turn, for each cluster or point of sale of the selected cluster, you can assign a role and a differentiated strategy.',
          EXPORT_TTIP: 'Export',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          CATEGORIES: 'Categories',
          CLUSTERING: 'Clustering',
          ROL: 'Role',
          STRATEGY: 'Strategy',
          UNALLOCATED_TTIP: 'Not assigned',
          SEARCH_CLUSTER: 'Search Cluster...',
          SEARCH_ROL: 'Search Rol...',
          WITHOUT_CLUSTERS: 'Function enabled if clusters are assigned',
          SEARCH_STRATEGY: 'Search Strategy...',
          CHANGE_CLUSTERING_TITLE: 'Are you sure about changing this clustering?',
          CHANGE_CLUSTERING_SUBTITLE: 'This will clear the roles and strategies assigned to all the clusters in this category.',
          CANCEL_BTN: 'Cancel',
          CHANGE_BTN: 'Change',
          ACCEPT_BTN: 'Accept',
          CHANGE_RS_TITLE: 'Modify Role / Strategy',
          CHANGE_RS_SUBTITLE: 'Do you want to assign: {{val}} to all the clusters in this category?',
          ROUTE: 'Assortment / Configuration',
          TITLE: 'Category Setup',
        },
        ROLES: {
          ROUTE: 'Assortment / Setup',
          TITLE: 'Roles',
          SUBTITLE: 'On this screen you can create and modify the different roles that will be assigned to the categories.',
          NEW_ROLE_BTN: 'New Role',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          DELETE_TTIP: 'Delete selected roles',
          DELETE: 'Delete',
          NAME: 'Name',
          OBSERVATION: 'Observation',
          DELISTING_RULE: 'Rule Deactivate',
          LISTING_RULE: 'Rule Activate',
          OTHERS_RULES: 'Othes Rules',
          FRECUENCY: 'Frequency',
          DEFINE_ROL_STP1: 'Setup Role',
          DELISTING_RULES_STEP2: 'Deactivate Rules',
          LISTING_RULES_STEP3: 'Activate Rules',
          OTHERS_RULES_STEP4: 'Additional Rules',
          FRECUENCY_STEP5: 'Analysis Frequency',
          TITLE_FORM_NEW: 'New Role',
          TITLE2_FORM_NEW: 'New Role /',
          STP1: 'Step 1 - Setup Role',
          LEGEND_ROL:
            'The Category Role sets the criteria to activate or deactivate a product for a given cluster. The role and the strategy define the criteria and metrics to be evaluated to generate suggestions.',
          NAME_INPUT: 'Name',
          OBSERVATION_INPUT: 'Observation',
          NEXT_BTN: 'Next',
          STP2: 'Step 2 - Deactivate Rules',
          LEGEND_DELISTING_RULE: 'In this step you can define the criteria to deactivate products.',
          RULE_DELISTING_RULE: 'Suggest deactivate products whose ',
          RULE_LISTING_RULE: 'Suggest activate products whose ',
          RULE_DELISTING_RULE_ALT: 'Suggest deactivating products ',
          RULE_LISTING_RULE_ALT: 'Suggest activating products ',
          RULE_HIGH_AI: 'Suggest activate with AI',
          MINIMUM_AVAILABILITY: 'Minimum availability of ',
          PRESENCE_IN_STORES: 'Minimum store presence of ',
          SUPPLIERS_W_SKU: 'Suppliers with a minimum share of products of ',
          NEW_DATES: 'When new data is entered',
          OPPORTUNITIES_IA: 'When AI finds opportunities',
          AND: 'AND',
          THE: 'the',
          THEFREQ: 'the',
          AUXS: {
            _variableSource: {
              _DEMOMercado: 'Market',
              ParetoCluster: 'Cluster Pareto',
              ParetoGeneral: 'Total Pareto',
              ParetoMarket: 'Market Pareto',
              TotalMarket: 'Total Market',
              ParetoReference: 'Pareto Reference',
            },
            _variableSource_phrase: {
              ParetoCluster: 'of the Cluster Pareto',
              ParetoGeneral: 'of the Total Pareto',
              ParetoMarket: 'of the Market Pareto',
              TotalMarket: 'of the Total Market',
              ParetoReference: 'of the Reference Pareto',
            },
            _variable: {
              WeightedIndex: 'W index',
              SalesUnits: 'Sales U',
              Sales: 'Sales $',
              Margin: 'Margin $',
              MarginWithVendor: 'Margin per agreement',
              PercentageMargin: 'Margin %',
              Gmroi: 'GMROI',
              Presence: 'Presence',
              MarketSales: 'Market $',
              MarketUnits: 'Market Un.',
            },
            _variable_phrase: {
              WeightedIndex: 'of Sales in Weighted Index',
              SalesUnits: 'Sales in units',
              Sales: 'Sales in $',
              Margin: 'Margin in $',
              MarginWithVendor: 'Margin per agreement',
              PercentageMargin: 'Margin in %',
              Gmroi: 'of GMROI',
              Presence: 'of Presence',
              MarketSales: 'Market Sales in $',
              MarketUnits: 'Market Sales in Un.',
            },
            _method: {
              AbsoluteValue: 'Absolute value',
              TotalShare: 'Share %',
              CumulativeShare: 'Accumulated Share %',
            },
            _methodTop: {
              AbsoluteValue: 'Absolute value',
              TotalShare: 'Share %',
              CumulativeShare: 'Accumulated Share %',
            },
            _method_phrase: {
              AbsoluteValue: 'Absolute value',
              TotalShare: 'Share %',
              CumulativeShare: 'Accumulated Share %',
            },
            _condition: {
              GreaterThan: 'is greater',
              LowerThan: 'is less',
              Equals: 'is the same',
            },
            _condition_phrase: {
              GreaterThan: 'be greater than',
              LowerThan: 'be less than',
              Equals: 'be equal to',
            },
            _conditionTopBottom: {
              LowerThan: 'Top',
              GreaterThan: 'Bottom',
            },
            _conditionTopBottom_phrase: {
              LowerThan: 'is less than or equal to',
              GreaterThan: 'is greater than or equal to',
            },
            _combinationMethod: {
              And: 'and',
              Or: 'or',
            },
            _combinationMethod_phrase: {
              And: 'and the',
              Or: 'or the',
            },
            _weekday: {
              Monday: 'Monday',
              Tuesday: 'Tuesday',
              Wednesday: 'Wednesday',
              Thursday: 'Thursday',
              Friday: 'Friday',
              Saturday: 'Saturday',
              Sunday: 'Sunday',
            },
            _frecuency: {
              Biweekly: 'Fortnightly',
              Monthly: 'Monthly',
              BiMonthly: 'Bimonthly',
              ThreeMonth: 'Quarterly',
              FourMonth: 'Every 4 months',
              SixMonth: 'Biannual',
              Annually: 'Annual',
            },
            _frecuency_phrase: {
              Biweekly: 'Fortnightly',
              Monthly: 'Monthly',
              BiMonthly: 'Bimonthly',
              ThreeMonth: 'Quarterly',
              FourMonth: 'Every 4 months',
              SixMonth: 'Biannual',
              Annually: 'Annual',
            },
          },
          COMBINATION: 'RULE',
          DELETE_COMBINATION_TTIP: 'Delete Rule',
          CANCEL_BTN: 'Cancel',
          CONFIRM_BTN: 'Confirm deletion',
          VARIABLE_SOURCE_INPUT: 'Source',
          VARIABLE_INPUT: 'Metric',
          METHOD_INPUT: 'Method',
          CONDITION_INPUT: 'Condition',
          VALUE_INPUT: 'Value',
          LOGIC_INPUT: 'Logic',
          REFERENCE_CLUSTER: 'Reference Cluster',
          ADD_COMBINATION_BTN: 'Add Rule',
          PREVIUS_BTN: 'Previous',
          STP3: 'Step 3 - Activate Rules',
          LEGEND_LISTING_RULE: 'In this step you can define the criteria to activate new products.',
          STP4: 'Step 4 - Other Rules',
          LEGEND_OTHERS_RULES: 'In this step you can setup other rules and constraints that you want to add to this role.',
          CHECK_AVAILABILITY: 'Check availability before deactivate',
          CHECK_AVAILABILITY_TEXT:
            'Suggests to deactivate a product only if the availability is equal or higher to the value specified here. You have to have stock data.',
          MINIMUM_AVAILABILITY_INPUT: 'Min. Availability',
          MINIMUM_AVAILABILITY_TTIP: 'Minimum Availability',
          CHECK_PRESENCE: 'Check presence before deactivate',
          CHECK_PRESENCE_TEXT:
            'Suggests deactivate only when a product has had sales at a minimum of {{val}}% of the stores in the cluster and within the selected period of analysis.',
          MINIMUM_PRESENCE_INPUT: 'Minimum Pres.',
          MINIMUM_PRESENCE_TTIP: 'Minimum Presence',
          CHECK_SUPPLIER: 'Check suppliers with few products',
          CHECK_SUPPLIER_TEXT:
            'If this option is activated, Prisma will suggest the deactivate of all products belonging to suppliers with a sales share lower than specified here, thus optimizing your supply chain.',
          MINIMUM_PART_INPUT: 'Minimum Share',
          MINIMUM_PART_TTIP: 'Minimum Share',
          SUGGEST_HIGH: 'Suggest activate with artificial intelligence',
          SUGGEST_HIGH_TEXT:
            'Prisma artificial intelligence will suggest activate products depending on their attributes and performance in the {{val}} / cluster.',
          COMING_SOON: 'Coming soon',
          STP5: 'Step 5 - Frequency of Analysis',
          LEGEND_CATEGORY_ANALYSIS:
            'In this step you will define how often you will execute a range review analysis for the categories with this role.',
          FRECUENCY_INPUT: 'Frequency',
          WEEKDAY_INPUT: 'Weekday',
          GENERATE_SUGGESTIONS_IA: 'Generate activate suggestions when AI finds opportunities',
          GENERATE_SUGGESTIONS_IA_TEXT: 'Suggestions to activate new products will be generated using Prisma AI proprietary algorithms.',
          GENERATE_SUGGESTIONS: 'Generate activate suggestions every time new market information is available',
          GENERATE_SUGGESTIONS_TEXT: 'Generate activate suggestions for new product releases based on available market information.',
          SAVE_BTN: 'Save',
          NEW_COMBINATION: 'New Rule',
          ADD_BTN: 'Add',
          REPEATED_VARIABLE_AND_SOURCE: 'Variable and Source cannot be duplicated for more than one rule.',
          SUGGESTION_ROLE_NAME: null,
          SUGGESTION_ROLE_DESCRIPTION: null,
        },
        STRATEGIES: {
          ROUTE: 'Assortment / Setup',
          DELETE_TITLE: 'Delete Strategy',
          DELETE_TITLE_MULTIPLE: 'Delete Strategies',
          DELETE_CONTENT: '{{val}} strategy will be deleted.',
          DELETE_CONTENT_MULTIPLE: '{{val}} strategy records will be deleted.',
          TITLE: 'Strategies',
          SUBTITLE: 'On this screen you can create and modify the different strategies that will be assigned to the categories.',
          NEW_STRATEGY_BTN: 'New Strategy',
          FILTER_TTIP: 'Filter',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          DELETE_TTIP: 'Delete selected strategies',
          DELETE: 'Delete',
          STRATEGY: 'Strategy',
          OBSERVATION: 'Observation',
          VARIABLE: 'Metric',
          NEWS: 'New',
          TYPOLOGIES: 'Classification',
          PERIOD: 'Period',
          DEFINE_STRATEGY_STP1: 'Setup Strategy',
          VARIABLES_SELECTION_STP2: 'Metric Selection',
          OTHER_CONFIGURATIONS_STP3: 'Other Settings',
          ANALYSIS_PERIOD_STP4: 'Analysis period',
          TITLE_FORM_NEW: 'New Strategy',
          TITLE_FORM_EDIT: 'Edit Strategy',
          STP1: 'Step 1 - Setup Strategy',
          LEGEND_STRATEGY: 'The strategy will help you define the metric by which the Pareto analysis will be performed.',
          NAME_INPUT: 'Name',
          OBSERVATION_INPUT: 'Observation',
          NEXT_BTN: 'Next',
          STP2: 'Step 2 - Selection of Metrics',
          LEGEND_SELECTION_VARIABLES: 'In this step you can define the criteria to assortment is going to be analyzed and optimized.',
          VARIABLE_INPUT: 'Metric',
          COMBINATION_PERCENTAGE_INPUT: 'combination',
          VALIADATION: 'The value entered must be greater than 0 or equal to 100%',
          DELETE_VARIABLE_TTIP: 'Delete',
          VALIADATION2: 'The sum of all metrics must be 100%',
          ADD_COMBINATION_BTN: 'Add Combination',
          PREVIUS_BTN: 'Previous',
          STP3: 'Step 3 - Other Settings',
          LEGEND_OTHER_CONFIGURATIONS: 'In this step you can enter additional settings that will improve the accuracy of the analysis.',
          OTHER_CONFIGURATIONS_TEXT: 'A product is considered new by',
          DAYS_INPUT: 'Days',
          DAY_INPUT: 'Day',
          SINCE_TEXT: 'considered new from its',
          SINCE: 'from',
          EVENT_INPUT: 'Event',
          TYPOLOGIES_TEXT: 'CLASSIFICATION (Pareto sections):',
          DEFINE_PARETO_TEXT: ' Define the Pareto sections ranges to classify products automatically based on the selected metric.',
          HIGH_ROTATION: 'FAST-MOVING',
          HIGH_ROTATION_TEXT: ': Products that their accumulated index (Pareto) goes from 0 to',
          VALIDATION_HIGH_ROTATION: 'Must be less than 100% and less than Slow-Moving products',
          LOW_ROTATION: 'SLOW-MOVING',
          LOW_ROTATION_TEXT: ': Products that their accumulated index (Pareto) goes from {{val}}% to',
          VALIDATION_LOW_ROTATION: 'Must be less than 100% and greater than High Rotation',
          LONG_TAIL: 'LONG TAIL',
          LONG_TAIL_TEXT:
            ': Products that their accumulated index (Pareto) goes from {{val}}% to 100%. They are the last {{val2}}% of the Pareto.',
          WITHOUT_ROTATION: 'NON-MOVING',
          WITHOUT_ROTATION_TEXT: ': Products with no sales during the selected period of analysis.',
          NEWS1: 'NEW ITEMS',
          NEWS_TEXT: 'Products that have less than {{val}} days since their {{val2}}',
          STP4: 'Step 4 - Analysis Period',
          DEFINE_PERIOD_TEXT:
            'In this step you can define the period in months that it will take to perform the analysis. To normalize and be able to compare you will always see average values ​​expressed at 30 days in reports and pareto.',
          LATEST_INPUT: 'Latest',
          MONTHS: 'Months',
          JANUARY: 'JAN',
          FEBRUARY: 'FEB',
          MARCH: 'MAR',
          APRIL: 'APRL',
          MAY: 'MAY',
          JUNE: 'JUN',
          JULY: 'JUL',
          AUGUST: 'AUG',
          SEPTEMBER: 'SEP',
          OCTOBER: 'OCT',
          NOVEMBER: 'NOV',
          DECEMBER: 'DEC',
          LEGEND_MOTHS:
            'If you exclude a month from the list, it will not be considered as part of the average. If the number of selected months is less than the selected period, the average will be calculated using the number of months available.',
          SAVE_BTN: 'Save',
          FROM_DATE_OF: 'days from',
          LAST_MONTH_: 'Last Month',
          LASTS_: 'Last',
          MONTHS_: 'Months',
          EXCEPT_FOR: 'Except for',
        },
        SEG_ASSIGNMENT: {
          ROUTE: 'Assortment / Setup',
          TITLE: 'Assign Segments',
          SUBTITLE:
            'On this screen you can assign for each category level, previously defined in the category tree, the main product segmentation that will be used to carry out the analysis and assortment optimization.',
          EXPORT_TTIP: 'Export',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          CATEGORY: 'Category',
          SEGMENTATION: 'Segmentation',
          NOT_ASSIGNED_TTIP: 'Not allocated',
          SEARCH_SEGMENTATION: 'Search Segmentation...',
          SEGMENT_QUANTITY: '# Segm. Quantity',
          SEGMENT_QUANTITY_TTIP: 'Segment Quantity',
          CLASSIFIELDS: 'Classified %',
          CLASSIFIELDS_TTIP: 'Percentage of products within the category classified with a segment.',
        },
        CATEGORIES: {
          ROUTE: 'Assortment / Setup',
          TITLE: 'Category Tree',
          SUBTITLE: 'Here you can visualize the hierarchy of categories loaded in Prisma.',
          SAVE_BTN: 'Save',
          PRINT_TTIP: 'Print',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          SECTOR: 'Sector',
          CATEGORY: 'Category',
          SUBCATEGORY: 'Subcategory',
          ELEMENTS: 'Elements',
          SELECTED_CATEGORIES_TTIP: 'Selected Categories: ',
          MODAL_TTILE: 'Do you wish to save changes?',
          MODAL_SUBTTILE: 'When saving the previous changes will be stepped on',
          CLOSE_BTN: 'Close',
          UNDO_BTN: 'Undo changes',
          ACCEPT_BTN: 'Accept',
        },
        HELP: {
          VARIABLE_SOURCE_INPUT:
            'Indicates if the suggestion is going to be generated based on the pareto of the cluster being analyzed, the pareto of all the clusters or with market information.',
          VARIABLE_INPUT: 'Indicate with which variable the pareto is to be constructed to evaluate the rule.',
          CONDITION_INPUT: 'Indicates the numerical condition to generate the rule. It can be greater, lesser or equal.',
          METHOD_INPUT:
            'It allows defining what type of cut is going to be used for the rule. It can be an absolute value, a percentage of participation or a cumulative percentage of participation.',
          VALUE_INPUT: 'Numeric value required to complete the rule condition.',
          FRECUENCY_INPUT: 'Indicates the repetition of the category analysis over time.',
          WEEKDAY_INPUT: 'Days of the week in which the category analysis should be carried out.',
          VARIABLE_S_INPUT: 'Indicates with which variable the assortment is to be analyzed.',
          COMBINATION_PERCENTAGE_INPUT:
            'Indicates the percentage of the variable that will be taken into account for the analysis. If it is a single variable, the value must be 100%. If there are more analysis variables, the sum of the analysis percentages of the same should be 100%.',
        },
        MARKET_INFO: {
          TITLE: 'Market Items',
          CATEGORY_ASSIGN: 'Assign Category',
          CATEGORY_ASSIGN_TOOLTIP: 'Assign Category',
          CATEGORY_ASSIGN_DISABLED_TOOLTIP: "You don't have permissions",
          CATEGORY_ASSIGN_MSG: 'Please select a Category to assign to the chosen Items.',
          marketItemName: 'Name',
          marketItemCode: 'Barcode',
          marketCategory: 'Market Category',
          prismaCategory: 'Category',
          marketItemBrand: 'Brand',
          marketItemManufacturer: 'Manufacturer',
          matching: 'Matching',
          CREATION_DATE: 'Creation Date',
          ITEMS_NOT_MATCH: 'See Items without matching',
          MARKET_CATEGORIES: 'Market Categories',
          MARKET_CATEGORY: 'Market Category',
          PRODUCT: 'Item',
          PRODUCTS: 'Items',
          itemMarketDataType: {
            ownItems: 'Own Items',
            marketItems: 'Market Items',
            all: 'Everyone',
          },
        },
      },
      DECISIONS: {
        ASSORT_ASSIGNATION: {
          ROUTE: 'Assortment / Decisions',
          TITLE: 'Assortment Decisions',
          SUBTITLE:
            'On this screen you can see how the assortment mix by category is made up in each cluster. In turn, we had a summary of overvoltages and impacts of the same. Using the switches, you can activate or deactivate categories in each cluster and within each category, segments, or assortment mix.',
          SELECT_CATEGORY: 'Select the category you want to work with.',
          LAST_SALE: 'Last month of sales:',
          NO_DATA: 'No data',
          LAST_STOCK: 'Last month of stock:',
          LAST_MARGIN2: 'Last month of Margin by Agreements:',
          LAST_MARKET_INFO: 'Last month of Market Information:',
          SHOW_CLUSTER_ASSORTMENT: 'Choose a Category to show the assortment of each cluster',
          FILTER_TTIP: 'Filter',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          PRINT_TTIP: 'Print',
          CLUSTER: 'Cluster',
          CATEGORY: 'Category',
          SEGMENT: 'Segment',
          NO_SEGMENT: 'You must have a segment to be able to activate it',
          IE: 'EI %',
          LISTINGS: '# Active',
          SKUS: '# Products',
          TOTAL: '# Total',
          INACTIVE: '# To Deactivate',
          ACTIVE: '# To Activate',
          IMPACT: 'Impact $',
          VIEW_PARETOC_TTIP: 'Click here to view pareto of "category" in "cluster"',
          VIEW_PARETOT_TTIP: 'Click here to view pareto of "category" total company',
          IMPACT_TTIP: 'Impact on $ Sales if suggestions are activate or deactivate',
          DELIST_TTIP: 'Number of Suggestions to Deactivate',
          LIST_TTIP: 'Number of Suggestions to Activate',
          TOTAL_TTIP: 'Total products within the segment',
          LISTINGS_TTIP: 'Total active products in the cluster',
          PERCENTAGE_TTIP: 'Percentage of products active',
          ERROR: 'Allocation failed',
          CORRECT: 'Allocation saved successfully',
          ACCEPT_BTN: 'Accept',
          ENABLE_ASSIGNMENT_TITLE: 'Confirm activate these products in this cluster?',
          DISABLE_ASSIGNMENT_TITLE: 'Confirm deactivate these products in this cluster?',
          ACTION_ASSIGNMENT_SUBTITLE: 'This action will modify the assortment of all the products within the Cluster.',
          CANCEL_BTN: 'Cancel',
          EDIT_BTN: 'Modify',
          CHECK: 'Do not ask again.',
          TITLE_FILTERS: 'Filters',
          CATEGORY_FILTERS: 'Category',
          CATEGORY_FILTERS_TTIP: 'You must select at least one category to be able to filter by cluster',
          SEARCH_CATEGORY: 'Search Categories...',
          COUNT_FILTRED: 'Filtered count',
          TOTAL_COUNT: 'Total count',
          CLUSTER_FILTERS: 'Cluster',
          WITHOUT_NEWS: 'Without new products',
          TOTALITEMS: 'Total Products',
          TOTALSALES: 'Total sales',
          MSG_WITHOUT_NEWS:
            'The products that are not in a new state will be displayed, maintaining the position they occupy in the pareto',
          WITHOUT_INACTIVES: 'Without inactive products',
          MSG_WITHOUT_INACTIVES: 'Inactive products for selected clusters will not be displayed',
          RECALC_PARETO: 'Recalculate pareto',
          SHOW_ONLY_SUGGESTIONS: null,
          MSG_RECALC_PARETO: 'The pareto will be updated based on the filtered products',
          ANALYST_FILTERS: 'Analyst',
          APPROVER_FILTERS: 'Approver',
          CLEAN_FILTER_BTN: 'Clear',
          APPLY_FILTER_BTN: 'Apply',
          UNASSIGNEDSEGMENTS: 'Show Unassigned Segments',
          UNASSIGNEDCLUSTERS: 'Show Unassigned Clusters',
          EXPORT_TTIP: null,
        },
        ASSORT_ASSIGNATION_ITEM: {
          OBSERVATION: 'Observations',
          ACTIVE_IN: 'Active in',
          ITEM_TAGS: 'Product Filters',
          STORE_TAGS: null,
          BEHAVIOR_FOR_CATEGORY: 'This item can only be operated from the general cluster pareto.',
          SUGGESTION_MODAL_MSG:
            'The request to generate suggestions for this cluster will be taken, you will be notified once the process is complete, it may take a few minutes',
          NOT_ASSORTMENT_BASED: 'This Item is not assortment based. You must modify it from item master first.',
          BLOCKED_FOR_SALES: 'This item is blocked for sale. You must modify it from item master first.',
          BLOCKED_FOR_MARKET: 'This item is blocked. Market Product, it should be managed from the suggestions by cluster.',
          ROUTE: 'Assortment Allocation /',
          FILTER_TTIP: 'Filter',
          FULLSCREEN_TTIP: 'Expand',
          ORDER_TTIP: 'Order',
          EXITFULLSCREEN_TTIP: 'Reduce',
          PRINT_TTIP: 'Print',
          ACTIVATE: 'Activate',
          ACTIVATE_TTIP: 'Activate the selected products',
          INACTIVATE: 'Deactivate',
          INACTIVATE_TTIP: 'Deactivate the selected products',
          PRODUCT: 'Product',
          BRAND: 'Brand',
          STATE: 'State',
          ACTIVE: 'Active',
          INACTIVE: 'Inactive',
          INACTIVE_MARKET_PROD: 'Market Product, suggestions for action should be generated',
          SEGMENT: 'Segment',
          CIA: '# UN Comp.',
          MARKET: '# UN Mark.',
          PRICE: 'Price $',
          MARGIN: 'Margin %',
          CORRECT: 'Allocation saved successfully',
          REPORT_OK: null,
          ACCEPT_BTN: 'Accept',
          TITLE_FILTERS: 'Filters',
          STATE_FILTERS: 'State',
          ALL_OP1: 'All',
          ACTIVE_OP2: 'Active',
          INACTIVE_OP3: 'Inactive',
          CLEAN_FILTER_BTN: 'Clear',
          APPLY_FILTER_BTN: 'Apply',
          LISTING: 'Activate',
          DELISTING: 'Deactivate',
          ACCEPT_SUGGESTION: 'Accept Suggestion',
          REJECT_SUGGESTION: 'Reject Suggestion',
          ACCEPT_SUGGESTIONS: null,
          REJECT_SUGGESTIONS: null,
          REVERT_SUGGESTION: 'Revert Changes',
          REVERT_SUGGESTION_TO_INACTIVE: 'Revert Changes (Inactivate)',
          REVERT_SUGGESTION_TO_ACTIVE: 'Revert Changes (Activate)',
          COLUMN_DISPLAY_TTIP: 'Column Display',
          REPORTS: 'Reports',
          SUGGESTION: 'Generate Suggestion',
          PRODUCTS: 'Products',
          SALES: 'Sales',
          IMPACT: 'Sales Impact',
          DAYS: 'Days',
          COST: 'Cost $',
          ORDERS: '# Orders',
          DAYS_STOCK: 'Days of Stock',
          IN_STOCK: 'In-Stock',
          CLUSTER_SUGGESTION_ADD_TTIP: 'Inactive / It is suggested to add this product in this cluster based on the generated rule.',
          CLUSTER_SUGGESTION_REMOVE_TTIP: 'Active / It is suggested to remove this product in this cluster based on the generated rule.',
          CLUSTER_SUGGESTION_ADD_DEMO_TTIP: 'Suggest activating products with sales share within the top 80% of the market',
          CLUSTER_SUGGESTION_REMOVE_DEMO_TTIP: 'Suggest deactivating products at bottom 5% of sales and availability above 80%',
          ACTIVE_NEW: 'To Activate',
          INACTIVE_NEW: 'To deactivate',
          ASSORTMENT_FOR_CATEGORY__BY_CLUSTER: 'Handle by Cluster',
          ASSORTMENT_FOR_CATEGORY__BY_CATEGORY: 'Handle by General Total',
          LISTING_ALL_CLUSTERS: 'Activate in all Clusters',
          DELISTING_ALL_CLUSTERS: 'Deactivate in all Clusters',
          STATUS_PDV: 'Only Assortment of Stores',
          STATUS_COMBO: 'Part of Recipe or Combo',
          CHANGE_GENERAL_CONFIRM_DIALOG_TITLE: 'Assortment Assignment Confirmation',
          CHANGE_GENERAL_CONFIRM_DIALOG_BODY: 'Do you want to handle this Item in a General Total?',
          CHANGE_GENERAL_CONFIRM_DIALOG_BODY_ALT:
            'Assortment assignment will be changed to "General". The individual assignments of each cluster will be lost.',
          WHITH_FILTERS: 'With Filters',
          WHITH_NO_FILTERS: 'Without Filters',
          setOrderBydefault: 'Sort by Pareto',
          setOrderBysalesShare: 'Sort by Sales Share',
          setOrderBysalesShareGap: 'Sort by Share GAP',
          EXIST_EXCEPTION_STORE_ASSORTMENT_TOOLTIP: null,
          STORE_CLUSTER_COUNT_TOOLTIP_ACTIVE: 'The product is Active for sale in {{val}} out of {{val2}} POS.',
          STORE_CLUSTER_COUNT_TOOLTIP_INACTIVE: 'The product is Inactive for sale in {{val}} out of {{val2}} POS.',
        },
        ASSORT_CLUSTER_REPORTS: {
          GENERAL_REPORT: 'General Report',
          CLUSTER_REPORT: 'Report by Cluster',
          PREFIX_REPORT: 'Report by',
        },
        ERP: {
          ROUTE: 'Assortment / Decisions',
          TITLE: 'Publish Decisions',
          DOWLOAD_BTN: 'Download Info',
          PREVIEW_BTN: 'Preview',
          INFORM_BTN: 'Publish',
          LAST_PRICES: 'Latest Prices Published',
          GENERATION_DATE: 'Generation date',
          GENERATION_TIME: 'Generation Time',
          USER: 'User',
          FILE: 'File',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          PREVIEW_PROCCESING: 'Processing has started. You will receive a notification when it is finished.',
          PREVIEW_WITHOUT_PROCCESING: 'There is no new data to process.',
          REPORT_ASSORTMENT_DECISIONS: 'Assortment Decisions',
          REPORT_MARKET_ITEM: 'Market Items',
        },
      },
      REPORTS: {
        TITLE_DECISIONS: 'Decision Reports',
        CLUSTER_ANALYSIS: {
          ROUTE: 'Assortment / Reports',
          TITLE: 'Cluster Analysis',
          SUBTITLE:
            'Prisma uses artificial intelligence algorithms to detect consumer purchasing patterns and thus validate the clustering carried out. On this screen, you can analyze the consistency of previously defined clustering and change a point of sale of a cluster if necessary. By accepting a change suggestion, Prisma will automatically produce the assortment reassignment and the report that must be published or points of sale for the change to be correctly implemented.',
          DISPERSION_TITLE: 'Scattering of {{val}} by Cluster',
          EXPORT_TTIP: 'Export',
          APP_TTIP: 'Share to the App',
          DASHBOARD_TTIP: 'Go to dashboard',
          NUMBER_OF_STORES: 'Number of Stores',
          PREVIOUS_MONTH_DIF: 'Dif % Vs Previous Month',
          CLUSTER_QUANTITY: 'Cluster Quantity',
          PREVIEW_PROCCESING: 'Processing has started. You will receive a notification when it is finished.',
          PREVIEW_WITHOUT_PROCCESING: 'There is no new data to process.',
        },
      },
      ADMIN: {
        IMPORTERS: {
          MARKET_INFO: {
            TITLE: 'Market Information',
          },
        },
      },
      ABM_MODAL: {
        ROLE_SELECTION: 'Role Selection',
        NEW_ROL_NAME: 'Role Data',
        RULES_TO_DELIST: 'Rules to Deactivate',
        RULES_TO_LIST: 'Rules to Activate',
        MARKET_LIST: 'Market Registration',
        VIEW: 'View',
        RULES_SELECT_LEGEND1:
          'The request to generate the suggestions for this category will be taken, you will be notified once the process is complete, it may take a few minutes.',
        RULES_SELECT_LEGEND2:
          'The role is assigned to the category and combined with the strategy define the criteria and variables that will be evaluated in the suggestions.',
        RULES_SELECT_LEGEND3: ' Suggestions will only be generated for visible clusters.',
        ASSIGN_ROLE: 'Assign Role',
        ROLES_TYPES_TITLES: {
          NORMAL: 'Default role of the category:',
          OTHER: 'Assign Previously Created Role',
          NEW: 'Create and assign a new Role',
          TEST: 'Assign Test Rules',
          MARKET: 'Registration of Market products',
        },
        ROLES_TYPES_DESCRIPTION: {
          NORMAL:
            'Suggestions for registration and cancellation will be generated according to the Role already selected for this category.',
          OTHER:
            'The registration and cancellation suggestions will be generated according to the selection of a previously registered Role.',
          NEW: 'Allows you to Create and Assign a new role for this category.',
          TEST: ' Up and Down rules will be assigned and generated according to the predefined settings in a personalized way for this category.',
          MARKET: 'Allows you to create products that you do not have in ItemMaster',
        },
        GENERATE_SUGGESTIONS_OK: 'Suggestion assigned successfully',
      },
    },
    IMV2: {
      LIST: {
        TITLE: 'Item Master',
        NEW_ITEM: 'New Item',
        ROUTE: 'item-master / list',
        DELETE: 'Delete',
        DELETE_TTIP: 'Deactivate the registry',
        UNDELETE: 'Activate',
        UNDELETE_TTIP: 'Activate the registry',
        DELETE_ROWS: 'Inactive records',
        DELETE_ROW_CONTENT: 'Are you sure you want to inactive {{val}}? it can be inactivated if is not in use',
        DELETE_ROWS_CONTENT: 'Are you sure you want to inactive {{val}} products? they can only be inactivated if they are not in use',
        UNDELETE_ROW: 'Activate Record',
        UNDELETE_ROWS: 'Activate Records',
        UNDELETE_ROW_CONTENT: 'Are you sure you want to activate {{val}}? it will be activated with the state new',
        UNDELETE_ROWS_CONTENT: 'Are you sure you want to activate {{val}} products? they will be activated with the state new',
        ACTION_ROW_CONTENT_SUCCESS: 'Action completed successfully',
        ENTITIES: {
          REPORT: 'Full report here',
          APPLY: 'Due to being in use in the following entities:',
          ITEM_NOT_FOUND: 'Item/s not found',
          USEDINCOMBOS: 'Combos: {{val}}',
          USEDINMISSIONS: 'Missions: {{val}}',
          USEDINPARENTRULES: 'Pricing rules: {{val}}',
          USEDINPLANOGRAMS: 'Planograms: {{val}}',
          USEDINPROMOTIONS: 'Promotions: {{val}}',
          USEDINRECIPES: 'Recipes: {{val}}',
          IMPEDIMENT: {
            USEDINPARENTRULES: 'Parent product cannot be empty. Delete rule and create a new one with another parent product if required.',
            USEDINMISSIONS: 'Gift product cannot be left empty. Assign another gift product first.',
            USEDINPLANOGRAMS: 'A product present in a planogram cannot be deleted. Remove from planogram first.',
            USEDINRECIPES: 'You cannot delete a product that is part of a recipe. Remove from recipe first.',
            USEDINCOMBOS: 'The last product of a block cannot be removed. Add another product to the block or remove the block.',
          },
        },
        ACTION_FAIL: {
          DESCRIPTION_ERROR: 'The problem was caused by ',
          TYPE: 'Type: {{val}}',
          KEYWORD: 'Keyword: {{val}}',
          VALUES: 'Values: {{val}}',
          WARNING: 'Alert',
          ERROR: 'Error',
          ENTITYNOTFOUND: 'Entity not found',
        },
        FILTER_TTIP: 'Filter',
        FULLSCREEN_TTIP: 'Maximize',
        EXPORT_TTIP: 'Export',
        NAME: 'Name',
        CODE: 'Code',
        INTERNAL_CODE: 'Internal Code',
        CODE_EXIST: 'The code {{val}} already exist',
        TYPE: 'Type',
        CATEGORY: 'Category',
        SUBCATEGORY: 'Subcategory',
        BRAND: 'Brand',
        EDIT_ROW: 'Edit record',
        VIEW_ROW: 'View record',
        EDIT_ROW_DISABLED_TOOLTIP: 'Cannot edit a record in publishing process',
        COPY_ROW: 'Copy record',
        DISABLED_CLONE_TOOLTIP: 'Only records in published state can be cloned',
        DISABLED_CLONE_TOOLTIP_OR_NEW: null,
        DELETE_ROW: 'Inactive record',
        TYPE_FILTERS: 'Types of items',
        SEE_DELETED_FILTERS: 'View Inactivated',
        SEE_ITEMS_CREATED_WHIT_MARKET_RULE: 'See Items created based on Market Information',
        SHORT_NAME: 'Short Description',
        STATUS: 'Status',
        STATUS_NEW: 'New',
        STATUS_MODIFIED: 'Modified',
        STATUS_CURRENT: 'Posted',
        EAN: 'Internal Code',
        ITEM_CREATION_ON: 'Item Creation Date',
        ITEM_MODIFY_ON: 'Item Modification Date',
        ASSORTMENTBEHAVIORS: 'Assortment',
        STORES_FILTER: 'Stores',
        CLONE_ITEM_TITLE: 'Clone Item',
        CLONE_ITEM_CONTENT:
          'Define name and internal code to the new item. All existing points will be cloned by {{val}} ({{val2}}), having the possibility of editing.',
        ITEM_TAGS: 'Product Filters',
        ITEM_FILTER: 'Item',
        ACTION_ROW_CONTENT_FAILED: 'The action cannot be carried out correctly',
      },
      EDIT: {
        ITEM: {
          SAVE: 'Save',
          PUBLISH: 'Publish',
          WARNING_SAVE: 'Current changes are required to be saved.',
          WARNING_PUBLISH: 'You need to republish for the saved changes to take effect.',
          NOVALID_PUBLISH_ADVICE: 'The following tab is not valid:',
          BASIC_INFORMATION_TAB: 'Main data',
          CLASSIFICATION_TAB: 'Classification',
          UNITS_OF_MEASURE_TAB: 'Units of measure',
          ASSORTMENT_TAB: 'Assortment Planning',
          WARNING_SEGMENT: 'It is necessary to complete the Segment field on Assortment tab, to be able to publish changes.',
          VENDORS_TAB: 'Suppliers, Cost. & Tax.',
          PRICES_TAB: 'Prices',
          INGREDIENTS_TAB: 'Ingredients',
          COMPONENTS_TAB: 'Kit Components',
          CODES_TAB: 'Codes',
          CODES_MATCHING: 'Matchings',
          DELIVERY_TAB: 'Sales channels',
          LOADING: 'Loading...',
          NEW_ITEM_BTN: 'New Item',
          PUBLISH_CONFIRM:
            'The publication process includes the creation of the Assortment, Prices, Costs and other records related to the Product.<br><br><b>Are you sure you want to Publish?</b>',
          PUBLISH_CONFIRM_TITLE: 'Publication Confirmation',
          PUBLISHING_WARNING: 'Item in process of publication, try again in a few minutes.',
          PUBLISH_LONG_TIME:
            'The publication of this Product may take a few minutes, when it ends you will be notified with a notification.',
          PUBLISH_InvalidCombo: 'The combo is invalid, check the flap corresponding to the product.',
          PUBLISH_InvalidRecipe: 'The recipe is invalid, check the label corresponding to the product',
          PUBLISH_Vendors: 'There is an error in the associated suppliers, check the tab corresponding to suppliers.',
          REPORT: 'Report',
          REPORT_WARNING: 'Publish is required to download the report.',
        },
        MAIN_DATA: {
          NAME: 'Name',
          SHORT_NAME: 'Short Description',
          CREATION_DATE: 'Item Creation Date',
          INTERNAL_CODE: 'Internal Code',
          VALIDATION_CODE2: 'The {{val}} code already exists.',
          BAR_CODE: 'Bar Code',
          REGIONAL_ITEM: 'Regional Item',
          REGIONAL_ITEM_CHANGE: 'Change to Standard Item',
          REGIONAL_ITEM_DISABLED: 'You do not have permissions to switch to Standard Product',
          REGIONAL_ITEM_MODAL_TITLE: 'Change Regional Item Status',
          REGIONAL_ITEM_MODAL_CONTENT:
            'Are you sure you want to modify the item to stop being a Regional item type? This operation is irreversible. Only by means of interfaces can items of the Regional type be created and other types of items cannot be modified to this state.',
        },
        UNITS_OF_MEASURE: {
          NAME: 'Name',
          DUP_CODE_AND_CONVERSION: 'There cannot be 2 units of measure with an exact name and conversion factor.',
          UNIT_NAME: 'Unit Name',
          CONVERSION: 'Conversion factor',
          UNIT_BASE: 'Base Unit',
          SALES_UNITS: 'Sales unit',
          SOME_SELLING_UNIT: 'There must be at least one sales unit.',
          INITIAL_PRICE: 'Initial price',
          INITIAL_PRICE_TIP: 'This price will be applied to all the stores where the product is active',
          FRONT_PHOTO: 'Front item image',
          SIDE_PHOTO: 'Item image from side',
          PHOTO_ABOVE: 'Item image above',
          EXHIBITION_UNITS: 'Exhibition Unit',
          FACINGS_LIMIT: 'Frentes Limit',
          MIN_FACES: 'Minimum Faces',
          MAX_FACES: 'Maximum Faces',
          MAG_HEIGHT_WITH_MAG: 'Height (cm.)',
          MAG_WIDTH_WITH_MAG: 'Width (cm.)',
          MAG_DEPTH_WITH_MAG: 'Depth (cm.)',
          STACKABLE_SWITCH: 'Stackable',
          OVERTHROW_SWITCH: 'Overthrowing',
          ROTATE_SWITCH: 'Rotating',
          MAX_STACKS: 'Maximum stacks',
          NEW_UNIT_BTN: 'New Unit',
          REGULATED_PRICE_MAX: 'Maximum Price',
          REGULATED_COST_MAX: 'Maximum Cost',
          REGULATED_PRICE_TIP: 'Define the maximum value for the price',
          REGULATED_COST_TIP: 'Define the maximum value for the cost',
          FORCE_REGULATED_PRICE_MAX: 'Force Maximum Price',
          FORCE_REGULATED_COST_MAX: 'Force Maximum Cost',
          REGULATED_PRICE_MARGIN: 'Margin',
          REGULATED_PRICE_MARGIN_TIP: 'Define the margin for the price',
        },
        RECIPE: {
          COMPONENT: 'Component',
          TYPE: 'Type',
          NO_UOM: 'Item without Unit of measure',
          UOM: 'Unit of measure',
          QUANTITY: 'Quantity',
          WASTE_INPUT: 'Waste %',
          COST: 'Cost',
          TAX: 'Tax',
          TOTAL_COST: 'Approximate Total Cost:',
          ADD_COMPONENT: 'Add Component',
        },
        COMBO: {
          NAME: 'Name',
          QUANTITY: 'Quantity',
          NO_UOM: 'Item without Unit of measure',
          UOM: 'Unit of measure',
          MAX_COST_TIP: 'The component with the highest cost is taken into account',
          TOTAL_COST: 'Approximate Total Cost:',
          ADD_BLOCK: 'Add Group',
          ADD_COMPONENT: 'Add Component',
          COST: 'Cost',
          TAX: 'Tax',
        },
        CODES: {
          HELP_BOX: 'In this section you can add additional barcodes or other external codes.',
          CODE_TYPE: 'Code type',
          CODE: 'Code',
          VALIDATION_CODE2: 'The {{val}} code already exists.',
          VALIDATION_CODE_DUPLICATE: 'There are duplicate codes',
          INVALID_CODE: 'Invalid code format.',
          UNITS_OF_MEASURE_BUY: 'Unit of Measure',
          ADD_CODE: 'Add Code',
          NEW_CODE: 'New Code',
          PRIMARY_CODE_DELETE_WARNING: 'It is not possible to delete a Primary Code.',
        },
        MATCHINGS: {
          ADD_MATCHING: 'Add matching',
          ITEM_CODE_TYPE: 'Item code type',
          ITEM_CODE: 'Item code',
          ITEM_NAME: 'Item name',
          DUPLICATED_FLAGS: 'Duplicated flags',
          INVALID_CODE: 'Invalid code',
          NAME_REQUIRED: 'Name required',
          FLAG_REQUIRED: 'Flag required',
          MATCHING_OF: 'Matching of',
        },
        ASSORTMENT: {
          NOT_PARAMETERIZED: 'To enable this button, the parameterization of the category in Assortment must be completed.',
          NOT_ACCESS_PERMISSION: "Don't have permissions to complete this section",
          NOTIFY_USER: 'NOTIFY USER',
          NOTIFIED_USER: 'Notified user',
          IMPORT_FILE_NAME_LINK: 'determinar-PDV.xlsx',
          IMPORT_FILE_NAME_BTN: 'load assortment exception',
          IMPORT_ASSORTMENT_STORE_CONTEXT:
            'Can import exceptions of assortment by the importer button or see the assortment state for this item',
          BTN1_TITLE: 'Based on assortment',
          BTN1_MSG:
            'It takes into account the main segmentation defined for the category, being able to make exceptions through an importer.',
          BTN2_TITLE: 'Active in all Sales Points',
          BTN2_MSG: 'The product will be active at all points of sale.',
          BTN3_TITLE: 'Only if it is part of Recipe or Combo',
          BTN3_MSG: 'The product can participate in a recipe or a combo.',
          BTN4_TITLE: 'Only Assortment of Sales Points',
          BTN4_MSG: 'The product will be active in certain Points of Sale',
          ASSORTMENT_SEGMENT_SETUP_TITLE: 'Define segment',
          ASSORTMENT_SEGMENT_SETUP_CONTENT_1: 'Define segment for category {{val}}.',
          ASSORTMENT_SEGMENT_SETUP_CONTENT_2: 'This change will impact the segment assignment screen in the Assortments module.',
          SEGMENT: 'Segment',
          DEFINE: 'Define',
          CANCEL: 'Cancel',
          IMPORT_TITLE: 'Import Points of Sale',
          ASSORTMENTBEHAVIORS_BASEDONASSORTMENT: 'Based on assortment',
          ASSORTMENTBEHAVIORS_ACTIVEINALLSTORES: 'Active at all points of sale',
          ASSORTMENTBEHAVIORS_ONLYIFPARTOFRECIPEORCOMBO: 'Only if it is part of Recipe or Combo',
          ASSORTMENTBEHAVIORS_ONLYSTOREASSORTMENT: 'Only assortment of Stores',
          INIT_ASSORMENT: 'Assortment configuration',
          CHOOSE_OPT_ASSORMENT: 'Choose assortment behavior',
          BLOCKED_FOR_PURCHASE: 'Buy block',
          BLOCKED_FOR_SALES: 'Block sale',
          CLONE_EXCEPTION_STORE_ASSORTMENT: 'Clone Assortment Exceptions',
          CLONE_EXCEPTION_STORE_ASSORTMENT_MSG:
            'Enabling this option will clone stock exceptions from this record. There must be at least one assortment exception that is not blocked for sale',
          DOWLOAD_EXCEL: 'Download excel',
          VIEW_ASSORTMENT_STATUS: 'Assortment Report',
          REQUIRE_SAVE_ASSORTMENT_STATUS: 'Requires saving changes made',
          REQUIRE_SAVE_ASSORTMENT_STATUS_MSG:
            'Changes were detected that affect the status of the Assortment, to be able to access you must save the changes made',
          IMPORT_ITEMS: 'Assortment by Point of Sale',
          IMPORT_EXCEL_ITEMS_FIXED_PRICE: 'Import Fixed Prices',
          IMPORT_EXCEL_ITEMS_DISCOUNT: 'Import Discounts',
          IMPORT_EXCEL_ITEMS: 'Import exceptions',
          DELETE_EXCEL_ITEMS: 'Delete Exceptions',
          DELETE_EXCEL_ITEMS_MESSAGE: 'All loaded exceptions will be deleted.',
          IMPORT_EXCEL_ITEMS_WARNING:
            'In order to import assortment exceptions and check the status of the same, it is necessary to have at least the main data saved. ',
          CATEGORY_CONFIG_WARNING:
            'To use assortment-based behavior, it is required that this items category have Segmentation and Clustering assigned.',
          CONFIG_CATEGORY: 'Assign Segmentation and Clustering to Category',
          CONFIG_TAG: 'Assign tag values',
          CONFIG_TAG_CONTENT: 'The segment that belongs to the selected category has no values',
          DEFINE_SEGMENT: 'DEFINE SEGMENT',
          CATEGORY_WARNING:
            'Category {{val}} does not have a defined main segmentation, choose an option to move forward with assortment initialization.',
          CATEGORY_SELECT_WARNING: 'You need to select a category for this product in order to proceed with assortment initialization.',
          NAME: 'Name',
          CODE: 'Code',
          ACTIVE: 'Active',
          BLOCQUED_SELL: 'Blocked for sale',
          BLOCQUED_BUY: 'Blocked for purchase',
          OBSERVATIONS: 'Observations',
        },
        PRICES: {
          SUGGESTED_PRICE_INPUT: 'National fixed price',
          DINAMIC_PRICE_INPUT: 'Discount on components %',
          PRICE_TYPE_INPUT: 'Price Type',
          DISCOUNT_INPUT: 'Discount',
          BTN3_TITLE: 'Based on the price of its components',
          BTN3_MSG: 'Every time you change the price of a component, the kit price can change.',
          PERMANENT_PRICE_INPUT: 'Fixed price',
          BTN1_TITLE: 'National Price',
          BTN1_MSG: 'It takes into account the initial unique price defined for each of the sales measurement units',
          BTN2_TITLE: 'Clone Price',
          BTN2_MSG: 'Clone the price of an existing product',
          INIT_PRICE: 'Initialization of Prices',
          CHOOSE_INIT_PRICE: 'Choose a price initialization option',
          REVIEW_CHANGES: 'To consult price changes go to ',
          PRICE_DECISIONS: 'Pricing Decisions',
          NO_PRICE_TIP:
            'In case of not finding a price at any point of sale for the cloned product, the initial price of the sales unit of measure will be used.',
          INITIAL_PRICE: 'Initial Price',
          SELL_UOM: 'Sales unit of measure',
          IVA: 'VAT',
          VIEW_ASOCIATED_RULES: 'VIEW ASSOCIATED RULES',
          CONFIRM_CLONE_VALIDATION_MSG:
            'The product from which you want to clone prices does not have the same units of measure. It is recommended to choose a product with the same sales units of measure.',
          CONFIRM_CLONE_VALIDATION_TITLE: 'Confirm selection',
          CONFIRM_CLONE_VALIDATION_OK: 'Continue with current selection',
          CONFIRM_CLONE_VALIDATION_CANCEL: 'Choose new product',
          VIEW_ASOCIATED_RULES_TITLE: 'No associated rules',
          VIEW_ASOCIATED_RULES_TITLE_MGS: 'Cannot query an item that was not published',
        },
        VENDORS: {
          TITLE: 'Suppliers, Costs & Taxes',
          SUBTITLE:
            'Define a supplier/s for this product. If you choose with a segmentation, all the points of sale must have an assigned value and in this way they can charge specific costs and taxes for each value of the segment.',
          VENDOR: 'Vendor',
          PRIMARY_VENDOR_WARNING: 'At least one Primary Vendor must exist.',
          PRIMARY_VENDOR_WARNING_ONLY_ONE: 'There cannot be more than one Primary Provider.',
          PRIMARY_VENDOR_WARNING_UOM: 'The same Supplier cannot exist with the same unit of measure.',
          PRIMARY_VENDOR_WARNING_DUP: 'It is not possible to register a provider more than once.',
          REGIONAL_PRODUCT_WITH_ONE_VENDOR: 'You cannot have more than one supplier',
          PRIMARY_VENDOR_DELETE_WARNING: 'It is not possible to delete a Primary Provider.',
          INTERNAL_CODE: 'Internal Supplier Code',
          SEGMENT: 'Segment',
          SEGMENTATION: 'Segmentation',
          UOM_BUY: 'Unit of measure',
          MIN_ORDER: 'Minimum Order',
          MAX_ORDER: 'Maximum Order',
          NEW_VENDOR: 'Associate Vendor',
          UNIQUE_COST: 'Unique cost',
          UNIQUE_COST_NATIONAL: 'National cost',
          SEGMENT_COSTS: 'Segment Costs',
          PRIMARY_VENDOR: 'Primary vendor',
          SECONDARYY_VENDOR: 'Secondary vendor',
          INDICATE_TEXT: 'indicate the cost and tax values corresponding to:',
        },
      },
    },
    ITEM_MASTER: {
      TITLE: 'Items',
      NEW_ITEM_BTN: 'New Item',
      DUPLICATED_ITEM: 'Duplicated Item',
      FILTER_TTIP: 'Filter',
      FULLSCREEN_TTIP: 'Expand',
      EXITFULLSCREEN_TTIP: 'Reduce',
      EXPORT_TTIP: 'Export',
      IMPORT_TTIP: 'Import',
      PRINT_TTIP: 'Print',
      DELETE_TTIP: 'Delete selected items',
      DELETE: 'Delete',
      RECOVER: 'Recover',
      RECOVER_TTIP: 'Recover selected items',
      NAME: 'Name',
      CODE: 'Code',
      TYPE: 'Type',
      CATEGORY: 'Category',
      SUBCATEGORY: 'Subcategory',
      BRAND: 'Brand',
      CREATION_DATE: 'Creation Date',
      RECOVER_ITEMS_TITLE: 'Do you want to recover the selected items?',
      RECOVER_ITEMS_SUBTITLE: 'These items will disappear from the list of deleted items.',
      CANCEL_BTN: 'Cancel',
      CONFIRM_BTN: 'Confirm',
      NEW_ITEM_MODAL: 'New Item',
      ITEM_TYPE_INPUT: 'Item Type',
      LEGEND_RETAIL:
        'Retail items are regular items that can be sold in any sales channel without undergoing transformations or combinations with other items.',
      LEGEND_INGREDIENT: 'These items cannot be sold individually, but are part of a recipe.',
      LEGEND_RECIPES: 'It is a item that is made up of one or more ingredients.',
      LEGEND_SUPPLY: 'These items cannot be sold individually, but are part of a recipe.',
      CREATE_BTN: 'Create',
      TITLE_FILTERS: 'Filters',
      SEE_DELETED_FILTERS: 'View Deleted',
      TYPE_FILTERS: 'Type',
      CLEAN_FILTER_BTN: 'Clear',
      APPLY_FILTER_BTN: 'Apply',
      DEFINE_ITEM_STP1: 'Setup Item',
      CLASSIFICATION_STP2: 'Classification',
      LOGISTICS_DATA_STP3: 'Logistics Info',
      INTEGRATION_STP3: 'Integration',
      PURCHASE_DATA_STP4: 'Supply Info',
      SALES_DATA_STP5: 'Sales Info',
      EDIT_TITLE_FORM: 'Edit',
      NEW_TITLE_FORM: 'New',
      INGREDIENT_TITLE: 'Ingredient',
      SUPPLY_TITLE: 'Supply',
      RETAIL_TITLE: 'Retail',
      RECIPE_TITLE: 'item - Recipe',
      COMBO_TITLE: 'item - Kit',
      ITEM: 'Item',
      STP1: 'Step 1 - Setup Item',
      INTERNAL_CODE_INPUT: 'Internal code',
      ERROR: 'incorrect',
      EAN_INPUT: 'Barcode',
      TYPE_INPUT: 'Type',
      NAME_INPUT: 'Name',
      SHORT_DESCRIPTION_INPUT: 'Short description',
      SHORT_DESCRIPTION_VALIDATION: 'Characters entered:',
      CREATION_DATE_INPUT: 'Creation date',
      NEXT_BTN: 'Next',
      STP2: 'Step 2 - Item Classification',
      CATEGORY_INPUT: 'Category',
      SEARCH_CATEGORY: 'Search Category ...',
      SUBCATEGORY_INPUT: 'Subcategory',
      SEARCH_SUBCATEGORY: 'Search Subcategory ...',
      BRAND_INPUT: 'Brand',
      SEARCH_BRAND: 'Search Brand...',
      ADD_BRAND_BTN: 'Add Brand',
      PREVIUS_BTN: 'Previous',
      STP3: 'Step 3 - Logistics Info',
      UNIT_NAME: 'Unit Name',
      UNIT_NAME_INPUT: 'Unit Name',
      DELETE_UM: 'Delete Unit of Measure',
      CODE_INPUT: 'Code',
      REQUIRED: 'Required field',
      CODE_VALIDATION: 'Code must be unique',
      UNIT_REPEATED: 'Unit repeated',
      CODE_REPEATED: 'Code repeated',
      CREATE_UNIT: 'Create new Unit',
      CONVERSION_FACTOR_INPUT: 'Conversion factor',
      UNIT_BASE_CHECK: 'It is Base Unit',
      NEW_UNIT_BTN: 'New Unit',
      UNIT_ALREADY_EXIST: 'Unit already exist',
      STP4: 'Step 4 - Purchase Info',
      STP5: 'Step 5 - Purchase Info',
      PURCHASE_UNIT_INPUT: 'Purchase Unit',
      QUANTITY_UMB_INPUT: 'Quantity x BUoM',
      SUPPLIER_INPUT: 'Suppliers',
      SEARCH_SUPPLIER: 'Search Suppliers...',
      COST_INPUT: 'Cost',
      PURCHASE_IVA_INPUT: 'Purchase Tax',
      SALE_IVA_INPUT: 'Sales Tax',
      SAVE_BTN: 'Save',
      STP_4_2: 'Step 4 - Integration',
      UNITS_OF_MEASURE_INPUT: 'Unit of measurement',
      WASTE_INPUT: 'Waste %',
      STP5_R: 'Step 5 - Sales Info',
      SALES_UNITS: 'SALES UNIT',
      SALES_UNITS_DELETE_TTIP: 'Delete Sales Unit',
      SALES_UNITS_INPUT: 'Sales unit',
      SALES_UNITS_VALIDATION: 'Unit already selected',
      HIGH_INPUT: 'High',
      WIDTH_INPUT: 'Width',
      DEPTH_INPUT: 'Depth',
      MAX_STACKABLE: 'maximum number of Stacks',
      OVERTHROW_SWITCH: 'Overthrowing',
      ROTATE_SWITCH: 'Rotating',
      ROTATEY_SWITCH: 'Rotating in Y',
      EXHIBITION_UNITS_INPUT: 'Exhibition Unit',
      STACKABLE_SWITCH: 'Stackable',
      FRONT_PHOTO: 'Upload front item image',
      SIDE_PHOTO: 'Upload item image from side',
      PHOTO_ABOVE: 'Upload item image above',
      COMPOSITION_STP3: 'Ingredients',
      STP3_RECIPES: 'Step 3 - Recipe Ingredients',
      INGREDIENT: 'Ingredient',
      ITEM_TITLE: 'Item',
      INGREDIENT_DELETED_TTIP: 'Delete the {{val}}° ingredient',
      ITEM_DELETED_TTIP: 'Delete the {{val}}° item',
      INGREDIENT_INPUT: 'Ingredient',
      ITEM_INPUT: 'Item',
      SEARCH_INGREDIENT: 'Search Ingredients ...',
      QUANTITY_INPUT: 'Quantity',
      NEW_INGREDIENT_BTN: 'New Ingredient',
      DEFINE_COMBO_STP1: 'Setup Kit',
      COMBO_ARMING_STP3: 'Kit Item Groups',
      PRICE_STP4: 'Price',
      STP1_COMBO: 'Step 1 - Setup Kit',
      STP3_COMBO: 'Step 3 - Kit Item Groups',
      BLOCK_NAME_INPUT: 'Group Name',
      BLOCK_DELETED_TTIP: 'Delete Group',
      ITEMS_INPUT: 'Items',
      SEARCH_ITEMS: 'Search Items ...',
      ADD_BLOCK_BTN: 'Add Group',
      STP4_COMBO: 'Step 4 - Definition of price type',
      PRICE_TYPE_INPUT: 'Price Type',
      DISCOUNT_INPUT: 'Discount',
      FIXED_PRICE_MESSAGE: 'The fixed price nationwide will set the same price for all stores and channels',
      DISCOUNT_MESSAGE:
        'It takes into account the price of the cheapest component of each group and only the items that are active in the assortment of each store.',
      SUGGESTED_PRICE_INPUT: 'National fixed price',
      PERMANENT_PRICE_INPUT: 'Fixed price',
      EXCLUDED_RULES_CHECK: 'This kit will be excluded from the margin and competitive rules',
      EXCLUDED_RULES_CHECK_ALT: 'This combo will be included in the margin and competition rules',
      DINAMIC_PRICE_INPUT: 'Discount on components %',
      PRICE_STRATEGY: 'How are you going to price this kit?',
      INIT_PRICE: 'How are you going to set the initial price of this kit?',
      INIT_PRICE_EDIT: 'Do you want to change the current price?',
      PRICE_STRATEGY_COMPONENT_TITLE: 'Based on the price of its components',
      PRICE_STRATEGY_COMPONENT_MSG: 'Every time you change the price of a component, the kit price can change.',
      PRICE_STRATEGY_RULE_TITLE: 'Based on competitive or relationship rules',
      PRICE_STRATEGY_RULE_MSG:
        "When a competitor's price changes or when the price of a related item changes, Prisma will suggest a price change.",
      SUGGESTED_PRICE_TEXT:
        ': Minimum price of products in all blocks - Same price at all points of sale, starting or not from the suggested one.',
      DINAMIC_PRICE_TEXT:
        ': Current price of each point of sale (according to their products) + discount. It will not be affected by the kinship rules.',
      DINAMIC_PRICE_TTIP: 'Dynamic Price: Current price of each store (according to its items) + discount',
      NEW_UNIT: 'New Unit',
      NAME_INNPUT: 'Name',
      ADD_BTN: 'Add',
      NEW_INGREDIENT: 'New Ingredient',
      FREE_STORES: 'Stores without cost or price',
      FREE_STORES_SUBTITLE: 'Costs and prices were generated for all stores',
      ACCEPT_BTN: 'Accept',
      ERROR1: 'No cost or price was generated for these stores, because some of the kits items do not have a price.',
      ERROR2: 'No cost or price was generated for these stores, because some of the ingredients do not have a cost.',
      NEW_BRAND: 'New Brand',
      NEW_SUPPLIER: 'New Supplier',
      INFO_PERMISSION: 'The current user have only permission to edit attributes, any other change will not be allowed.',
      Retail: 'Retail',
      Ingrediente: 'Ingredient',
      Receta: 'Recipe',
      Combo: 'Kit',
      Supply: 'Supply',
      Insumo: 'Supply',
    },
    SUPPLY: {
      SETUP: {
        ORDER_SCHEDULE: {
          TITLE: 'Order Schedule',
          ROUTE: 'Supply / Setup',
          DEFINITION: 'Supplier / Center Definition',
          SCOPE_PDV: 'Scope of Store',
          FREQUENCY: 'Frequency',
          NEW_ORDER_BTN: 'NEW SCHEDULE',
          STORES_FILTER: 'Stores',
          SUPPLY_FILTER: 'Supply',
          TYPE_ALL: 'All',
          TYPE_SUPPLIER: 'Supplier',
          TYPE_DISTRIBUTION_CENTER: 'Distribution Center',
          TITLE_SCHEDULE_TO_LEAD: 'Schedule to load',
          TITLE_FRECUENCY: 'Frequency',
          TITLE_INCLUDES: 'Includes',
          TITLE_LEAD_TIME: 'Lead Time (days)',
          TITLE_OVERLAPP: 'Overlapping Schedules',
          TTIP_OVERLAPP: 'View overlapping schedules',
          BTN_OVERLAPP_MODAL_CLOSE: 'Close',
          PH_CATEGORYS: 'Categories',
          PH_CATEGORYS_EXC: 'Categories Excluded',
          FORM: {
            EDIT: 'Edit Schedule',
            NEW: 'New Schedule',
            STEP: 'Step',
            NEXT_BTN: 'Next',
            PREVIUS_BTN: 'Previous',
            SAVE_BTN: 'Save',
            SCOPE_PDV: {
              CODE_SEARCH: 'Search by code',
              TITLE: 'Advanced Selection',
              DESC: 'Apply filters to your search in order to reach the desired result. This option will deselect by filters.',
              CLIPBOARD_TITLE: 'Paste stores from clipboard',
            },
            FRECUENCY: {
              HOLIDAY_RECEPTION: 'In case the day of "Order reception" / "Merchandise delivery" is a holiday / non-working day',
              NEXT_BUSINESS_DAY: 'The order is received next business day',
              NEXT_DAY_STIPULATED: 'The order is received next day stipulated in Agenda.',
            },
          },
          TYPEOFDELIVER: {
            DELIVERY: 'Product delivery',
            RECEIPT: 'Receipt of orders',
          },
          FREQUENCYTIME: {
            DAILY: 'Daily',
            WEEKLY: 'Weekly',
            MONTHLY: 'Monthly',
          },
          HOLIDAYSHANDLING: {
            WEEKLY_DAYS_NO_HOLIDAYS: 'Only days of the week except holidays',
            ALL_DAYS_NO_HOLIDAYS_NO_SUNDAYS: 'Every day except holidays and Sunday',
            ALL_DAYS_NO_HOLIDAYS: 'Every day except holidays',
            ALL_DAYS: 'Every day',
          },
        },
        ORDER_SCHEDULE_LIST: {
          TYPE_FILTER: 'Types',
          STORES_FILTER: 'Stores',
          NAME: 'Suppliers / DC',
          NAME_TTIP: 'Supplier or Distribution Center',
          CATEGORY: 'Categories',
          STORES: 'Stores',
          STORES_TTIP: 'Stores',
          FREQUENCY: 'Frequency',
          MONDAY_CHAR: 'M',
          TUESDAY_CHAR: 'T',
          WEDNESDAY_CHAR: 'W',
          THURSDAY_CHAR: 'T',
          FRIDAY_CHAR: 'F',
          SATURDAY_CHAR: 'S',
          SUNDAY_CHAR: 'S',
        },
      },
      DECISIONS: {
        ORDER_CONTROL: {
          TITLE: 'Order Control',
          ROUTE: 'Smart Supply / Decisions',
          INFORM_BTN: 'Inform',
          NEW_ORDER_BTN: 'New Order',
          FILTER_TTIP: 'Filter',
          FULLSCREEN_TTIP: 'Expand',
          EXPORT_TTIP: 'Export',
          STATUS_FILTER: 'Status',
          TYPE_FILTER: 'Movement type',
          TYPE_ALL: 'All',
          TYPE_AUTOMATIC: 'Automatic',
          TYPE_MANUAL: 'Manual',
          ORIGIN_FILTER: 'Origin',
          DESTINATION_FILTER: 'Destination',
          CREATION_DATE_FILTER: 'Order Date',
          PLANNING_DATE_FILTER: 'Target Delivery Date',
          DELIVERY_DATE_FILTER: 'Delivery date',
          NRO: 'N.',
          TYPE: 'Type',
          TYPE_TOOLTIP: 'Movement type',
          ORIGIN: 'Origin',
          DESTINATION: 'Destination',
          CREATION_DATE: 'Order Date',
          CREATION_DATE_TTIP: 'Order Date',
          PLANNING_DATE: 'Target Del. Date',
          PLANNING_DATE_TTIP: 'Target Delivery Date',
          DELIVERY_DATE: 'Delivery date',
          DELIVERY_DATE_TTIP: 'Delivery date',
          STATUS: 'Status',
          VALUE: '$ Cost',
          DECISIONS: 'Decisions',
          SAVE_BTN: 'Save',
          HEADER: 'Headboard',
          PRINT_TTIP: 'Print',
          ADD_TTIP: 'Add Products to Order',
          COPY_TTIP: 'Add products from a list copied to your clipboard',
          CODE: 'Code',
          DESCRIPTION: 'Description',
          CATEGORY: 'Category',
          BRAND: 'Brand',
          APPROVE: 'Approve',
          REJECT: 'Reject',
          INFORM: 'Report',
          TYPE_INPUT: 'Movement type',
          CODE_INPUT: 'Code',
          ORIGIN_INPUT: 'Origin',
          DESTINATION_INPUT: 'Destination',
          STATE_INPUT: 'Status',
          PRIORITY_INPUT: 'Priority',
          OBSERVATION_INPUT: 'Observation',
          CREATION_DATE_INPUT: 'Order Date',
          PLANNING_DATE_INPUT: 'Target Delivery Date',
          DELIVERY_DATE_INPUT: 'Delivery Date',
          CANCEL_BTN: 'Cancel',
          ORDER_VALUE: 'Order Cost:',
          ADD_BTN: 'Add',
          ADD_PROD_TITLE_MODAL: 'Add products to order',
          PRODUCT_INPUT: 'Product',
          UDM_INPUT: 'Units of Measure',
          QUANTITY_INPUT: 'Quantity',
          DELETE: 'Delete',
          DELETE_TTIP: 'Remove product from order',
          SUGGESTED: '# Suggested',
          SUGGESTED_TTIP: 'Suggested Amount',
          TO_ASK: '# To ask',
          TO_ASK_TTIP: 'Quantity to order',
          TO_ASK_TTIP_ANEXO: '(Pallets)',
          UDM: 'UOM',
          EAN: 'EAN: {{val}}',
          SUPPLIER: 'Supplier: {{val}}',
          STEP_BY_STEP: 'Step to Step',
          UNITS_INPUT: 'Units',
          NAME: 'Name',
          FORECASTED_SALE: 'Forecasted sale',
          CURRENT_STOCK: 'Current stock in Super San Martin',
          CURRENT_STOCK2: 'Current stock in Distribution Center Campana',
          TRANSIT_STOCK: 'Stock in transit to Super San Martin',
          MINIMUM_STOCK: 'Minimum Stock',
          MAXIMUM_STOCK: 'Maximum Stock',
          AVAILABITY_DAYS_NOW: 'Availability Days Now',
          AVAILABITY_DAYS_AFTER: 'Availability Days After',
          NEED: 'Need',
          SUBCATEGORY: 'Subcategory',
          EDIT_TTIP: 'Edit product quantity to order',
          INVENTORY: 'Inventory',
        },
      },
    },
    ADMIN: {
      MASTER: {
        STORES: {
          LIST: {
            TYPE_FILTERS: 'Type',
            STATUS: 'Status',
            STORE_TAGS: 'Point of Sale Tags',
            NAME: 'Name',
            CODE: 'Code',
            BRAND: 'Flag',
            STATE: 'Status',
            TYPE: 'Type',
            AVAIBLE: 'Enabled',
            ROUTE: 'Administration / Teachers',
            TITLE: 'Points of Sale',
            NEW_STORE_BTN: 'New Point of Sale',
            FILTER_TTIP: 'Filter',
            FULLSCREEN_TTIP: 'Expand',
            EXITFULLSCREEN_TTIP: 'Reduce',
            VIEW_TTIP: 'View',
            VIEW_TABLE: 'Only Table',
            VIEW_MAP: 'Map only',
            VIEW_TABLE_MAP: 'Map and table',
            EDIT_ROW: 'Edit',
            COPY_ROW: 'Clone',
            DELETE_ROW: 'Delete',
            INACTIVE: 'Inactive',
            ACTIVE: 'Active',
            CLONE_STORE_TITLE: 'Clone Point of Sale',
            CLONE_ITEM_CONTENT: 'A new point of sale will be created from the data of the selected row',
            CANCEL_BTN: 'Cancel',
            CREATE_BTN: 'Create',
          },
          SAVE_TOOLTIP: {
            ASSORTMENT_INVALID: 'You must assign all clusters a cluster in order to save.',
          },
          PRICES: {
            BTN2_TITLE: 'Clone prices of a store',
            BTN2_MSG: 'Choose a reference point of sale, from where all the prices will be copied.',
          },
          STORES_FILTER: 'Store',
          ROUTE: 'Administration / Master',
          TITLE: 'Stores',
          NEW_STORE_BTN: 'New Store',
          FILTER_TTIP: 'Filters',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          VIEW_TTIP: 'View',
          VIEW_TABLE: 'Table Only',
          VIEW_MAP: 'Map Only',
          VIEW_TABLE_MAP: 'Map and table',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          DELETE_TTIP: 'Delete selected stores',
          DELETE: 'Delete',
          NAME: 'Name',
          CODE: 'Code',
          BRAND: 'Brand',
          TYPE: 'Type',
          STORE: 'Store',
          STORES_V2: 'Store',
          STATE: 'State',
          INACTIVE: 'Inactive',
          ACTIVE: 'Active',
          TITLE_FILTERS: 'Filters',
          STATE_FILTER: 'State',
          TYPE_FILTER: 'Type',
          CLEAN_FILTER_BTN: 'Clear',
          APPLY_FILTER_BTN: 'Apply',
          TITLE_FORM: 'New Store',
          SAVE_BTN: 'Save',
          BASIC_INFORMATION_TAB: 'Main Data',
          LATITUDE_INPUT: 'Latitude',
          VALIDATION_LATITUDE1: 'Latitude must be less than or equal to 90',
          VALIDATION_LATITUDE2: 'Latitude must be greater than or equal to -90',
          LONGITUDE_INPUT: 'Longitude',
          VALIDATION_LONGITUDE1: 'Length must be less than or equal to 180',
          VALIDATION_LONGITUDE2: 'Length must be greater than or equal to -180',
          CODE_INPUT: 'Code',
          EXTERNALCODE_INPUT: 'External Code',
          VALIDATION_CODE: 'The field must be greater than 0',
          VALIDATION_CODE2: 'The {{val}} code already exists.',
          NAME_INPUT: 'Name',
          BRAND_INPUT: 'Brand',
          EMAIL_INPUT: 'Email',
          PHONE_INPUT: 'Telephone',
          OPENING_DATE_INPUT: 'Opening date',
          ADRESS_INPUT: 'Address',
          ACTIVE_CHECK: 'Active',
          ACTIVE_TTIP: 'Mark as Activate the {{val}}',
          EXCEMPT_CHECK: 'Exempt',
          EXCEMPT_TTIP: 'Mark as exempt',
          CLASSIFICATION_TAB: 'Classification',
          WITHOUT_SELECTION: 'Without Selection ...',
          ADD_ATRIBUTE_BTN: 'Add Attributes',
          QUERY_FILTER: 'Name',
          FEATURES_TAB: 'Features',
          STORE_TYPE_INPUT: 'Store Type',
          TERMINAL_INPUT: 'Distribution Center',
          TRADE_AREA_TAB: 'Trade Area',
          LEGEND_FILTERS: 'Filter competitors by setting the Trade Area',
          TRADE_AREA_TITLE: 'Trade Area distance from competitors: ',
          SELECTED_COMPETITORS: 'Selected Competitors',
          NO_SELECTED_COMPETITORS: 'No competitors selected.',
          NO_SELECTED_COMPETITORS_TTIP: 'Refresh listing',
          DELETE_BTN: 'Remove',
          DELETE_COMPETITOR_TTIP: 'Delete Competitor',
          CODE_NAME: 'Cod | Name',
          MARKER: 'Marker',
          PROVINCE: 'Province',
          LOCATION: 'Location',
          ADD_COMPETITORS_BTN: 'Add competitors',
          EMPTY_STATE: 'No competitors found with the selected search criteria.',
          ADD_BTN: 'Add',
          ADD_COMPETITOR_TTIP: 'Add competitor',
          CHANGE_TRADE_AREA_TITLE_MODAL: 'Modify Trade Area to: {{val}} Km.',
          CHANGE_TRADE_AREA_SUBTITLE_MODAL: 'The selected competitors will be lost and will be modified with those of the new Trade Area.',
          EDIT_BTN: 'Modify',
          SEARCH: 'Search ',
          SET_PRINCIPAL_COMPETITOR_TTIP: 'Mark as main competitor for this Store',
          SET_PRINCIPAL_COMPETITOR__REMOVE_TTIP: 'Remove as main competitor for this Store',
          ASSORTMENT_TAB: 'Assortment',
          ASSORTMENT_TAB_ENABLED: 'Tab enabled only in state modified',
          ASSORTMENT_TAB_NO_EDIT_TOOLTIP: 'this information can be completed when modify the store previous to publish',
          ASSORTMENT_TITLE: 'Assortment Initialization',
          ASSORTMENT_SUBTITLE: 'Select the cluster to which the new store will belong in each of the Clusterizations defined in Prisma.',
          CLUSTERING: 'Clustering',
          CLUSTER: 'Cluster',
          CLUSTER_ASSIGNMENT: 'Cluster Assignment',
          CLONE_TTIP: 'Clone an existing Store.',
          CLONE_MODAL_TITLE: 'Clone Assortment',
          CLONE_MODAL_SUBTITLE: 'The clusters will be cloned for each clustering according to the selected store.',
          STORES: 'Store',
          CANCEL_BTN: 'Cancel',
          CLONE_BTN: 'Clone',
          PRICING_TAB: 'Pricing',
          PRICING_TITLE: 'Pricing Initialization',
          PRICING_SUBTITLE: 'Choose a price initialization option',
          BTN1_TITLE: 'Complete prices in Excel',
          BTN1_MSG: 'The price column should be completed for the entire active assortment.',
          BTN2_TITLE: 'Clone prices of a store',
          BTN2_MSG: 'Choose a reference point of sale, from where all the prices will be copied.',
          BTN3_TITLE: 'Relationship Rules',
          BTN3_MSG: 'Relationship rules will be taken into account for price autocompletion.',
          DOWLOAD_EXCEL: 'Download Excel',
          IMPORT_EXCEL_BTN: 'Import prices from excel',
          CLONE_PRICE_MODAL_TITLE: 'Clone Prices',
          CLONE_PRICE_MODAL_SUBTITLE: 'Prices will be cloned according to the selected store.',
          EXCEL1: 'Complete-Prices.xlsx',
          EXCEL2: 'Clone-Prices.xlsx',
          EXCEL3: 'Parent-Rule.xlsx',
          IMPORT_PRICES_TITLE: 'Import Prices',
          VENDORS: 'Suppliers',
          TABS: {
            VENDORS: {
              TITLE: 'Provider initialization',
              INTRO: 'Assign which suppliers supplies each product to the point of sale.',
              DISABLED_VENDORS_INPUT: 'Providers already initialized',
              BTN_IMPORT_SWITCH: 'Solve Conflicts',
              COMP: {
                TITLE: 'Advanced Selection',
                INTRO: 'Select the suppliers that will supply the point of sale.',
              },
            },
          },
          CHANGE_TRADE_AREA_TITLE_MODAL_ALT: 'Modify Localization',
          CHANGE_TRADE_AREA_SUBTITLE_MODAL_ALT:
            'The selected competitors will be lost and will be modified with those of the new Trade Area.',
          STORE_V2: 'Store',
        },
        STORESV2: {
          LIST: {
            TYPE_FILTERS: 'Type',
            STATUS: 'Status',
            STORE_TAGS: 'Point of Sale Tags',
            NAME: 'Name',
            CODE: 'Code',
            BRAND: 'Flag',
            STATE: 'Status',
            TYPE: 'Type',
            AVAIBLE: 'Enabled',
            ROUTE: 'Administration / Teachers',
            TITLE: 'Points of Sale',
            NEW_STORE_BTN: 'New Point of Sale',
            FILTER_TTIP: 'Filter',
            FULLSCREEN_TTIP: 'Expand',
            EXITFULLSCREEN_TTIP: 'Reduce',
            VIEW_TTIP: 'View',
            VIEW_TABLE: 'Only Table',
            VIEW_MAP: 'Map only',
            VIEW_TABLE_MAP: 'Map and table',
            EDIT_ROW: 'Edit',
            EDIT_ROW_DISABLED_TOOLTIP: 'Cannot edit a record in publishing process',
            COPY_ROW: 'Clone',
            DISABLED_CLONE_TOOLTIP: 'Only records in published state can be cloned',
            DISABLED_DELETE_EQUIPMENT: 'There is equipment assigned, remove or relocate all equipment to delete the store',
            DELETE_ROW: 'Inactive',
            UNDELETE_ROW: 'Active',
            INACTIVE: 'Inactive',
            ACTIVE: 'Active',
            CLONE_STORE_TITLE: 'Clone Point of Sale',
            CLONE_ITEM_CONTENT: 'A new point of sale will be created from the data of the selected row',
            CANCEL_BTN: 'Cancel',
            CREATE_BTN: 'Create',
            SEE_DELETED_FILTERS: 'View Deleted',
          },
          SAVE_TOOLTIP: {
            ASSORTMENT_INVALID: 'You must assign all clusters a cluster in order to save.',
          },
          PRICES: {
            BTN2_TITLE: 'Clone prices of a store',
            BTN2_MSG: 'Choose a reference point of sale, from where all the prices will be copied.',
            REVIEW_CHANGES: 'To consult price changes go to ',
            PRICE_DECISIONS: 'Pricing Decisions',
            VIEW_ASOCIATED_RULES: 'VIEW ASSOCIATED RULES',
            MAIN_SAVE_WARNING: 'In order to use this section, it is necessary at least have the mainstairs data saved',
          },
          EQUIPMENT: {
            ownerShip: 'Owner',
            acquisitionDate: 'Acquisition Date',
            acquisitionValue: 'Acquisition Value',
            adquisitionDate: 'Acquisition Date',
            adquisitionValue: 'Acquisition Value',
            usefulLife: 'Useful life (years)',
            EQUIPMENT_TYPE: 'Type of equipment',
            ADD_EQUIPMENT: '+ Add Equipment',
            REMAINING_LIVE: 'Remaining life',
            NAME: 'Name',
            ASSIGMENT_DATE: null,
            EDIT_EQUIPMENT: 'Edit Equipment',
            ADD_EQUIPMENT_TITLE: 'Add Equipment',
            AWARD_DATE: 'Award Date',
            DELETE_TITLE: 'Deactivate Equipment',
            DELETE_CONTENT: 'This action cannot be reversed. Do you wish to continue?',
            NAME_ALREADY_EXIST: 'The name is already in use',
            DISABLE_REUBICATION: 'The assignment must be saved to be relocated',
            REUBICATION_CONFIRM: 'Are you sure you want to relocate this equipment to the {{val}} point of sale?',
            REUBICATION: 'Relocate to another point of sale',
            OWNER_OPTION: {
              Owner: 'Own',
              Third: 'Third',
            },
            RELOCATION_CONFIRM: 'Are you sure you want to relocate this equipment {{val}} to the store?',
            RELOCATION: 'Relocate to another point of sale',
            ASSIGNMENT_DATE: 'Assignment Date',
          },
          COMPETITORS_SCOPE: 'Scope of competitors',
          COMPETITORS_SCOPE_SUBTITLE: 'Select the scope of competitors for this store',
          SELECTED_COMPETITOR_TTIP: 'Competitor selected',
          COMPETITOR_SELECTED: 'Selected competitors',
          COMPETITOR_SELECTED_COUNT: 'Number of Competitors selected',
          COMPETITOR_SELECTED_TITLE: 'Select Competitors',
          COMPETITOR_SELECTED_CONTENT: 'Search and add competitors by trade Area and tags',
          VIEW_ASOCIATED_RULES_TITLE: 'No associated rules',
          VIEW_ASOCIATED_RULES_TITLE_MGS: 'Cannot query a store that was not published',
          INFO_ASSORTMENT_STATUS:
            'The status of assortment displayed represents the status once it is published, in the same way all the data affected by this change will take effect when being in that state.',
          IMPORT_ITEMS: 'Assortment by Item',
          CODE_EXIST: 'Code {{val}} already exist',
          ITEMS_ALLOWED: '# Items allowed',
          TIP_ITEMS_ALLOWED: 'Maximum number of own products that this store can create.',
          NOT_ACCESS_PERMISSION: "Don't have permissions to complete this section",
          NOTIFY_USER: 'NOTIFY USER',
          NOTIFIED_USER: 'Notified user',
          IMPORT_FILE_NAME_LINK: 'determinar-PDV.xlsx',
          IMPORT_FILE_NAME_LINK_ITEMS: 'determinar-surtido.xlsx',
          IMPORT_FILE_NAME_BTN: 'load assortment exception',
          IMPORT_ASSORTMENT_STORE_CONTEXT:
            'Can import exceptions of assortment by the importer button or see the assortment state for this store',
          STORES_FILTER: 'Store',
          ROUTE: 'Administration / Master',
          TITLE: 'Stores',
          NEW_STORE_BTN: 'New Store',
          FILTER_TTIP: 'Filters',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          VIEW_TTIP: 'View',
          VIEW_TABLE: 'Table Only',
          VIEW_MAP: 'Map Only',
          VIEW_TABLE_MAP: 'Map and table',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          DELETE_TTIP: 'Delete selected stores',
          DELETE: 'Delete',
          NAME: 'Name',
          CODE: 'Code',
          BRAND: 'Brand',
          TYPE: 'Type',
          STORE: 'Store',
          STORES_V2: 'Store',
          STATE: 'State',
          INACTIVE: 'Inactive',
          ACTIVE: 'Active',
          TITLE_FILTERS: 'Filters',
          STATE_FILTER: 'State',
          TYPE_FILTER: 'Type',
          CLEAN_FILTER_BTN: 'Clear',
          APPLY_FILTER_BTN: 'Apply',
          TITLE_FORM: 'New Store',
          SAVE_BTN: 'Save',
          BASIC_INFORMATION_TAB: 'Main Data',
          LATITUDE_INPUT: 'Latitude',
          VALIDATION_LATITUDE1: 'Latitude must be less than or equal to 90',
          VALIDATION_LATITUDE2: 'Latitude must be greater than or equal to -90',
          LONGITUDE_INPUT: 'Longitude',
          VALIDATION_LONGITUDE1: 'Length must be less than or equal to 180',
          VALIDATION_LONGITUDE2: 'Length must be greater than or equal to -180',
          CODE_INPUT: 'Code',
          EXTERNALCODE_INPUT: 'External Code',
          VALIDATION_CODE: 'The field must be greater than 0',
          VALIDATION_CODE2: 'The {{val}} code already exists.',
          NAME_INPUT: 'Name',
          BRAND_INPUT: 'Brand',
          EMAIL_INPUT: 'Email',
          PHONE_INPUT: 'Telephone',
          OPENING_DATE_INPUT: 'Opening date',
          ADRESS_INPUT: 'Address',
          ACTIVE_CHECK: 'Active',
          ACTIVE_TTIP: 'Mark as Activate the {{val}}',
          EXCEMPT_CHECK: 'Exempt',
          EXCEMPT_TTIP: 'Mark as exempt',
          CLASSIFICATION_TAB: 'Classification',
          WITHOUT_SELECTION: 'Without Selection ...',
          ADD_ATRIBUTE_BTN: 'Add Attributes',
          FEATURES_TAB: 'Features',
          STORE_TYPE_INPUT: 'Store Type',
          TERMINAL_INPUT: 'Distribution Center',
          TRADE_AREA_ALL_DISTANCE: 'All competitors will be searched regardless of their distance',
          GEOLOCATION_STORE: 'Coordinates of store for Trade Area',
          TRADE_AREA_TAB: 'Trade Area',
          LEGEND_FILTERS: 'Filter competitors by setting the Trade Area',
          TRADE_AREA_TITLE: 'Trade Area distance from competitors: ',
          SELECTED_COMPETITORS: 'Selected Competitors',
          NO_SELECTED_COMPETITORS: 'No competitors selected.',
          NO_SELECTED_COMPETITORS_TTIP: 'Refresh listing',
          DELETE_BTN: 'Remove',
          DELETE_COMPETITOR_TTIP: 'Delete Competitor',
          CODE_NAME: 'Cod | Name',
          MARKER: 'Marker',
          PROVINCE: 'Province',
          LOCATION: 'Location',
          ADD_COMPETITORS_BTN: 'Add competitors',
          EMPTY_STATE: 'No competitors found with the selected search criteria.',
          ADD_BTN: 'Add',
          ADD_COMPETITOR_TTIP: 'Add competitor',
          CHANGE_TRADE_AREA_TITLE_MODAL: 'Modify Trade Area to: {{val}} Km.',
          CHANGE_TRADE_AREA_TITLE_MODAL_ALT: 'Modify Location',
          CHANGE_TRADE_AREA_SUBTITLE_MODAL: 'The selected competitors will be lost and will be modified with those of the new Trade Area.',
          CHANGE_TRADE_AREA_SUBTITLE_MODAL_ALT:
            'The selected competitors will be lost and will be modified with those of the new Trade Area.',
          EDIT_BTN: 'Modify',
          SEARCH: 'Search ',
          SET_PRINCIPAL_COMPETITOR_TTIP: 'Mark as main competitor for this Store',
          SET_PRINCIPAL_COMPETITOR__REMOVE_TTIP: 'Remove as main competitor for this Store',
          ASSORTMENT_TAB: 'Assortment',
          ASSORTMENT_TAB_ENABLED: 'Tab enabled only in state modified',
          ASSORTMENT_TAB_NO_EDIT_TOOLTIP: 'this information can be completed when modify the store previous to publish',
          ASSORTMENT_TITLE: 'Assortment Initialization',
          ASSORTMENT_SUBTITLE: 'Select the cluster to which the new store will belong in each of the Clusterizations defined in Prisma.',
          CLUSTERING: 'Clustering',
          CLUSTER: 'Cluster',
          CLUSTER_ASSIGNMENT: 'Cluster Assignment',
          CLONE_TTIP: 'Clone an existing Store.',
          CLONE_MODAL_TITLE: 'Clone Assortment',
          CLONE_MODAL_SUBTITLE: 'The clusters will be cloned for each clustering according to the selected store.',
          STORES: 'Store',
          CANCEL_BTN: 'Cancel',
          CLONE_BTN: 'Clone',
          PRICING_TAB: 'Pricing',
          PRICING_TITLE: 'Pricing Initialization',
          PRICING_SUBTITLE: 'Choose a price initialization option',
          BTN1_TITLE: 'Complete prices in Excel',
          BTN1_MSG: 'The price column should be completed for the entire active assortment.',
          BTN2_TITLE: 'Clone prices of a store',
          BTN2_MSG: 'Choose a reference point of sale, from where all the prices will be copied.',
          BTN3_TITLE: 'Relationship Rules',
          BTN3_MSG: 'Relationship rules will be taken into account for price autocompletion.',
          DOWLOAD_EXCEL: 'Download Excel',
          VIEW_ASSORTMENT_STATUS: 'Assortment Report',
          REQUIRE_SAVE_ASSORTMENT_STATUS: 'Requires saving changes made',
          REQUIRE_SAVE_ASSORTMENT_STATUS_MSG:
            'Changes were detected that affect the status of the Assortment, to be able to access you must save the changes made',
          IMPORT_EXCEL_BTN: 'Import prices from excel',
          CLONE_PRICE_MODAL_TITLE: 'Clone Prices',
          CLONE_PRICE_MODAL_SUBTITLE: 'Prices will be cloned according to the selected store.',
          EXCEL1: 'Complete-Prices.xlsx',
          EXCEL2: 'Clone-Prices.xlsx',
          EXCEL3: 'Parent-Rule.xlsx',
          IMPORT_PRICES_TITLE: 'Import Prices',
          VENDORS: 'Suppliers',
          IMPORT_EXCEL_ITEMS: 'IMPORT POS FROM EXCEL',
          IMPORT_EXCEL_ITEMS_EXCEPT: 'IMPORT ASSORTMENT FROM EXCEL',
          IMPORT_TITLE_ITEMS: 'Importa assortment',
          MAIN_SAVE_WARNING: 'In order to use this section, it is necessary at least have the mainstairs data saved',
          IMPORT_PDV_OK: 'IMPORTED STORES',
          NOVALID_PUBLISH_ADVICE: 'The following tab is not valid:',
          TABS: {
            VENDORS: {
              TITLE: 'Provider initialization',
              INTRO: 'Assign which suppliers supplies each product to the point of sale.',
              DISABLED_VENDORS_INPUT: 'Providers already initialized',
              BTN_IMPORT_SWITCH: 'Solve Conflicts',
              COMP: {
                TITLE: 'Advanced Selection',
                INTRO: 'Select the suppliers that will supply the point of sale.',
              },
            },
          },
          ITEM: {
            SAVE: 'Save',
            PUBLISH: 'Publish',
            REPORT: 'Report',
            REPORT_TOOLTIP: 'A report will be generated that can be downloaded through notifications.',
            REPORT_WARNING: 'Publish is required to download the report.',
            WARNING_SAVE: 'Current changes are required to be saved.',
            WARNING_PUBLISH: 'You need to republish for the saved changes to take effect.',
            BASIC_INFORMATION_TAB: 'Main data',
            CLASSIFICATION_TAB: 'Classification',
            PRICES_TAB: 'Prices',
            LOADING: 'Loading...',
            NEW_ITEM_BTN: 'New Item',
            TRADE_AREA_TAB: 'Trade Area',
            COMPETITORS_SCOPE: 'Competitors Scope',
            PUBLISH_CONFIRM:
              'The publication process includes the creation of the Assortment, Prices, Costs and other records related to the Store.<br><br><b>Are you sure you want to Publish?</b>',
            PUBLISH_CONFIRM_TITLE: 'Publication Confirmation',
            PUBLISHING_WARNING: 'Item in process of publication, try again in a few minutes.',
            PUBLISH_LONG_TIME:
              'The publication of this Item may take a few minutes, when it ends you will be notified with a notification.',
          },
        },
        SUPPLIER: {
          ROUTE: 'Administration / Master',
          TITLE: 'Supplier',
          NEW_SUPPLIER_BTN: 'New Supplier',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          DELETE_TTIP: 'Delete selected suppliers',
          DELETE: 'Delete',
          NAME: 'Name',
          CODE: 'Code',
          PHONE: 'Telephone',
          DEFINE_SUPPLIER_STP1: 'Setup Supplier',
          ITEMS_ALLOCATION_STP2: 'Products Supplied',
          EDIT_TITLE_FORM: 'Edit Supplier',
          NEW_TITLE_FORM: 'New Supplier',
          STP1: 'Step 1 - Setup Supplier',
          NAME_INPUT: 'Name',
          CODE_INPUT: 'Code',
          PHONE_INPUT: 'Telephone',
          NEXT_BTN: 'Next',
          STP2: 'Step 2 - Products Supplied',
          ITEM: 'Product',
          MAIN_STORES: '# Main Stores',
          SECONDARY_STORES: '# Secondary Stores',
          DELETE_ITEM_TTIP: 'Delete product',
          ADD_ITEM_BTN: 'Add Product',
          PREVIUS_BTN: 'Previous',
          SAVE_BTN: 'Save',
          DELETE_TITLE_MODAL: 'Delete',
          DELETE_SUBTITLE_MODAL: 'Are you sure you want to delete?',
          DELETE_SUBTITLE2_MODAL: 'You will delete: {{val}}}. Are you sure?',
          DELETE_BTN: 'Delete',
          ALLOCATION_STORES: 'Stores Supplied',
          ITEM_INPUT: 'Product',
          SEARCH_ITEM: 'Search Product...',
          STORES_INPUT: 'Stores',
          ADD_STORES: 'Add more stores',
          SEARCH_STORES: 'Search Stores...',
          ALL_CHECK: 'All',
          SECONDARY_STORES_INPUT: 'Secondary Stores',
          ADD_SECONDARY_STORE: 'Add more secondary Stores',
          SEARCH_SECONDARY_STORE: 'Search secondary Stores ...',
          CANCEL_BTN: 'Cancel',
          ACCEPT_BTN: 'Accept',
        },
        SUPPLIERV2: {
          ROUTE: 'Administration / Master',
          TITLE: 'Supplier',
          NEW_SUPPLIER_BTN: 'New Supplier',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          DELETE_TTIP: 'Delete selected suppliers',
          DELETE: 'Delete',
          NAME: 'Name',
          CODE: 'Code',
          PHONE: 'Telephone',
          STATUS: 'Status',
          DEFINE_SUPPLIER_STP1: 'Setup Supplier',
          ITEMS_ALLOCATION_STP2: 'Products Supplied',
          EDIT_TITLE_FORM: 'Edit Supplier',
          NEW_TITLE_FORM: 'New Supplier',
          STP1: 'Step 1 - Setup Supplier',
          NAME_INPUT: 'Name',
          CODE_INPUT: 'Code',
          PHONE_INPUT: 'Telephone',
          NEXT_BTN: 'Next',
          STP2: 'Step 2 - Products Supplied',
          ITEM: 'Product',
          MAIN_STORES: '# Main Stores',
          SECONDARY_STORES: '# Secondary Stores',
          DELETE_ITEM_TTIP: 'Delete product',
          ADD_ITEM_BTN: 'Add Product',
          PREVIUS_BTN: 'Previous',
          SAVE_BTN: 'Save',
          DELETE_TITLE_MODAL: 'Delete',
          DELETE_SUBTITLE_MODAL: 'Are you sure you want to delete?',
          DELETE_SUBTITLE2_MODAL: 'You will delete: {{val}}}. Are you sure?',
          DELETE_BTN: 'Delete',
          ALLOCATION_STORES: 'Stores Supplied',
          ITEM_INPUT: 'Product',
          SEARCH_ITEM: 'Search Product...',
          STORES_INPUT: 'Stores',
          ADD_STORES: 'Add more stores',
          SEARCH_STORES: 'Search Stores...',
          ALL_CHECK: 'All',
          SECONDARY_STORES_INPUT: 'Secondary Stores',
          ADD_SECONDARY_STORE: 'Add more secondary Stores',
          SEARCH_SECONDARY_STORE: 'Search secondary Stores ...',
          CANCEL_BTN: 'Cancel',
          ACCEPT_BTN: 'Accept',
          SEGMENTATION: 'Segmentation',
          INTERNAL_CODE_INPUT: 'Internal Code',
          UNIQUE_CODE_INPUT: 'Unique Code',
          ITEM_FORM: {
            SAVE: 'Save',
            PUBLISH: 'Publish',
            WARNING_SAVE: 'Current changes are required to be saved.',
            WARNING_PUBLISH: 'You need to republish for the saved changes to take effect.',
            LOADING: 'Loading...',
            NEW_ITEM_BTN: 'New Item',
            PUBLISH_CONFIRM:
              'The publication process includes the creation of costs in stores related to the supplier.<br><br><b>Are you sure you want to Publish?</b>',
            PUBLISH_CONFIRM_TITLE: 'Publication Confirmation',
            NO_SEGMENT: 'Unsegmented',
            PUBLISHING_WARNING: 'Item in process of publication, try again in a few minutes.',
            PUBLISH_LONG_TIME:
              'The publication of this Item may take a few minutes, when it ends you will be notified with a notification.',
            TIP_ATTRIBUTES_NO_SELECT: 'Supplier costs will apply to the entire network.',
            TIP_ATTRIBUTES_SELECT: 'Supplier costs apply only to points of sale tagged as:',
            TIP_USESOWNITEMNUMBERS:
              "If Internal Code is required, both the cost update and the supply module orders must be complete with the supplier's internal codes.",
          },
        },
        INSIGHTS: {
          ROUTE: 'Administration / Master',
          TITLE: 'Insights',
          FILTER_TTIP: 'Filter',
          NAME: 'Name',
          DESCRIPTION: 'Description',
          MARGIN: 'Margin $',
          CREATE_DATE: 'Date',
          ACTIONS: 'Actions',
          CONSULT_REPORTS: 'Consult Reports',
          REVIEW_PRICES: 'Review Prices',
          TOGGLE_FILTER: 'Just my Insights',
          ANTIQUITY_FILTER: 'Antiquity',
          INSIGHTS_TYPE_FILTER: 'Insight Type',
          STORES_FILTER: 'Stores',
        },
        EQUIPMENTS: {
          ROUTE: 'Administration / Masters',
          TITLE: 'Equipment',
          TITLE_FULL: 'Equipment Types',
          FILTER_TTIP: 'Filter',
          NAME: 'Name',
          DESCRIPTION: 'Description',
          EDIT: 'Edit Equipment Type',
          NEW: 'New Equipment Type',
          DELETE: 'Delete Equipment Type',
          DELETE_WARN: 'This type of equipment is assigned, it is not possible to delete it',
          ITEMS: 'Items',
          ATTR: 'attributes',
          ATTR_ALT: 'Attributes',
          REQUIRED: 'required',
          SAVE: 'Save',
          PUBLISH: 'Publish',
          WARNING_SAVE: 'Saving current changes is required.',
          WARNING_PUBLISH: 'Republishing is required for saved changes to take effect.',
          LOADING: 'Loading...',
          ADD_ATTR: 'Add Attribute',
          EDIT_ATTR: 'Edit Attribute',
          TYPE_ATTR: 'Attribute Type',
          NEW_ITEM: 'New Equipment Type',
          VALUE_LIST: 'List of Values:',
          ADD_VALUE: '+ Add Value',
          VALUE: 'Value',
          VALIDATION_NAME_DUPLICATE: 'This name already exists',
          VALIDATION_ATTR_DUPLICATE: 'There are duplicate attributes',
          ASSIGNED: 'Assigned',
          ATTR_REQUIRED: 'Required attribute',
          GENERAL: 'Main Data',
          ASSIGN_REQUIRED_DISCLAIMER:
            'This type of equipment already has equipment assigned to stores, this change will not be reactive for equipment that has already been assigned.',
          REPORT: 'Download Report',
          TYPES: {
            NUMBER: 'Number',
            STRING: 'Text',
            BOOLEAN: 'Boolean',
            LIST: 'List of values',
            PRICE: 'Price',
            DATETIME: 'Date',
          },
        },
        TAGS: {
          ACCEPT: 'Accept',
          NAME_ALREADY_EXIST: 'The name already is in use',
          TYPES: {
            Item: 'Item',
            Store: 'Store',
            Competitor: 'Competitor',
            Brands: 'Brands',
          },
          DATA_TYPES: {
            Text: 'Text',
            Number: 'Number',
            Boolean: 'Boolean',
          },
          ENTITIES: {
            COMPETITORS: 'Competitors: {{val}}',
            ITEMS: 'Products: {{val}}',
            ROUNDINGRULES: 'Rounding rules: {{val}}',
            COMPETITIVELIMITRULES: 'Competitors limit: {{val}}',
            PRICINGRULESITEMS: 'Product relationship rules: {{val}}',
            PRICINGRULESSTORES: 'Relationship rules for points of sale: {{val}}',
            STORES: 'Points of sale: {{val}}',
            VENDORS: 'Suppliers: {{val}}',
            VENDORCOSTS: 'Costs: {{val}}',
            ASSORTMENTASSIGNMENTS: 'Assortment allocation: {{val}}',
            ASSORTMENTITEMEXCEPTIONS: 'Assortment exception by products: {{val}}',
            ASSORTMENTSTOREEXCEPTIONS: 'Assortment exception by points of sale: {{val}}',
            ASSORTMENTCATEGORIES: 'Assortment categories: {{val}}',
          },
          DEFAULTVALUE: 'Start with this default value',
          ISDEFAULTVALUE: 'Is Default value',
          TAG_IN_USE: 'Failed to delete tag is in use',
          ENTITIES_IN_USE: 'You must reassign the following number of records for each entity using the tag to delete',
          CANCEL_BTN: 'Cancel',
          NEW_TAG_VALUE_ADD_BTN: 'Create new tag value',
          ROUTE: 'Administration / Master',
          ISDELETED: 'Eliminated',
          ISDELETED_POPOVER_TOOLTIP: 'When enabling only deleted labels will be shown',
          USEFORREPORTS: 'Used for reports',
          USEFORREPORT: 'Used for reports',
          USEFORREPORTS_POPOVER_TOOLTIP: 'When enabling, only the labels assigned for reports will be shown',
          YES: 'Yes',
          NO: 'No',
          REQUIRED_CHECKBOX_POPOVER_TOOLTIP:
            'Makes the loading of this field mandatory for all "products". It allows assigning a default value if this option is active.',
          REQUIRED_FOR_COMPETITOR_POPOVER_TOOLTIP:
            'Makes the loading of this field mandatory for all "competitors". It allows assigning a default value if this option is active.',
          REQUIRED_FOR_STORE_POPOVER_TOOLTIP:
            'Makes the loading of this field mandatory for all "points of sale". It allows assigning a default value if this option is active.',
          TERMINATION_SLIDE_POPOVER_TOOLTIP:
            'You can add a default termination to the tag values. Ex: for Net Content, "cc" or "cm3". Tag: 250 cc',
          DEPENDENCIES_POPOVER_TOOLTIP:
            'Use it in case this tag depends on another. Parent / child relationship. Ex: "City" depends on "State"',
          SELECT_PARENT_TAG_POPOVER_TOOLTIP:
            'In case of changing for another parent label the values of this label will be eliminated. In case of deallocation, the values of this label will lose relation to parent values',
          SELECT_PARENT_TAG_WARNING:
            'Make sure to select the correct parent tag before loading the parent values, if changed you will lose the previously added values',
          TITLE: 'Tags',
          CANCEL: 'Cancel',
          OK: 'Ok',
          NEW_STORE_BTN: 'New Tag',
          DELETE: 'Delete',
          DELETE_TTIP: 'Delete selected Tags',
          FILTER_TTIP: 'Filters',
          EXPORT_TTIP: 'Export',
          IMPORT_TTIP: 'Import',
          PRINT_TTIP: 'Print',
          NAME: 'Name',
          TYPE: 'Type',
          FIELD_TYPE: 'Field Type',
          REQUIRED: 'Required',
          QUANTITY_TAGS: '# Tags',
          QUANTITY_TAGS_TTIP: 'Number of Tags that belong to a group',
          DEFINE_TAG_STP1: 'Define Tags',
          ADMIN_TAG_STP2: 'Manage Tags',
          EDIT_TAG: 'Edit Tags',
          DELETE_ROW: 'Delete Tag',
          NEW_TAG: 'New Tags',
          STP1: 'Define Tags',
          NAME_INPUT: 'Name',
          TYPE_INPUT: 'Tags Type',
          DATATYPE_INPUT: 'Datatype',
          REQUIRED_CHECKBOX: 'Required for all products',
          REQUIRED_FOR_COMPETITOR: 'Required for all competitors',
          REQUIRED_FOR_STORE: 'Required for all own brands Points of Sale',
          ADDITIONAL_SLIDE: 'Additional features',
          COLOR_SLIDE: 'Color',
          ICON_SLIDE: 'Icon and Image',
          REQUIRED_SLIDE: 'Requires Special Permit',
          TERMINATION_SLIDE: 'Termination',
          TERMINATION_INPUT: 'Enter Termination',
          DEPENDENCIES: 'Dependencies',
          DEPENDENCIES_INPUT: 'Select Parent Tag',
          REQUIRED_WARNING_OFF_CONTENT: 'If a value of this tag exists as a default value, it will be disabled',
          REQUIRED_WARNING_OFF_TITLE: 'Do you want this tag to not be required?',
          NEXT_BTN: 'Next',
          STP2: 'Administrate Tag Value',
          NEW_TAG_INPUT: 'Add new tag',
          DEPENCENCY_TAG_INPUT: 'Select Parent Tag Value',
          PARENT: 'Parent',
          TYPE_CENTER: 'Type Center',
          COLOR: 'Color',
          ICON: 'Icon',
          TERMINATION: 'Termination',
          PARENT_VALUE: 'Parent Value',
          ELEMENTS: '# Elements',
          ELEMENTS_TTIP: 'Quantity Elements',
          PREVIOUS_BTN: 'Previous',
          SAVE_BTN: 'Save',
          ADD_BTN: 'Add',
          EDIT_BTN: 'Edit',
          TAG_VALUE_EXIST: 'Tag value already exist',
          DEFAULT_TTP: 'No default value can be assigned if the tag is not marked as mandatory',
          INVALID_TAG_VALUES_FORM: 'All loaded values must contain the required fields',
          OWN_STORES: 'own stores',
          PRODUCTS: 'products',
          COMPETITORS: 'competitors',
          REQUIRED_DEFAULT_TAG: 'You must set a default tag value in order to Save',
          TAG_UPDATE_ALL_PRODUCTS: 'This tag will update all Products. Do you wish to continue?',
          TAG_UPDATE_ALL_OWN_STORES: 'This tag will update all Own Brand Stores. Do you wish to continue?',
          TAG_UPDATE_ALL_COMPETITORS: 'This tag will update all Competitors. Do you wish to continue?',
        },
      },
      SECURITY: {
        USERS: {
          LIST: {
            TITLE: 'Users',
            EDIT_ROW: 'Edit',
            USERNAME_INPUT: 'Username',
            EMAIL_INPUT: 'Email',
            ROLE_INPUT: 'Role',
          },
          ITEM: {
            TAB1: 'General Data',
            USERNAME_INPUT: 'Userame',
            FIRSTNAME_INPUT: 'Name',
            LASTNAME_INPUT: 'Last Name',
            EMAIL_INPUT: 'Email',
            PASSWORD_INPUT: 'Password',
            PREV_PASSWORD_INPUT: 'Previous password',
            RESET_PASS: 'Reset Password',
            REPEAT_PASSWORD_INPUT: 'Repeat Password',
            PASSWORD_BTN: 'Hide password',
            ROLES_INPUT: 'Role',
            CATEGORIES: 'Categories',
            STORES: 'Stores',
            EDIT_STORES: 'Edit Stores',
            CATEGORIES_ADVICE: 'If you leave this selection empty, no category restrictions will be applied to the user.',
            STORES_ADVICE: 'If you leave this selection empty, no stores restrictions will be applied to the user.',
          },
          NEW_USER_BTN: 'New User',
        },
        ROLES: {
          LIST: {
            TITLE: 'Roles',
            EDIT_ROW: 'Edit',
            NAME_INPUT: 'Name',
          },
          ITEM: {
            TAB1: 'General Data',
            NAME_INPUT: 'Name',
            NAME_ROLE: 'Name',
            DESCRIPTION_ROLE: 'Description',
            SELECTED: 'Selected',
            SELECTED_PLURAL: 'Selected',
            ROLE: {
              admin_view: 'View Administration menu',
              itemmaster_view: 'View Item Master menu',
              prices_competitors_view: 'View flags and competitors menu',
              prices_costsandtaxes: 'Importer of store costs and supplier costs',
              prices_decisions: 'Change prices, Accept and ignore suggestions. Request approval, and Import new prices',
              prices_endorse: 'Approve prices and reject changes.',
              prices_guidelineStrategy_view: 'View guidelines and strategies menu',
              prices_output: 'Publish price decisions',
              prices_output_readonly: 'View Publish decisions menu and generate preview',
              prices_roundings_view: 'View rounding rules menu',
              prices_rules_view: 'View pricing rules menu',
              prices_sensitivities_view: 'View sensitivities menu',
              prices_view: 'View price decisions menu',
              pricing_manual_suggestions: 'Generate manual price suggestions',
              pricing_survey: 'Import competition prices',
              storemaster_view: 'View Store Points menu',
              tags_view: 'View tags menu',
              prices_competitors_save: null,
              prices_guidelineStrategy_save: null,
              prices_roundings_save: null,
              prices_rules_save: null,
              prices_sensitivities_save: null,
              tags_save: 'Create and update tags',
              assortment_categoryTree_view: 'View category tree and category configuration menu.',
              assortment_clustering_view: 'View clustering menu',
              assortment_decisions_view: 'View assortment decisions menu',
              assortment_output_readonly: 'View assortment decision publishing menu and allow preview generation',
              assortment_roles_view: 'View roles menu',
              assortment_strategies_view: 'View Strategies menu',
              assortment_view: 'Enable general assortment menu',
              equipments_view: 'View equipments menu',
              mysteryShopper_missions_view: 'View missions menu',
              mysteryShopper_userAssigment_view: 'View user assignment menu',
              mysteryShopper_view: 'Enable general missions menu',
              promotion_action_view: 'View Actions menu',
              promotion_campaign_view: 'View Campaigns menu',
              promotion_campaign_save: 'Create and update campaigns',
              promotion_view: 'Enable general promotions menu',
              promotion_calendar_view: 'View the calendar menu',
              promotion_configuration_view: 'View the configuration menu',
              promotion_reportes_view: 'View the reports menu',
              promotion_periods_view: 'View the promotional periods menu',
              promotion_reportes_production_view: 'View production lists',
              promotion_reportes_logistic_view: 'View logistics lists',
              promotion_reportes_pos_view: 'View POS data',
              promotion_action_save: 'Create and update promotional actions',
              promotion_action_cancel: 'Delete promotional actions',
              promotion_action_publish: 'Publish promotional actions',
              promotion_reports_view: 'View promotional reports',
              promotion_can_edit_all_promotional_actions: 'Create or edit promotions for periods that have already started/finished',
              promotion_promotional_process_view: 'View the menu of promotional processes',
              promotion_promotional_process_edit: 'Create and update promotional processes',
              spaces_layout_view: 'View Layout menu',
              spaces_planograms_pop_material_view: 'View Material Pop menu',
              spaces_planograms_view: 'View planograms menu',
              spaces_view: 'Enable general spaces menu',
              vendor_view: 'View vendors menu',
              admin_security: 'Permissions in Role and User management',
              spaces_planograms_request_approval:
                'Allows the user to filter recently created or modified planograms and request approval for new and modified planograms.',
              spaces_planograms_approve: 'Allows the user to filter planograms pending approval and approve planograms pending approval.',
              spaces_planograms_inform: 'Enables the user to inform (publish) the approved planograms.',
              spaces_planograms_delete: 'Enables the user to deactivate all created planograms.',
            },
          },
          NEW_ROLE_BTN: 'New Role',
        },
      },
      PROFILE: {
        CHANGE_PASS: 'Change Password',
        PROFILE_TITLE: 'My Profile',
        PERMISSIONS: 'Permissions',
      },
    },
    PROMO: {
      CAMPAIGNS: {
        ROUTE: 'Promotions',
        TITLE: 'Campaigns',
        NEW_CAMPAING_BTN: 'New Campaign',
        NAME: 'Name',
        CODE: 'Code',
        ACTIONS: '# Actions',
        SINCE: 'Starts',
        TO: 'Ends',
        STATE: 'State',
        USER: 'User',
        LAST_MOD: 'Last Modif.',
        FULLSCREEN_TTIP: 'Expand',
        EXITFULLSCREEN_TTIP: 'Reduce',
        EXPORT_TTIP: 'Export',
        IMPORT_TTIP: 'Import',
        PRINT_TTIP: 'Print',
        TITLE_FORM: 'New Campaign',
        SAVE_BTN: 'Save',
        BASIC_INFORMATION_TAB: 'Main Data',
        CODE_INPUT: 'Code',
        NAME_INPUT: 'Name',
        SINCE_INPUT: 'Starts',
        TO_INPUT: 'Ends',
        DESCRIPTION_INPUT: 'Description',
        STATUS_STORES: 'All',
        STATUS_HISTORY: 'Historical',
        STATUS_VIGENT: 'Valid',
        STATUS_FUTURE: 'Future',
        STATUS_NOTVALID: 'Not valid',
        STATUS_EMPTY: 'Not valid',
        INVALID_TO_DATE: 'Must be greater than the Start date and within the campaign date range',
        INVALID_FROM_DATE: 'Must be less than the End date and within the campaign date range',
        INVALID_TO_DATE_CAMPAING: 'Must be greater than the Start date',
        INVALID_FROM_DATE_CAMPAING: 'Must be smaller than the End date',
        NAME_TTIP: 'Click here to see related actions',
        ACTIONS_TTIP: 'Number of Actions',
        LAST_MOD_TTIP: 'Last modification',
      },
      ACTIONS: {
        ROUTE: 'Promotions',
        TITLE: 'Actions',
        FILTER_TTIP: 'Filter',
        FULLSCREEN_TTIP: 'Expand',
        EXITFULLSCREEN_TTIP: 'Reduce',
        NAME: 'Name',
        CODE: 'Code',
        TYPE: 'Type',
        STORES: '# Stores',
        LIFT: 'Lift %',
        BRAND: 'Brand',
        NONE: 'None',
        SINCE: 'Starts',
        TO: 'Ends',
        SUPPLIES_LAST: 'Subject to stock availability',
        USER: 'User',
        LAST_MOD: 'Last Modif.',
        STATE: 'State',
        TYPE_OP1: 'Get A pay B',
        TYPE_OP2: 'Direct Discount',
        TYPE_OP3: 'Amount discount',
        STATUS_STORES: 'All',
        STATUS_HISTORY: 'Historical',
        STATUS_VIGENT: 'Valid',
        STATUS_FUTURE: 'Future',
        STATUS_NOTVALID: 'Not valid',
        STATUS_EMPTY: 'Not valid',
        STATUS_CANCELLED: 'Cancelled',
        INVALID_TO_TIME: 'Must be greater than the Starting Hour',
        INVALID_FROM_TIME: 'Must be less than the Ending Hour',
        INVALID_TO_DATE: 'Must be greater than the Start date and within the campaigns date range',
        INVALID_FROM_DATE: 'Must be less than the End date and within the campaigns date range',
        INVALID_TO_DATE_CAMPAING: 'Must be greater than the Start date',
        INVALID_FROM_DATE_CAMPAING: 'Must be less than the End date',
        DEFINE_ACTION_STP1: 'Setup Action',
        RESTRICTIONS_STP2: 'Restrictions',
        PRODUCTS_SCOPE_STP3: 'Products Scope',
        STORES_SCOPE_STP4: 'Stores Scope',
        OTHERS_TERMS_STP5: 'Other conditions',
        TITLE_FORM_NEW: 'New Action - ',
        TITLE_FORM_EDIT: 'Edit Action - ',
        STP1: 'Step 1 - Setup Action',
        CODE_INPUT: 'Code',
        PROMOTION_CODE_INPUT: 'External Promo Code',
        NAME_INPUT: 'Name',
        TYPE_INPUT: 'Type',
        QUANTITY_BUY_INPUT: 'Quantity to buy',
        QUANTITY_PAY_INPUT: 'Quantity to pay',
        DISCOUNT_RATE_INPUT: 'Discount rate',
        UNIT_DISCOUNT_INPUT: 'Unit discount',
        DISCOUNT_VALUE_INPUT: 'Discount amount',
        QUANTITY_UNITS_INPUT: 'Quantity of Units to take',
        UNIT_DISCOUNT_PER_INPUT: 'Unit discount {{val}}',
        SINCE_INPUT: 'Starts',
        TO_INPUT: 'Ends',
        SUPPLIES_LAST_CHECK: 'While supplies last',
        UNITS_AVAILABLE_INPUT: 'Units available',
        FRECUENCY_INPUT: 'Frequency',
        DAYS_INPUT: 'Days',
        TIME_FROM_INPUT: 'Starting Hour',
        TIME_TO_INPUT: 'Ending Hour',
        TICKET_MESSAGE_INPUT: 'Ticket Message',
        OBSERVATION_INPUT: 'Observation',
        NEXT_BTN: 'Next',
        MONDAY: 'Monday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        THURSDAY: 'Thursday',
        FRIDAY: 'Friday',
        SATURDAY: 'Saturday',
        SUNDAY: 'Sunday',
        DAILY: 'Daily',
        WEEKLY: 'Weekly',
        BIWEEKLY: 'Biweekly',
        MONTHLY: 'Monthly',
        BIMONTHLY: 'Bimonthly',
        CUARTERLY: 'Quarterly',
        SEARCH_CONDITIONS: 'Search Other Conditions...',
        STP2: 'Step 2 - Restrictions',
        UNIT_RESTRICTION: 'Unit Restriction',
        MINIMUM_AMOUNT_INPUT: 'Minimum amount',
        MAXIMUM_AMOUNT_INPUT: 'Maximum quantity',
        AMOUNT_RESTRICTION: 'Amount Restriction',
        OTHERS_INPUT: 'Other conditions',
        PREVIOUS_BTN: 'Previous',
        EQUAL_BRAND: 'Same brand',
        EQUAL_PRICE: 'Same price',
        EQUAL_CATEGORY: 'Same category',
        EQUAL_DISCOUNT: 'Same discount',
        EQUAL_PRODUCT: 'Same product',
        STP3: 'Step 3 - Products Scope',
        MANUAL_SELECTION_RADIO: 'Manual Selection',
        CATEGORY_INPUT: 'Category',
        SEARCH_CATEGORY: 'Search Category',
        EXCLUDE_CATEGORY_INPUT: 'Category to exclude',
        SEARCH_EXCLUDE_CATEGORY: 'Search Category to exclude',
        SUBCATEGORY_INPUT: 'Subcategory',
        SEARCH_SUBCATEGORY: 'Search Subcategory',
        EXCLUDE_SUBCATEGORY_INPUT: 'Subcategory to exclude',
        SEARCH_EXCLUDE_SUBCATEGORY: 'Search Subcategory to exclude',
        SUPPLIER_INPUT: 'Supplier',
        SEARCH_SUPPLIER: 'Search Supplier',
        EXCLUDE_SUPPLIER_INPUT: 'Supplier to exclude',
        SEARCH_EXCLUDE_SUPPLIER: 'Search Supplier to exclude',
        BRAND_INPUT: 'Brand',
        SEARCH_BRAND: 'Search Brand',
        EXCLUDE_BRAND_INPUT: 'Brand to exclude',
        SEARCH_EXCLUDE_BRAND: 'Search brand to exclude',
        TAGS_INPUT: 'Attributes',
        SEARCH_TAGS: 'Search Attributes',
        EXCLUDE_TAGS_INPUT: 'Attributes to exclude',
        SEARCH_EXCLUDE_TAGS: 'Search Attributes to exclude',
        ITEMS_INPUT: 'Products',
        SEARCH_ITEMS: 'Search Products',
        EXCLUDE_ITEMS_INPUT: 'Products to exclude',
        SEARCH_EXCLUDE_ITEMS: 'Search Products to exclude',
        MANUAL_LOAD_RADIO: 'Manual loading',
        DRAG_FILE: 'Drag and drop the file here, or click to search on your PC',
        STP4: 'Step 4 - Stores Scope',
        CLUSTER_INPUT: 'Cluster',
        SEARCH_CLUSTER: 'Search Cluster...',
        EXCLUDE_CLUSTER_INPUT: 'Cluster to exclude',
        SEARCH_EXCLUDE_CLUSTER: 'Search Cluster to exclude',
        FORMAT_INPUT: 'Format',
        SEARCH_FORMAT: 'Search Clients / Communities ...',
        EXCLUDE_FORMAT_INPUT: 'Format to exclude',
        SEARCH_EXCLUDE_FORMAT: 'Search Format to exclude...',
        STORE_INPUT: 'Store',
        SEARCH_STORE: 'Search Store...',
        EXCLUDE_STORE_INPUT: 'Store to exclude',
        SEARCH_EXCLUDE_STORE: 'Search Store to exclude...',
        STP5: 'Step 5 - Other Conditions',
        CLIENTS_COMMUNITIES: 'Clients / Communities',
        SEARCH_CLIENTS_COMMUNITIES: 'Search Clients / Communities',
        PAYMENT_METHODS: 'Payment methods',
        SEARCH_PAYMENT_METHODS: 'Search Payment Methods ...',
        BANK_PROMOTIONS: 'Bank promotions',
        SEARCH_BANK_PROMOTIONS: 'Search bank promotions...',
        CUMULATIVE: 'Cumulative with other promotions',
        SAVE_BTN: 'Save',
        NEW_ACTION_TITLE: 'New Action',
        LEGEND_OP1:
          'It is the discount where A represents the units to be purchased by the customer and B represents the units to be paid by the customer. Examples: 2x1 (take 2 and paid for 1) or 3x2 (take 3 and paid for 2)',
        LEGEND_OP2:
          'It is the percentage or amount of discount that will be applied to the current price of the product. Examples: "a 15% discount over current price" or "$ 10 discount over current price"',
        LEGEND_OP3:
          'It is the discount percentage that will be applied to the current price of the product when more than one unit is purchased. Example: Buy the second unit with an 80% discount.',
        CANCEL_BTN: 'Cancel',
        CREATE_BTN: 'Create',
        OVERLAP_TITLE: 'There is an overlap',
        OVERLAP_SUBTITLE: 'Products are being promoted in different promotional actions.',
        DATAIL_BTN: 'Show me the overlaps',
        ACCEPT_BTN: 'Accept',
        ROUTE2: 'Promotions / Campaign',
        ROUTE2_PROMOTIONAL_PERIOD: 'Promotions / Promotional Periods',
        NEW_ACTION_BTN: 'New Action',
        ACTIONS_TITLE: 'Actions',
        VIEW_TTIP: 'View',
        VIEW_TABLE: 'Table Only',
        VIEW_CALENDAR: 'Calendar only',
        TITLE_FILTERS: 'Filters',
        STATE_FILTER: 'State',
        STATE_OP1_FILTER: 'Historical',
        STATE_OP2_FILTER: 'Valid',
        STATE_OP3_FILTER: 'Future',
        CAMPAINGS_FILTER: 'Campaigns',
        CLEAN_FILTER_BTN: 'Clear',
        APPLY_FILTER_BTN: 'Apply',
        STORES_TTIP: 'Number of Stores',
        LAST_MOD_TTIP: 'Last modification',
        STP1_SUB: 'In this step you can define the main components of the type of action selected.',
        STP2_SUB: 'In this step you can define the assortment conditions and minimum and maximum restrictions for the action.',
        STP3_SUB: 'In this step you can select the products to be promoted.',
        STP4_SUB: 'In this step you can select the stores that will execute this promotion.',
        STP5_SUB: 'In this step you can establish conditions that will limit the application of this promotional action.',
        STRATEGY: 'Next, choose the selection strategy for the child products.',
        STRATEGY_ATTR_TITLE: 'Selection by attribute (Dinamic)',
        STRATEGY_ATTR_MSG:
          'Attributes are selected to define the scope of products, if the scope changes, the impact of this setting is done automatically and does not need to be changed',
        STRATEGY_STORE_ATTR_MSG: 'Attributes are selected to define the scope of stores',
        STRATEGY_PROD_TITLE: 'Selection by product',
        STRATEGY_STORE_TITLE: 'Selection by store',
        STRATEGY_PROD_MSG: 'Products should be selected one by one',
        STRATEGY_STORE_MSG: 'Stores should be selected one by one',
        GENERIC_MSG_ERRROR_EXECSUGGESTION: 'Suggestions could not be generated.',
        GENERIC_MSG_NOSUGGESTIONS_EXECSUGGESTION: 'There are no new suggestions.',
        GENERIC_ACTUAL_FILE: 'Actual File:',
      },
      HELP: {
        QUANTITY_BUY_INPUT: 'Indicates the units (A) that the client will take. Example: If the action is "3 x 2", you must enter "3".',
        QUANTITY_PAY_INPUT:
          'Indicates the units (B) that the customer will pay. It should always be less than (A). Example: If the action is "3 x 2" here you must enter "2".',
        FRECUENCY_INPUT: 'Indicates the frequency of the action.',
        DAYS_INPUT: 'Days of the week in which the action will be effective.',
        TIME_FROM_INPUT: 'Time range in which the action will be effective.',
        TIME_TO_INPUT: 'Time range in which the action will be effective.',
        TICKET_MESSAGE_INPUT: 'Short description of the action that will be reflected in the invoice or ticket.',
        OBSERVATION_INPUT: 'It allows entering some type of information or detail of the action.',
        UNIT_DISCOUNT_INPUT: 'Indicates the value of the discount to be applied to each unit sold.',
        DISCOUNT_RATE_INPUT: 'Indicates if the discount is going to be in percentage or a fixed amount.',
        SUPPLIES_LAST_CHECK: 'Informative message for legal purposes for the point of sale.',
        UNITS_AVAILABLE_INPUT:
          'Indicates the total company product units available for the action. Informative message for legal purposes for the point of sale.',
        DISCOUNT_VALUE_INPUT: 'Discount value. Example: If the action is "2nd unit at 30% discount", here we must enter the number "30".',
        UNIT_DISCOUNT_PER_INPUT:
          'Discount value for each unit sold. Example: If the action is "2nd unit at 30% discount", here we must enter the number "15" which is the percentage that will apply the discount on each unit sold.',
        QUANTITY_UNITS_INPUT:
          'Indicates the number of units that the customer should buy for the discount to apply. Example: If the action is "2nd unit at 30% discount", here we must enter the number "2".',
        MINIMUM_AMOUNT_INPUT:
          'Indicates the minimum number of units that the customer should buy for the discount to apply. Example: If the action is "15% discount taking 3 units", here we must enter the number "3".',
        MAXIMUM_AMOUNT_INPUT:
          'Indicates the maximum number of units that the customer can buy with the discount. Example: If the action is "15% discount up to 6 units", here we must enter the number "6". If the customer buys seven units, the 7th unit will not have a discount.',
        MINIMUM2_AMOUNT_INPUT:
          'Indicates the minimum monetary value that the customer should spend for the promotion to apply. Example: If the action is "10% discount on purchases over $ 1,000", here we must enter the number "1,000". If that amount is not reached, the discount does not apply.',
        MAXIMUM2_AMOUNT_INPUT:
          'Indicates the maximum monetary value that the customer should spend for the promotion to apply. Example: If the action is "10% discount on purchases up to $ 1,000", here we must enter the number "1,000". If the purchase is $ 1,500, the discount will still be $ 100 = 1000 x 10%.',
      },
    },
    PROMOV2: {
      CONFIGURATIONS: {
        LIST: {
          TITLE: 'Promotion Rules',
          NAME: 'Name',
          CODE: 'Code',
          RULE_TYPE: 'Rule Type',
        },
        FORM: {
          NEW_ITEM_TITLE: 'New Promotion Rule',
          NAME: 'Name',
          CODE: 'Code',
          RULE_TYPE: 'Rule Type',
          PROMOTIONAL_PROCESS_TYPE: 'Promotional Process Type',
          PROMOTION_TYPE: 'Mechanics',
          PROMOTION_RULE_TYPE: 'Rule Type',
          LIMITS: 'Limits',
          PRICE_RULES_TITLE: 'The price must be:',
          VALUE: 'Value {{val}}',
          RULE_MESSAGES: {
            FIXPRICE_MINIMUM: 'My {{val}} promotional price must be ${{val2}}.',
            FIXPRICE_MAXIMUM: 'My {{val}} promotional price must be ${{val2}}.',
            REGULARPRICE_MINIMUM: 'My {{val}} promotional price must be {{val3}}{{val2}} lower than the regular price.',
            REGULARPRICE_MAXIMUM: 'My {{val}} promotional price must be {{val3}}{{val2}} above the regular price.',
            SELECT_CONDITION: '(Select a condition for the rule)',
            INSERT_VALUE: '(Insert a value)',
            SELECT_DISCOUNT_TYPE: '(Select the discount type)',
          },
          REFERENCE_PRICE_TYPES: {
            PLACEHOLDER: 'Reference Price Type',
            FIXPRICE: 'Fixed Price',
            REGULARPRICE: 'Regular Price',
            TRUCKPRICE: 'Truck Price',
          },
          RULE_LIMIT_TYPES: {
            PLACEHOLDER: 'Condition',
            MINIMUM: 'Minimum',
            MAXIMUM: 'Maximum',
          },
          PROMOTION_REGULAR_PRICE_CONDITION: {
            CURRENCY: '$',
            PERCENTAGE: '%',
          },
        },
        RULE_TYPES: {
          PRICE: 'Price',
        },
        PROMOTIONAL_PROCESS: {
          LIST_TITLE: 'Promotional Processes',
          NAME: 'Name',
          CODE: 'Code',
          TASK_QUANTITY: 'Number of tasks',
          EDIT: 'Edit',
          CANT_EDIT_TOOLTIP: 'The promotional process cannot be edited as it has assigned promotional periods.',
          CANT_DELETE_TOOLTIP: 'The promotional process cannot be deleted as it has assigned promotional periods.',
          NEW: 'New promotional process',
          MAIN_DATA_TAB: 'Main data',
          TASKS_TAB: 'Tasks',
          WITHOUT_PERMISSION: 'The user does not have permission to edit.',
          DELETE_ROW: 'Delete promotional process',
          DELETE_MODAL_TITLE: 'Do you want to delete the promotional process{{val}}?',
          TASKS: {
            NAME: 'Name',
            STARTING_DAY: 'Starting day',
            BEFORE: 'Before',
            AFTER: 'After',
            TASK_OWNER: 'Responsible',
            CONSULTANTS: 'Consultants',
            DURATION_IN_DAYS: 'Duration',
            REQUIRES_COMPLETION: 'Requires completion',
            ACTIONABLE: 'Actionable',
            NO_ACTIONABLES: 'No actionables available',
            NEW: 'Add task',
            DELETE_MODAL_TITLE: 'Do you want to delete task {{val}}?',
            CLOSE_ALL: 'Close All',
            EXPAND_ALL: 'Expand All',
            ORDER: 'Order',
            ORDER_TOOLTIP: 'Sort tasks by start date',
          },
        },
      },
      CAMPAIGNS_LIST: {
        ROUTE: 'Promotions',
        TITLE: 'Campaigns',
        TITLE_PROMOTIONAL_PERIOD: 'Promotional Period',
        NEW_CAMPAING_BTN: 'New Campaign',
        NEW_PROMOTIONAL_PERIOD: 'New Promotional Period',
        NAME: 'Name',
        CODE: 'Code',
        ACTIONS: '# Actions',
        FROM: 'From',
        TO: 'Until',
        STATE: 'Status',
        USER: 'User',
        ACTIONSCOUNT: '# Actions',
        LAST_MOD: 'Last Modified',
        FULLSCREEN_TTIP: 'Expand',
        EXITFULLSCREEN_TTIP: 'Reduce',
        EXPORT_TTIP: 'Export',
        IMPORT_TTIP: 'Import',
        PRINT_TTIP: 'Print',
        TITLE_FORM: 'New Campaign',
        SAVE_BTN: 'Save',
        BASIC_INFORMATION_TAB: 'Main Data',
        CODE_INPUT: 'Code',
        NAME_INPUT: 'Name',
        FROM_TTIP: 'Date from',
        TO_TTIP: 'Date to',
        DESCRIPTION_INPUT: 'Description',
        STATUS_STORES: 'All',
        STATUS_HISTORY: 'Historical',
        STATUS_VIGENT: 'Current',
        STATUS_FUTURE: 'Future',
        STATUS_NOTVALID: 'Invalid',
        STATUS_EMPTY: 'Invalid',
        INVALID_TO_DATE: 'Must be greater than the From date and be in the date range of the campaign',
        INVALID_FROM_DATE: 'Must be less than the Until date and be in the date range of the campaign',
        INVALID_TO_DATE_PROMOTIONAL_PERIOD: 'Must be greater than the From date and be in the promotional period date range',
        INVALID_FROM_DATE_PROMOTIONAL_PERIOD: 'Must be less than the To date and be in the promotional period date range',
        INVALID_TO_DATE_CAMPAING: 'Must be greater than From date',
        INVALID_FROM_DATE_CAMPAING: 'Must be less than Until date',
        NAME_TTIP: 'Click here to see associated actions',
        ACTIONS_TTIP: 'Number of Actions',
        LAST_MOD_TTIP: 'Last Modified',
        SEE_ACTIONS: 'Actions',
      },
      CAMPAIGNS_EDIT: {
        SAVE_BTN: 'Save',
        BASIC_INFORMATION_TAB: 'Main Data',
        CODE_INPUT: 'Code',
        NAME_INPUT: 'Name',
        FROM: 'From',
        INVALID_TO_DATE_CAMPAING: 'Must be greater than From date',
        INVALID_FROM_DATE_CAMPAING: 'Must be less than Until date',
        TO: 'Until',
        DESCRIPTION_INPUT: 'Description',
        TITLE_FORM: 'New Campaign',
        TITLE_FORM_PROMOTIONAL_PERIOD: 'New Promotional Period',
      },
      ACTIONS_LIST: {
        EXPORT_TTIP: 'export',
        FILTER_TTIP: 'filter',
        DELETE_TTIP: 'delete',
        DELETE: 'delete',
        TITLE: 'List of actions',
        NEW_ACTION: 'new action',
        EDIT_ROW_DISABLED_TOOLTIP: 'Cannot edit a record in publishing process',
        EDIT_ROW: 'Edit',
        DELETE_ROW: 'Delete',
        REVALIDATE_ROW: 'Revalidate',
        REVALIDATING_TOOLTIP: 'Revalidate',
        SEE_DELETED_FILTERS: 'View deleted',
        SEE_CANCELLED_FILTERS: 'View Only Canceled',
        SEE_SUGGESTED_FILTERS: 'View Suggestions Only',
        SEE_SUGGESTED_FILTERS_TOOLTIP: 'Suggested Action',
        ACTION_STATUS: 'Action Status:',
        STATUS_VAL: 'Status and Validation',
        STATUS: 'Status',
        ACTION_STATUS_VALIDATION: 'Validation Status:',
        TYPE_FILTERS: 'Filter by type',
        CREATION_ON: 'Creation date',
        STORES_FILTER: 'Points of Sale',
        ITEM_TAGS: 'Filters for Products',
        STORE_TAGS: 'Point of Sale Tags',
        ITEM_TYPE_INPUT: 'Type of Action',
        ITEM_TYPE_INPUT_OXXO: 'Promotional process',
        ITEM_TYPE_MEC_INPUT: 'Mechanics',
        NEW_ITEM_MODAL: 'New Action',
        CLONE_ROW: 'Clone record',
        CANCEL_ROW: 'Cancel registration',
        CREATE_BTN: 'Create',
        COPY_ROW: 'Copy record',
        DISABLED_CLONE_TOOLTIP: 'Only records in published state can be cloned',
        REPORT_TOOLTIP: 'Download Report',
        REPORT_TOOLTIP_MORE_INFO:
          "This report generates records based on the promotion's configuration. \nTo generate the records, the following fields must be filled: period, spaces, store and product coverage, and discount and approved coverage configuration.",
        ACTIONS: {
          ROUTE: 'Promotions',
          TITLE: 'Actions',
          FILTER_TTIP: 'Filter',
          FULLSCREEN_TTIP: 'Expand',
          EXITFULLSCREEN_TTIP: 'Reduce',
          NAME: 'Name',
          CODE: 'Code',
          TYPE: 'Type',
          LIFT: 'Lift%',
          BRAND: 'Brand',
          NONE: 'None',
          TO: 'Until',
          SUPPLIES_LAST: 'Until stocks last',
          USER: 'User',
          LAST_MOD: 'Last Modified',
          STATE: 'Status',
          PROMOTIONAL_SPACES: 'Promotional spaces',
          PROMOTIONAL_MATERIAL: 'Promotional Material',
          EDIT_PROMOTIONAL_SPACES: 'Edit Promotional Material',
          EDIT_VALIDATIONS: 'Edit business validations',
          LIST_PROMOTIONAL_SPACES: 'Selected Promotional Spaces:',
          VALIDATION_STATE: 'Validation Status',
          VALID_TO_PUBLISH: 'Already to publish',
          TYPE_OP1: 'Take A pays B',
          TYPE_OP2: 'Direct Discount',
          TYPE_OP3: 'Quantity Discount',
          METHODTYPE: 'Method type',
          DISCOUNTTYPE: 'Discount type',
          STOREQUANTITY: '# Points of sale',
          ESTIMATEDSALE: 'Estimated sale',
          FREQUENCYTYPE: 'Frequency type',
          FROM: 'From',
          FROM_TTIP: 'Date from',
          TO_TTIP: 'Date to',
          PROMOTINOAL_PERIOD: 'Promotional Period',
          PROMOTIONAL_PERIOD_CODE: 'Promotional Period Code',
          EXTERNAL_CODE: 'External Code',
          FREECUENCYTYPE: 'Frequency type',
          ONLY_ACTIVE_PERIODS: 'Only active periods',
          NO_ACTIVE_PERIODS: 'There are no active periods.',
          NO_ACTIVE_PERIODS_IN_SELECTION:
            'There are no active periods in the selected year and promotional process. Check the promotional calendar for the next date to carry out this type of activity.',
          NAVIGATE_TO_CALENDAR: 'Go to Calendar',
          CANT_EDIT_VALIDATIONS: 'The validations cannot be edited because the promotional period has already started/ended.',
        },
        TYPE: {
          DISCOUNT: 'Discount',
          BUYAPAYB: 'Take A pays B',
          BUYAGETDISCOUNTINXUNIT: 'Get discount per unit',
          SURCHARGE: 'Surcharge',
          FIXPRICE: 'Fixed Price',
          GIFT: 'Gift',
          WHOLESALER: 'Wholesaler',
          IMAGECOMMUNICATION: 'Image Communication',
        },
        TYPE_DEC: {
          DISCOUNT:
            'Allows you to define a monetary value or percentage that will be applied reducing the final price that the customer will pay.',
          BUYAPAYB: 'Discount where A is the number of units to be purchased by the customer and B is the units to be paid.',
          BUYAGETDISCOUNTINXUNIT: 'A discount is applied to a number of units within a minimum quantity to buy of the same product.',
          SURCHARGE:
            'Allows you to define a monetary value or percentage that will be applied increasing the final price that the client will pay.',
          FIXPRICE: 'Establish the price to pay carrying a number of units.',
          GIFT: 'Allows you to define a gift product when buying other products, a minimum purchase and/or setting the price of the gift product.',
          WHOLESALER: 'Wholesaler',
          IMAGECOMMUNICATION: 'Highlight new products or the regular price that is so attractive',
        },
        METHODTYPE: {
          ACTIVATEONLYDURINGPROMOTION: 'Active only during promotion',
          ACTIVATEINDEFINITELY: 'Active indefinitely',
          DONOTACTIVATE: 'Do not activate products',
        },
        DISCOUNTTYPE: {
          ABSOLUTEVALUE: '$',
          PERCENTAGE: '%',
        },
        FREQUENCYTYPE: {
          EVERYDAY: 'Every day',
          CERTAINDAYSOFTHEWEEK: 'Certain days of the week',
          CERTAINDAYSOFTHEMONTH: 'Certain days of the month',
        },
        CLONE: {
          MODAL_CONTENT:
            'Define name and internal code for the new promotion. All existing points will be cloned from {{val}} ({{val2}}), having the possibility of editing.',
          CREATE_BTN: 'Clone',
          CANCEL: 'Cancel',
        },
        CANCEL: {
          MODAL_CONTENT:
            'The promotion will be canceled {{val}} ({{val2}}) all its content will be disabled for modifications and the promotion will not be valid',
          CREATE_BTN: 'OK',
          CANCEL: 'Cancel',
        },
      },
      ACTIONS_TYPES: {
        Discount: 'Direct Discount',
        BuyAPayB: 'Buy A pays B',
        BuyAGetDiscountInXUnit: 'Get discount per unit',
        Surcharge: 'Surcharge',
        FixPrice: 'Fixed Price',
        Gift: 'Gift',
        Wholesaler: 'Wholesaler',
        ImageComunication: 'Image Communication',
      },
      ACTIONS_EDIT: {
        TEST: 'TEST',
        SAVE: 'Save',
        PUBLISH: 'Publish',
        CANCEL_ACTION: 'Cancel',
        OK: 'Ok',
        WARNING_DOWNLOAD: 'Only one promotional period selected in the filters is required.',
        WARNING_SAVE: 'Requires saving current changes.',
        WARNING_PUBLISH: 'You need to republish for saved changes to take effect.',
        NOVALID_PUBLISH_ADVICE: 'The following tab is not valid:',
        PROMOTION_EDIT_DISABLED: 'Cannot edit the promotion because the selected promotional period has already started or ended.',
        BASIC_INFORMATION_TAB: 'Main Data',
        ACTIVATION_TAB: 'Activation',
        LOADING: 'Loading ...',
        SCOPE_OF_ITEMS: 'Scope of Products',
        PAYMENTMETHODS: 'Payment Methods',
        CUSTOMFIELDS: 'Additional Information',
        SCOPE_OF_STORES: 'Scope of Points of Sale',
        GIFT_ITEM: 'Gifts Product',
        GIFT_ITEM_TAB: 'Scope of Gifts Products',
        NEW_ITEM_BTN: 'New Promotional Action',
        PUBLISH_CONFIRM:
          'The publication process includes the creation of the Assortment, Prices, Costs and other records related to this action. <br> <br> <b> Are you sure you want to Publish? </b>',
        PUBLISH_CONFIRM_TITLE: 'Publication Confirmation',
        PUBLISHING_WARNING: 'Item in process of publishing, please try again in a few minutes.',
        PUBLISH_LONG_TIME:
          'The publication of this Product may take a few minutes, when it is finished you will be notified with a notification.',
        INVALID_TO_TIME: 'Must be greater than Time From',
        INVALID_FROM_TIME: 'Must be less than Hours Until',
        STRATEGY_ATTR_TITLE: 'Selection by attribute (Dinamic)',
        STRATEGY_ATTR_MSG:
          'Attributes are selected to define the scope of products, if the scope changes, the impact of this setting is done automatically and does not need to be changed',
        STRATEGY_PROD_TITLE: 'Selection by product',
        STRATEGY_PROD_MSG: 'Products must be chosen explicitly one by one',
        STRATEGY_ATTR_TITLE_STORE: 'Selection by attribute (Dinamic)',
        STRATEGY_ATTR_MSG_STORE:
          'Attributes are selected to define the scope of points of sale, if the scope changes, the impact of this setting is done automatically and does not need to be changed',
        STRATEGY_STORE_TITLE_STORE: 'Selection by points of sale',
        STRATEGY_STORE_MSG_STORE: 'The points of sale must be explicitly chosen one by one',
        CLIPBOARD_STORE_TITLE: 'Paste points of sale from clipboard',
        CODE_AND_DESCRIPTION_INPUT: 'Code / Description',
        CLIPBOARD_PRODUCT_TITLE: 'Paste products from clipboard',
        DELETE_TIME_RANGE: 'Borrar Rango Horario',
        ADD_TIME_RANGE: 'Agregar Rango Horario',
        OVERLAP_TIME_RANGE: 'Overlapping Time Range',
        SAVE_LONG_TIME: 'Confirm Save',
        SAVE_LONG_TIME_MSG_A:
          'Saving this promotion may take a few minutes. It will be validated, and you will be notified once it is completed.',
        SAVE_LONG_TIME_MSG_B:
          'Until the validation process is completed, the promotion cannot be modified again.<br><br><b>Are you sure you want to Save?</b>',
        SAVE_PVD_MSG:
          'I agree that my promotional action applies to all my <br> products and points of sale. (I am aware that this may result in a longer wait time in the validation process.)',
        SAVE_STORE_SCOPE_PVD_MSG:
          'I agree that my promotional action applies to all my <br> points of sale. (I am aware that this situation may result in a longer wait time in the validation process.)',
        SAVE_ALL_ITEMS_SELECTED_WARNING:
          'The defined product scope corresponds to 100% of the products, please define a specific scope to save.',
        PROCESS_WARNING_TITLE: 'The required action cannot be completed',
        PROCESS_WARNING_MSGLONGTIME: 'The publication and/or saving cannot be completed for the following reasons:',
        PROCESS_WARNING_MSGLONGTIME_EXTEND_1:
          'The promotion is in the Validation Process, so it cannot be saved or published until it is completed.',
        PROCESS_WARNING_MSGLONGTIME_EXTEND_2: 'The promotion is in a Rejected Validation state, so it cannot be published.',
        PROCESS_WARNING_MSGLONGTIME_EXTEND_3: 'The promotion is in a Cancelled Validation state, so it cannot be published.',
        PROCESS_WARNING_MSGLONGTIME_EXTEND_4:
          'The promotion is pending Required Action, so it cannot be published until the action is resolved.',
        COVERAGE_TITLE: 'Coverage',
        MAIN_DATA: {
          NAME: 'Name',
          CODE: 'Code',
          EXTERNAL_CODE: 'External Code',
          MESSAGE: 'Ticket Message',
          REGENERATE: 'Regenerate',
          REGENERATE_TOOLTIP: 'A message is generated with the first {{val}} characters of the promotional action name, excluding prices.',
          INTERNAL_CODE: 'Internal Code',
          VALIDATION_CODE2: 'The code {{val}} already exists.',
          SINCE_INPUT: 'From',
          TO_INPUT: 'Until',
          OBSERVATION: 'Observation',
          TITLE_FRECUENCY: 'Frequency',
          TIME_FROM: 'Time from',
          TIME_TO: 'Schedule Until',
          CAMPAING: 'Campaign',
          PROMOTIONAL_PERIOD: 'Promotional Period',
          SET_PROMOTIONAL_PERIOD:
            'When selecting or modifying the dates of the chosen promotional period, the dates of the chosen promotional period will be loaded.',
          PROMOTIONAL_MATERIAL: 'Promotional Material',
          GIFT_ITEM: 'Gift Product',
          ACCUMULATE_ACTIONS: 'Accumulative with other actions',
          GIFT_ITEM_POPOVER_MSG: 'You can select a single gift product, existing in the Item Master module',
          GIFT_ITEM_NEW: 'New Gift Product',
          GIFT_ITEM_NEW_POPOVER_MSG:
            'If the gift product should not be managed by the Item Master module, the name of the same can be indicated in this field',
          SELECTTIMERANGE: 'Active 24 hours a day',
          SELECTTIMERANGE_TEXT:
            'If this option is active, the promotional action will be in effect 24 hours a day, otherwise it will be necessary to define a daily time range to indicate the period in which the promotional action is active.',
          PLANOGRAMS_WARNING_PRODUCT_SCOPE: 'Select the Product Scope to be able to edit.',
          PLANOGRAMS_WARNING_STORE_SCOPE: 'Select the Store Scope to be able to edit.',
          PLANOGRAMS_WARNING_SAVE: 'Save changes to be able to edit.',
          PLANOGRAMS_WARNING_NO_PLANOGRAMS: 'There are no planograms in the selected store scope.',
          PLANOGRAMS_WARNING_ALL_ITEMS: 'Define a specific product scope to get the available promotional material.',
          PLANOGRAMS_WARNING_MAIN_DATA: 'Complete the mandatory fields to save changes and edit.',
          PLANOGRAMS_WITHOUT_POP_MATERIAL: 'Without promotional material',
          PLANOGRAMS_DELETED: '{{val}} previously selected promotional materials are no longer available.',
          PERIOD_WARNING_INVALID_FROM_TODAY: 'The start date is earlier than the current date',
          PERIOD_WARNING_INVALID_FROM_TASK: 'The first planning task for the selected period should have started on',
          PERIOD_WARNING_INVALID_FROM_TO: 'The start date cannot be later than the end date',
          ESTIMATEDSALE: 'Estimated sale',
          TICKET_MESSAGE: {
            MODAL_TITLE: 'Do you want to regenerate the ticket message?',
            PRICE: 'Prices are not allowed in the message.',
            SPECIALCHARACTERS: 'Special characters are not allowed in the message.',
            DOUBLESPACES: 'Double spaces are not allowed in the message.',
            MAXCHARACTER: 'You have reached the maximum number of characters allowed for the message.',
          },
        },
        VALIDATIONS: {
          VALIDATIONS_TITLE: 'Validations',
          COVERAGE_TOOLTIP:
            'To improve this validation, we suggest adjusting the scope of stores or products, checking coverage through labels beforehand, and analyzing the current coverage status by different sets of points of sale.',
          MAX_SLOTS_TOOLTIP:
            'To improve this validation, we suggest adjusting the scope of stores or modifying the promotional materials used.',
          FIX_AND_REGULAR_PRICE_TOOLTIP:
            'To improve the results of this validation, we suggest adjusting the promotional price from the activation tab.',
          STORE_AND_PROMOTIONAL_SPACE:
            'To improve this validation, we suggest adjusting the scope of stores or modifying the promotional materials used.',
          FIX_PRICE_EXCEPTIONS: 'To improve this validation, we suggest adjusting the price exceptions in the exceptions importer.',
          REFERENCE_PRICE: 'To improve this validation, we suggest adjusting the promotional price from the activation tab.',
          ACTION_BUTTON_DISABLED_TOOLTIP: 'Changes are only allowed when the promotional action is published.',
          TAB_TITLE: 'Business Validations',
          TAB_DESCRIPTION:
            'Business validations are used to ensure that the promotional action meets the necessary criteria for execution. In the event that a validation encounters errors, it can be approved or rejected when publishing or preemptively modifying the involved data to obtain new results. Through reports, we can obtain detailed information about the failures, and if all the necessary data is not available to perform the validation, it will be reported as incomplete. It is necessary to have all validations approved and validated to obtain productive reports.',
          storesAndPromotionalSpace: {
            VALIDATION_NAME: 'Store Scope vs Promotional Material',
            VALIDATION_DESCRIPTION:
              'Validates if any store configured for this promotion does not have the selected promotional materials.',
            VALIDATION_ERROR:
              '{{val}} stores configured for this promotion were found not to have some of the selected promotional materials.',
            VALIDATION_SUCCESS: 'All selected promotional materials are available in the promotion stores.',
            VALIDATION_INCOMPLETE:
              'The impact on stores cannot be determined because promotional materials or store scope for this promotion were not selected.',
          },
          MaxSlots: {
            VALIDATION_NAME: 'Maximum Number of Promotions per Promotional Material',
            VALIDATION_DESCRIPTION:
              'Validates if the maximum number of promotions supported in any of the selected promotional materials has been exceeded.',
            VALIDATION_ERROR: 'This promotion exceeds the maximum capacity supported by {{val}} space-stores.',
            VALIDATION_SUCCESS: 'The promotion was successfully assigned to the selected promotional materials.',
            VALIDATION_INCOMPLETE: 'The maximum number of promotions was not calculated because promotional materials were not selected.',
          },
          FixAndRegularPrice: {
            VALIDATION_NAME: 'Promotional Price vs Regular Price',
            VALIDATION_DESCRIPTION:
              'Validates that the fixed promotional price is lower than the regular price in that store, ensuring it is true for all stores where this promotion applies.',
            VALIDATION_ERROR: 'The promotional price was found to be greater than or equal to the regular price in {{val}} product-stores.',
            VALIDATION_SUCCESS: 'The promotional price is lower than the regular price in all product-stores for this promotion.',
            VALIDATION_INCOMPLETE:
              'It cannot be determined if the promotional price is correct because products or stores in their respective scopes were not selected.',
          },
          FixPriceExceptions: {
            VALIDATION_NAME: 'Price exception vs regular price',
            VALIDATION_DESCRIPTION:
              'Validates that the promotional price set in the price exceptions importer is lower than the regular price for each store in the store scope defined in the importer.',
            VALIDATION_ERROR:
              'The promotional price is greater than or equal to the regular price in {{val}} product-stores in the exceptions of this promotion.',
            VALIDATION_SUCCESS: 'The promotional price is lower than the regular price in all product-stores of this promotion.',
            VALIDATION_INCOMPLETE:
              'To validate this promotional action it is necessary to define product scope, store scope and import at least 1 price exception correctly.',
          },
          Coverage: {
            VALIDATION_NAME: 'Minimum Coverage',
            VALIDATION_DESCRIPTION: 'Validates that the minimum coverage is reached.',
            VALIDATION_ERROR: 'The defined minimum coverage of {{val}}% is not met.',
            VALIDATION_SUCCESS: 'The defined minimum coverage of {{val}}% is met.',
            VALIDATION_INCOMPLETE: 'Coverage was not calculated because products or stores in their respective scopes were not selected.',
          },
          ReferencePrice: {
            VALIDATION_NAME: 'Promotional Price vs Truck Price',
            VALIDATION_DESCRIPTION:
              'Validates that the promotional price complies with the defined price rule, ensuring it applies to all stores where this promotion is valid.',
            VALIDATION_ERROR:
              'The promotional price does not comply with the truck price rule for all product-store combinations in this promotion.',
            VALIDATION_SUCCESS:
              'The promotional price complies with the truck price rule for all product-store combinations in this promotion.',
            VALIDATION_INCOMPLETE:
              'It cannot be determined if the promotional price is correct because it needs to be loaded in the activation tab.',
          },
        },
        DOWNLOAD_VALIDATION_REPORT: 'Download Validation Report',
        VALIDATION_NO_DATA: 'There is not enough data to validate',
        ACTIVATION: {
          ASSORTMENTMETHOD: 'Assortment Behavior',
          CHANNELCODES: 'Sales Channels',
          LOYALTYPROGRAMCODES: 'Loyalty Programs',
          LOYALTYPROGRAMCODES_GLOBAL: 'Restriction for Loyalty Programs',
          LOYALTYPROGRAMCODE: 'Loyalty Program',
          LOYALTY_PROGRAM_CODE_EXC: 'Exceptional Loyalty Program',
          BUY_A_QUANT: 'Carries',
          BUY_B_QUANT: 'Pay',
          BUY_B_QUANT_DESC: 'Units to Discounted',
          MIN_QUANT: 'Quantity',
          SAMEITEM: 'Same Product',
          UNTILSTOCKLAST: 'Until Stock Out',
          MIN_VALUE: 'Minimum Value $',
          MIN_VALUE_TICKET: 'Total Ticket $ Minimum Value',
          DISCOUNT: 'Discount',
          LOYALTY_DISCOUNT: 'Exceptional Loyalty Discount',
          LOYALTY_FIXED_PRICE: 'Exceptional Loyalty Fixed Price',
          SURCHARGE: 'Surcharge',
          DISCOUNTTYPE: 'Discount Type',
          SURCHARGETYPE: 'Type of Surcharge',
          FIXPRICE: 'Fixed Price $',
          FIXPRICE_TOOLTIP:
            "Indicates the promotional price for the total quantity of products loaded. Example: If the action is '3 x $40,' the Minimum Quantity of products is 3, and the fixed price to be applied is the final one, in this case, $40.",
          SHIPPINGDISCOUNT: 'Shipping Discount%',
          MAXIMUMDISCOUNTVALUE: 'Discount Cap $',
          COMMUNICATIONTYPE: 'Communication type',
          COMMUNICATIONDISPLAY: 'Communication Order',
          COMMUNICATIONDISPLAY_TOOLTIP:
            'Communication Order: Allows you to define the order in which the text is generated for the production list.',
          EXCEPTIONAL_FIXED_PRICE_TITLE: 'Exceptional Fixed Price Upload',
          EXCEPTIONAL_DISCOUNT_TITLE: 'Exceptional Discount Upload',
          EXCEPTIONAL_FIXED_PRICE_SBUTITLE: 'You can upload price exceptions via the importer',
          EXCEPTIONAL_FIXED_PRICE_SBUTITLE_NO_ID: 'You can upload price exceptions via the importer. You need to save the current changes.',
          EXCEPTIONAL_FIXED_PRICE_SBUTITLE_NO_ID_ALT:
            'You can upload price exceptions via the importer. Allowing you to upload exceptions at the store or tag level (only one of the two levels can be used for the entire importer). Each import replaces the previous import. You need to save the current changes.',
          EXCEPTIONAL_DISCOUNT_SBUTITLE: 'You can upload discount exceptions via the importer',
          EXCEPTIONAL_DISCOUNT_SBUTITLE_ALT:
            'You can upload discount exceptions via the importer. Allowing you to upload exceptions at the store or tag level (only one of the two levels can be used for the entire importer). Each import replaces the previous import.',
          EXCEPTIONAL_FIXED_PRICE_SBUTITLE_ALT:
            'You can upload price exceptions via the importer. Allowing you to upload exceptions at the store or tag level (only one of the two levels can be used for the entire importer). Each import replaces the previous import.',
          EXCEPTIONAL_DISCOUNT_SBUTITLE_NO_ID: 'You can upload discount exceptions via the importer. You need to save the current changes.',
          LOYALTYPROGRAMCODES_GLOBAL_TOOLTIP: 'Optionally restrict the execution of activation for the selected loyalty program(s).',

          COMMUNICATIONTYPE_TOOLTIP:
            'If you select <b>"Communicate Discount"</b> the percentage of discount that will be applied to the product for your purchase will be indicated.<br>If you select <b>"Communicate Price"</b> you will obtain the price end of the promotion in the promotions and printing reports.<br><br><b>Report example:</b><br><b>Promotion 1</b> Coca Cola 600ml with a 10% discount each.<br><b>Promotion 2</b> Coca Cola 600ml only $10 each in store A and Coca Cola 600ml only $9 each in store B, considering that regular prices may be different in each store.',
          LOYALTY_DISCOUNT_TOOLTIP: 'Optionally allows granting a different and exclusive discount value for the selected loyalty program.',
          LOYALTY_FIXED_PRICE_TOOLTIP:
            'Optionally allows granting a different and exclusive fixed price value for the selected loyalty program.',
          LOYALTY_FIXED_PRICE_TOOLTIP_ALT:
            'Indicates the promotional price for the selected loyalty program, respecting the total quantity of products loaded. Example: If the action is "3 x $30 for loyalty program," the Minimum Quantity of products is 3 and the exceptional fixed price by loyalty to be loaded is the final one, in this case, $30. This price applies to all stores without exception.',
          ActionsV2_communicationType: {
            CommunicateDiscount: 'Communicate Discount',
            CommunicatePrice: 'Communicate Price',
          },
          ActionsV2_communicationDisplay: {
            CommunicateFirstValue: 'Promotional Value + Product Name',
            CommunicateFirstName: 'Product Name + Promotional Value',
          },
          COMMUNICATION: {
            PROMOTIONAL_MESSAGE: 'Promotional message',
            PROMOTIONAL_MESSAGE_TOOLTIP:
              'This field allows you to quickly view an example of the promotional message obtained in productive reports',
            PROMOTIONAL_MESSAGE_WARNING:
              'The promotional action does not have enough data to generate the promotional message, please complete all required data',
            SUPPORT_TEXT: 'Support text',
            SUPPORT_TEXT_TOOLTIP: '',
          },
          IMPORT_LIST: {
            valuePrice: 'Fixed Price $',
            valueDiscount: 'Discount',
            tagValueId: 'Tag {{val}}',
            storeId: 'Store',
            storeName: 'Store',
            storeScope: 'Scope of points of sale',
            regularPrice: 'Regular Price $',
            discount: 'Estimated Discount %',
            comboId: 'Combo ID',
          },
          COMBOS: {
            DELETE_BLOCK: 'Delete block',
            EDIT_ITEMS: 'Edit items',
            EDIT_NAME_STAGGERED: 'When editing the name of this combo, the change will be applied to all combos.',
            EDIT_NAME_MIXED: 'Edit name',
            EDIT_QUANTITY: 'Edit quantity',
            ADD_COMBO: 'Add combo',
            ADD_STAGGERED_COMBO: 'Add staggered combo',
            COMBO_ITEMS: 'Products{{val}}',
            COMBO_ITEMS_EDIT: 'Edit items',
            ADD_BLOCK: 'Add block',
            COPY_IDS: 'Copy IDS',
            COPY_ID: 'Copy ID',
            EDIT_STAGGERED_COMBO: 'Edit Staggered Combo',
            EDIT_COMBO: 'Edit Combo',
            DELETE_COMBO: 'Do you want to delete the combo?',
            CREATE_COMBO: 'Create Combo',
            CREATE_STAGGERED_COMBO: 'Create Staggered Combo',
            CLONE_COMBO: 'Clone Combo',
            DELETE_BLOCK_TITLE: 'Do you want to delete the block {{val}}?',
            NAME_INPUT_VALIDATION_MESSAGES: {
              DUPLICATE_NAMES: 'Blocks cannot have the same name.',
              PRICE: 'Prices are not allowed in the name.',
              SPECIALCHARACTERS: 'Special characters are not allowed in the name.',
              DOUBLESPACES: 'Double spaces are not allowed in the name.',
              MAXCHARACTER: 'The maximum allowed characters for the name has been reached.',
              DUPLICATE_ITEMS: 'Duplicate items are not allowed between blocks.',
            },
          },
          DISCOUNT_AND_REGULAR_PRICE: 'Regular price ${{val}}. Estimated discount {{val2}}%.',
          DISCOUNT_TOOLTIP_TITLE: 'Estimated discount on fixed price',
          DISCOUNT_TOOLTIP_DESCRIPTION:
            'An estimated discount is defined by comparing the regular price and the fixed price. The regular price is defined as the mode, the most frequently occurring price, within the store range for this promotion.<br><br> If the promotion includes more than one product or a combo has more than one product in each block, the lowest mode price among the participating products in the promotional action or block is used.',
          STORES_WITHOUT_REGULAR_PRICE: 'There is no regular price in the selected stores to calculate the discount.',
          DISCOUNT_WARNING_MESSAGE: 'To see the new applied discount, it is necessary to save.',
          WITHOUT_POP_MATERIAL_TOOLTIP_TITLE: 'Without promotional material',
          WITHOUT_POP_MATERIAL_TOOLTIP_DESCRIPTION:
            'Active: Does not require selecting a promotional material to publish the promotional action, and validations dependent on this data will be automatically validated. The promotions report will not include advertising space, and this promotional action will not be part of the results in reports related to promotional material.<br><br> Not active: Requires selecting at least one promotional material, the promotional action will be included in reports related to promotional material, and validations dependent on this data must be verified.',
        },
        SCOPE_ITEM: {
          IMPORT: 'Import',
          RADIO_SIMPLE_PRODUCT: 'Simple Product',
          RADIO_MULTIPLE_PRODUCT: 'Multiple Products',
          RADIO_STAGGERED_PRODUCT: 'Staggered Promotions',
          DESCRIPTION_SIMPLE_PRODUCT:
            'Select the products that are part of the promotion. Choose either a single type or combine them as desired. All products will have the same promotional price.',
          DESCRIPTION_MULTIPLE_PRODUCT:
            'Create one or more sets of products participating in the promotion. Define the quantity and the products that are part of each component.',
          DESCRIPTION_STAGGERED_PRODUCT:
            'Create a set of products to define the base promotion and variants, differing only in the quantity of their components. Each set will have a different promotional price.',
          WARNING_MESSAGE:
            'By modifying the scope of products, the Promotional Material assigned to this promotional action will be removed. You will need to reassign them after saving the changes.',
          WARNING_IMPORTANT_MESSAGE: 'Important Notice:',
        },
        SCOPE_STORE: {
          IMPORT: 'Import',
          WARNING_MESSAGE:
            'When modifying the store scope, the promotional spaces assigned to this promotional action will be deleted. You will need to reassign them after saving the changes.',
          WARNING_IMPORTANT_MESSAGE: 'Important Notice:',
        },
        ActionsV2_FrequencyType: {
          EveryDay: 'Every day',
          CertainDaysOfTheWeek: 'Certain days of the week',
          CertainDaysOfTheMonth: 'Certain days of the month',
        },
        ActionsV2_AssortmentType: {
          ActivateOnlyDuringPromotion: 'Active only during promotion',
          ActivateIndefinitely: 'Active indefinitely',
          DoNotActivate: 'Do not activate products',
        },
        ActionsV2_DiscountType: {
          AbsoluteValue: '$',
          Percentage: '%',
        },
        PAYMENTS: {
          ADD_BLOCK: 'Add Payment Method',
          PAYMENT_BLOCK: 'Payment method',
          FINANCIAL_INSTITUTION: 'Financial Institution',
          CARD_TYPE: 'Card Type',
          PAYMENT_BLOCK_OPT: 'Private payment method (optional)',
          BANK: 'Bank',
          INSTALLMENTS: 'Quotas',
          LOYALTYPROGRAMPOINTS: 'Loyalty Points',
          LOYALTYPROGRAMPOINTSEQUIVALENCEINCASH: 'Loyalty Points Equivalence',
          SPONSORSHIP: 'Percentage of Sponsorship %',
          PAYMENT_BLOCK_COMBO: 'Combination of payment methods',
          VALUE: 'Value',
          ADDSUBCOMBINATION: 'Add type of Payment Method',
          REMOVECOMBINATION: 'Remove Combination',
          REMOVESUBCOMBINATION: 'Delete type of Payment Method',
          TYPE: 'Type of Payment Method',
          ALL: 'All',
          DEBIT: 'Debit',
          CREDIT: 'Credit',
          NONE: 'None',
          ADD_COMBINATION_BLOCK: 'Add Combination',
          HELP_PAYMENT1:
            'If no means of payment is selected, all means of payment will be applied. If means of payment are selected, these means of payment will be necessary requirements for the promotion to be activated',
          DUPLICATE_WARNING: 'Duplicate payment method',
          DUPLICATE_COMB_WARNING: 'Duplicate Payment Medium Combination',
          PAYMENT_ALL: 'All payment methods',
          PAYMENT_NO_COMB: 'Selection by Types of Payment Means',
          PAYMENT_COMB: 'Price of the Gift Product',
          PAYMENT_ALL_DESC: 'The promotional action will be active for any payment method.',
          PAYMENT_NO_COMB_DESC: 'If means of payment are selected, they will be necessary requirements for the promotion to be activated',
          PAYMENT_COMB_DESC:
            'Only applies if the Gift Product has an additional price (eg: "take a brownie for an additional $ 50 to your coffee"). It admits one or more combinations of one or more payment methods.',
          NO_ITEMS: 'You must select at least one option as a means of payment',
          NO_ITEMS_COMB: 'You must select at least one combination of payment methods',
          GIFT_ITEMS: {
            SIZE: 'Amount of products',
            Size_help: 'Amount of products that will be given.',
            GIF_SELECTION_NO_MANUAL_ITEMS_SELECTED: 'No items selected',
          },
        },
        GIFT_ITEMS: {
          SIZE: 'Quantity of products',
          SIZE_HELP: 'Amount of products that will be given away if the promotion is completed.',
          GIF_SELECTION_NO_MANUAL_ITEMS_SELECTED: 'There are no items selected',
        },
        PUBLISH_CONTENT: 'Do you want to publish the following selected {{val}} records?',
      },
      ACTIONS_STATUS: {
        NEW: 'New',
        ENDORSED: 'Endorsed',
        INFORMED: 'Informed',
        DELETED: 'Deleted',
        MODIFIED: 'Modified',
        CURRENT: 'Current',
        CANCELLED: 'Cancelled',
      },
      ACTIONS_VALIDATION_STATUS: {
        APPROVED: 'Approved',
        VALIDATING: 'Validating',
        ACTIONREQUIRED: 'Action Required',
        REJECTED: 'Rejected',
        PENDING: 'Pending',
      },
      HELP: {
        QUANTITY_BUY_INPUT: 'Indicate the units (A) that the customer will take. Example: If the action is "3 x 2" here we must enter "3".',
        QUANTITY_PAY_INPUT:
          'Indicates the units (B) that the customer will pay. It is a value less than "Quantity to Buy". Example: If the action is "3 x 2" here we must enter "2".',
        FRECUENCY_INPUT: null,
        DAYS_INPUT: 'Days of the week in which the action will be effective.',
        DATE_FROM_INPUT: 'Effective start date of the action.',
        DATE_TO_INPUT: 'Action expiration date.',
        TIME_FROM_INPUT: 'Beginning of the daily time range of validity of the action.',
        TIME_TO_INPUT: 'Completion of the daily time range of the actions validity.',
        TICKET_MESSAGE_INPUT: 'Short description of the action that will be reflected in the invoice ticket.',
        OBSERVATION_INPUT: 'Allows you to enter some type of information or details of the action.',
        UNIT_DISCOUNT_INPUT:
          'Indicate the value of the discount to be applied to the total regular price of the promotion. Example: $5 discount when buying 2 products A, if each product has a regular price of $10. (2x$10) -$5 discount. Final promotional price $15.',
        DISCOUNT_RATE_INPUT: 'Indicates if the discount will be in percentage or in pesos.',
        SUPPLIES_LAST_CHECK: 'Informative message for legal purposes for the point of sale.',
        UNITS_AVAILABLE_INPUT:
          'Indicates the total company product units available for action. Informative message for legal purposes for the point of sale.',
        DISCOUNT_VALUE_INPUT:
          'Total value of the discount corresponding to the promotion. Example: If the action is "2nd unit at 30% discount", here we must enter the number "30".',
        UNIT_DISCOUNT_PER_INPUT:
          'Indicates the value of the discount to be applied to each unit sold. Example: If the action is "2nd unit at 30% discount", here we must enter the number "15" which is the percentage that will be applied as a discount on each unit sold.',
        QUANTITY_UNITS_INPUT:
          'Indicates the mandatory units that the customer must buy for the discount to apply. Example: If the action is "2nd unit at 30% discount", here we must enter the number "2" which are the units necessary for the discount to apply.',
        MINIMUM_AMOUNT_INPUT:
          'Indicates the minimum units that the customer must buy for the discount to apply. Example: If the action is "15% discount carrying 3 units", here we must enter the number "3".',
        MAXIMUM_AMOUNT_INPUT:
          'Indicates the maximum units that the customer can buy with the discount. From this value, the units will not have the promotion. Example: If the action is "15% discount up to 6 units", here we must enter the number "6", in unit "7" or successive 15% does not apply.',
        MINIMUM2_AMOUNT_INPUT:
          'Indicates the minimum monetary value that the customer must buy (on the total ticket) for the promotion to apply. Example: If the action is "10% discount on purchases over $ 1,000", here we must enter the number "1,000". If that amount is not reached, the discount does not apply.',
        MAXIMUM2_AMOUNT_INPUT:
          'Indicates the maximum monetary value that the customer can buy to apply the promotion. From that amount, the surplus of the purchase will not have a discount. Example: If the action is "10% discount on purchases up to $ 1,000", here we must enter the number "1,000". If the purchase is for $ 1,500, the $ 500 does not have the 10% discount.',
        ASSORTMENTMETHOD: 'Indicates Assortment behavior',
        MINIMUM_AMOUNT_INPUT_GROUP:
          'Indicates the minimum monetary value that the customer must buy (on the products reached) for the promotion to apply. Example: If the action is "10% discount on soft drinks from $ 1,000", here we must enter the number "1,000". If that amount is not reached, the discount does not apply.',
        SAME_ITEM: 'The discount will be calculated by grouping the same products',
        COMPLETE_COVERAGE:
          "Activating full coverage changes the calculation, indicating that the store meets the coverage only if all the products within the scope of this promotion are active in the store's assortment. If it is deactivated, but at least one product is active in the store, the coverage will be met. <br><br> If the product is a combo type, whether active or not, at least one variant of each component of the combo must be active for the store to meet the coverage.",
        COVERAGE_BASED_COMMUNICATION:
          'Active: Promotional messages vary with each change in coverage of the promotional action across stores in the scope. <br><br> Inactive: Regardless of the coverage, all stores within the scope receive the same promotional message.',
        FREECUENCY_INPUT: 'Indicates the repetition of the action over time.',
        COMMUNICATE_REGULAR_PRICE:
          'If enabled, reports will calculate the regular price of the product for each store within the defined scope. <br> If disabled, the message to communicate will not include a price.',
      },
      PAY_METHODS: {
        PAY_METHOD_Money: 'Money',
        PAY_METHOD_LoyaltyPoints: 'Loyalty Program',
      },
      PRODUCT_LIST: {
        TITLE: 'Printing report',
        DOWNLOAD: 'Download Report',
        GENERATE: 'Generate Report',
        LAST: 'Last Generated Report',
        PERIODS: 'Periods',
        VENDORS: 'Printers',
        ERROR_REPORT: 'The report could not be generated correctly',
      },
      PROMOTION_OUTPUT: {
        TITLE: 'Data for POS',
        DOWNLOAD: 'Download Data',
        GENERATE: 'Generate Data',
        LAST: 'Last Generated Report',
        PERIODS: 'Periods',
        ERROR_REPORT: 'The report could not be generated correctly',
      },
      LOGISTIC_REPORT: {
        TITLE: 'Logistics Report',
        DOWNLOAD: 'Download Report',
        GENERATE: 'Generate Report',
        LAST: 'Last Generated Report',
        PERIODS: 'Periods',
        VENDORS: 'Vendors',
        ERROR_REPORT: 'The report could not be generated correctly',
      },
      PROMOTION_REPORT: {
        TITLE: 'Promotion Report',
        DOWNLOAD: 'Generate Report',
        LAST: 'Last Generated Report',
        PERIODS: 'Periods',
        VENDORS: 'Vendors',
        ERROR_REPORT: 'The report could not be generated correctly',
      },
      MARKETPLACE_LIST: {
        EXPORT_TTIP: 'export',
        FILTER_TTIP: 'filter',
        DELETE_TTIP: 'delete',
        DELETE: 'delete',
        DELETE_ROW: 'Delete',
        EDIT_ROW: 'Edit',
        TITLE: 'Marketplace',
        PROMOTION_TYPE: 'Promotion Type',
        YEAR: 'Year',
        CLONE_ALL_PERIODS: 'Clone periods',
        CLONE_CONTENT: 'Clone all periods from the first Promotion Type field to the second for the indicated year',
        CLONE_TITLE: 'Clone periods by promotion type',
        PROMOTION_TYPE_FROM: 'Clone promotional process periods from',
        PROMOTION_TYPE_TO: 'Clone promotional process periods to',
        NEW_PERIOD: 'New',
        EDIT_ROW_DISABLED_TOOLTIP: 'Cannot edit a record in the publishing process',
        SEE_DELETED_FILTERS: 'See deleted',
        CREATION_ON: 'Creation date',
        CLONE: 'Clone Periods',
        COPY: 'Copy Periods',
        NAME: 'Name',
        ROUTE: 'Marketplace',
        FULLSCREEN_TTIP: 'Expand',
        EXITFULLSCREEN_TTIP: 'Reduce',
        STORENAME: 'Store Name',
        CODE: 'Code',
        STATUS: 'Status',
        VIEW_MATERIAL_POP: 'View promotional material',
        DISABLE_STATUS: 'Filter disabled for using filter `Contracts about to expire`',
        STATUS_AVAILABLE: 'Available',
        STATUS_RESERVED: 'Reserved',
        STATUS_RENTED: 'Rented',
        RESERV: 'Reserve',
        RENT: 'Rent',
        POPMATERIAL: 'Promotional Material',
        CADUCATE: 'Contracts about to expire',
        CADUCATE_1MONTH: 'Within 1 Month',
        CADUCATE_2MONTH: 'Within 2 Months',
        CADUCATE_3MONTH: 'Within 3 Months',
        ADVANCED_SEARCH: 'Advanced Filters',
        INTERNAL_USE: 'Internal Use',
        CONTRACT: 'Manage Contract',
        CONTRACT_CONTENT: 'Reserve or Rent {{val}}. Assigning dates and price for the selected vendor',
        VENDOR: 'Vendor',
        PRICE: 'Price',
        STORECOUNT: '# Stores',
        OCUPATION: 'Occupation',
        SPACE: 'Space',
        VALIDFROM: 'Start date',
        VALIDTO: 'End date',
      },
      PERIODS_LIST: {
        EXPORT_TTIP: 'export',
        FILTER_TTIP: 'filter',
        DELETE_TTIP: 'delete',
        DELETE_MODAL_TITLE: 'Do you want to delete the promotional period{{val}}?',
        CANT_DELETE_TTIP: 'The promotional period cannot be deleted because there are promotional actions that use it.',
        DELETE: 'delete',
        DELETE_ROW: 'Delete',
        IS_CANCELING: 'deleting',
        EDIT_ROW: 'Edit',
        TITLE: 'Promotional Periods',
        PROMOTION_TYPE: 'Promotion Type',
        PROMOTION_TYPE_PROCESS: 'Promotional process',
        YEAR: 'Year',
        YEAR_FROM: 'Year From',
        YEAR_TO: 'Year To',
        CLONE_ALL_PERIODS: 'Clone periods',
        CLONE_CONTENT: 'Clone all periods from the first Promotion Type field to the second for the indicated year',
        CLONE_TITLE: 'Clone periods by promotion type',
        PROMOTION_TYPE_FROM: 'Clone promotion type periods from',
        PROMOTION_TYPE_TO: 'Clone promotion type periods to',
        NEW_PERIOD: 'New promotional period',
        EDIT_ROW_DISABLED_TOOLTIP: 'Cannot edit a record in the publishing process',
        SEE_DELETED_FILTERS: 'See deleted',
        CREATION_ON: 'Creation date',
        CLONE: 'Clone Periods',
        COPY: 'Copy Periods',
        ROUTE: 'Promotional Periods',
        FULLSCREEN_TTIP: 'Expand',
        EXITFULLSCREEN_TTIP: 'Reduce',
        NAME: 'Name',
        CODE: 'Code',
        RESPONSIBLEROLEID: 'Responsible',
        OBSERVATIONS: 'Observations',
        CONSULTEDROLEID: 'Consultant',
        STARTINGDAYOFWEEK: 'Starting day',
        DURATIONINDAYS: 'Duration in days',
        ORDER: 'Order',
        VALIDFROM: 'Start date',
        VALIDTO: 'End date',
        EXTRAPLANNINGDAYS: 'Extra planning days',
        ISSPECIALEVENT: 'Special event',
        VALIDATION_CODE: 'The code {{val}} already exists',
        EDIT_ERROR: 'Error editing: {{val}}',
      },
      CALENDAR_LIST: {
        EXPORT_TTIP: 'export',
        FILTER_TTIP: 'filter',
        DELETE_TTIP: 'delete',
        DELETE: 'delete',
        TITLE: 'Calendar',
        MODIFY: 'Modify',
        CHANGE_DATE_TITLE: 'Modify Date',
        CHANGE_DATE_CONTENT: 'Modify the date of this activity',
        EDIT_ROW_DISABLED_TOOLTIP: 'Cannot edit a record in the publishing process',
        ONLY_CURRENT_PERIODS: 'Only active periods.',
        ACTIONS: {
          NAME: 'Description',
          RESPONSIBLEROLEID: 'Responsible',
          CONSULTEDROLEID: 'Consultant',
          OBSERVATIONS: 'Observations',
          STARTTASKXDAYSBEFORE: 'Starting day',
          DURATIONINDAYS: 'Duration in days',
        },
      },
      PERIODS_EDIT: {
        SAVE: 'Save',
        PUBLISH: 'Publish',
        WARNING_SAVE: 'Current changes need to be saved.',
        WARNING_PUBLISH: 'Republishing is necessary for the saved changes to take effect.',
        NOVALID_PUBLISH_ADVICE: 'The following tab is not valid:',
        BASIC_INFORMATION_TAB: 'Basic Information',
        ACTIVATION_TAB: 'Activation',
        LOADING: 'Loading...',
        NEW_ITEM_BTN: 'New Promotional Action',
        PUBLISH_CONFIRM:
          'The publishing process includes the creation of Assortment, Prices, Costs, and other records related to this action.<br><br><b>Are you sure you want to Publish?</b>',
        PUBLISH_CONFIRM_TITLE: 'Publish Confirmation',
        PUBLISHING_WARNING: 'Item in publishing process, please try again in a few minutes.',
        PUBLISH_LONG_TIME: 'Publishing this Product may take a few minutes, when finished you will be notified with a notification.',
        MAIN_DATA: {
          VALID_DATE: 'Validity date',
          ORDER_WARNING:
            'Choosing an order lower than the maximum will recalculate the dates of subsequent periods, excluding special events',
          VALIDFROMEXCEEED: 'Start date exceeds the current year',
          PERIOD_IN_USE: 'The period cannot be edited because it is in use.',
        },
      },
      COVERAGE: {
        WARNING_IS_NEW: 'You need to save changes to check coverage.',
        WARNING_STORE_AND_ITEM_SCOPE: 'When modifying both item and store scopes, you need to save changes to check coverage.',
        COVERAGE_BASED_COMMUNICATION_TITLE: 'Coverage-Based Communication',
        COMPLETE_COVERAGE_TITLE: 'Complete Coverage',
        COMMUNICATION_REGULAR_PRICE_TITLE: 'Communicate regular price',
      },
      REPORTS: {
        AVAILABLE_SPACES: {
          REGION: 'Region',
          AVAILABLE_SLOTS_PERCENTAGE: 'Total % available',
          AVAILABLE_SLOTS: 'Total available quantity',
          SLOTS_TOTAL: 'Total number of slots',
          SLOTS_TOTAL_IN_USE: 'Total number in use',
          SLOTS_PERCENTAGE_IN_USE: 'Total % in use',
          SLOTS_EXCEEDED: 'Slots exceeded',
        },
        TAG_VALUES: 'Download tag report',
      },
    },
    M_SHOPPER: {
      MISSIONS: {
        CHECK_RULE: {
          MODAL_TITLE: 'Do you want to change the selection?',
          MODAL_CONTENT_FIRST_STEP: 'If you confirm, the following steps will copy the competition rule settings',
          MODAL_CONTENT:
            'If confirmed, the affected missions will be replaced by the modifications made based on the first step competition rule.',
          MODAL_BTN_CLOSE: 'Cancel',
          MODAL_BTN_ACCEPT: 'Confirm',
          CHECK: "Don't ask again.",
        },
        TYPE_OPTION: {
          PriceSurvey: 'Price Survey',
          ExhibitionSurvey: 'Exhibition Survey',
        },
        FRECUENCY_OPTION: {
          OneTime: 'One Time',
          Daily: 'Daily',
          Weekly: 'Weekly',
          Biweekly: 'Biweekly',
          Monthly: 'Monthly',
          Biannual: 'Biannual',
          Annual: 'Annual',
        },
        AUDYT_OPTION: {
          Internal: 'Internal',
          External: 'External',
        },
        METHODS_OPTION: {
          Strip: 'Strip',
          Ticket: 'Ticket',
          Catalog: 'Catalog',
          Totem: 'Totem',
        },
        ACTION_OPTION: {
          PriceLabelScan: 'Price Label Scan',
          VoiceCommand: 'Voice Command',
          ManualEntry: 'ManualEntry',
          Indistinct: 'Indistinct',
        },
        CADUCATE: 'expired',
        TODAY: 'Today',
        DAYTOEXPIRE: 'Days to expire',
        ROUTE: 'Mystery Shopper',
        TITLE: 'Missions',
        SUBTITLE:
          'On this screen you will see all the missions created, with their progress percentage, their frequency and whether or not they have competition rules associated with them. These missions will be executed by the shopper through the Prisma Mystery Shopper app, if there are relationships between own stores or those of the competition with the shopper, the mission will be made available to that user, in order to ascertain their own prices or those of the competition.',
        NEW_MISSION_BTN: 'New Mission',
        DAILYREPORT: 'Daily Report',
        FILTER_TTIP: 'Filter',
        FULLSCREEN_TTIP: 'Expand',
        EXITFULLSCREEN_TTIP: 'Reduce',
        EXPORT_TTIP: 'Export',
        IMPORT_TTIP: 'Import',
        PRINT_TTIP: 'Print',
        DELETE_TTIP: 'Delete selected missions',
        DELETE: 'Delete',
        EDIT: 'Edit',
        NAME: 'Name',
        TYPE: 'Type',
        AUDIT_METHOD: 'Audit Method',
        AUDIT_METHOD_TTIP: 'Audit Method',
        START_DATE: 'Start Date',
        START_DATE_TTIP: 'Mission Start Date',
        FINISH_DATE: 'End Date',
        FINISH_DATE_TTIP: 'Mission End Date',
        FRECUENCY: 'Frequency',
        ADVANCED: 'Progress %',
        SURVEYED_STATUS: 'Survey status',
        SURVEYED_STATUS_NOTAVAIBLE_TOOLTIP:
          'The mission is not available to users because they are not assigned or do not have competitors',
        SURVEYED_STATUS_AVAIBLE_TOOLTIP: 'The mission is available to users',
        SURVEYED_STATUS_TAKENBYME_TOOLTIP: 'A user accepted the mission and will be in charge of relieving it',
        SURVEYED_STATUS_COMPLETED_TOOLTIP: 'The mission has already been surveyed, download the daily report to obtain the results',
        SURVEYED_STATUS_NOTSURVEY_TOOLTIP: 'The mission was not surveyed and the effective date has ended',
        SURVEYED_STATUS_NOTAVAIBLE: 'Not Available',
        SURVEYED_STATUS_AVAIBLE: 'Available',
        SURVEYED_TAKENBYME: 'In progress',
        SURVEYED_STATUS_COMPLETED: 'Completed',
        SURVEYED_STATUS_NOTSURVEY: 'Not surveyed',
        DAYS_TO_EXPIRE: 'Days to expire',
        SKUS: '# Products',
        SKUS_TTIP: 'Quantity of products',
        SITES: '# Stores',
        SITES_TTIP: 'Quantity of stores',
        USERS_TTIP: 'Quantity of users',
        USERS: '# Users',
        RULE: 'Rule',
        RULE_TTIP: 'Mission associated with a competition rule',
        YES: 'Yes',
        NO: 'No',
        STATUS: 'Status',
        DATE: 'Date',
        STATUS_ACTIVE: 'Mision Active',
        STATUS_OVERDUE: 'Mision Overdue',
        STORES_FILTER: 'Points of sale',
        DEFINE_MISSION_STP1: 'Setup Mission',
        COMPETITORS_SCOPE_STP2: 'Competitors Scope',
        PRODUCT_SCOPE_STP3: 'Products Scope',
        STORE_SCOPE_STP4: 'Stores Scope',
        RESPONSIBLE_STP5: 'Auditor',
        NEW_TITLE_FORM: 'New Mission',
        EDIT_TITLE_FORM: 'Edit Mission',
        STP1: 'Step 1 - Setup Mission',
        STP1_SUBTITLE: 'In this step you will be able to define the main components of a Mission',
        NAME_INPUT: 'Name',
        TYPE_INPUT: 'Type',
        AUDIT_METHOD_INPUT: 'Audit Method',
        FRECUENCY_INPUT: 'Frequency',
        ACTION_TYPE_INPUT: 'Action Type',
        AUDIT_TYPE_INPUT: 'Audit Type',
        VALIDATE_LOCATION_TOOLTIP: 'The mission must within the allowed range of distance',
        REQUEST_EVIDENCE_TOOLTIP: 'It will be mandatory to load an image of evidence of the surveyed product',
        REQUEST_EVIDENCE: 'require photographic evidence',
        VALIDATE_LOCATION: 'Validate location',
        SURVEYSAMOUNT: '#Competitors',
        SURVEYSAMOUNT_TTIP: 'Competitors Amount',
        STARTS_INPUT: 'Starts on',
        FINISHED_INPUT: 'Ends on',
        VALIDATE_PROMOTION_CHECK: 'Validate Promotion',
        VALIDATE_DATE_START: 'The start date must be less than or equal to the end date',
        VALIDATE_DATE_END: 'The end date must be greater than or equal to the start date',
        VALIDATE_STOCK_CHECK: 'Validate Stock',
        COMPETITIVE_RULE_INPUT: 'Rule',
        COMPETITIVE_RULE_INPUT_IN_USE: 'This rule is applied in a mission',
        NEXT_BTN: 'Next',
        STP2: 'Step 2 - Competitors Scope',
        LEGEND_SKIP_STEP: 'This Step is not necessary if the type of Audit is Internal',
        SKIP_BTN: 'Skip',
        PREVIUS_BTN: 'Previous',
        STP3: 'Step 3 - Products Scope',
        STP3_SUBTITLE:
          'In this step you will be able to define which products and which ones this Mission will not achieve. You can choose them by selection by attribute, or individual selection.',
        STP3_TOGGLE: 'Allow capturing product prices outside the mission',
        STP3_TOGGLE_TOOLTIP:
          'A batch of generic products will be available to identify products found that are not configured in the mission.',
        VIEW_PRODUCTS_BTN: 'View Products',
        REFRESH_PRODUCTS_BTN: 'Refresh Products',
        STP4: 'Step 4 - Stores Scope',
        STP4_SUBTITLE:
          'In this step you will be able to define which stores and which ones this Mission will not reach. You can choose them by selection by attribute, or individual selection.',
        AUTOMATIC_ASIGNATION_CHECK: 'Automatic allocation',
        ADD_RESPONSIBLE_BTN: 'Add Auditor',
        SAVE_BTN: 'Save',
        ADD_RESPONSIBLE_TITLE: 'Add Auditor',
        COMPETITOR_INPUT: 'Competitor',
        SHOPPER_INPUT: 'Shopper',
        ACCEPT_BTN: 'Accept',
        CANCEL_BTN: 'Cancel',
        ADD_BTN: 'Add',
        STRATEGY_ATTR_TITLE: 'Selection by attribute (Dinamic)',
        STRATEGY_ATTR_MSG:
          'Attributes are selected to define the scope of products, if the scope changes, the impact of this setting is done automatically and does not need to be changed',
        STRATEGY_PROD_TITLE: 'Selection by product',
        STRATEGY_PROD_MSG: 'Products should be selected one by one',
        STRATEGY_ATTR_TITLE_STORE: 'Selection by attribute (Dinamic)',
        STRATEGY_ATTR_MSG_STORE:
          'Attributes are selected to define the scope of stores, if the scope changes, the impact of this setting is done automatically and does not need to be changed',
        STRATEGY_STORE_TITLE_STORE: 'Selection by store',
        STRATEGY_STORE_MSG_STORE: 'Stores should be selected one by one',
        VIEW_STORES_BTN: 'View Stores',
        REFRESH_STORES_BTN: 'Refresh Stores',
        STP5: 'Step 5 - Responsible for the Mission',
        LEGEND_RESPONSIBLE_STEP:
          'The assignment of users to the mission will be done automatically based on the relationships loaded in the User Assignment screen. The mission will be available in MS App, to all users related to the points of sale and/or competitors reached, and the first user to take the mission will be the one assigned to it',
        PASTE: 'Paste',
        PASTE_TTIP: 'Add points of sale from a list copied to your clipboard.',
        SEARCH: 'Search',
        CODE_AND_DESCRIPTION_INPUT: 'Code / Description',
        CLIPBOARD_TITLE: 'Paste stores from clipboard',
        CLIPBOARD_STEP1: '1) Define what type of codes you are using',
        TYPE_CODE: 'Type Code',
        INTERNAL_CODE_INPUT: 'Internal Code',
        EXTERNAL_CODE_INPUT: 'External Code',
        NOT_FOUND_CODE_ITEM: 'This product code was not found in Item Master',
        NOT_FOUND_CODE_STORE: 'This point of sale code was not found in the Store Master',
        CLIPBOARD_STEP2:
          '2) Select and copy the codes from a spreadsheet or text. (Ctrl + C) as shown in the image below. They can also be loaded by delimiting each code with the following characters "," ";" "|" "/" "" (tab) (enter) (space) ',
        CLIPBOARD_STEP3: '3) Press here and paste the codes (Ctrl + V)',
        CLIPBOARD_MAX: 'Individual selection supports up to {{val}} products.',
        CLIPBOARD_COUNT: 'Codes have been loaded',
        CLIPBOARD_TITLE_DEFUALT: 'Press here and paste the codes (Ctrl + V)',
        CLIPBOARD_NONE: 'No code has been loaded',
        CLIPBOARD_ACTION: 'Paste',
        PASTE_PRODUCT_TTIP: 'Add products from a list copied to your clipboard.',
        CLIPBOARD_PRODUCT_TITLE: 'Paste products from clipboard',
        CLIPBOARD_STORE_TITLE: 'Paste stores from clipboard',
        FILTER_PROD: 'Filtered Products',
        SELECT_PROD: 'Selected Products',
        ADD_PROD: 'Add Products to the list',
        FILTER_STORE: 'Filtered Stores',
        SELECT_STORE: 'Selected Stores',
        ADD_STORE: 'Add Stores to the list',
        PROVINCE_CHIP: 'Province:',
        COUNTRY_CHIP: 'Country:',
        PRICE_CHIP: 'Price:',
        COST_CHIP: 'Cost:',
        LEGEND_SKIP_STEP4: 'This Step is not necessary since the Competitors Selection option was chosen in the Competitors Scope',
        STP2_COMP_BUTTON_TITLE: 'Select how you want to compare against your competitors.',
        MAINMARKET_TITLE: 'Markers',
        ONLY_MARKERS_TITLE: 'Only select Markers',
        MAINMARKET_MSG: 'It will search the price of the Markers of each Point of Sale.',
        USE_TRADE_AREA_TITLE: 'Trade Area',
        USE_TRADE_AREA_MSG: 'It will search the price of the Competitors associated with the Trade Area of ​​each Point of Sale.',
        SELECT_COMPETITORS_TITLE: 'Selection of Competitors',
        SELECT_COMPETITORS_MSG: 'It will search for competitors who meet the criteria to be defined below',
        HELP: {
          TYPE_INPUT:
            'Price Survey: You will send the shopper to audit the prices of the competition and within your points of sale, to inform you or ensure that your policies are met.',
          TYPE_INPUT2:
            'Exhibition Survey: You will send the shopper to audit how the products are displayed at your points of sale or at competitive points of sale.',
          AUDIT_METHOD_INPUT: 'Strapping: The price that appears in the gondola where the product is located must be audited. ',
          AUDIT_METHOD_INPUT2: 'Ticket: The price that appears on the ticket or invoice will be audited after purchasing the products.',
          AUDIT_METHOD_INPUT3:
            'Catalog: The prices that appear in a catalog, magazine, flyer or any printed product publication will be audited.',
          AUDIT_METHOD_INPUT4: 'Totem: Prices in totem and / or product billboard should be audited.',
          STARTS_INPUT: 'From this date the mission begins.',
          FINISHED_INPUT: 'From this date on, Mystery Shopper APP will not ask shoppers to take over the mission. It will be paused.',
          VALIDATE_PROMOTION_CHECK: 'The selected products must also be audited, whether they are in promotion or not.',
          VALIDATE_STOCK_CHECK: 'It must be confirmed whether or not there was stock of the product available at the time of auditing.',
          FRECUENCY_INPUT: 'Determine how often the audit will be repeated until the completion date.',
          ACTION_TYPE_INPUT:
            'Determine if you need to audit in any specific way, Prisma Mystery Shopper APP will allow the shopper to audit only by using the selected methodology.',
          AUDIT_TYPE_INPUT: 'Internal: It will be audited in your establishments.',
          AUDIT_TYPE_INPUT2: 'External: The establishment of the competition will be audited.',
          COMPETITIVE_RULE_INPUT:
            'When choosing a competition rule, the following steps will be completed by what has already been defined previously in the creation of the rule.',
        },
        CHANGES_REFLECTED_NEXT_SURVEY: 'The changes made will be reflected from the next survey.',
      },
      USER_ASSIGNMENT: {
        TITLE: 'User Assignment',
        STATUS: {
          ASSIGNED: 'Assigned',
          NOTASSIGNED: 'Unassigned',
        },
        CLONATION: {
          TITLE: 'Clone user settings',
          CONTENT: 'The configuration of {{val}} will be assigned to the selected users',
          CLIPBOARD: 'Import Users',
          USER_SELECTED: 'Selected Users',
          USER_FOUND: 'Users Filtered',
          ADD_USERS: 'Add Users',
          PASTE_USERS: 'Paste Users to clipboard',
          USER_NOTFOUND: 'User not found',
          HAS_ASSIGNED:
            "Currently assigned users were found, if you wish to continue they will be overwritten with the assignments of the user you wish to clone from. Otherwise cancel and remove the assigned users you Don't want to overwrite.",
          CLIPBOARD_SELECT_STEP:
            'Select and copy codes from a spreadsheet or text. (Ctrl + C) as shown in the image below. They can also be loaded by delimiting each code with the following characters "," ";" "|" "/" "" (tab) (enter) (space) ',
          CLIPBOARD_PASTE_STEP: 'Click here and paste the codes (Ctrl + V)',
          SUCCESSFULL: 'The cloning was completed successfully',
          FAILED: 'An error has occurred and cloning failed',
        },
        UNASSIGNED: {
          TITLE_ONE: 'Do you want to unassign {{val}}?',
          CONTENT: 'The action unassign the configuration of the own stores to which it belongs and the competitors that it has designated',
          TITLE_MULTIPLE: 'Do you want to unassign the selected users ({{val}})?',
        },
        LIST: {
          NAME: 'Name',
          EMAIL: 'Email',
          CODE: 'Code',
          STORES_COUNT: '# Own Stores',
          STORES: 'Own Stores',
          COMPETITORS_COUNT: '# Competitors',
          STATUS: 'Status',
          UNASSIGNED_ROW: 'Unassign',
          FILTER: 'Filter',
        },
        EDIT: {
          SAVE_WITH_CURRENT_COMPETITORS: 'Save with Current Scope Competitors',
          WARNING_SAVE:
            'Due to the change made in the Scope of Sales Points, there are new Competitors by Trade Area available, if you wish to use them you must go to the Scope of Competitors before saving. Otherwise the currently selected competitors will remain',
          WARNING_COMPETITORS: 'News Competitors by Trade Area',
          STRATEGY_ATTR_TITLE_STORE: 'Selection by attribute (Static)',
          STRATEGY_ATTR_MSG_STORE:
            'Attributes are selected to define the scope of points of sale, if the scope changes, they will not impact this configuration, if you want it to impact you must modify this scope and save it again',
          STRATEGY_STORE_TITLE_STORE: 'Selection by points of sale',
          STRATEGY_STORE_MSG_STORE: 'The points of sale must be explicitly chosen one by one',
          CLIPBOARD_COMPETITORS_TITLE: 'Paste competitors from clipboard',
          STORESCOPE: 'Scope of Sales Points',
          COMPETITORSSCOPE: 'Scope of competitors',
          CHANGE_STORE_SCOPE_TITLE: 'Are you sure you want to change the store scope?',
          CHANGE_STORE_SCOPE_DESCRIPTION:
            'If you cancel it will return to the previous configuration. If you confirm, you will modify the scope of points of sale, only the selected competitors marked as trade area will be overwritten',
          STORE_SCOPE: {
            SUBTITLE:
              'In this step you can define which own stores this user belongs to. You can choose them by attribute selection or individual selection / import them',
            VIEW_STORES_BTN: 'View outlets',
            CODE_AND_DESCRIPTION_INPUT: 'Search by name or code',
            PASTE: 'Import Points of sale',
          },
          COMPETITORS_SCOPE: {
            SUBTITLE:
              'In this step you can define which competitors the user will have available missions to relieve. Competitors will initially be assigned within the trade area of ​​their own stores, with the possibility of removing or adding new competitors',
            SEARCH_COMPETITORS: 'Search by name or code',
            PASTE: 'Import Competitors',
            NOTFOUND: 'Not found',
            SAVE_FIRST: 'You must save the changes to be able to configure the scope of competitors',
          },
        },
      },
    },
    COMP: {
      GENERIC_TABLE: {
        COMPETITORS: {
          COMPETITORS_FOUND: 'Competitors Filtered',
          COMPETITORS_SELECTED: 'Competitors selected',
          ADD_COMPETITORS: 'Add Competitors',
        },
      },
      PRISMA_TABLE: {
        CONFIG_COLUMNS: 'Config columns',
        DELETE_ACTION: {
          DELETE: 'Delete',
          DELETE_TTIP: 'Deactivate the registry',
          UNDELETE: 'Activate',
          UNDELETE_TTIP: 'Activate the registry',
          DELETE_ROW: 'Inactive Record',
          DELETE_ROWS: 'Inactive, Records',
          DELETE_ROW_CONTENT: 'Are you sure you want to inactive {{val}}?',
          DELETE_ROWS_CONTENT: 'Are you sure you want to inactive {{val}}',
          UNDELETE_ROW: 'Activate Record',
          UNDELETE_ROWS: 'Activate Records',
          UNDELETE_ROW_CONTENT: 'Are you sure you want to activate {{val}}? it will be activated with the state new',
          UNDELETE_ROWS_CONTENT: 'Are you sure you want to activate {{val}} Records? they will be activated with the state new',
          ACTION_ROW_CONTENT_SUCCESS: 'Action completed successfully',
          ACTION_ROW_CONTENT_FAILED: 'The action could not be carried out correctly',
        },
        ENTITIES: {
          REPORT: 'Full report here',
          APPLY: 'Due to being in use in the following entities:',
          ITEM_NOT_FOUND: 'Item/s not found',
          USEDINCOMBOS: 'Combos: {{val}}',
          USEDINMISSIONS: 'Missions: {{val}}',
          USEDINPARENTRULES: 'Pricing rules: {{val}}',
          USEDINPLANOGRAMS: 'Planograms: {{val}}',
          USEDINPROMOTIONS: 'Promotions: {{val}}',
          USEDINRECIPES: 'Recipes: {{val}}',
        },
        ACTION_FAIL: {
          DESCRIPTION_ERROR: 'The problem was caused by ',
          TYPE: 'Type: {{val}}',
          KEYWORD: 'Keyword: {{val}}',
          VALUES: 'Values: {{val}}',
          WARNING: 'Alert',
          ERROR: 'Error',
          ENTITYNOTFOUND: 'Entity not found',
        },
      },
      INSIGHT: {
        CONTEXT_MENU: {
          SUBSCRIBED: 'Subscribed',
          UNSUBSCRIBED: 'Unsuscribed',
          SUBSCRIBE: 'Subscribe to all insights',
          UNSUBSCRIBE: 'Subscribe to all insights',
          TYPE: 'of {{val}}',
          CATEGORY: 'of the Category {{val}}',
          STORE: 'of the Store {{val}}',
          STORETAG: 'of the Tag {{val}}',
          CONCATENATE_MESSAGE: '{{val}} {{val2}}',
        },
      },
      MODAL: {
        IMPORT_VENDORS: {
          TITLE: 'Solve supplier conflicts',
          CLOSE: 'Close',
        },
        ADD_MODAL_VERIFY: {
          SELECT_ALL: 'Select All',
          SELECT_OPTIONS_MSG: 'Select Options',
          CURRENT_SELECTION: 'Current Selection',
          VALIDATE_BTN: 'Validate',
          VALIDATION_TITLE: 'Validation',
          PLACEHOLDER: 'Search {{val}}',
          TITLE_EMPTY: 'There is no {{val}} to validate',
          VALIDATION_MESSAGE_OK: 'All vendors were configured correctly for the point of sale.',
          VALIDATION_MESSAGE_WARNING:
            'The selected {{val}} do not cover 100% of the active assortment. No suppliers were found for the following products',
          VALIDATION_MESSAGE_WARNING_FOOTER: 'Select {{val}} until the entire assortment is covered.',
          VALIDATION_TITLE_WARNING: 'Warning',
          CLOSE: 'Close',
          VALIDATION_MESSAGE_ERROR: 'More than one supplier has the same product',
          VALIDATION_TITLE_ERROR: 'Conflicts ',
          SOLVE: 'Solve',
          DOWNLOAD_XLSX: 'Download excel',
          XLSX: 'suppliers.xlsx',
          BTN_TOOLTIP_ERROR: 'Download excel, and fill in the IsPrimary column with the main provider with an X',
          INIT_VENDORS: 'Initialize vendors',
          SURE_TO_SAVE:
            'This process is unique and then it cannot be modified on this screen, make sure that the selected providers are the correct ones',
          CANCEL: 'Cancel',
          SAVE: 'Save',
        },
        COPY_VENDORS_BY_STORE: {
          INTRO: 'Vendors will be cloned according to the selected point of sale.',
          PLACEHOLDER: 'Stores',
          SAVE: 'Save',
          CLOSE: 'Close',
        },
      },
      PICK_COLOR: {
        SELECTION: 'Select',
        CANCEL: 'Cancel',
        CLEAR: 'Clear',
        SELECTION_TITLE: 'Select a color',
      },
      KPIS: {
        DOUBLE_KPI_SUGGESTION: 'SUGGESTIONS ACCEPTED',
      },
      MULTIPLE_GREEN_BUTTON: {
        MODAL_TITLE: 'Do you want to change the selection?',
        MODAL_CONTENT: 'If you confirm, the data related to the selection can be removed from the current load',
        MODAL_BTN_CLOSE: 'Cancel',
        MODAL_BTN_ACCEPT: 'Confirm',
        CHECK: 'Do not ask again.',
      },
      ALERT_AND_CAP: {
        ALERTACOMPETENCIA:
          'The suggested price of ${{val}} is ${{val2}} than the limit set for competitor {{val3}}. Your price should be between ${{val4}} and ${{val5}}',
        ALERTACOMPETENCIA_NO_MIN: null,
        ALERTACOMPETENCIA_NO_MAX: null,
        ALERTACOMPETENCIA_NO_RANGE: null,
        ALERTACOMPETENCIAMISSING: "The competitor limit could not be checked because we didn't find valid market prices for {{val}}",
        ALERTAMARGEN: 'The suggested price of ${{val}} is {{val2}} than the {{val3}} {{val4}} according to the margin limit.',
        ALERTAMARGENMISSING: "The margin limit could not be checked because we didn't find costs for this product at this store.",
        ALERTAVARIACION: 'The suggested price of ${{val}} is {{val2}} than the {{val3}} {{val4}} according to the variation limit.',
        CAPCOMPETENCIA:
          'The suggested price of {{val}} has been changed to {{val2}} because it was {{val3}} than {{val4}} {{val7}} price. Your price should be between {{val5}} and {{val6}}',
        CAPCOMPETENCIAMISSING: "The competitor limit could not be checked because we didn't find valid market prices for {{val}}.",
        CAPMARGE:
          'The suggested price of ${{val}} has been changed to ${{val2}} because it was {{val3}} than the {{val4}} {{val7}} according to the margin limit. Your price should be between {{val5}} and {{val6}}',
        CAPMARGEMISSING: "The margin limit could not be checked because we didn't find costs for this product at this store.",
        CAPVARIACION:
          'The suggested price of ${{val}} has been changed to ${{val2}} because it was {{val3}} than the {{val4}} {{val7}} according to the variation limit. Your price should be between {{val5}} and {{val6}}',
        MINIMOMAXIMO: '{{val}} of ${{val2}}',
        MINIMUM: 'minimum',
        MAXIMUM: 'maximum',
        GREATER: 'greater',
        ABOVE: 'above',
        BELOW: 'below',
        ALERT_MARGEN_LIMIT: 'The suggested price is within {{val}} of the {{val2}} of the required margin limit.',
        ALERT_VARIATION_LIMIT: 'The suggested price is within {{val}} of the {{val2}} of the required variation limit.',
        OF: 'of',
        LOWER: 'lower',
        FOR: 'for {{val}}',
      },
      DEFAULT_TBAR: {
        SEARCH: 'Search...',
        SEARCH_ITEM: 'Search Item...',
        SEARCH_STORE: 'Search Point of Sale...',
      },
      ACTION_TBAR: {
        ACTIONS: 'Actions',
        SELECTED_SINGULAR: 'Selected',
        SELECTED_PLURAL: 'Selected',
      },
      ITEMS_NO_FOUND: {
        NO_RESULTS_FILTERS: 'No results found with the selected filters.',
        NO_RESULTS_FILTERS_MANDATORY: 'No results were found with the selected filters. Please complete the mandatory filters.',
        NO_RESULTS: 'No results found.',
        NO_RESULTS_REPORTS: null,
      },
      ERROR_MESSAGE: {
        TITLE: 'An error has occurred',
        SUBTITLE: 'Contact the support team.',
        ACCEPT_BTN: 'Accept',
        DOWNLOAD_LOG: 'Download error-log',
      },
      DINAMIC_TAGS: {
        ADD_ATTRIBUTE_BTN: '+',
        WITHOUT_SELECTION: 'Without Selection...',
        SEARCH: 'Search ',
        ELEMENTS_NOTFOUND: 'No tags found',
        NO_ELEMENTS_SELECTED: 'No tags were selected',
        SELECTION_ALL: 'Select all',
      },
      FILTER_CHARGE_COMBO: {
        OTHERS: ', Others',
        OTHER: ', Other',
        SELECTED: 'Selected',
        RESULT: 'Results found',
        TOOLTIP: 'Selected Options: ',
      },
      ADD_ATTRIBUTE: {
        TITLE_MODAL: 'New Attribute',
        SELECT_ATTRIBUTE: 'Select Attribute',
        SEARCH_ATTRIBUTE: 'Search Attribute',
        SELECT_ALL: 'Select all',
        CANCEL_BTN: 'Cancel',
        ADD_BTN: 'Add',
        EXCLUDED: 'Excluded',
      },
      ERASURE_CONFIRMATION: {
        TITLE: 'Delete',
        SUBTITLE: 'Do you want to continue with the removal?',
        CANCEL_BTN: 'Cancel',
        DELETE_BTN: 'Delete',
      },
      DRAG_DROP: {
        TITLE: 'Drag and drop the image here or click to search on your PC',
        SUBTITLE: 'Extension not allowed: ',
        SUBTITLE_TTIP: 'Allowed extensions: "jpeg", "jpg", "png", "ico"',
        SUBTITLE_TTIP_EXCEL: 'Allowed extensions: "xls", "xlsx"',
        SUBTITLE_TTIP_ONLY: 'Allowed extensions:',
      },
      OPERATION_SUCCESS: {
        TITLE: 'Successful operation',
        SUBTITLE: 'The operation was completed successfully',
        ACCEPT_BTN: 'Accept',
        TITLE_EMPTY: 'Operation Without Modifications',
        SUBTITLE_EMPTY: 'The operation completed successfully, no changes were found pending to report',
        SUBTITLE_WITHOUT_RESULT: 'Without results',
      },
      IAN_IMPORTER: {
        BTN_IMPORT_SWITCH: 'Finish',
        CLOSE: 'Close',
        UPLOAD_FILES_STP1: 'Upload files',
        VERIFY_FORMAT_STP2: 'Verify Format',
        VALIDATE_INFORMATION_STP3: 'Validate Information',
        UPDATE_TABLES_STP4: 'Update tables',
        UPDATE_TABLES_STP_END: 'Finalize',
        RESTART_TTIP: 'Restart importer to upload new files',
        STP1: 'Step 1 - Upload file',
        DRAG_FILE: 'Drag and drop the file here, or click to search on your PC',
        STP2: 'Step 2 - Verify format',
        CHECKING_FORMAT: 'Verifying the format and extension of the file to upload to the server',
        STP3: 'Step 3 - Validate information',
        VALIDATING_DATA: 'Validating file data for inconsistencies',
        STP4: 'Step 4 - Update tables',
        STP_END: 'Step 2 - Finalize',
        INCORPORATING_INFORMATION: 'Processed information is being added to Prisma',
        CORRECT_FILE: 'The file format and extension are correct',
        WITHOUT_ERRORS: 'The information has been added to Prisma successfully, but with',
        WARNINGS: 'warnings',
        ERRORS: 'The information has not been added to Prisma. The following errors and warnings were found',
        WITHOUT_ERRORS_WARNINGS: 'The information has been added to Prisma successfully, without errors or warnings',
        DOWNLOAD_TEMPLATE: 'Download Template',
        TOTAL_ROWS_REGISTRED: 'Total records loaded',
        TOTAL_ROWS_REGISTRED_WITH_WARNINGS: 'Total records with warnings not loaded',
      },
      GEN_CATEGORYS: {
        CATEGORIES_INPUT: 'Categories',
        CATEGORIES_INPUT_EXC: 'Categories Excluded',
        CATEGORIES_TTIP: 'You must select at least one category, to select the subcategories',
        SEARCH_CATEGORIES: 'Search Categories',
        SUBCATEGORIES_INPUT: 'Subcategories',
        SUBCATEGORIES_INPUT_EXC: 'Subcategories Excluded',
        DEPT_INPUT: 'Deparatament Excluded',
        DEPT_INPUT_EXC: 'Deparatament Excluded',
        SUBCATEGORIES_TTIP: 'You must select at least one subcategory per selected category',
        SEARCH_SUBCATEGORIES: 'Search Subcategories',
        SELECT_ALL: 'Select all',
        SELECT_ALL_SUBCATEGORY: 'Select all Subcategories',
        SEARCH_SUBCATEGORIES_FILTER: 'Search Subcategories',
        SELECT_ALL_CATEGORY: 'Select all Categories',
      },
      GEN_ITEM_LIST: {
        NAME: 'Name',
        CODE: 'Code',
        BRAND: 'Brand',
        MANUFACTURER: 'Manufacturer',
        PRICE: 'Price $',
        COST: 'Cost $',
      },
      GEN_ITEM_STORE: {
        NAME: 'Name',
        CODE: 'Code',
        BRAND: 'Brand',
      },
      GEN_TAGS: {
        FILTER_TAG_VALUES: 'Filter options that are related to the results of the list',
        ADD_ATRIBUTE_BTN: 'Add Attributes',
        NO_TAGS: null,
        NONE: 'None',
        ALL: 'All',
        EXCLUDED: 'excluded',
        SEARCH: 'Search',
      },
    },
    MAT_PAGINATOR: {
      QUANTITY: 'Number of products per page',
      NEXT: 'Next page',
      PREVIUS: 'Previous page',
      FIRST: 'First page',
      LAST: 'Last page',
    },
    SERVICES: {
      CAN_DEACTIVATE: {
        TITLE_MODAL: 'Changes without saving',
        SUBTITLE_MODAL: 'Do you want to exit anyway?',
        GO_OUT_BTN: 'Exit',
        FOLLOW_BTN: 'Continue on the form',
      },
    },
    SPACES: {
      COMMON: {
        SEE_MORE: 'See more',
        STORES_LIST_TITLE: 'Stores',
        STORES_TABLE_NAME: 'Name',
        STORES_TABLE_ACTION: 'Actions',
      },
      LIST: {
        ENTITIES: {
          REPORT: 'Full report here',
          APPLY: 'Due to being in use in the following entities:',
          CONTENT_DELETE_LAYOUT:
            'The action could not be carried out correctly, the planogram is associated with at least one layout, press continue to delete it and remove it from the corresponding layouts',
          ITEM_NOT_FOUND: 'Not enough permissions on all linked stores by requested planograms',
          USEDINLAYAOUTS: 'Used in Layouts: {{val}}',
          IMPEDIMENT: {
            USEDINLAYAOUTS: 'Used in Layouts: {{val}}',
          },
        },
        STATUS: {
          New: 'New',
          PendingEndorsement: 'Pending Approval',
          Current: 'Published',
          Endorsed: 'Approved',
          Deleting: 'Deleting',
          Draft: null,
        },
        TITLE_STRUCTURE: 'Structure',
        BTN_STRUCTURE: 'New Structure',
        TITLE_GENERIC_SPACE: 'Generic Spaces',
        BTN_GENERIC_SPACE: 'New Generic Space',
        TITLE: 'Planograms',
        POPOVER_TEXT:
          'Only planograms whose Points of Sale are available to the user are displayed. If one or more planograms are not displayed, please contact the system administrator, informing them of the missing planograms and requesting verification of the Points of Sale associated with the user.',
        ROUTE: 'Space Planning',
        BTN: 'New Planogram',
        ADMIN: 'Planograms Administration',
        EXPORT_TTIP: 'Export',
        FILTER_TTIP: 'Filter',
        FULLSCREEN_TTIP: 'Expand',
        EXITFULLSCREEN_TTIP: 'Reduce',
        PRINT_TTIP: 'Print',
        DELETE: 'Delete',
        DELETE_TTIP: 'Delete selected planograms',
        RECOVER: 'Recover',
        RECOVER_TTIP: 'Recover',
        CLONE_STRUCTURE: 'Clone structure only',
        CLONE_STRUCTURE_AND_PRODUCTS: 'Clone structure and products',
        SURE_CLONE:
          'Are you sure you want to clone this item? only the planogram structure will be cloned and not the configuration with all its products applied',
        SURE_CLONE_COMPLETE:
          'Are you sure you want to clone this item? the planogram structure and configuration will be cloned with all its products applied',
        EDIT: 'Edit',
        STATUS_NEW: 'New',
        STATUS_EDITED: 'Edited',
        STATUS_ACTUAL: 'Current',
        STATUS_APPROVED: 'Approved',
        STATUS_PENDING_APPROVAL: 'Pending approval',
        STATUS_PEVIEW: 'Preview',
        STATUS_INFORMED: 'Informed',
        STATUS_INFORM: 'Inform',
        REQUEST_APPROVAL: 'Request Approval',
        REQUEST_APPROVAL_SMALL: 'Request Approval',
        APPROVE: 'Approve',
        COLUMN: {
          NAME: 'Name',
          CODE: 'Code',
          TYPE: 'Type',
          LAST_MODIF: 'Last Modif.',
          STATUS: 'Status',
          CAT: '# Categories',
          SUBCAT: '# Subcategories',
          GENERATE: 'Generate',
          CHARGE: 'Capacity (UN)',
          FRONT: 'Length',
          LINEAL_SIZE: 'Linear space',
          LAYOUT: '# Layouts',
          STORES: '# Stores',
          SKU: '# Products',
          SALES: 'Sales month/ Linear space',
          VAR: 'Var. Last Month',
          PLANOGRAMTYPE: 'Type',
          STRUCTUREONLY: 'Structure',
          STRUCTUREONLY_Structure: 'Structure',
          STRUCTUREONLY_Planogram: 'Planogram',
          STRUCTUREONLY_Generic: null,
          FILTER_PREVIEW: null,
          FILTER_TEMPLATE_GENERATE: null,
          FILTER_TEMPLATE_GENERATE_FOR: 'Generate:',
          MASIVE_ACTIONS: 'Masive Actions:',
          request_approval_msg: 'Are you sure you want to request approval for {{val}} records?',
          approve_msg: 'Are you sure you want to approve {{val}} records?',
          inform_msg: 'Are you sure you want to inform {{val}} records?',
          request_approval: 'Request Approval',
          APPROVE_ALERT: 'Approval sent. You will receive a notification when the process is completed.',
          REQUEST_APPROVAL_ALERT: 'Approval request sent. You will receive a notification when the process is completed.',
          INFORM_ALERT: 'Informing planograms. You will receive a notification when the process is completed.',
          INACTIVATE_ALERT: 'Deleting planograms. You will receive a notification when the process is completed.',
          approve: 'Approve',
          inform: 'Inform',
          delete: 'Deactivate',
          NO_PERMISSIONS: 'You do not have permissions.',
          AUTOMATIC: 'Automatic',
          MANUAL: 'Manual',
        },
        SNACKBAR_REPORT: 'The report is being generated',
        SNACKBAR_CLOSE: 'Cerrar',
        PLANOGRAM_TYPES: {
          All: 'All',
          Structure: 'Structures',
          Planogram: 'Planograms',
          GenericSpace: null,
          isGenericSpace: null,
        },
        PLANOGRAM_TYPE: {
          Structure: 'Structure',
          Planogram: 'Planogram',
          GenericSpace: null,
          isGenericSpace: null,
        },
      },
      TABS: {
        TAB1_ICON: 'settings',
        TAB1: 'General',
      },
      GENERAL: {
        TITLE_NEW: 'New Planogram',
        TITLE_NEW_TEMPLATE: 'New Structure',
        TITLE_NEW_GENERICSPACE: null,
        TITLE_EDIT: 'Edit Planogram',
        TITLE_EDIT_TEMPLATE: 'Edit Structure',
        TITLE_EDIT_GENERICSPACE: null,
        SUBTITLE: 'You are creating a new planogram, type a Name and select related stores and categories',
        SAVE_BTN: 'Save',
        NAME_INPUT: 'Name',
        OBSERVATIONS_INPUT: 'Observations',
        ADVANCED_SEARCH: 'Advanced search',
        UPDATE: 'Refresh',
        UPDATE_STRUCTURES: 'You must update the list of structures to see the changes made.',
        CODE_SEARCH: 'Search by codes',
        VALIDATION_CODE: 'The code {{val}} already exists and is in a working or inactive planogram',
        STORES: 'Stores',
        CATEGORIES: 'Categories',
        DELETE_ALL: 'Delete all',
        CONFIRM_DELETE: 'Are you sure you want to delete the store?',
        CONFIRM_DELETE_ALL: 'Are you sure you want to delete all?',
        SUBTITLE_LAYOUT: 'You are creating a new layout, type a Name and select related stores',
      },
      STRUCTURE: {
        HIDE_PANEL: 'Hide Panel',
        OPEN_L_PANEL: 'Open left panel',
        OPEN_R_PANEL: 'Open right panel',
      },
      PRODUCTS: {
        TURN_OFF_INDICATOR: 'Turn off indicator',
        FILTER_TITLE: 'Filters',
      },
      CONFIRMATION: {
        TITLE: 'Attention!',
        SUBTITLE: 'Do you want to add it to the existing or replace it completely?',
        REPLACE_BTN: 'Replace',
        ADD_BTN: 'Add',
      },
      EXTRAS: {
        CATEGORY_TITLE: 'Subcategories',
        CATEGORY_DESC: 'Select the subcategories related to this planogram. You can modify them later on.',
        STORE_TITLE: 'Stores',
        STORE_DESC:
          'Select the Stores related to this planogram individually or massively. You can modify them later on. Use (Ctrl + P) to paste them from the Clipboard.',
      },
      MATERIAL_POP: {
        TITLE: 'POP Material',
        ROUTE: 'Space Planning',
        NEW_BTN: 'New Pop Material',
        FULLSCREEN_TTIP: 'Expand',
        EXITFULLSCREEN_TTIP: 'Reduce',
        NAME: 'Name',
        CODE: null,
        TYPE: 'Type',
        LAST_MODIF: 'Last Modif.',
        OBSERVATION: 'Observation',
        CHARGE_UNITS: 'Capacity (UN)',
        LAYOUTS: '# Layouts',
        STP1_INITIAL_DATA: 'Initial Data',
        STP2_ASSEMBLY: 'Assembly',
        STP1: 'Step 1 - Initial Data',
        BTN_NEXT: 'Next',
        STP2: 'Step 2 - Assembly',
        DISTANCE: 'Distance between Products (cm.)',
        PREVIOUS_BTN: 'Previous',
        SAVE_BTN: 'Save',
        MOVE_UP: 'Move the product up',
        MOVE_DOWN: 'Move the product down',
        DELETE: 'Remove the product',
        SEARCH_PRODUCT: 'Search by product or code',
        TITLE_FORM: 'New Pop Material',
        POP_MATERIAL: 'POP Material / ',
        EMTY_MSG: 'Drag products here',
        VALIDATION_CODE: null,
        PARENT_MATERIAL: 'Parent POP Material',
        LIST: {
          DELETE_ROW: 'Inactive record',
          DELETE_ROWS: 'Inactive records',
          DELETE_ROW_IN_PROCESS: "Inactivation request in progress. We'll notify you once completed.Inactive record",
          CONTENT_DELETE_PLANOGRAM:
            'The action could not be carried out correctly, the Material POP is associated with at least one planogram.',
        },
      },
      EDITOR: {
        IS_PREVIEW: 'Is a Preview',
        PREVIEW: 'Preview',
        SAVE_FOR_PREVIEW: 'Changes must be saved to generate a preview.',
        DELETE: 'Remove preview',
        OPACITY: 'Opacity',
        NEW_STRUCTURE: 'New structure',
        NEW_GENERIC_SPACE: 'New generic space',
        DOWNLOAD_EXCEL: 'Download report',
        LOADING_POG: 'Loading planogram...',
        NEW_POG: 'New planogram',
        SAVE: 'Save',
        VISUALIZATION: 'Display',
        NULL_SPACES: 'Empty space',
        CLEAN_NI: 'Clear empty spaces',
        CLEAN_POG: 'Clear planogram',
        NEW_BLOCK_SPACES: 'Add Area',
        EDIT_BLOCK_SPACES: 'Edit Area',
        DELETE_ALL_BLOCK_SPACES: 'Clear Areas',
        DELETE_ALL_WARNING: 'Delete all Areas',
        DELETE_ALL_MSG: 'This action cannot be undone. Do you wish to continue?',
        TITLE_BLOCK_SPACE: 'Blocked Area',
        NAME_BLOCK_SPACE: 'Name',
        COUNT_SHELFS: 'Shelves selected:',
        WIDTH_BLOCK_SPACE: 'Width (cm):',
        DST_X_BLOCK_SPACE: 'Start distance from furniture (cm):',
        BARTAB_BLOCK_SPACES: 'Blockable Areas',
        MIRROR_POG: 'Mirror planogram',
        CLEAN_MOD: 'Clear module',
        MIRROR_MOD: 'Mirror module',
        CLEAN_SHELF: 'Clear shelf',
        MIRROR_SHELF: 'Mirror shelf',
        AUTOCOMPLETE_SHELF: 'Autocomplete shelf',
        SHELF_AVOID_CHECK_SPACE: null,
        AUTOCOMPLETE_MODULE: 'Autocomplete module',
        TITLE_POG: 'Planogram',
        TITLE_LAYERS: 'Layers',
        TITLE_HEATMAP: 'Heat Maps',
        TITLE_HEATMAP_SING: 'Heat Map',
        TITLE_VARIABLE: 'Variable',
        TITLE_TIMELINE: 'Timeline',
        TITLE_VARIATIONS: 'Variations',
        TITLE_VIS_ATTRS: 'Display attributes',
        TITLE_VIS_ATTRS_SING: 'Display attribute',
        TITLE_VIS_ATTRS_CAT: 'Ranking by Category',
        TITLE_VIS_ATTRS_DEP: 'Ranking by Department',
        TITLE_STORES: 'Stores',
        TITLE_FILTERS: 'Filters',
        TITLE_SEARCH_BTN: 'Add products to the Impulse Strip',
        TITLE_TYPE: 'Type',
        TITLE_SHELF: 'Shelf',
        TITLE_OWN: 'Own',
        TITLE_CAPACITY: 'Capacity',
        TITLE_CAPACITY_LIN: 'Linear capacity',
        TITLE_CAPACITY_LIN_FREE: 'Free linear capacity',
        TITLE_ASSIGNED_TO: 'Assigned to',
        TITLE_PRODS: 'Products',
        TITLE_FACINGS: 'Facings',
        TITLE_LEVELS: 'Levels',
        TITLE_LOAD: 'Capacity',
        TITLE_STOCK_VALUE: 'Stock $',
        TITLE_PERFORMANCE: 'Performance',
        TITLE_ACTIVES: 'All Active',
        TITLE_NO_ACTIVES: 'Not active',
        TITLE_ACTIVE: 'Active',
        TITLE_NO_ACTIVE: 'Active in some Stores',
        TITLE_NO_ACTIVE_ALL_STORES: 'Inactive in all stores',
        TITLE_IS_BLOCKED: 'Available for Sales in some Stores',
        TITLE_NO_BLOCKED: 'Available for Sales',
        TITLE_BLOCKED_ALL_STORES: 'Blocked for Sales',
        TITLE_SEARCH: 'Search',
        TITLE_EXHIBITED: 'Exhibited',
        TITLE_EXHIBITED_SING: 'Exhibited',
        TITLE_NO_EXHIBITED: 'Not exhibited',
        TITLE_NEW_ONLY: 'Only New',
        TITLE_BLOCKEDFORSALES: 'Blocked for Sales',
        TITLE_BLOCKEDFORSALES_NO: 'Available for Sales',
        TITLE_ADVANCED_FILTERS: 'Advanced Filters',
        TITLE_CLEAN_FILTERS: 'Clear Filters',
        TITLE_SMALL_COD: 'Code',
        TITLE_SMALL_UNIT_MED: 'UOM',
        TITLE_EAN: 'EAN',
        TITLE_BRAND: 'Brand',
        TITLE_SUPLIER: 'Supplier',
        TITLE_CAN_STACK: 'Can Stack',
        TITLE_CAN_LAYDOWN: 'Can Tumb',
        TITLE_CAN_ROTATE: 'Can Rotate',
        TITLE_SALES_PART_PER: 'Sales Share',
        TITLE_SALES_PART_LIN_ESP: 'Space Share',
        TITLE_EXH_DAYS: 'Days in Display',
        TITLE_EXH_STOCK: 'Days in Stock',
        TITLE_STOCK: 'Stock $',
        TITLE_STOCK_UN: 'Stock UN',
        TITLE_DATE: 'Date',
        TITLE_USER: 'User',
        TITLE_ANALISYS_TYPE: 'Analysis Type',
        TITLE_LOAD_LIMIT: 'Load limit',
        TITLE_STACK_LIMIT: 'Stack limit',
        TITLE_LIMIT_PLACEHOLDER: 'lim.',
        TITLE_IS_PALLET: 'is Pallet',
        TITLE_IS_PALLET_PLACEHOLDER: 'is Pallet',
        FILTER_POPMATERIALESPECIAL: null,
        FREE_AREA: 'Free Area',
        TOTAL_AREA: 'Total Area',
        TOTAL_PLANOGRAMS: 'Planograms',
        YES: 'Yes',
        NO: 'No',
        TTIP_UNDO: 'Undo last change [ctrl+z]',
        TTIP_REDO: 'Redo last change [ctrl+y]',
        TTIP_DRAG: 'Drag [spacebar+mouse]',
        TTIP_FIT: 'Fit content',
        TTIP_ZOOM_IN: 'Zoom in [+]',
        TTIP_ZOOM_OUT: 'Zoom out [-]',
        TTIP_FULL_SCREEN: 'Maximize screen',
        TTIP_FULL_PRINT: 'Print [ctrl+p]',
        TTIP_OPTIONS: 'Options',
        TTIP_DEL: 'Delete [del]',
        TTIP_CLONE: 'Clone Module',
        TTIP_CLONE_ALT: 'Clone',
        TTIP_COMMENTS: 'Comments',
        TTIP_STACK_WARING: 'This product is not stackable',
        PRINT_NAME: 'Name',
        PRINT_POSITION: 'Position',
        PRINT_NAME_CAT: 'Category Name',
        PRINT_NAME_SUBCAT: 'Subcategory Name',
        PRINT_UNIT_MESURE: 'Unit of Measure',
        PRINT_BRAND: 'Brand',
        PRINT_MANUFACTURE: 'Manufacturer',
        PRINT_WIDTH: 'Width',
        PRINT_HEIGHT: 'Height',
        PRINT_DEPTH: 'Depth',
        PRINT_FACES: 'Faces',
        PRINT_LOAD: null,
        PRINT_LINEAL_SPACE: 'Linear Space',
        PRINT_ROTATION: 'Rotation',
        PRINT_STACK: 'Stacks',
        MSG_SHELF_SHOULD_EMPTY: 'The shelf must be empty.',
        MSG_CONFIRM_SAVE: 'Are you sure you want to save the planogram?',
        MSG_CONFIRM_SAVE_TEMPLATE: 'Are you sure you want to save the structure?',
        MSG_CONFIRM_SAVE_SPACE: 'Are you sure you want to save the generic space?',
        MSG_WIDTH_NI: 'The width of the empty space must be assigned.',
        MSG_CONFIRM_DEL_PRODS: 'Do you want to proceed with removing all products from the planogram?',
        MSG_CONFIRM_DEL_NI_PRODS: 'Do you want to proceed with removing all empty spaces from the planogram?',
        MSG_CONFIRM_MIRROR_PRODS: 'Do you want to continue mirroring the planogram?',
        MSG_CONFIRM_DEL_SHELF_PRODS: 'Do you want to continue removing all products from the shelf?',
        MSG_CONFIRM_MIRROR_SHELF_PRODS: 'Do you want to continue mirroring all products in the shelf?',
        MSG_CONFIRM_DEL_PRODS_MOD: 'Do you want to continue removing all products from the module?',
        MSG_CONFIRM_MIRROR_PRODS_MOD: 'Do you want to continue mirroring all products from the module?',
        MSG_CONFIRM_DEL_PRODS_MOD_PRD:
          'Do you want to continue with the removal of the module? <br> <b> All products will be removed. </b>',
        MSG_CONFIRM_DEL_PRODS_SHELF_PRD: 'Do you want to continue removing the shelf? <br> <b> All products will be removed. </b>',
        MSG_CONFIRM_DEL: 'Delete confirmation.',
        MSG_CONFIRM_MIRROR: 'Mirror confirmation.',
        MSG_MODULE_WIDTH_ALERT: 'There are products in the shelf that do not fit into the new width.',
        MSG_MODULE_DEPTH_ALERT: 'There are products in the shelf that do not fit into the new depth.',
        MSG_PRD_NO_ENOUGH_SPACE: 'Not enough space.',
        MSG_PRD_NO_ENOUGH_SPACE_LIMIT: 'Max load.',
        MSG_PRD_CATN_ROTATE: 'The selected product cannot be rotated.',
        MSG_PRD_CATN_LAYDOWN: 'The selected product cannot be tumbed.',
        MSG_PRD_CATN_ROTATE_LAYDOWN: 'Tumbed products cannot be rotated.',
        MSG_PRD_CATN_ROTATE_ROTATEY: 'Tumbed products cannot be rotated.',
        MSG_PRD_CATN_PALLET: null,
        MSG_SHELF_SHOULD_EMPTY_TYPE: 'Empty the shelf before changing its type.',
        MSG_SHELF_PRD_HEIGTH_WARNING: 'There are products with a height higher than the shelf',
        MSG_SHELF_PRD_DEPTH_WARNING: 'There are products with a greater depth than the shelf.',
        BTN_CANCEL: 'Cancel',
        BTN_DEL: 'Remove',
        BTN_ACEPT: 'Accept',
        BTN_PRINT: 'Print',
        BTN_CLEAN: 'Clear',
        BTN_APPLY: 'Apply',
        BTN_SAVE: 'Save',
        BTN_CLOSE: 'Close',
        ACTION_REORDER_LEFT: 'Reorder Left',
        ACTION_REORDER_RIGHT: 'Reorder Right',
        ACTION_ADD_SHELF: 'Add Shelf',
        ACTION_DEL_PRODS: 'Delete products',
        ACTION_PRD_ADD_FACING: 'Add Facing [ctrl+right]',
        ACTION_PRD_REMOVE_FACING: 'Remove Facing [ctrl+left]',
        ACTION_PRD_STACKSLYINGDOWN: 'Do not tumb',
        ACTION_PRD_NO_STACKSLYINGDOWN: 'Tumb facings',
        ACTION_PRD_ROTATE_CW_NORMAL: 'Rotate right [r]',
        ACTION_PRD_ROTATE_NOCW: 'Rotate left',
        ACTION_PRD_ROTATE_CW: 'Lay right [r]',
        ACTION_PRD_ROTATE_UP_CW: 'Lay up[r]',
        ACTION_PRD_ROTATE_REV: 'Lay left',
        ACTION_PRD_ROTATE_REV_Y: 'Side View',
        ACTION_PRD_ROTATE_REV_Y2: 'Front View',
        ACTION_SHELF_MOVEUP: 'Move up',
        ACTION_SHELF_MOVEDOWN: 'Move down',
        ACTION_SHELF_ADD_UP: 'Add shelf above',
        ACTION_SHELF_ADD_DOWN: 'Add shelf below',
        ACTION_SHELF_DEL_PRODS: 'Delete products',
        ACTION_LAYER_TOGGLE_MODS: 'Hide / Turn on Modules',
        ACTION_LAYER_HIDE_MODS: 'Hide modules',
        ACTION_LAYER_SHOW_MODS: 'Show modules',
        ACTION_LAYER_HIDE_PRODS: 'Hide products',
        ACTION_LAYER_HIDE_IMG: 'Hide images',
        ACTION_LAYER_SHOW_IMG: 'Show images',
        ACTION_LAYER_SHOW_PRODS: 'Show products',
        ACTION_LAYER_HIDE_POP: 'Hide POP material',
        ACTION_LAYER_SHOW_POP: 'Show POP material',
        ACTION_LAYER_HIDE_PITSHELF: 'Show Box Front',
        ACTION_LAYER_SHOW_PITSHELF: 'Show Box 3D',
        ACTION_ADD_CONSTRAINTS: 'Add Constraints',
        SHELF: 'Shelf',
        SHELF_FRONT_VIEW: 'Box Front View',
        SHELF_NORMAL_TYPE_SHELF: 'Shelves',
        SHELF_NORMAL_TYPE_REFRIGERATOR: 'Refrigerators',
        SHELF_NORMAL_TYPE_SIMPLEPEG: 'Peg Shelf',
        SHELF_NORMAL_TYPE_PITSHELF: 'Box / Fridge',
        SHELF_NORMAL_TYPE_PALLET: 'Pallet',
        SHELF_CANT_LEVELS: '# of Levels',
        MOD_TITLE: 'Module',
        BARTAB_GENERAL: 'Main Data',
        BARTAB_MODULES: 'Structure',
        BARTAB_PROMOTION_MATERIAL: null,
        BARTAB_SCOPES: 'Products Scope',
        BARTAB_MODULES_PL: 'Structures',
        BARTAB_PRODS: 'Products',
        BARTAB_OTHERS: 'Others',
        BARTAB_POPMAT: 'POP material',
        BARTAB_ANALISYS: 'Analysis',
        BARTAB_CONSTRAINTS: 'Constraints',
        BARTAB_AUDIT: 'Audit',
        BARTAB_MAX_QUANT: 'Maximum Quantity of Promotions',
        IND_SALES: 'Sales',
        IND_SALES_MAG: 'Sales $',
        IND_SALES_UINIT: 'Unit Sales',
        IND_SALES_UINIT_PER: 'Unit Sales %',
        IND_SALES_PER: '% Sales $',
        IND_MARGIN: 'Margin',
        IND_MARGIN_UNIT: 'Unit Margin',
        IND_MARGIN_MAG: 'Margin $',
        IND_MARGIN_PER: 'Margin %',
        IND_MARGIN_TOT: 'Total Margin',
        IND_ESP_MT_LIN: 'Space %',
        IND_ESP_MT2_LIN: 'Space m2',
        IND_SPACE_SUB: 'Underspaced',
        IND_SPACE_OK: 'Balanced',
        IND_SPACE_HI: 'Overspaced',
        SPACE_WARNING: null,
        IND_SPACE_SUB_SMALL: 'Under',
        IND_SPACE_OK_SMALL: 'Bal.',
        IND_SPACE_HI_SMALL: 'Over',
        IND_SPACE_GMROI: 'GMROI',
        IND_SPACE_GMROS: 'GMROS',
        IND_SPACE_PRICE: 'Price',
        IND_SPACE_NONE: 'None',
        IND_SPACE_DAYS_EXH: 'Days in Display',
        IND_SPACE_DAYS_EXH_FULL: 'Days in Display',
        IND_SALES_PER_PROD: 'Sales per prod.',
        IND_SALES_PER_FACING: 'Sales per facing',
        IND_SALES_MTS_LIN_RATIO: 'Sales $ / space',
        IND_SALES_MTS_LIN_RATIO_USED: 'Sales $ / used space',
        IND_SALES_PRICE_PROM: 'Avg Price $',
        IND_SALES_MARGIN_PROM: 'Avg Margin $',
        IND_SALES_MARGIN_PROM_PER: 'Avg Margin %',
        IND_SALES_MONTH_UNITS: 'Avg. Unit Sales',
        OPEN_IN_ITEM_MASTER: 'Open in Item Master',
        IND_SALES_MONTH: 'Avg. Sales $',
        IND_SALES_UNITS_FACINGS: 'Units / Facings',
        IND_MARGIN_MONTH: 'Avg. Margin',
        IND_SPACE_ASSIGN: 'Space Allocation',
        IND_SPACE_ASSIGN_FULL: 'Space Allocation',
        IND_LOAD: 'Capacity',
        IND_STOCK_DAYS: 'Days in stock',
        IND_STOCK_DAYS_FULL: 'Days in stock',
        IND_ATTRS: 'Attributes',
        IND_NONE: 'None',
        IND_DAYS: 'days',
        IND_MIN_SMALL: 'Min',
        IND_MAX_SMALL: 'Max',
        IND_NORMAL: 'Normal',
        IND_BIGGER_THAN_DAYS: 'Greater than {{val}} days',
        IND_SMALER_THAN_DAYS: 'Less than {{val}} days',
        MAG_DEFAULT: 'cm.',
        MAG_VOL_DEFAULT: 'm³',
        MAG_HEIGHT_WITH_MAG: 'Height (cm.)',
        MAG_HEIGHT_WITH_MAG_ALLMOD: 'Height (cm.)',
        MAG_WIDTH_WITH_MAG: 'Width (cm.)',
        MAG_DEPTH_WITH_MAG: 'Depth (cm.)',
        MARGIN_LEFT: 'Margin Left (cm.)',
        MARGIN_RIGHT: 'Margin Right (cm.)',
        MAG_GUIDES: 'Guides distance (cm.)',
        MAG_HEIGHT: 'Height',
        MAG_WIDTH: 'Width',
        MAG_DEPTH: 'Depth',
        MAG_VOL: 'Vol.',
        PRINT_TITLE_DIALOG: 'Print Planogram',
        PRINT_MODS_SELECTION:
          'Choose the module to be printed, if you leave the range empty, all modules will be printed. (It is possible to use ranges, ex: 2-4)',
        PRINT_MODS_TO_PRINT: 'Modules to print',
        PRINT_PRINT_TYPES: 'Printing types',
        PRINT_PRINT_TYPE_IMAGES: 'Images',
        PRINT_PRINT_TYPE_GROUP: 'Group Codes',
        PRINT_PRINT_TYPE_INDIV: 'Individual Codes',
        PRINT_PRINT_TYPE_ALL_PLANOGRAM_IMAGE: 'Full Planogram',
        PRINT_PRINT_TYPE_POP: 'POP material',
        PRINT_REPORT: 'Report',
        PRINT_WARN_NO_RANGE: 'There are no modules to print within the selected range.',
        AUDIT_STATUS: 'Status',
        AUDIT_LAST_PUBLISH: 'Last. Pub.',
        AUDIT_NO_IMAGE: 'No image',
        AUDIT_IMG_UPLOAD: 'Upload image',
        AUDIT_IMG_VIEW: 'View image',
        AUDIT_TITLE: 'Audit',
        AUDIT_WARNING_NO_SAVED: 'To change the status of the planogram there must be no unsaved changes.',
        AUDIT_AUDIT_IMG: 'Audit image',
        AUDIT_NEW_AUDIT_IMG: 'New audit image',
        AUDIT_NEW_AUDIT_IMG_OK: 'Image saved successfully',
        CONSTRAINTS_TITLE: 'Constraints',
        TLINE_TITLE: 'Timeline',
        TLINE_ACTUAL_TIME: 'Current (Today)',
        TLINE_TOTAL_MARG: 'Total margin',
        TLINE_SALES_UNITS: 'Unit Sales',
        TLINE_LAST_PUB: 'Previous [ctrl+left]',
        TLINE_NEXT_PUB: 'Next [ctrl+right]',
        TLINE_CLOSE_TL: 'Close timeline',
        TLINE_OPEN_TL: 'Open timeline',
        TYPES: {
          'normalShelf-module': 'Shelf',
          'simplePeg-module': 'Peg Shelf',
          'pitShelf-module': 'Pit Shelf',
          'refrigerator-module': 'Refrigerator',
          'normalShelf-level': 'Shelf',
          'simplePeg-level': 'Peg Shelf',
          'pitShelf-level': 'Pit Shelf',
          'float-pop-impulse-strip-simple': 'Impulse strip',
        },
        COMM_TITLE: 'Comments',
        COMM_DATE: 'Date',
        COMM_COMMENT: 'Comment',
        COMM_NEW_COMMENT: 'New comment',
        COMM_READ_MORE: '[Read more]',
        NEW_NORMALSHELF: 'New Shelf',
        NEW_REFRIGERATOR: 'New Refrigerator',
        NEW_SIMPLEPEG: 'New Peg Shelf',
        NEW_PITSHELF: 'New Box / Fridge',
        CONSTRAINS_TYPES: {
          MIN_FACINGS: 'Minimum fronts per product',
          MAX_FACINGS: 'Maximum fronts per product',
          MIN_LINE_SPACE: 'Linear space minimum',
          MAX_LINE_SPACE: 'Maximum linear space',
          MIN_SHARE_SPACE: 'Minimum space share',
          MAX_SHARE_SPACE: 'Maximum share of space',
        },
        CONSTRAINS_NAME_TITLE: 'Name',
        CONSTRAINS_APPLY_TO: 'Apply to:',
        CONSTRAINS_ALL_THE_PLANOGRAM: 'All the planogram',
        CONSTRAINS_ALL_THE_PLANOGRAM_TTIP: 'This rule will apply to the entire planogram',
        CONSTRAINS_ALL_SHELF_TTIP: 'This rule will apply to the entire shelf',
        CONSTRAINS_ALL_SHELF_MODULE_TTIP: 'This rule will apply to the entire module',
        CONSTRAINS_SHELF_SELECTED: 'Shelf',
        CONSTRAINS_SHELF_MODULE: 'Module',
        CONSTRAINS_CONDITION: 'Condition',
        CONSTRAINS_FRONTS_VALUE: 'Fronts',
        CONSTRAINS_LINE_SPACE_VALUE: 'linear space (cm.)',
        CONSTRAINS_SHARE_SPACE_VALUE: '% share of space',
        CONSTRAINS_GROUP_EAN: 'Grouped by ean',
        CONSTRAINS_ALL_ITEMS: 'All items',
        CONSTRAINS_WARNINGS: 'warnings',
        CONSTRAINS_ONLY_THIS: 'View warnings',
        CONSTRAINS_WARNINGS_SIZE: 'Warnings',
        DELETE_DELETE_ITEM: 'Remove item',
        DELETE_DELETE_ALL_EANS: 'Remove all items grouped by EAN',
        CLONE_STRUCTURE: 'Clone structure only',
      },
      SPACES_TEMPLATES: {
        GENERATION_PLANOGRAMS: 'Generation planograms...',
        GENERATION_PLANOGRAMS_ALT: null,
        TITLE: 'Templates',
        TITLE_NEW: null,
        TAB_GENERAL: 'General',
        TAB_BLOCKS: 'Blocks Config',
        TAB_BLOCKS_TT: 'Defines the sorting rules and commercial conditions for the generation of planograms.',
        TAB_PLANOGRAMS: 'Planograms',
        TAB_STRUCTURES: 'Structures',
        TAB_GENERATE: 'Generation',
        DEMO_TITLE_EDIT: 'Template para YOGHURT',
        DEMO_TITLE_CODE_EDIT: 'YOG1',
        PREVIEW: 'Preview',
        CLOSE_PREVIEW: 'Close Preview',
        NEW_DIVISION: 'New Division',
        DIVISION: 'Division',
        LEVELS: 'Levels',
        LEVEL_BLOCK: 'Block Level',
        TABLE: {
          NAME: 'Name',
          FRONT: 'Width',
          HEIGHT: 'Height',
          SHELVES: 'Shelves',
          STORES: 'Stores',
          STORES_VIEW: 'View Stores',
          STRUCTURE_VIEW: 'View Structure',
          DELETE: 'Delete',
        },
        LIST: {
          TITLE: 'Template list',
          PLANOGRAMS: 'Planograms',
          STRUCTURES: 'Structures',
          GenericSpace: null,
          DIVISIONS: 'Divisions',
          NO_DIVISIONS: 'Add divisions to start defining your blocks.',
          NO_BLOCKS: 'Currently, there are no products available for the selected configuration.',
          TAG_SCOPE: 'Tags Hit',
          CATEGORY_SCOPE: 'Categories Reached',
          EXCEED_TOLERANCE: 'Excess tolerated',
          MIN_LINEAR_SPACE: 'Minimum linear space',
          MAX_LINEAR_SPACE: 'Maximum linear space',
          LINEAR_SPACE_CALC_TYPE: 'Linear Calculation Type',
          LEVELS: 'Levels',
          CODE: 'Code',
          NAME: 'Name',
          EDIT_ROW: 'Edit',
          COPY_ROW: 'Clone',
          DELETE_ROW: 'Delete',
          ITEM_TAGS: 'Product Tags',
          NEW_ITEM: 'New Template',
          DELETE: 'Delete',
          DELETE_TTIP: 'Delete a template',
          FILTER: 'Advanced filter',
          FILTER_TTIP: 'Enable or disable Advanced Filter',
          EXPORT: 'Export',
          EXPORT_TTIP: 'Export a template',
          DELETE_ROW_IN_PROCESS: "Inactivation request in progress. We'll notify you once completed.Inactive record",
          DELETE_ROWS: 'Inactive records',
        },
        PLANOGRAMS: {
          TITLE: 'Associated Structures',
          INTRO: 'Assign the structures to link them with the template',
          PLANOGRAMS: 'Planograms',
          STRUCTURES: 'Structures',
        },
        LINEAR_SPACES: {
          SALES: 'Sales $',
          UNITS: 'Sales Units',
          MARGIN: 'Margin $',
        },
        MAIN_TAB: {
          TITLE: 'General Data',
          CODE: 'Code',
          CODE_EXIST: 'Code already exist',
          NAME: 'Name',
          LEVELS: 'Levels',
          LINEAR_SPACE_CALC: 'Fronts calculation based on',
          MIN_LINEAR_SPACE: 'Minimum of linear space',
          MAX_LINEAR_SPACE: 'Maximum linear space',
          LEVELS_PER_PRODUCT: 'Levels by product',
          FILL_SPACES: 'Optimize space',
          FACINGS_LIMIT: 'Use item limits',
          FILL_SPACES_MSG:
            'By enabling this option, empty spaces in the planograms will be automatically filled, maximizing the use of available space and avoiding loss of business opportunities',
          FACINGS_LIMIT_MSG:
            'By enabling this option, the configured minimum and maximum limits for each SKU will be applied, ensuring visibility and preventing excess stock',
          EXCEED_BLOCK_TOLERANCE: 'Tolerance to Exceed the width of the block',
          SCOPE_ITEMS: 'Product Scope',
          SCOPE_ITEMS_COUNT: 'Selected products',
        },
        GENERATE: {
          TITLE: 'Planogram Generation',
          INTRO:
            'Select the criteria to efficiently generate your planograms. Optimize the presentation of your products at points of sale according to your specific needs.',
          BTN1_TITLE: 'Generation by Structure',
          BTN1_MSG: 'Develop a planogram for each structure, using the average data from all stores with that structure.',
          BTN2_TITLE: 'Generation by Point of Sale',
          BTN2_MSG: 'Create a specific planogram for each store, using detailed data from each point of sale.',
          BTN3_TITLE: 'Generation by Cluster',
          BTN3_MSG: 'Generate planograms for clusters of stores that share similar behaviors, averaging the data from each group.',
          TEXT1:
            'A planogram will be generated for each structure associated with the Template; the performance of each product will be the average of the points of sale associated with the structure.',
          TEXT2:
            "A planogram will be generated for each Point of Sale associated with the Template's Structures; the performance of each product will correspond to each Point of Sale. Important: If one or more Points of Sale are present in more than one structure, then a planogram will be generated for each Structure-Point of Sale pair.",
          TEXT3:
            'A planogram will be generated for each Cluster selected; the performance of each product will be the average within each Cluster. Important: If there are multiple structures, and these are associated with Points of Sale in the same Cluster, then planograms will be generated for each Cluster-Structure pair.',
          INTRO2: 'This process may take several minutes',
          PLANOGRAM: 'Generate Planogram',
          PREVIEW: 'Generate Preview',
          MODAL_TITLE_PREVIEW: 'Generate Preview',
          MODAL_INTRO_PREVIEW: 'Select the point of sale to generate the preview',
          MODAL_TITLE_GENERATE: 'Generate Planograms',
          MODAL_INTRO_GENERATE: 'Select the associated Stores to generate the related planograms',
          SELECT_PLANOGRAM: 'Planograms',
          SELECT_STORE: 'Stores',
          SELECT_STRUCTURE: 'Structures',
          SELECT_CLUSTER: null,
          ACCEPT: 'Accept',
          CANCEL: 'Cancel',
          ALERT: 'Save changes for generate planograms',
          WARNING: 'You must save the changes to generate planograms with the new modifications of the structures tab',
          WARNING_ALT: null,
          WARNING_ALT_PDV: 'You must save the changes to view the points of sale.',
          CREATEVERSIONFOR: null,
          PREVIEW_MODE: null,
          CREATEVERSIONFOR_OPTIONS: {
            PerStructure: null,
            PerStore: null,
            PerClusterStructure: null,
          },
          NAME_COLUMN: 'Name',
          PLANOGRAM_EXIST_TITLE: 'Existing Planograms',
          PLANOGRAM_EXIST_SUBTITLE:
            'Are you sure you want to continue with the generation of planograms? This process will overwrite the items in the following list:',
          ENTER:
            'The current content of the planograms for the points of sale associated with this master will be replaced by the current generation.',
        },
        BLOCKS: {
          SELECT_PARENT: 'Select Parent Block',
          SELECT_VARIABLE: 'Select Variable',
          SELECT_VARIABLE_OPTS: {
            Department: 'by Departament',
            Category: 'by Category',
            SubCategory: 'by Subcategory',
            Tag: 'by Label',
            Price: 'by Price',
            Size: 'by Size',
            category: 'Category',
            subcategory: 'Subcategory',
            tag: 'Label',
            price: 'Price',
            size: 'Size',
          },
          SELECT_ORIENTATION_OPTS_NEW: {
            Vertical: 'Vertical',
            Horizontal: 'Horizontal',
          },
          SELECT_CALCULATIONTYPE: {
            Sales: 'Sales',
            Units: 'Units',
            Margin: 'Margin',
            Price: 'Price',
            Size: 'Size',
            Custom: 'Custom',
          },
          SELECT_ORDERTYPE: {
            Descending: 'Descending',
            Ascending: 'Ascending',
            Custom: 'Custom',
          },
          COLOR: 'Color',
          SELECT_DIVISIONTYPE_ROOT: 'Division type',
          SELECT_DIVISIONTYPE: 'Block Division Calculation',
          SELECT_DIVISIONTYPE_ITEM: 'Item Division Calculation',
          SELECT_ORDER_TYPE: 'Item Order',
          SELECT_ORDER_TYPE_ITEMS: 'Item Order',
          SELECT_ORDER_TYPE_BLOCKS: 'Block Order',
          SELECT_DIVISIONTYPE_OPTS: {
            Horizontal: 'Horizontal',
            Vertical: 'Vertical',
          },
          MAX_DIVISIONS: 'Maximum Divisions',
          MAX_DIVISIONS_ALT: 'Maximum',
          MIN_DIVISIONS_ALT: 'Minimum',
          DIVISION_INDEX: 'Division No.',
          DIVISION_WIDTH_ALT: 'Width (%)',
          ALLOCATION_CRITERIA: 'Criteria for Space Allocation',
          ALLOCATION_CRITERIA_OPTS: {
            '0': 'Prop. to sales $',
            '1': 'Sale Un.',
            '2': 'Margin $',
          },
          ORDER: 'Order',
          ORDER_OPTS: {
            '0': 'Ascending',
            '1': 'Descending',
            '2': 'Custom',
          },
          ADD_DIVITION: 'Add Division',
          PRODUCTS: 'Products',
          UNITS: 'Units',
          MARGIN: 'Margin',
          SALES: 'Sales',
          ADD_DIVITION_EXCEPTION: 'Add custom order',
          ADD_DIVITION_EXCEPTION_SIZE: 'Add custom size',
          DIVISION_WIDTH: 'Size (%)',
          DIVISION: 'Division',
          DIVISIONS: 'Divisions',
          DEMO_BRAND: 'Brand',
          DEMO_FLAVOR: 'Flavor',
          DEMO_SIZE: 'Size',
          DEMO_PRESENTATION: 'Presentation',
          SELECT_ORIENTATION: 'Orientation',
          SELECT_ORIENTATION_OPTS: {
            horizontal: 'Horizontal',
            vertical: 'Vertical',
          },
          VALIDATIONS: {
            blockCustomSize_invalidWidth: null,
            blockCustomSize_invalidWidth_max: null,
            blockCustomSize_invalidtagValueOrCategoryId: null,
            blockCustomOrder_invalidtagValueOrCategoryId: null,
            blockCustomOrder_invalidOrder: null,
            blockCustomSize_invalidtagValueOrCategoryId_dup: null,
            blockCustomOrder_invalidtagValueOrCategoryId_dup: null,
            blockCustomDivisionType_invalidHorizontal: 'Invalid split type at top level',
            divisionVariable_duplicate: 'Duplicated variable',
            tagId_duplicate: 'Duplicated tag',
          },
        },
      },
      FLOOR_PLANS: {
        LIST: {
          NEW_ITEM: 'Create Layout',
          TITLE: 'List of Layouts',
          NAME: 'Name',
          CODE: 'Code',
          SIZE_PLANOGRAMS: 'Nr Planograms',
          SIZE_LEVELS: 'Levels',
          EDIT_ROW: 'Edit',
          COPY_ROW: 'Clone',
          DELETE_ROW: 'Delete',
          ITEM_TAGS: 'Product Tags',
          DELETE: 'Delete',
          DELETE_TTIP: 'Delete a template',
          FILTER: 'Advanced filter',
          FILTER_TTIP: 'Turn Advanced Filter On or Off',
          EXPORT: 'Export',
          EXPORT_TTIP: 'Export a template',
          DELETE_CONFIRM: 'Do you want to continue with the deletion of the Layout? ',
        },
        EDITOR: {
          TAB_GENERAL: 'General',
          TAB_LAYOUTS: 'Layouts',
          TAB_ANALISYS: 'Analysis',
          TITLE_EDIT: 'Layouts',
          TAB_LEVELS: 'Levels',
          LEVEL: 'Level',
          LEVEL_LABEL_NAME: 'Level',
          TITLE_NEW: 'New Layout',
          SUBTITLE: 'You are creating a new Layout, select the Name, the stores or categories',
          BACK_OPACITY: 'Background Opacity',
          ROTATION: 'Angle of Rotation',
          BACK_BW: 'Monochrome Image',
          DELETE_ALL_MODULES: 'Delete Modules',
          OPEN_PLANOGRAM: 'Open Planogram',
          MSG_CONFIRM_DEL_MODULES: 'Do you want to continue deleting all planograms from the layouts',
          ADD_LEVEL: '+ Add Level',
          EDIT_LEVEL: 'Edit Level',
          NEW_LEVEL: 'New Level',
          WIDTH: 'Width (m.)',
          DEPTH: 'Depth (m.)',
          TOTALSPACE: 'Total area (m².)',
          SELLINGAREASPACE: 'Sales area (m².)',
          BLUEPRINT_PHOTO: 'Plane Reference Image',
          DELETE_LEVEL: 'Delete Level',
          MOVELEVELTOP: 'Move up (reorder)',
          MOVELEVELBOTTOM: 'Move Bottom (Reorder)',
          MSG_CONFIRM_DEL_LEVEL: 'Do you want to continue deleting level {{val}}?',
          NAME: 'Name',
          CODE: 'Code',
          CATEGORY: 'Category',
          NO_LEVELS: 'No levels',
          PLANOGRAMS: 'Planograms',
        },
      },
    },
    TAGS_MODAL: {
      TITLE: 'Advanced Selection',
      DESC: 'Apply filters to your search in order to get the desired result. This option will deselect by filters.',
      FIRST_TABLE_BTN: 'Apply',
      FIRST_TABLE_HEADER: 'Filters',
      SECOND_TABLE_BTN: 'To Select',
      THIRD_TABLE_HEADER: 'Current Selection',
      THIRD_TABLE_EMPTY: 'Select Options',
      CANCEL: 'Cancel',
      APPLY: 'Apply',
    },
    REPORTS: {
      GENERAL_TITLE: null,
      PREFIX_TITLE: null,
      TYPE_FILTERS: null,
      DOWNLOAD: 'Generate and download Report',
      GENERATE: null,
      FORMAT: null,
      GENERATE_FORMAT: null,
      GO_TO_REPORT_LIST: null,
      OPEN_REPORT_LIST: null,
      ASYNC_START_MSG: null,
      FILTERS_TITLE: null,
      TABLE: {
        filename: null,
        lastModified: null,
        reportType: null,
        username: null,
      },
      REPORT_TYPE_KEYS: {
        COMMENTARIO_DOCU: null,
      },
      LABEL_KEYS: {
        INCLUDEISDELETED: null,
        SEARCH: null,
        PRICE: null,
        PERCENT: null,
        DATE: null,
        DATEFROM: null,
        DATETO: null,
        FROMTODATES: null,
        ITEMTAGVALUES: null,
        STORETAGVALUES: null,
        CATEGORYVALUES: null,
      },
    },
    SELLING: {
      PRICING: {
        TITLE: 'Pricing',
        SUBTITLE: 'Get the right price with AI.',
        SEE_MORE_BTN: 'See More',
        FEATURE1_TITLE: 'Achieve your desired competitive positioning',
        FEATURE1_SUBTITLE:
          'Location algorithms identify your competitors in each price zone and help you adjust your prices to reach your positioning.',
        FEATURE2_TITLE: 'Analyze the impact of your decisions',
        FEATURE2_SUBTITLE: 'Prisma estimates an impact in margin and price image that will help your decision-making process.',
        CTA_TITLE: 'Find out how we can help you!',
        CTA_BTN: 'Lets talk',
      },
      CTA_MODAL: {
        TITLE: 'Contact us for more information',
        NAME_INPUT: 'Name',
        COMPANY_INPUT: 'Company',
        PHONE_INPUT: 'Telephone',
        SEND_BTN: 'Send',
      },
      ASSORTMENT: {
        TITLE: 'Assortment Planning',
        SUBTITLE: 'OPTIMIZE YOUR ASSORTMENT AND MAXIMIZE YOUR PROFITS.',
        SEE_MORE_BTN: 'See More',
        FEATURE1_TITLE: 'Cataloging',
        FEATURE1_SUBTITLE:
          'Assign products to your stores: *Segment your products. *Group your stores into clusters and relate your product segmentation in each cluster. ',
        FEATURE2_TITLE: 'Assortment Optimization',
        FEATURE2_SUBTITLE:
          'Pareto Rules with AI: Your business rules can work together with AI in order to exhibit in your stores the products that your customers want. Add products that perform better in the market or in other stores. Easily remove products that are not performing as expected.',
        CTA_TITLE: 'Find out how we can help you!',
        CTA_BTN: 'Lets talk',
      },
      PROMOTIONS: {
        TITLE: 'Promotions',
        SUBTITLE: 'BOOST YOUR PROMOTIONAL INTELLIGENCE.',
        SEE_MORE_BTN: 'See More',
        FEATURE1_TITLE: 'Design amazing promotions',
        FEATURE1_SUBTITLE: 'Delight your customers by implementing different promotional mechanics.',
        FEATURE2_TITLE: 'Measure the results in detail',
        FEATURE2_SUBTITLE:
          'Take into account the cannibalization effect, the pre and post-promotional sales and the marketing investment to calculate the incremental profitability of each promotion.',
        CTA_TITLE: 'Find out how we can help you!',
        CTA_BTN: 'Lets talk',
      },
      ITEM_MASTER: {
        TITLE: 'Item Master',
        SUBTITLE: 'SIMPLIFY YOUR PRODUCT MANAGEMENT PROCESS WITH AI.',
        SEE_MORE_BTN: 'See More',
        FEATURE1_TITLE: 'Product Matching with AI',
        FEATURE1_SUBTITLE: 'Find matching products in order to compare prices and assortment and detect market trends.',
        FEATURE2_TITLE: 'Category Tree continuous improvement',
        FEATURE2_SUBTITLE: 'Increase precision and speed in the process of classifying products.',
        CTA_TITLE: 'Find out how we can help you!',
        CTA_BTN: 'Lets talk',
      },
      SPACES: {
        TITLE: 'Space Planning',
        SUBTITLE: 'LOCATE YOUR PRODUCTS IN A COST-EFFECTIVE, OPTIMIZED AND VISUALLY APPEALING WAY FOR THE SHOPPER.',
        SEE_MORE_BTN: 'See More',
        FEATURE1_TITLE: 'Smart Autocomplete',
        FEATURE1_SUBTITLE:
          'Use the autocomplete feature setting the exhibition days target and Prisma will suggest facings to minimize out of stocks.',
        FEATURE2_TITLE: 'Use mobile elements and different types of shelves',
        FEATURE2_SUBTITLE:
          'Add products such as hooks, drawers or hangers on planogram shelves. Freezers for frozen or dairy products, free baskets and tables, to be able to locate your promotions.',
        CTA_TITLE: 'Find out how we can help you!',
        CTA_BTN: 'Lets talk',
      },
      SUPPLY: {
        TITLE: 'Smart Supply',
        SUBTITLE: 'INTEGRATE YOUR DATA INTO THE SUPPLY CHAIN',
        SEE_MORE_BTN: 'See More',
        FEATURE1_TITLE: 'Reduce stock-outs and Inventory Days',
        FEATURE1_SUBTITLE:
          'Estimate the behavior of each SKU in each of the stores to calculate a suggested distribution. Using information from sales, prices and promotions to adjust the suggestion.',
        FEATURE2_TITLE: 'Design your Supply Chain',
        FEATURE2_SUBTITLE:
          'Define warehouses per store and design an omnichannel supply network where suppliers and warehouses are involved in each part of the Supply Chain.',
        CTA_TITLE: 'Find out how we can help you!',
        CTA_BTN: 'Lets talk',
      },
      PROMOTIONSV2: {
        TITLE: 'Promotions',
        SUBTITLE: 'Boost your promotional intelligence.',
        SEE_MORE_BTN: 'See More',
        FEATURE1_TITLE: 'Design amazing promotions',
        FEATURE1_SUBTITLE: 'Surprise your customers by implementing a variety of promotional dynamics.',
        FEATURE2_TITLE: 'Measure the results in details',
        FEATURE2_SUBTITLE:
          'It takes into account the cancellation effect, pre and post promotion sales and investment in marketing to measure the incremental profitability per promotion.',
        CTA_TITLE: 'Know how we can help you!',
        CTA_BTN: 'Lets talk',
        LIST: {
          EXPORT_TTIP: 'export',
          FILTER_TTIP: 'filter',
          DELETE_TTIP: 'delete',
          DELETE: 'delete',
          TITLE: 'List of actions',
          NEW_ACTION: 'new action',
        },
      },
    },
    GLOBAL_WARNINGS: {
      MODAL_WARNING_TITLE: 'Publish Warning - Confirmation',
      MODAL_WARNING_TITLE_ONLY_ALERT: 'Publish Warning',
      MODAL_FILE_REPORT_TITLE: 'Download Report',
      TEST: 'Inconsistent data was found in the publication process.\nWould you like to publish anyway?',
      ITEM_UNCOVERED_COST:
        'The item does not resolve the costs for all the points of sale selected by assortment.\nWould you like to publish anyway?',
      STORE_UNCOVERED_COST:
        'The point of sale does not resolve the costs for all the items selected by assortment.\nWould you like to publish anyway?',
      ASSORTMENT_UNCOVERED_COST:
        'Some points of sale and items do not cover the costs according to this assortment decision, you can download the report to see the detail.\nWould you like to publish anyway?',
      CLUSTER_UNCOVERED_COST_ONLYALERT:
        'Changes made to clustering affect cost coverage by provider. Please review the coverage report or reverse your last action.',
    },
    INTERFACE_LOG_MSG_KEYS: {
      PromotionNotFound: 'Promotion not found',
      ItemNotFound: 'Item not found',
      ItemDoesNotExists: 'Item does not exist',
      UomNotFound: 'Unit of Measure not found',
      NoDataProcessed: 'No data processed',
      HeaderIsInvalid: 'Invalid header',
      ItemCodeMustNotBeNull: 'The Item Code cannot be "null"',
      ItemCostDoesNotBeNull: 'The Item Cost cannot be "null"',
      ItemCostGretherThanZero: 'Item Cost must be greater than 0',
      DiscountGretherThanZero: 'Item Discount must be greater than 0',
      Cost2GretherThanZero: 'Item Cost 2 must be greater than 0',
      Cost3GretherThanZero: 'Item Cost 3 must be greater than 0',
      TaxGretherThanZero: 'Item Tax must be greater than 0',
      Tax2GretherThanZero: 'Item Tax 2 must be greater than 0',
      Tax3GretherThanZero: 'Item Tax 3 must be greater than 0',
      StoreCodeMustNotBeNull: 'The Store Code cannot be null',
      StoreDoesNotExists: 'The Store does not exist',
      TagValueDoesNotExists: 'The tag value does not exist',
      VendorDoesNotExists: 'The Vendor does not exist',
      ItemCannotBeSupply: 'Item cannot be of type Supply',
      ItemCannotBeIngredient: 'Item cannot be of type Ingredient',
      ItemCannotBeNew: 'Item cannot be in a new state',
      ItemBaseOnComponents: 'Cannot import combo prices with prices based on their components',
      ItemPriceGretherThanZero: 'Price must be greater than zero',
      ItemPriceStoreAndSegmentationSameTime: 'Point of sale and segmentation cannot be informed at the same time',
      ItemAndSegmentationSameTime: 'Item and segmentation cannot be informed at the same time',
      ValidFromGreaterThanTodayAndLessThen: 'The date must be greater than today and at most 30 days in the future',
      DiscountGretherThanOrEqualZero: 'Item Discount must be greater than or equal 0',
      DiscountLowerThanToItemCost: 'Item Discount must be lower than to item cost',
      keyword: 'Message',
      type: 'Type of message',
      values: 'Values',
      'GatewayTime-out': 'Gateway Time-Out',
      NULL: 'Unspecified error',
      ItemNameMustNotBeNull: 'Item Name must not be null',
      SalesTaxRateGreaterThanOrEqualToZero: 'Sales Tax Rate must be greater or equal to zero',
      CategoryCodeMustNotBeNull: 'Category Code must not be null',
      CategoryDoesNotExists: 'Category Code does not exists',
      CompetitorCodeMustNotBeNull: 'Competitor code must not be null',
      WrongAssortmentBehavior: 'Incorrect dispensing behavior',
      StoreAssortmentGeneratedByPromotion: 'Assortment per store generated by promotion',
      StoreAssortmentNotFound: 'The assortment by store was not found',
      ItemCodeTypeNotFound: 'ItemCodeType not found',
      DuplicateItemCode: 'Duplicate item code found',
      ItemCostGretherThanRegulated: 'The cost of an item exceeds the regulated cost',
      InvalidUnits: 'The units of measures are not correctly completed',
      InvalidDate: 'Invalid date',
      MonthlySaleAlreadyExist: 'The sales record already exists',
      TagsRequiredNotCompleted: 'You must complete the required labels.',
      InvalidVendors: 'You must complete vendors.',
      WrongItemCode: 'You must complete mandatory codes.',
      InvalidCombo: 'Please check the components tab.',
      InvalidRecipe: 'Please check the ingredients tab.',
      ItemCannotPublish: 'The item cannot be published automatically.',
      ItemCostGreaterThanRegulated: 'The cost of an item exceeds the regulated cost.',
      DiscountGreaterThanOrEqualZero: 'Item Discount must be greater than or equal 0',
      ItemPriceGreaterThanZero: 'Price must be greater than zero',
      ItemCostGreaterThanZero: 'Item Cost must be greater than 0',
      DiscountGreaterThanZero: 'Item Discount must be greater than 0',
      Cost2GreaterThanZero: 'Item Cost 2 must be greater than 0',
      Cost3GreaterThanZero: 'Item Cost 3 must be greater than 0',
      TaxGreaterThanZero: 'The Item Tax must be greater than 0.',
      Tax2GreaterThanZero: 'Item Tax 2 must be greater than 0.',
      Tax3GreaterThanZero: 'Item Tax 3 must be greater than 0.',
      ValidFromGreaterThanToday: 'The date must be greater than today',
      DuplicateRecord: 'Duplicate record',
      CostCodeDoesNotExists: 'Cost code does not exist',
      TaxCodeDoesNotExists: 'Tax code does not exist',
      VendorItemDoesNotExist: 'The relationship item and vendor does not exist',
      VendorCodeMustNotBeNull: 'The Vendor Code cannot be "null"',
      TaxGreaterThanOrEqualZero: 'Item Tax must be greater than or equal to 0',
      Tax2GreaterThanOrEqualZero: 'Item Tax 2 must be greater than or equal to 0',
      Tax3GreaterThanOrEqualZero: 'Item Tax 3 must be greater than or equal to 0',
      ItemCostGreatherThanRegulated: 'The cost of an item exceeds the regulated cost',
      InvalidSalesUnits: 'Sales units of measures are not correctly populated',
      ItemHasNotSellingUnit: 'The item does not have a sales unit of measure',
      InvalidCompetitorPriceSource: 'Invalid competitor price source',
      CodeMustNotEmpty: 'Code must not be empty',
      NameMustNotEmpty: 'Name must not be empty',
      ItemIsDeleted: 'Item is deleted',
      StoreCostValueGreaterThanZero: 'StoreCost value must be greater than zero',
      WrongStoreCode: 'Wrong store code',
      InvalidSaleUnits: 'Invalid sale units',
      ValueShouldBeGreaterThanZero: 'Value should be greater than zero',
      MalFormedFieldTagValues: 'Malformed field in Tagvalues',
      TagNotFound: 'Tag not found',
      TaskFailedAfterRetries: 'Task failed after retries',
      UnitsLowerOrEqualToZero: 'Units lower or equal to zero',
      DateTimeGreaterThanTodayAndLessThen: 'Datetime greater than today',
      DailySaleAlreadyExist: 'DailySales already exists',
      InvalidVendorSegmentation: 'Invalid vendor segmentation',
      DocumentTypeDoesNotExists: 'Document type does not exist',
      DailyInventoryAlreadyExist: 'Daily inventory already exists',
      InvalidMonth: 'Invalid month',
      DuplicateTagValueForOneOrMoreStores: 'Duplicate tag value for one or more stores',
      SalesTotalCannotBeEmpty: 'Sales total cannot be empty',
      NetSalesCannotBeEmpty: 'Net sales cannot be empty',
      SalesTotalCannotBeEmptyOrEqualToZero: 'Sales total cannot be empty or equal to zero',
      NetSalesCannotBeEmptyOrEqualToZero: 'Net sales cannot be empty or equal to zero',
      ComboCodeDoesNotExists: 'Combo code does not exists',
      TagValueWrongType: 'Tag value has an incorrect data type',
      QuantityGreaterThanOrEqualOne: 'Quantity is greater or equal to one',
      QuantityMustBeGreaterThanOrEqualOne: 'Quantity must be greater or equal to one',
    },
    CUSTOM_ERRORS: {
      '1': 'Test Custom Error Trad EN val: {{val}}',
      BaseUnitOfMeasureNotFound: 'No base unit of measure was found for this product. 1 will be used as the conversion.',
      UnitOfMeasureNotFound: 'Unit of measure not found',
      CalculationTypeCostNotFound: '{{val}} not found for Store {{val2}} to calculate the cost or tax of product {{val3}} ',
      MissingInitialPriceMethod:
        'A Pricing Initialization Method was not found. Make sure you have an initial price for each selling unit of measure and that you have selected one initialization method in the Prices Tab.',
      MissingSellingUnitOfMeasure: 'You must create at least one selling unit of measure for this type of product',
      NotGeneratedPriceMissingSellingUom: 'Prices were not generated. Selling unit of measure missing',
      CannotDeleteRegistryInUse: 'The registry is already in use',
      NoCodeSpecified: 'Item not found',
      ItemNotFound: 'Item not found: {{val}}',
      ItemIsPublishingCannotUpdate: "Cannot update this item, It's publishing.",
      CodeAlreadyExists: 'The code already exists {{val}}.',
      CLONED_PROCESS_FAILURE_TITLE: 'The cloning process failed',
      CLONED_PROCESS_FAILURE_MSG: 'Cloning was not performed or was partially performed without assortment.',
      InvalidCodeTypes: "The code type doesn't exists {{val}}.",
      ParamCannotBeNull: "The param {{val}} can't be null.",
      ParentCannotBeUse:
        'The selected parent cannot be used for the rule because it was already used as a child in another relationship rule',
      AtLeastOneChildWasFound: 'At least one child product was found that was already used as a child in another rule.',
      NoNeedToRepublish: 'This item does not need to be republished.',
      ItemHasNotStoreAssortment:
        'The publication was not carried out. Assortment exception is active and not charged correctly, at least one product must be charged to the importer, it be active and not blocked for sale',
      StoreIsPublishingCannotUpdate: 'This point of sale cannot be edited because it is being published.',
      UNAUTHORIZED_ACCESS: 'You do not have sufficient permissions to perform this action.',
      INVALID_STORE_SCOPE: 'No stores found for the selected product scope.',
      INVALID_MANUAL_STORE_SCOPES: 'The following selected stores are not valid for the chosen scope of products',
      AnyRoleInUse: 'It is not possible to delete Roles that are in use.',
      NoRecordSelected: 'There are no items selected to delete.',
      EntityCreated: 'Entity created successfully',
      EntityUpdated: 'Entity updated successfully',
      CodeCompetitorCannotBeNull: 'The competitor code cannot be empty.',
      CompetitorBadGeoData: 'The latitude or longitude data is incorrect.',
      BrandCompetitorCannotBeNull: 'The flag cannot be empty.',
      NameCompetitorCannotBeNull: "The competitor's name cannot be empty.",
      FileIsTooBig: 'The file size is too large.',
    },
  },
};
