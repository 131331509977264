import { _log } from '@shared/aux_helper_environment';
import { PlanogramsPopMaterial } from './generic-promotional-spaces.component';

export const __getplanogramPopMaterialIdDummy = () => {
  _log('<__getplanogramPopMaterialIdDummy>');
  return __planogramPopMaterialIdDummy;
};

let __planogramPopMaterialIdDummy: PlanogramsPopMaterial[] = [
  {
    id: 0,
    planogramId: 0,
    planogramTitle: 'Hielera',
    planogramPriority: null,
    popMaterialId: 0,
    popMaterialName: 'Frontal IZQ',
    popMaterialCode: null,
    itemScope: null,
    storeIds: null,
    fullName: 'Hielera > Frontal IZQ',
    groupKey: '555-556',
    selectedGroupIds: [555, 556],
    selectedGroup: [
      {
        id: 555,
        planogramId: 1,
        planogramTitle: 'Hielera',
        planogramPriority: 1,
        popMaterialId: 120,
        popMaterialName: 'Frontal IZQ',
        popMaterialCode: 'PLN_LOC_0013',
        itemScope: {
          categoryIdsToInclude: [],
          categoryIdsToExclude: [],
          tagValueIdsToInclude: [],
          tagValueIdsToExclude: [],
          itemIds: [],
          itemIdsToExclude: [],
          onlyItemsAllowedForSelling: true,
        },
        storeIds: [10574, 4942, 4416],
        fullName: 'Hielera > Frontal IZQ',
        groupKey: '',
        selectedGroupIds: [],
        selectedGroup: null,
      },
      {
        id: 556,
        planogramId: 1,
        planogramTitle: 'Hielera',
        planogramPriority: 1,
        popMaterialId: 121,
        popMaterialName: 'Frontal IZQ',
        popMaterialCode: 'PLN_LOC_0013',
        itemScope: {
          categoryIdsToInclude: [],
          categoryIdsToExclude: [],
          tagValueIdsToInclude: [],
          tagValueIdsToExclude: [],
          itemIds: [],
          itemIdsToExclude: [],
          onlyItemsAllowedForSelling: true,
        },
        storeIds: [9287, 2116, 20203],
        fullName: 'Hielera > Frontal IZQ',
        groupKey: '',
        selectedGroupIds: [],
        selectedGroup: null,
      },
    ],
  },
  {
    id: 1,
    planogramId: 0,
    planogramTitle: 'Hielera 2',
    planogramPriority: null,
    popMaterialId: 0,
    popMaterialName: 'Frontal DER',
    popMaterialCode: null,
    itemScope: null,
    storeIds: null,
    fullName: 'Hielera 2 > Frontal DER',
    groupKey: '450',
    selectedGroupIds: [450],
    selectedGroup: [
      {
        id: 450,
        planogramId: 5,
        planogramTitle: 'Hielera 2',
        planogramPriority: 1,
        popMaterialId: 127,
        popMaterialName: 'Frontal DER',
        popMaterialCode: 'PLN_LOC_0013',
        itemScope: {
          categoryIdsToInclude: [],
          categoryIdsToExclude: [],
          tagValueIdsToInclude: [],
          tagValueIdsToExclude: [],
          itemIds: [],
          itemIdsToExclude: [],
          onlyItemsAllowedForSelling: true,
        },
        storeIds: [4545, 47653],
        fullName: 'Hielera 2 > Frontal DER',
        groupKey: '',
        selectedGroupIds: [],
        selectedGroup: null,
      },
    ],
  },
];
