import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '@prisma/components/generic-confirmation-modal/generic-confirmation-modal.component';
import { _error, _log } from '@shared/aux_helper_environment';
import { _cloneDeep, _diffObjectFromAToB, _equal } from '@shared/aux_helper_functions';
import { DevSettingsService } from 'core/services/dev-settings.service';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { _getBrandConfigLS, _setBrandConfigLS } from 'core/services/ian-prepareBrandCustomizationV2';
import { EditJsonModal } from './edit-json-modal.component';
import { HttpClient } from '@angular/common/http';

const JSON5 = require('json5');

const _isLocalhost = () => {
  return Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
};

@Component({
  selector: 'global-dev-settings-brand-customization',
  template: `
    <ng-container>
      <mat-selection-list [multiple]="false">
        <mat-list-option (click)="logFinal($event)">Show App Version</mat-list-option>
        <mat-list-option (click)="logDefault($event)">Show Defaults</mat-list-option>
        <mat-list-option (click)="logRaw($event)">Show Raw OverWrite</mat-list-option>
        <mat-divider class="my-6"></mat-divider>
        <mat-list-option (click)="viewBrandCustomizationDiffDefault()">Show Diff Overwrites</mat-list-option>
        <mat-divider class="my-6"></mat-divider>
        <mat-list-option (click)="editBrandCustomizationLS()" *ngIf="true">Edit Local Storage OverWrite</mat-list-option>
        <mat-list-option (click)="resetBrandCustomization()" *ngIf="hasBrandConfigLs">Reset Local Storage OverWrite</mat-list-option>
        <mat-divider class="my-6"></mat-divider>
        <mat-list-option (click)="resetRemoteAwsConfigCache()" *ngIf="true">Reset Aws BrandConfig Cache</mat-list-option>
        <mat-list-option (click)="resetRemoteAwsFFCache()" *ngIf="true">Reset Aws FeatureFlags Cache</mat-list-option>
      </mat-selection-list>
    </ng-container>
  `,
  styles: [
    `
      :host ::ng-deep .mat-list-item-content {
        padding: 0px !important;
      }

      :host ::ng-deep .mat-list-base {
        padding-top: 0px !important;
      }

      :host ::ng-deep .mat-list-option {
        background: transparent !important;
      }
    `,
  ],
})
export class GlobalSettingsBrandCustomizationComponent implements OnInit {
  lang = null as any;
  hasBrandConfigLs = false;

  constructor(
    private translate: IanTranslateService,
    private devSettingsService: DevSettingsService,
    public dialog: MatDialog,
    private http: HttpClient
  ) {
    this.setLangs();
  }

  async ngOnInit() {
    this.hasBrandConfigLs = _getBrandConfigLS() != null;
  }

  setLangs() {
    if (this.lang != null) return;

    this.lang = {};
  }

  clipboardWriteText(text) {
    (navigator as any).clipboard?.writeText?.(text);
  }

  async promptConfirmation() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '550px',
      data: {
        message: 'Para cambiar este seteo hace falta reiniciar la aplicación',
        title: 'Aviso',
        labelConfirm: 'Reiniciar',
        labelCancel: 'Cancelar',
        disableClose: true,
        timeToConfirm: 720,
      },
    });

    return dialogRef.afterClosed().toPromise();
  }

  test() {
    _log('TEST');
  }

  logFinal(ev?) {
    let rv = (window as any)?._info?._prismaDynamicEnvData?.brandCustomization;
    if (false) this.clipboardWriteText(JSON.stringify(rv, null, 2));
    this.viewJson(rv, 'brandCustomization App Version');
    console.log(['brandCustomization App Version'], rv);
  }

  logRaw(ev?) {
    let rv = _cloneDeep((window as any)?._info?._prismaDynamicEnvData?._brandCustomizationRaw);

    if (rv?.ConfigOverwrites != null && typeof rv?.ConfigOverwrites === 'string' && rv?.ConfigOverwrites.length > 0) {
      try {
        rv.ConfigOverwrites = JSON5.parse(rv.ConfigOverwrites);
      } catch (e) {
        rv.ConfigOverwrites = null;
      }
    }

    if (false) this.clipboardWriteText(JSON.stringify(rv, null, 2));
    this.viewJson(rv, 'brandCustomization Raw OverWrite');
    console.log(['brandCustomization Raw OverWrite'], rv);
  }

  logLocalStorage(ev?) {
    let rv = (window as any)?._info?._prismaDynamicEnvData?._brandCustomizationLocalStorage;
    if (false) this.clipboardWriteText(JSON.stringify(rv, null, 2));
    this.viewJson(rv, 'brandCustomization Local Storage');
    console.log(['brandCustomization Local Storage'], rv);
  }

  logDefault(ev?) {
    let rv = (window as any)?._info?._prismaDynamicEnvData?._brandCustomizationDefaults;
    if (false) this.clipboardWriteText(JSON.stringify(rv, null, 2));
    this.viewJson(rv, 'brandCustomization Defaults');
    console.log(['brandCustomization Defaults'], rv);
  }

  async resetBrandCustomization() {
    let confirm = await this.promptConfirmation();
    if (!confirm) return;
    _setBrandConfigLS(null);
    (window as any)?.location?.reload?.();
  }

  resetRemoteAwsConfigCache() {
    let host = window.location.origin;
    if (_isLocalhost()) host = 'https://lab.prismaretail.dev';

    const url = `${host}/api/support/reset-brandconfig`;

    this.http.post(url, {}).subscribe(
      response => {
        _log('reset-brandconfig successful', response);
        this.devSettingsService.restartApp(true);
      },
      error => {
        _error('reset-brandconfig failed', error);
      }
    );
  }

  resetRemoteAwsFFCache() {
    let host = window.location.origin;
    if (_isLocalhost()) host = 'https://lab.prismaretail.dev';

    const url = `${host}/api/support/reset-featureflags`;

    this.http.post(url, {}).subscribe(
      response => {
        _log('reset-featureflags successful', response);
        this.devSettingsService.restartApp(true);
      },
      error => {
        _error('reset-featureflags failed', error);
      }
    );
  }

  viewBrandCustomizationDiffDefault() {
    let _default = (window as any)?._info?._prismaDynamicEnvData?._brandCustomizationDefaultsBase;
    let _local = _cloneDeep((window as any)?._info?._prismaDynamicEnvData?._brandCustomizationRaw?.ConfigOverwrites || null);

    if (_local != null && typeof _local === 'string' && _local.length > 0) {
      try {
        _local = JSON5.parse(_local);
      } catch (e) {
        _local = null;
      }
    }

    let rv = _diffObjectFromAToB(_local, _default);

    if (false) this.clipboardWriteText(JSON.stringify(rv, null, 2));
    this.viewJson(rv, 'Show Diff Overwrites');
    console.log(['Show Diff Overwrites'], rv);
  }

  async viewJson($json, title, readOnly = true, description = null) {
    let json = $json;

    if (json != null && typeof json === 'string' && json.length > 0) {
      try {
        json = JSON5.parse(json);
      } catch (e) {
        json = null;
      }
    }

    const dialogRef = this.dialog.open(EditJsonModal, {
      width: '850px',
      data: {
        title: title ?? 'Obj',
        json: json,
        readOnly: readOnly,
        description: description,
      },
    });
  }

  async editBrandCustomizationLS() {
    const dialogRef = this.dialog.open(EditJsonModal, {
      width: '850px',
      data: {
        title: 'Edit Local Storage OverWrite',
        json: this.devSettingsService.__getBrandConfigLS(),
        readOnly: false,
        description: `ej:
        {
            "brandLogoAsset": "./assets/brands/oxxo.png"
        }
        `,
      },
    });

    let rv = await dialogRef.afterClosed().toPromise();

    if (rv !== false && !_equal(rv, this.devSettingsService.__getBrandConfigLS())) {
      let confirm = await this.promptConfirmation();
      if (!confirm) return;

      this.devSettingsService.__setBrandConfigLS(rv || '', true);
    }
  }
}
