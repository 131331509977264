<mat-toolbar class="p-0 mat-elevation-z1" data-test-id="prisma-header-bar" id="prisma-header-bar">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="isAuthenticated"
        (click)="toggleSidebarOpen('navbar')"
        data-test-id="toggle-sidebar-panel"
        fxHide.gt-sm
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/Prisma_NewLogo_2024.svg" />
        </div>
      </div>
    </div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <!-- // INSIGHTS -->
      <ng-container *ngIf="isAuthenticated && false">
        <div *ngIf="getEnabledBlockVal('HEADER_TOOLBAR_INSIGHTS')" class="toolbar-separator"></div>
        <button
          *ngIf="getEnabledBlockVal('HEADER_TOOLBAR_INSIGHTS')"
          mat-icon-button
          class="quick-panel-toggle-button toggle-insights-panel"
          (click)="toggleInsights('insightsPanel')"
          data-test-id="toggle-insights-panel"
        >
          <mat-icon>lightbulb</mat-icon>
        </button>
      </ng-container>

      <!-- // NOTIFICATIONS -->
      <span class="{{!isAuthenticated || !getEnabledBlockVal('HEADER_TOOLBAR_NOTIFICATIONS') ? '_hiddenHard' : ''}}">
        <div class="toolbar-separator"></div>
      </span>
      <span class="{{!isAuthenticated || !getEnabledBlockVal('HEADER_TOOLBAR_NOTIFICATIONS') ? '_hiddenHard' : ''}}">
        <button
          mat-icon-button
          class="quick-panel-toggle-button"
          (click)="toggleNotifications('notificationsPanel')"
          aria-label="Toggle notifications panel"
          data-test-id="toggle-notifications-panel"
          [disabled]="!isAuthenticated"
        >
          <ng-container *ngIf="(notificationsSize$ | async) > 0">
            <div class="{{ (isPair(notificationsSize$ | async) ? 'badgeApairA' : 'badgeApairB') + ' badgeMark'}}">
              <mat-icon matBadge="{{(notificationsSize$ | async)}}" matBadgeColor="warn" matBadgePosition="above after">
                notifications
              </mat-icon>
            </div>
          </ng-container>
          <ng-container *ngIf="!((notificationsSize$ | async) > 0)">
            <div class="badgeMark">
              <mat-icon>notifications</mat-icon>
            </div>
          </ng-container>
        </button>
      </span>

      <!--  Configurable id:1LVI0Obirf  -->

      <!-- // RIGHT MENU -->
      <ng-container *ngIf="isAssistantEnabled()">
        <span class="{{!isAuthenticated || !getEnabledBlockVal('HEADER_TOOLBAR_RIGHTLIST') ? '_hiddenHard' : ''}}">
          <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
        </span>
        <span class="{{!isAuthenticated || !getEnabledBlockVal('HEADER_TOOLBAR_RIGHTLIST') ? '_hiddenHard' : ''}}">
          <button
            mat-icon-button
            class="quick-panel-toggle-button"
            (click)="toggleSidebarOpen('quickPanel')"
            aria-label="Toggle quick panel"
            [disabled]="!isAuthenticated"
            data-test-id="toggle-quick-panel"
          >
            <mat-icon class="secondary-text_">mic_none</mat-icon>
          </button>
        </span>
      </ng-container>

      <!-- // RIGHT MENU quickPanelAi - CORE-40 -->
      <ng-container *ngIf="isAssistantEnabledAi()">
        <span class="{{!isAuthenticated ? '_hiddenHard' : ''}}">
          <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
        </span>
        <span class="{{!isAuthenticated ? '_hiddenHard' : ''}}">
          <button
            mat-icon-button
            class="quick-panel-toggle-button"
            (click)="toggleSidebarOpen('quickPanelAi')"
            aria-label="Toggle quick panel ai"
            [disabled]="!isAuthenticated"
            data-test-id="toggle-quick-panel"
          >
            <ai-icon [svgStyle]="'width: 28px; height: 28px; padding-top: 1px'"></ai-icon>
          </button>
        </span>
      </ng-container>

      <!-- //Logo/Brand Cliente  -->
      <ng-container *ngIf="pathBrandImage != null">
        <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
        <div [style]="brandLogoCss">
          <img
            [src]="pathBrandImage"
            style="height: 64px; min-width: fit-content"
            [style.visibility]="!pathBrandImage ? 'hidden' : 'visible'"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="true">
        <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
        <button mat-button class="user-button" (click)="login()" *ngIf="!isAuthenticated" data-test-id="login-btn">
          <div fxLayout="row" fxLayoutAlign="center center">
            <span class="username pr-12 pl-12">LogIn</span>
          </div>
        </button>

        <button
          mat-button
          [matMenuTriggerFor]="userMenu"
          class="user-button"
          *ngIf="isAuthenticated"
          data-test-id="user-dropdown-btn"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/profile.jpg" />
            <span class="username mr-12" fxHide fxShow.gt-sm style="text-align: left">
              {{userData.name}}
              <span style="font-size: 11px; opacity: 0.6; line-height: 1; top: -2px; display: block">
                ({{userData.role}})
              </span>
            </span>
            <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
          </div>
        </button>

        <mat-menu #userMenu="matMenu" overlapTrigger="false">
          <button mat-menu-item *ngIf="viewProfile" matRipple (click)="profile()" data-test-id="exit-btn">
            <mat-icon>person</mat-icon>
            <span *ngIf="getCurrentLang() === 'es'">Ver Perfil</span>
            <span *ngIf="getCurrentLang() === 'en'">View Profile</span>
            <span *ngIf="getCurrentLang() === 'pt'">Ver Perfil</span>
          </button>
          <button mat-menu-item matRipple (click)="logout()" data-test-id="exit-btn">
            <mat-icon>exit_to_app</mat-icon>
            <span *ngIf="getCurrentLang() === 'es'">Salir</span>
            <span *ngIf="getCurrentLang() === 'en'">Exit</span>
            <span *ngIf="getCurrentLang() === 'pt'">Sair</span>
          </button>
        </mat-menu>
      </ng-container>

      <!-- HELPERS -->
      <ng-container *ngIf="isAuthenticated && false">
        <div class="toolbar-separator"></div>
        <button
          mat-icon-button
          class="quick-panel-toggle-button"
          [matMenuTriggerFor]="menu"
          aria-label="Toggle notifications panel"
        >
          <mat-icon>help_outline</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <app-help-main-content></app-help-main-content>
        </mat-menu>
      </ng-container>

      <!-- LANGUAJES -->
      <ng-container *ngIf="canShowChangeLang()">
        <div class="toolbar-separator"></div>
        <button
          mat-icon-button
          class="quick-panel-toggle-button"
          [matMenuTriggerFor]="menu"
          aria-label="Toggle language"
          data-test-id="toggle-language"
          style="margin: 0 10px"
          [attr.data-test-current-lang]="getCurrentLang()"
        >
          <mat-icon>language</mat-icon>
          &nbsp;
          <span *ngIf="getCurrentLang() === 'es'" data-test-id="toggle-language-es">ESP</span>
          <span *ngIf="getCurrentLang() === 'en'" data-test-id="toggle-language-en">ENG</span>
          <span *ngIf="getCurrentLang() === 'pt'" data-test-id="toggle-language-en">POR</span>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="setCurrentLang('es')" [disabled]="getCurrentLang() === 'es'">
            <span class="label">Español</span>
          </button>
          <button mat-menu-item (click)="setCurrentLang('en')" [disabled]="getCurrentLang() === 'en'">
            <span class="label">English</span>
          </button>
          <button
            mat-menu-item
            (click)="setCurrentLang('pt')"
            [disabled]="getCurrentLang() === 'pt'"
            *ngIf="canShowPortuguesLang()"
          >
            <span class="label">Português</span>
          </button>
        </mat-menu>
      </ng-container>

      <!-- //GOLBAL-SETTINGS -->
      <ng-container *ngIf="isAuthenticated && _useUXV2">
        <div *ngIf="true" class="toolbar-separator"></div>
        <button
          *ngIf="true"
          mat-icon-button
          class="quick-panel-toggle-button"
          (click)="toggleGlobalSettings()"
          data-test-id="toggle-global-settings-panel"
        >
          <mat-icon>settings</mat-icon>
        </button>
      </ng-container>

      <!-- FULL SCREEN -->
      <ng-container>
        <div class="toolbar-separator"></div>
        <button
          mat-icon-button
          class="fuse-search-bar-expander quick-panel-toggle-button"
          aria-label="Fullscreen"
          matRipple
          (click)="toggleFullScreen()"
          data-test-id="toggle-full-screen"
        >
          <mat-icon class="s-24 secondary-text_" *ngIf="!isFullScreen()">fullscreen</mat-icon>
          <mat-icon class="s-24 secondary-text_" *ngIf="isFullScreen()">fullscreen_exit</mat-icon>
        </button>
      </ng-container>

      <!-- //DES-5109 -->
      <ng-container *ngIf="isAuthenticated && enableTthirdPartyPlatforms">
        <div class="toolbar-separator"></div>
        <a id="CUSTOM_INTERCOM_CHAT_BUTTON" class="quick-panel-toggle-button custom-intercom-chat-button">
          <div class="custom-intercom-chat-button-wrapper" [matTooltip]="'Ayuda en línea'">
            <mat-icon>live_help</mat-icon>
          </div>
        </a>
      </ng-container>

      <div class="toolbar-separator"></div>

      <!-- SIDEBAR -->
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        data-test-id="toggle-full-sidebar"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
