import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormModel_scopeItems_FE, FormModel_scopeStores_FE } from '@prisma/containers/model/scope.models';
import { PrismaDynamicEnv } from 'core/services/ian-core-singleton.service';
import { PromotionalSpacesService } from './generic-promotional-spaces.service';
import { _log } from '@shared/aux_helper_environment';

export interface PlanogramsPopMaterial {
  id: number;
  _internalId?: string;
  planogramId: number;
  planogramTitle: string;
  planogramPriority: number | null;
  popMaterialId: number;
  popMaterialName: string;
  popMaterialCode: string | null;
  itemScope: any;
  storeIds: number[];
  fullName: string;
  selectedGroupIds: number[];
  groupKey: string;
  selectedGroup: PlanogramsPopMaterial[];
}

@Component({
  selector: 'app-generic-promotional-spaces',
  templateUrl: './generic-promotional-spaces.component.html',
  styleUrls: ['./generic-promotional-spaces.component.scss'],
})
export class GenericPromotionalSpacesComponent implements OnInit {
  @Input('actionpromotionTypeId') actionPromotionTypeId: number = null; // id del proceso promocional
  @Input('id') promotionalActionId: number; // id de la accion promocional
  @Input('planogramPopMaterialIds') planogramPopMaterialIds: number[] = []; // items selected
  @Input('disabled') disabled = false;
  @Input('required') required = true;
  @Input('showPlanogramsWarning') showPlanogramsWarning = false;
  @Input('planogramWarningMessage') planogramWarningMessage = '';
  @Input('isEdit') isEdit = false;
  @Output() onChange: EventEmitter<any[]> = new EventEmitter();
  @Output() planogramPopMaterialError: EventEmitter<boolean> = new EventEmitter();
  @Output() planogramPopMaterialListEvent: EventEmitter<any> = new EventEmitter();
  @Input() storeScope?: FormModel_scopeStores_FE;
  @Input() itemScope?: FormModel_scopeItems_FE;
  @Input() storeScopeValid = false;
  @Input() itemScopeValid = false;
  @Input() storeScopeDirty = false;
  @Input() itemScopeDirty = false;
  @Input() showFullModalWidth = false;
  @Input() isAllScopeItemsSelected: boolean;
  @Input() getCalculatedPop = false;
  @Input() showOnlySelected = false;

  isLoading = false;
  promotionOldVersion = this.prismaDynamicEnv.getConf('brandCustomization.promotions.promotionOldVersion');
  promotionsNewVersion = !this.promotionOldVersion;
  planogramPopMaterialListError = false;
  planogramPopMaterialList = null;
  deletedPlanogramsOnChange = [];
  includedPlanogramsOnChange = [];
  selectedItemsInteralIds: string[] = [];

  constructor(protected prismaDynamicEnv: PrismaDynamicEnv, private service: PromotionalSpacesService) {}

  ngOnInit() {
    this.getInitialData();
  }

  getInitialData() {
    if (this.storeScope && this.itemScope && ((this.storeScopeValid && this.itemScopeValid) || this.isEdit)) {
      this.getCalculatedPopMaterial();
      return;
    } else {
      if (!this.getCalculatedPop) this.getplanogramPopMaterialList();
    }
  }

  async getplanogramPopMaterialList() {
    this.planogramPopMaterialListError = false;
    this.isLoading = true;
    //TODO: Buscar una mejor forma de identificar "cerveza"
    this.service.getplanogramPopMaterialList(this.promotionalActionId || null).subscribe(
      data => {
        // Process data to mark selected items
        this.planogramPopMaterialList = this.processSelectedItems(data) || [];
        this.planogramPopMaterialError.emit(this.planogramPopMaterialListError);

        let checkPlanogramIdExist = true;
        if (checkPlanogramIdExist && this.isEdit) this.checkPlanogramIdExist();

        this.planogramPopMaterialListEvent.emit(this.planogramPopMaterialList);
        this.isLoading = false;
      },
      err => {
        this.planogramPopMaterialList = [];
        this.planogramPopMaterialListError = true;
        this.planogramPopMaterialError.emit(this.planogramPopMaterialListError);
        this.planogramPopMaterialListEvent.emit(this.planogramPopMaterialList);
        this.isLoading = false;
      }
    );
  }

  getCalculatedPopMaterial() {
    if (this.isAllScopeItemsSelected) return;
    this.isLoading = true;
    this.service.getCalculatedPlanogramPopMaterialList(Number(this.actionPromotionTypeId), this.storeScope, this.itemScope).subscribe(
      data => {
        const cloneData = data.map(pop => ({
          ...pop,
          value: pop.fullName,
        }));

        // Process data to mark selected items
        this.planogramPopMaterialList = this.processSelectedItems(cloneData) || [];

        if (this.planogramPopMaterialIds?.length > 0) {
          this.resetSelectedItemsOnListChange();
        }

        this.planogramPopMaterialError.emit(this.planogramPopMaterialListError);

        let checkPlanogramIdExist = false;
        if (checkPlanogramIdExist && this.isEdit) this.checkPlanogramIdExist();

        this.planogramPopMaterialListEvent.emit(this.planogramPopMaterialList);
        this.isLoading = false;
      },
      err => {
        this.planogramPopMaterialList = [];
        this.planogramPopMaterialListError = true;
        this.planogramPopMaterialError.emit(this.planogramPopMaterialListError);
        this.planogramPopMaterialListEvent.emit(this.planogramPopMaterialList);
        this.isLoading = false;
      }
    );
  }

  processSelectedItems(data: PlanogramsPopMaterial[]) {
    if (!(this.planogramPopMaterialIds?.length > 0) || !(data?.length > 0)) return data;

    const processedData = data.map(item => ({
      ...item,
      selected: this.isItemSelected(item),
    }));

    // Actualizar selectedItemsInteralIds con los _internalIds de los items seleccionados
    this.selectedItemsInteralIds = processedData.filter(item => item.selected).map(item => item._internalId);
    return processedData;
  }

  isItemSelected(item: PlanogramsPopMaterial): boolean {
    if (!(item?.selectedGroupIds?.length > 0) || !(this.planogramPopMaterialIds?.length > 0)) return false;
    return item.selectedGroupIds.some(groupId => this.planogramPopMaterialIds.includes(groupId));
  }

  onSelectedChanges($event) {
    this.onChange.emit(this._getSelectedGropIds($event));
  }

  _getSelectedGropIds(_internalIds: string[]) {
    return this.planogramPopMaterialList
      .filter(item => _internalIds.includes(item._internalId))
      .map(item => item.selectedGroupIds)
      .flat();
  }
  get isCervezaType(): boolean {
    return Number(this.actionPromotionTypeId) === 2;
  }

  checkPlanogramIdExist() {
    if (this.planogramPopMaterialIds?.length) {
      let firstId = this.planogramPopMaterialIds[0];

      if (this.planogramPopMaterialList == null) {
        console.error('Error en la peticion de planogramas');
        return;
      }

      let obj = this.planogramPopMaterialList?.find(el => firstId != null && String(el.id) === String(firstId));

      if (obj == null) {
        console.error('planogramPopMaterialId no está en el planogramPopMaterialList', firstId);
      }
    }
  }

  resetSelectedItemsOnListChange() {
    const planogramIds = this.planogramPopMaterialList.map(objeto => objeto._internalId);
    this.deletedPlanogramsOnChange = this.selectedItemsInteralIds.filter(_internalId => !planogramIds.includes(_internalId));
    this.includedPlanogramsOnChange = this.selectedItemsInteralIds.filter(_internalId => planogramIds.includes(_internalId));
    if (this.deletedPlanogramsOnChange?.length > 0) this.onChange.emit(this._getSelectedGropIds(this.includedPlanogramsOnChange));
  }
}
